import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import Menu from '../Menu'
import UserMenu from './UserMenu'
import AnimationTools from './AnimationTools'
import DrawingTools from './DrawingTools'
import PurchaseDialog from '../PurchaseDialog'

import logo from '../../assets/img/white_logo.png'
import charactersActions from '../../redux/actions/characters'
import signpostsActions from '../../redux/actions/signposts'
import guideActions from '../../redux/actions/guide'
import animationToolsActions from '../../redux/actions/animationTools'
import authActions from '../../redux/actions/auth'
import collaborateActions from '../../redux/actions/collaborate'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    position: 'fixed',
    minWidth: '100%'
  },
  imageHeader: {
    height: 65
  },
  appBar: {
    backgroundColor: '#202b2e'
  }
}))

export default function Header() {
  const classes = useStyles()
  const { isAnimationToolsOpen } = useSelector((state) => state.animationTools)
  const { zoomLevel } = useSelector((state) => state.signposts)
  const { isDrawingToolsOpen } = useSelector((state) => state.drawingTools)
  const { readOnly, previewMode } = useSelector((state) => state.auth)
  const { present } = useSelector((state) => state.undo)
  const dispatch = useDispatch()

  const handleExitPreviewMode = () => {
    dispatch(authActions.resetPreviewMode())
    dispatch(charactersActions.loadCharacters(present.characters))
    dispatch(signpostsActions.updateSignpostOrder(present.signposts))
    dispatch(guideActions.setGraphTitle(present.title))
    dispatch(guideActions.setGraphNotes(present.personalNotes))
    dispatch(guideActions.setMultimediaSource(present.draw))
    dispatch(collaborateActions.getCollaborators(present.collaborate))

    const totalWidth = present.signposts.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.width * zoomLevel,
      0
    )

    dispatch(animationToolsActions.animateGraph(totalWidth))
  }

  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <a
            href="https://www.hartchart.com/"
            target="_blank"
            style={{ margin: '0 40px' }}
          >
            <img
              src={logo}
              alt="hartchart logo"
              className={classes.imageHeader}
            />
          </a>
          <Menu />
          {previewMode && (
            <Button
              onClick={handleExitPreviewMode}
              variant="contained"
              color="secondary"
            >
              Exit Preview Mode
            </Button>
          )}
          {isAnimationToolsOpen && <AnimationTools />}
          {!readOnly && isDrawingToolsOpen && <DrawingTools />}
          <UserMenu />
        </Toolbar>
      </AppBar>
      <PurchaseDialog />
    </>
  )
}
