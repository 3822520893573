// import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonGroup } from '@material-ui/core'
import { useSelector } from 'react-redux'

import SignpostMenu from './SignpostMenu'
import FileMenu from './FileMenu'
import EditMenu from './EditMenu'
import ViewMenu from './ViewMenu'
import CollaborateMenu from './CollaborateMenu'
import ZoomMenu from './ZoomMenu'

const useStyles = makeStyles(() => ({
  menu_items: {
    flexGrow: 1,
    height: 64
  },
  menu_container: {
    borderRight: 'none'
  }
}))

export default function Menu() {
  const classes = useStyles()
  const { readOnly } = useSelector((state) => state.auth)
  const { chartType } = useSelector((state) => state.app)

  return (
    <ButtonGroup
      disableElevation
      variant="text"
      color="primary"
      className={classes.menu_items}
      classes={{ groupedTextHorizontal: classes.menu_container }}
      size="small"
    >
      <FileMenu />
      <EditMenu key="editMenu" />,
      {!readOnly && <SignpostMenu key="signpostMenu" />}
      {chartType !== 'EPISODE' && <CollaborateMenu />}
      <ViewMenu />
      {!readOnly && <ZoomMenu />}
    </ButtonGroup>
  )
}
