import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'reactfire'
import { useParams } from 'react-router-dom'
import { doc, collection, addDoc, getDocs } from 'firebase/firestore'
import { Button, Grid, Typography, TextField } from '@material-ui/core'

import { Dialog, SubmitButton } from '../ui'
import fileActions from '../../redux/actions/file'
import appActions from '../../redux/actions/app'

const SaveAsDialog = () => {
  const { user } = useSelector((state) => state.auth)
  const { chartType, saveAsModalOpen } = useSelector((state) => state.app)
  const { title, draw, personalNotes, color, startSignpost, finishSignpost } =
    useSelector((state) => state.guide)
  const { signposts } = useSelector((state) => state.signposts)
  const { characters } = useSelector((state) => state.characters)
  const { id: chartId } = useParams()
  const [isDone, setIsDone] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [value, setValue] = useState(title)
  const dispatch = useDispatch()
  const firestore = useFirestore()

  useEffect(() => {
    setValue(title)
  }, [title])

  const createNewChart = async () => {
    try {
      setIsLoading(true)
      const userRef = doc(firestore, 'users', user.email)
      const chartData = {
        signposts,
        characters,
        draw,
        title: value,
        personalNotes,
        collaborate: [],
        links: [],
        userId: userRef,
        projectId: null
      }
      if (chartType === 'SERIES') {
        chartData.type = 'SERIES'
        const seriesChartCreated = await addDoc(
          collection(firestore, 'series'),
          chartData
        )
        const chartEpisodes = await getDocs(
          collection(firestore, `series/${chartId}/episodes`)
        )
        if (!chartEpisodes.empty) {
          const tempEpisodes = []
          chartEpisodes.forEach((item) => tempEpisodes.push(item.data()))
          const promises = tempEpisodes.map((episodeToCreate) =>
            addDoc(
              collection(firestore, `series/${seriesChartCreated.id}/episodes`),
              episodeToCreate
            )
          )
          await Promise.all(promises)
        }
      } else if (chartType === 'EPISODE') {
        chartData.type = 'EPISODE'
        chartData.color = color
        chartData.startSignpost = startSignpost
        chartData.finishSignpost = finishSignpost

        await addDoc(
          collection(firestore, `series/${chartId}/episodes`),
          chartData
        )
      } else {
        await addDoc(collection(firestore, 'charts'), chartData)
      }
      setIsLoading(false)
      setIsDone(true)
    } catch (err) {
      setIsLoading(false)
      setError('Something has go wrong, please try again.')
    }
  }

  const closeDialog = () => {
    setIsDone(false)
    setError(null)
    setIsLoading(false)
    dispatch(appActions.handleSaveAsDialog(false))
  }

  const openHartChart = () => {
    dispatch(fileActions.foldersOpen())
    closeDialog()
  }

  return (
    <Dialog
      open={saveAsModalOpen}
      onClose={closeDialog}
      title="Save as"
      maxWidth="sm"
      closable
      divider
      dragglable
      fullWidth
      actions={
        <>
          <Button variant="outlined" onClick={closeDialog}>
            Close
          </Button>
          {isDone ? (
            <Button variant="contained" onClick={openHartChart}>
              Open your files
            </Button>
          ) : (
            <SubmitButton
              isLoading={isLoading}
              onClick={() => createNewChart(true)}
              text="Save"
              variant="contained"
              color="primary"
              style={{ marginLeft: 12 }}
            />
          )}
        </>
      }
    >
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 16, marginBottom: 16 }}
        >
          <TextField
            variant="outlined"
            label="Name"
            fullWidth
            value={value}
            disabled={isLoading}
            onChange={(e) => setValue(e.target.value)}
          />
        </Grid>
        {isDone && (
          <Grid item xs={12}>
            <Typography color="primary" align="center">
              Your chart has been saved.
            </Typography>
          </Grid>
        )}
        {error && (
          <Grid item xs={12}>
            <Typography color="error" align="center">
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

export default SaveAsDialog
