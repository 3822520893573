const config = {
  dev: 'http://localhost:3000',
  prod: 'https://app.hartchart.com',
  testAccount: {
    products: {
      monthlyProduct: 'price_1Kb6FzHPlX9jppu04emiEL6M',
      yearProduct: 'price_1Kb6GwHPlX9jppu0BhteM9Cy'
    }
  },
  productionAccount: {
    testModeProducts: {
      monthlyProduct: 'price_1KcC23DxsLM0HndcAmZggEXG',
      yearProduct: 'price_1KcC3WDxsLM0Hndcgikzz24P'
    },
    prodProducts: {
      monthlyProduct: 'price_1Jz8h7DxsLM0Hndce06Fl1U3',
      yearProduct: 'price_1Jz8hyDxsLM0Hndc9r4vLuQP'
    }
  }
}

export default config
