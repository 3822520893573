export const threeQuestions = [
  {
    title:
      'Who is/are the main character(s) and what do they want? (What they need is different; see below.)',
    fieldName: 'q1',
    videoUrl: 'https://media.writerduet.com/videos/hartchart/want.mp4'
  },
  {
    title:
      "What are the characters' relationships like and what are the obstacles the main characters must encounter and overcome in order to get what they want?",
    fieldName: 'q2',
    videoUrl:
      'https://media.writerduet.com/videos/hartchart/relationships%20and%20obsticles.mp4'
  },
  {
    title:
      'In the end, do the main characters get what they want? Is it good or bad for them if they did or did not? (Related: did they get what they need?)',
    fieldName: 'q3',
    videoUrl:
      'https://media.writerduet.com/videos/hartchart/get%20what%20they%20want%20or%20no.mp4'
  }
]

export const bonusQuestions = [
  {
    title: `Why does the audience care? What makes us want to watch the characters' journey?`,
    fieldName: 'q4',
    videoUrl:
      'https://media.writerduet.com/videos/hartchart/why%20do%20we%20care.mp4'
  },
  {
    title: `What do my main characters need? How does this differ from what they want when we meet them?

      HINT: Want = material things, ego-driven desires
      Need = inner desires and conflicts, spiritual`,
    fieldName: 'q5',
    videoUrl:
      'https://media.writerduet.com/videos/hartchart/characters%20need.mp4'
  },
  {
    title: `Why now? Why is this happening to the characters at this moment? What event, what conditions exist today for this story to take place? Why not yesterday or next year?`,
    fieldName: 'q6',
    videoUrl: 'https://media.writerduet.com/videos/hartchart/why%20now_1.mp4'
  },
  {
    title: `What are the main characters' wounds/aches? What are the flaws of the main characters?`,
    fieldName: 'q7',
    videoUrl:
      'https://media.writerduet.com/videos/hartchart/what%20is%20main%20char%20hidden%20wound.mp4'
  },
  {
    title: `What is the jeopardy? The danger? The price?`,
    fieldName: 'q8',
    videoUrl:
      'https://media.writerduet.com/videos/hartchart/what%20is%20main%20char%20hidden%20wound.mp4'
  },
  {
    title: `What do the characters fear most? What shuts them down emotionally? What breaks them into a sweat?`,
    fieldName: 'q9',
    videoUrl: 'https://media.writerduet.com/videos/hartchart/fear.mp4'
  },
  {
    title: `Do you have a satisfying ending? Not happy, not sad, etc. but a satisfying ending.`,
    fieldName: 'q10',
    videoUrl:
      'https://media.writerduet.com/videos/hartchart/do%20i%20have%20a%20satesfying%20ending%20not%20good%20not%20bad.mp4'
  }
]

export const userBaseSchema = {
  q1: '',
  q2: '',
  q3: '',
  q4: '',
  q5: '',
  q6: '',
  q7: '',
  q8: '',
  q9: '',
  q10: ''
}
