import { useState } from 'react'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  FormControl,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useFirestore } from 'reactfire'
import { doc, updateDoc, collection, addDoc } from 'firebase/firestore'
import { useFormik } from 'formik'
import * as yup from 'yup'

import collaborateActions from '../../redux/actions/collaborate'
import { SubmitButton } from '../ui'
import shareTemplate from '../../templates/shareTemplate'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    padding: '10px',
    background: '#005669',
    color: 'white'
  },
  closeBotton: {
    padding: '0px',
    marginLeft: 'auto',
    color: 'white'
  },
  Checkboxs: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footer: {
    display: 'flex',
    gap: '5px',
    padding: '10px'
  }
}))

const validationSchema = yup.object({
  emails: yup
    .array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value
      }
      return originalValue ? originalValue.split(/[\s,]+/) : []
    })
    .of(yup.string().email(({ value }) => `${value} is not a valid email`))
})

const EmailShare = () => {
  const classes = useStyles()
  const { collaborators } = useSelector((state) => state.collaborate)
  const { user } = useSelector((state) => state.auth)
  const { chartType } = useSelector((state) => state.app)
  const [isLoading, setIsloading] = useState(false)
  const [error, setError] = useState(null)
  const { id } = useParams()
  const firestore = useFirestore()
  const dispatch = useDispatch()

  const getChartRef = () => {
    let chartRef
    if (chartType === 'SERIES') {
      chartRef = doc(firestore, `series/${id}`)
    } else {
      chartRef = doc(firestore, `charts/${id}`)
    }
    return chartRef
  }

  const closeModal = () => {
    dispatch(collaborateActions.shareChartClose())
  }

  const formik = useFormik({
    initialValues: {
      emails: '',
      message: '',
      isAdminAccess: false,
      isReadOnlyAccess: false
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsloading(true)
        const allEmails = values.emails
          .split(',')
          .map((item) => item.trim())
          .filter((item) => item)

        const collaborate = allEmails.map((item) => ({
          email: item.trim(),
          isAdminAccess: values.isAdminAccess,
          isReadOnlyAccess: values.isReadOnlyAccess
        }))
        await updateDoc(getChartRef(), {
          collaborate: [...collaborate, ...collaborators]
        })
        await addDoc(collection(firestore, 'mail'), {
          bcc: allEmails,
          from: 'HartChart <noreply.hartchart@gmail.com>',
          message: {
            subject: `${user.name} has shared a chart with you!`,
            html: shareTemplate(id, user.name, values.message, chartType)
          }
        })
        setIsloading(false)
        formik.resetForm()
        closeModal()
      } catch (err) {
        setIsloading(false)
        setError(err.message)
      }
    }
  })

  return (
    <>
      <Typography>
        Add a collaborator. We'll send them a private link to edit the chart
        with you.
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          autoFocus
          required
          margin="dense"
          id="emails"
          label="E-mail, comma-separated for multiple"
          type="text"
          fullWidth
          variant="standard"
          value={formik.values.emails}
          onChange={formik.handleChange}
          error={formik.touched.emails && Boolean(formik.errors.emails)}
          helperText={formik.touched.emails && formik.errors.emails}
        />
        <TextField
          label="Message (optional)"
          margin="dense"
          type="text"
          id="message"
          multiline
          rows={4}
          fullWidth
          variant="standard"
          value={formik.values.message}
          onChange={formik.handleChange}
          error={formik.touched.message && Boolean(formik.errors.message)}
          helperText={formik.touched.message && formik.errors.message}
        />
        <div className={classes.Checkboxs}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isAdminAccess}
                  onChange={formik.handleChange}
                  name="isAdminAccess"
                  color="primary"
                />
              }
              label="Let this collaborator setup/modify collaborators (admin access)"
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.isReadOnlyAccess}
                  onChange={formik.handleChange}
                  name="isReadOnlyAccess"
                  color="primary"
                />
              }
              label="Only let this collaborator view, not edit, the chart (read-only access)"
            />
          </FormControl>
        </div>
        <div className={classes.buttons}>
          <SubmitButton
            isLoading={isLoading}
            type="submit"
            text="Add collaborator"
            color="primary"
            variant="contained"
          />
        </div>
      </form>
      {error && (
        <Grid item xs={12}>
          <Typography align="center" color="error">
            {error}
          </Typography>
        </Grid>
      )}
    </>
  )
}

export default EmailShare
