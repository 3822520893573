import { episodesTypes } from '../types'

const selectCharacter = (character) => (dispatch) => {
  dispatch({
    type: episodesTypes.SELECT_EPISODE,
    payload: character
  })
}

const createCharacter = (data) => (dispatch) => {
  dispatch({ type: episodesTypes.ADD_EPISODE, payload: data })
}

const removeCharacter = (id) => (dispatch) => {
  dispatch({
    type: episodesTypes.REMOVE_EPISODE,
    payload: id
  })
}

const changeCharacterColor = (startIndex, color) => (dispatch) => {
  dispatch({
    type: episodesTypes.CHANGE_EPISODE_COLOR,
    payload: {
      startIndex,
      color
    }
  })
}

const changeCharacterName = (startIndex, name) => (dispatch) => {
  dispatch({
    type: episodesTypes.CHANGE_EPISODE_NAME,
    payload: {
      startIndex,
      name
    }
  })
}

const setAllSeasonView = (payload) => (dispatch) => {
  dispatch({
    type: episodesTypes.FULL_SEASON_VIEW,
    payload
  })
}

const setShowCreateModal = (modalShow, data) => (dispatch) => {
  dispatch({
    type: episodesTypes.SHOW_MODAL,
    payload: {
      modalShow,
      data
    }
  })
}

const setShowConfigModal = (payload) => (dispatch) => {
  dispatch({
    type: episodesTypes.SHOW_CONFIG_MODAL,
    payload
  })
}

export default {
  setShowConfigModal,
  setShowCreateModal,
  setAllSeasonView,
  createCharacter,
  removeCharacter,
  changeCharacterName,
  changeCharacterColor,
  selectCharacter
}
