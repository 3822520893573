import { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  IconButton,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFirestore } from 'reactfire'
import { getDoc, doc } from 'firebase/firestore'

import { SubmitButton } from '../ui'
import collaborateActions from '../../redux/actions/collaborate'
import authActions from '../../redux/actions/auth'

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    padding: '10px',
    background: '#005669',
    color: 'white'
  },
  closeBotton: {
    padding: '0px',
    marginLeft: 'auto',
    color: 'white'
  },
  buttons: {
    display: 'flex',
    margin: '0 auto'
  }
}))

const JoinChart = () => {
  const { isOpenModalJoinChart } = useSelector((state) => state.collaborate)
  const [url, setUrl] = useState('')
  const [error, setError] = useState('')
  const [isLoading, setIsloading] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const history = useHistory()

  const classes = useStyles()

  const valueURL = (e) => {
    setUrl(e.target.value)
  }

  const closeModal = () => {
    dispatch(collaborateActions.joinChartClose())
  }

  const send = async () => {
    try {
      setIsloading(true)
      const isSeries = url.includes('series')
      const isShareLink = url.includes('share')
      const chartUrl = url.split('/')
      const chartId = chartUrl.at(-1)
      const chartRef = doc(
        firestore,
        isSeries ? `/series/${chartId}` : `/charts/${chartId}`
      )
      const data = await getDoc(chartRef)
      if (isShareLink) {
        window.location.replace(url)
      }
      if (data.exists()) {
        const chartData = data.data()
        const invitedUser = chartData.collaborate.find(
          (item) => item.email === user.email
        )
        if (!invitedUser) {
          setIsloading(false)
          setError('You are not invited to this chart')
        } else {
          setIsloading(false)
          closeModal()
          dispatch(authActions.resetGraphStatus())
          history.push(`/app/${chartId}`)
        }
      } else {
        setIsloading(false)
        setError('This chart does not exist')
      }
    } catch (err) {
      setIsloading(false)
      setError(err.message)
    }
  }

  return (
    <Dialog
      open={isOpenModalJoinChart}
      onClose={closeModal}
      fullWidth={true}
      maxWidth="sm"
    >
      <div className={classes.header}>
        Join another script
        <IconButton className={classes.closeBotton} onClick={closeModal}>
          <Close />
        </IconButton>
      </div>
      <DialogContent>
        <TextField
          value={url}
          onChange={valueURL}
          autoFocus
          margin="dense"
          id="name"
          label="Enter your private collaboration URL, or go to the link address in a browser"
          fullWidth
          variant="standard"
        />
        {error && (
          <Typography align="center" color="error">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <div className={classes.buttons}>
          <SubmitButton
            isLoading={isLoading}
            onClick={send}
            text="Collaborate"
            variant="contained"
            color="primary"
          />
        </div>
      </DialogActions>
    </Dialog>
  )
}

export default JoinChart
