import { useState } from 'react'
import { Typography, Box } from '@material-ui/core'
import { Videocam } from '@material-ui/icons'

const ParagraphTitle = ({ children, video, align, variant, ...rest }) => {
  const [videoOpen, setVideoOpen] = useState(false)

  return (
    <>
      <Typography
        variant={variant}
        align={align}
        paragraph
        style={{ fontWeight: 600 }}
        {...rest}
      >
        {video && (
          <Videocam
            onClick={() => setVideoOpen((prev) => !prev)}
            style={{ cursor: 'pointer' }}
          />
        )}
        {'  '}
        {children}
      </Typography>
      {videoOpen && (
        <Box
          style={{
            marginTop: 12,
            height: 300,
            width: '100%'
          }}
        >
          <video
            controls
            preload="none"
            style={{ width: '100%', height: '100%' }}
          >
            <source
              src="https://media.writerduet.com/videos/hartchart/questions%20to%20answer%20for%20character%20driven%20narrative.mp4"
              type="video/mp4"
            />
          </video>
        </Box>
      )}
    </>
  )
}

export default ParagraphTitle
