import { Switch, Route, Redirect } from 'react-router-dom'

import ProtectedRoute from '../components/ProtectedRoute'
import {
  Main,
  Login,
  Welcome,
  ShareLink,
  MainSeries,
  MainEpisodes
} from '../pages'

function Routes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <ProtectedRoute exact path="/app/:id" component={Main} />
      <ProtectedRoute exact path="/series/:id" component={MainSeries} />
      <ProtectedRoute
        exact
        path="/series/:id/episode/:episodeId"
        component={MainEpisodes}
      />
      <ProtectedRoute exact path="/welcome" component={Welcome} />
      <ProtectedRoute
        exact
        path="/share/:id/:chartType"
        component={ShareLink}
      />
      <Route path="*">
        <Redirect to="/welcome" />
      </Route>
    </Switch>
  )
}

export default Routes
