import PropTypes from 'prop-types'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Divider,
  Paper
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Draggable from 'react-draggable'

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      bounds="body"
    >
      <Paper {...props} />
    </Draggable>
  )
}

/**
 * Dialog customizado
 */

const CustomDialog = ({
  open,
  onClose,
  maxWidth,
  title,
  closable,
  divider,
  actions,
  fullWidth,
  children,
  dragglable,
  dialogContentProps,
  ...props
}) => (
  <Dialog
    maxWidth={maxWidth}
    fullWidth={fullWidth}
    onClose={onClose}
    open={open}
    PaperComponent={dragglable ? PaperComponent : Paper}
    {...props}
  >
    {(title || closable) && (
      <DialogTitle
        id="draggable-dialog-title"
        onClose={onClose}
        style={{
          float: 'right',
          padding: '0 24px',
          backgroundColor: '#005669',
          color: 'white',
          cursor: dragglable ? 'move' : null
        }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography>{title}</Typography>
          {closable && (
            <IconButton
              aria-label="close"
              onClick={onClose}
              style={{ float: 'right', padding: 5, color: 'white' }}
            >
              <Close />
            </IconButton>
          )}
        </Grid>
      </DialogTitle>
    )}
    {divider && title && (
      <Grid item style={{ width: '100%' }}>
        <Divider />
      </Grid>
    )}
    {children && (
      <DialogContent {...dialogContentProps}>{children}</DialogContent>
    )}
    {divider && actions && (
      <Grid item style={{ width: '100%' }}>
        <Divider />
      </Grid>
    )}
    {actions && <DialogActions>{actions}</DialogActions>}
  </Dialog>
)

CustomDialog.defaultProps = {
  open: false,
  maxWidth: 'md',
  fullWidth: false
}

CustomDialog.propTypes = {
  /** Permite abrir el dialog */
  open: PropTypes.bool,
  /** Función para cerrar el dialog */
  onClose: PropTypes.func.isRequired,
  /** Máximo tamaño del dialog */
  maxWidth: PropTypes.string,
  /** Título */
  title: PropTypes.string,
  /** Añade ícono para cerrar el dialog */
  closable: PropTypes.bool,
  /** Acciones al pie del dialog */
  actions: PropTypes.element,
  /** Ocupa todo el ancho disponible */
  fullWidth: PropTypes.bool
}

export default CustomDialog
