import { Button, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  iconMargin: {
    marginRight: 4
  }
}))

/**
 * Botón de submit para usar en formularios
 */

const SubmitButton = ({
  text,
  color,
  variant,
  disabled,
  fullWidth,
  onClick,
  style,
  isLoading,
  type,
  ...props
}) => {
  const classes = useStyles()

  return (
    <Button
      disabled={isLoading ? true : disabled}
      onClick={onClick}
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      style={style}
      type={type}
      {...props}
    >
      {isLoading && (
        <CircularProgress
          size={24}
          color="inherit"
          className={classes.iconMargin}
        />
      )}
      {text}
    </Button>
  )
}

SubmitButton.defaultProps = {
  text: '',
  color: 'primary',
  disabled: false,
  fullWidth: false,
  onClick: () => false,
  isLoading: false,
  type: 'button'
}

SubmitButton.propTypes = {
  /**
   * {String} - Texto del botón
   */
  text: PropTypes.string.isRequired,
  /**
   * {String} - Color del botón
   */
  color: PropTypes.string,
  /**
   * {String} - Variante del botón
   */
  variant: PropTypes.string,
  /**
   * {String} - Deshabilita el botón
   */
  disabled: PropTypes.bool,
  /**
   * {String} - Acción a ejecutar en onClick
   */
  action: PropTypes.func,
  /**
   * {String} - Ocupa todo el ancho del contenedor
   */
  fullWidth: PropTypes.bool,
  /**
   * {String} - Reemplaza la función onClick del botón
   */
  onClick: PropTypes.func,
  /**
   * {String} - Estilos custom para el botón
   */
  style: PropTypes.object,
  /**
   * {String} - Muestra ícono de carga
   */
  isLoading: PropTypes.bool,
  /** {String} - Tipo de botón a utilizar */
  type: PropTypes.oneOf(['button', 'submit', 'reset'])
}

export default SubmitButton
