import { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useParams } from 'react-router-dom'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import { doc, updateDoc, collection, orderBy, query } from 'firebase/firestore'

import guideActions from '../../redux/actions/guide'
import { Dialog } from '../ui'
import TemplatePDF from './TemplatePDF'
import ParagraphForm from './ParagraphForm'

const EpisodesDialog = () => {
  const { isEpisodeModalOpen, title } = useSelector((state) => state.guide)
  const [formValues, setFormValues] = useState({})
  const [renderDownload, setRenderDownload] = useState(false)
  const firestore = useFirestore()
  const { id: chartId } = useParams()
  const dispatch = useDispatch()

  const episodesRef = collection(firestore, 'series', chartId, 'episodes')
  const orderByStartQuery = query(episodesRef, orderBy('startSignpost'))

  const { data } = useFirestoreCollectionData(orderByStartQuery, {
    idField: 'id'
  })

  useEffect(() => {
    if (data) {
      const answersObject = {}
      data.forEach((item) => {
        answersObject[item.id] = item.description
      })
      setFormValues(answersObject)
    }
  }, [data])

  const updateDb = async (event) => {
    const id = event.target.name
    const chartRef = doc(firestore, `series/${chartId}/episodes/${id}`)

    await updateDoc(chartRef, { description: formValues[id] })
  }

  const handleClose = () => {
    dispatch(guideActions.showEpidodesModal(false))
  }

  const handleChange = (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value
    }))
  }

  return (
    <Dialog
      open={isEpisodeModalOpen}
      onClose={handleClose}
      title="Episodes: write what they are about!"
      maxWidth="sm"
      closable
      divider
      dragglable
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body2" align="justify" paragraph>
            Here you can write a summary about your episodes.
          </Typography>
        </Grid>

        <Grid item container justifyContent="flex-end" xs={12}>
          {renderDownload ? (
            <PDFDownloadLink
              document={<TemplatePDF title={title} episodes={data} />}
              fileName={`${title || 'untitled'} - episodes`}
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) => (
                <Button
                  variant="outlined"
                  onClick={() => setRenderDownload(false)}
                >
                  {loading ? 'Loading document...' : 'Download now!'}
                </Button>
              )}
            </PDFDownloadLink>
          ) : (
            <Button variant="outlined" onClick={() => setRenderDownload(true)}>
              Generate document
            </Button>
          )}
        </Grid>

        {data &&
          data.map((ep) => (
            <Grid item xs={12} key={ep.id}>
              <ParagraphForm
                episodeData={ep}
                formValues={formValues}
                handleChange={handleChange}
                handleBlur={updateDb}
              />
            </Grid>
          ))}
        <Grid item xs={12} container justifyContent="center">
          {renderDownload ? (
            <PDFDownloadLink
              document={<TemplatePDF title={title} episodes={data} />}
              fileName={`${title || 'untitled'} - episodes`}
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) => (
                <Button
                  variant="outlined"
                  onClick={() => setRenderDownload(false)}
                >
                  {loading ? 'Loading document...' : 'Download now!'}
                </Button>
              )}
            </PDFDownloadLink>
          ) : (
            <Button variant="outlined" onClick={() => setRenderDownload(true)}>
              Generate document
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default EpisodesDialog
