import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import ReduxThunk from 'redux-thunk'
import app from './reducers/app'
import auth from './reducers/auth'
import guide from './reducers/guide'
import signposts from './reducers/signposts'
import characters from './reducers/characters'
import animationTools from './reducers/animationTools'
import drawingTools from './reducers/drawingTools'
import dashboard from './reducers/dashboard'
import purchase from './reducers/purchase'
import collaborate from './reducers/collaborate'
import file from './reducers/file'
import undo from './reducers/undo'
import episodes from './reducers/episodes'

const createRootReducer = combineReducers({
  app,
  auth,
  guide,
  signposts,
  characters,
  animationTools,
  drawingTools,
  dashboard,
  collaborate,
  file,
  purchase,
  undo,
  episodes
})

// * -------- Estado inicial de la aplicación
const initialState = {}

// * -------- Middlewares
const middlewares = [ReduxThunk]

// * -------- Enhancers del store --------
const enhancers = []

// * -------- Componer Middlewares y Enhancers --------
let composedEnhancers = null
if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
  const composeEnhancers = composeWithDevTools({ trace: true })
  composedEnhancers = composeEnhancers(
    applyMiddleware(...middlewares),
    ...enhancers
  )
} else {
  composedEnhancers = compose(applyMiddleware(...middlewares), ...enhancers)
}

const store = createStore(createRootReducer, initialState, composedEnhancers)

export default store
