import { useDispatch, useSelector } from 'react-redux'
import { Tooltip, IconButton, InputBase, useTheme } from '@material-ui/core'
import { Create, OpenInNew } from '@material-ui/icons'
import { useParams, useHistory } from 'react-router-dom'
import { Resizable } from 're-resizable'
import { useFirestore } from 'reactfire'
import { doc, updateDoc } from 'firebase/firestore'

import EpisodesSignpostColums from './EpisodesSignpostColums'
import SvgGraph from './SvgGraph'
import guideActions from '../../redux/actions/guide'

export default function EpisodeMiniature({ episodeData, size }) {
  const { signposts, zoomLevel } = useSelector((state) => state.signposts)
  const { readOnly } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { id: chartId } = useParams()
  const history = useHistory()
  const firestore = useFirestore()
  const theme = useTheme()

  const changeEpisodeTitle = async (newName, id) => {
    try {
      const docuRef = doc(firestore, `series/${chartId}/episodes/${id}`)
      await updateDoc(docuRef, { title: newName })
    } catch (err) {
      console.log(err, 'error al cambiar nombre del episodio')
    }
  }

  const calculateWidth = () => {
    const totalWidth = signposts.reduce((acum, current, index) => {
      if (
        index >= episodeData.startSignpost &&
        index <= episodeData.finishSignpost
      ) {
        return acum + current.width * zoomLevel
      }
      return acum
    }, 0)
    return totalWidth
  }

  const calculateTranslate = () => {
    const totalWidth = signposts.reduce((acum, current, index) => {
      if (index < episodeData.startSignpost) {
        return acum + current.width * zoomLevel
      }
      return acum
    }, 0)
    return totalWidth
  }

  const handleSignpostResize = (e, direction, ref) => {
    const signpostCount = {
      prev: 0,
      current: 0
    }

    const episodePosition = calculateTranslate() + ref.offsetWidth

    let finalIndex = episodeData.finishSignpost
    for (let i = 0; i < signposts.length; i += 1) {
      const item = signposts[i]
      if (i === 0) {
        signpostCount.prev = 0
        signpostCount.current = item.width * zoomLevel
      } else {
        signpostCount.prev = signpostCount.current
        signpostCount.current += item.width * zoomLevel
      }
      if (
        episodePosition > signpostCount.prev &&
        episodePosition <= signpostCount.current
      ) {
        finalIndex = i
      }
    }

    const docuRef = doc(
      firestore,
      `series/${chartId}/episodes/${episodeData.id}`
    )
    updateDoc(docuRef, { finishSignpost: finalIndex })
  }

  return (
    <Resizable
      size={{ width: calculateWidth(), height: '100%' }}
      enable={{
        right: true
      }}
      style={{
        position: 'absolute',
        transform: `translateX(${calculateTranslate()}px)`
      }}
      onResizeStop={handleSignpostResize}
      minWidth={signposts[episodeData.startSignpost].width * zoomLevel}
      handleStyles={{
        right: {
          right: 0,
          borderRight: `6px solid ${episodeData.color}`
        }
      }}
    >
      <div
        style={{
          height: '100%',
          borderTop: '1px solid gray',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          borderLeft: `6px solid ${episodeData.color}`
        }}
      >
        <div
          style={{
            width: '100%',
            backgroundColor: episodeData.color,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 25
          }}
        >
          <Tooltip
            arrow
            placement="top"
            title={episodeData.description || 'Describe your story...'}
          >
            <IconButton
              onClick={() => dispatch(guideActions.showEpidodesModal(true))}
            >
              <Create
                style={{
                  fontSize: 18,
                  color: theme.palette.getContrastText(episodeData.color)
                }}
              />
            </IconButton>
          </Tooltip>
          <InputBase
            defaultValue={episodeData.title}
            placeholder="Unnamed"
            fullWidth
            onBlur={(e) => changeEpisodeTitle(e.target.value, episodeData.id)}
            disabled={readOnly}
            inputProps={{
              style: {
                textAlign: 'center',
                color: '#fff',
                fontSize: 14,
                fontWeight: 'bold',
                padding: 1,
                width: '100%'
              }
            }}
          />

          <Tooltip arrow placement="top" title="Open chart">
            <IconButton
              onClick={() =>
                history.push(`/series/${chartId}/episode/${episodeData.id}`)
              }
            >
              <OpenInNew
                style={{
                  fontSize: 18,
                  color: theme.palette.getContrastText(episodeData.color)
                }}
              />
            </IconButton>
          </Tooltip>
        </div>
        <div
          style={{
            position: 'relative',
            overflow: 'hidden',
            display: 'flex',
            width: '100%',
            height: '100%',
            flexDirection: 'column'
          }}
        >
          <EpisodesSignpostColums episodeData={episodeData} />
          <SvgGraph episodeData={episodeData} size={size} />
        </div>
      </div>
    </Resizable>
  )
}
