import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  InputBase,
  IconButton,
  List,
  ListItem,
  Box,
  ListItemSecondaryAction,
  ListItemText,
  ClickAwayListener,
  Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { useSelector, useDispatch } from 'react-redux'
import { nanoid } from 'nanoid'
import { useFirestore } from 'reactfire'
import {
  doc,
  updateDoc,
  arrayUnion,
  getDocs,
  getDoc,
  collection
} from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash'

import ColorPicker from './ColorPicker'
import charactersActions from '../../redux/actions/characters'
import generateRandomColor from '../../utils/randomColor'
import { userBaseSchema } from '../../utils/defaultCoreQuestions'
import useAddTrackingData from '../../hooks/useAddTrackingData'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
    height: 45,
    backgroundColor: '#fff'
  },
  selectedCharacter: {
    backgroundColor: '#ffffff66 !important'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 5,
    borderRadius: 0,
    backgroundColor: '#cbcbcb',
    height: 45,
    color: '#000',
    borderLeft: '1px solid black',
    '&:hover': {
      backgroundColor: '#939393'
    }
  },
  color_picker: {
    marginLeft: 5,
    border: '1px solid black'
  }
}))

export default function CharacterInput() {
  const [inputValue, setInputValue] = useState({
    name: '',
    color: '#000000',
    colorChanged: false
  })
  const [isRemane, setIsRename] = useState({})
  const { readOnly } = useSelector((state) => state.auth)
  const { selectedCharacter, characters } = useSelector(
    (state) => state.characters
  )
  const { draw } = useSelector((state) => state.guide)
  const { chartType } = useSelector((state) => state.app)
  const classes = useStyles()
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const { id: chartId, episodeId } = useParams()
  const updateTrackingData = useAddTrackingData()

  const addCharacter = async () => {
    const newCharacter = {
      id: nanoid(),
      name: inputValue.name,
      color: inputValue.colorChanged ? inputValue.color : generateRandomColor(),
      colorOpacity: 1,
      descriptionCQ: userBaseSchema,
      points: []
    }

    if (chartType === 'EPISODE') {
      const doctRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
      await updateDoc(doctRef, {
        characters: arrayUnion({ ...newCharacter, scope: 'EPISODE' })
      })
      updateTrackingData({
        characters: [...characters, { ...newCharacter, scope: 'EPISODE' }],
        action: `Created a new character: ${newCharacter.name}`
      })
    }

    if (chartType === 'MOVIES') {
      const doctRef = doc(firestore, `charts/${chartId}`)
      await updateDoc(doctRef, {
        characters: arrayUnion(newCharacter)
      })
      updateTrackingData({
        characters: [...characters, newCharacter],
        action: `Created a new character: ${newCharacter.name}`
      })
    }

    if (chartType === 'SERIES') {
      const doctRef = doc(firestore, `series/${chartId}`)
      const episodesRef = collection(firestore, 'series', chartId, 'episodes')
      const querySnapshot = await getDocs(episodesRef)
      const trackingPromises = []
      const promises = querySnapshot.docs.map((docItem) => {
        const docRef = doc(
          firestore,
          `series/${chartId}/episodes/${docItem.id}`
        )
        const docData = docItem.data()

        trackingPromises.push(
          updateTrackingData({
            characters: [
              ...docData.characters,
              { ...newCharacter, scope: 'SERIES' }
            ],
            action: `Created a new character: ${newCharacter.name}`
          })
        )

        return updateDoc(docRef, {
          characters: arrayUnion({ ...newCharacter, scope: 'SERIES' })
        })
      })

      promises.push(
        updateDoc(doctRef, {
          characters: arrayUnion({ ...newCharacter, scope: 'SERIES' })
        })
      )

      await Promise.all([...promises, trackingPromises])
      updateTrackingData({
        characters: [...characters, { ...newCharacter, scope: 'SERIES' }],
        action: `Created a new character: ${newCharacter.name}`
      })
    }

    dispatch(charactersActions.selectCharacter(newCharacter))
    setInputValue({ name: '', color: '#000000' })
  }

  const activateRename = (e, id) => {
    e.stopPropagation()
    setIsRename({ [id]: true })
  }

  const handleClickAway = () => {
    setIsRename({})
  }

  const changeCharacterName = debounce(
    async (newName, index, { id, scope }) => {
      dispatch(charactersActions.changeCharacterName(index, newName))

      const filtered = characters.map((character) =>
        character.id === id ? { ...character, name: newName } : character
      )

      if (chartType === 'MOVIES') {
        const doctRef = doc(firestore, `charts/${chartId}`)
        updateDoc(doctRef, {
          characters: filtered
        })
        return undefined
      }

      if (scope === 'SERIES') {
        // Update all characters in other episodes
        const episodesRef = collection(firestore, 'series', chartId, 'episodes')
        const querySnapshot = await getDocs(episodesRef)
        const promises = querySnapshot.docs.map((docItem) => {
          const docRef = doc(
            firestore,
            `series/${chartId}/episodes/${docItem.id}`
          )
          const documentData = docItem.data()
          const filteredEpisodesCharacters = documentData.characters.map(
            (character) =>
              character.id === id ? { ...character, name: newName } : character
          )

          return updateDoc(docRef, {
            characters: filteredEpisodesCharacters
          })
        })

        // Update character in Series chart
        const seriesChartRef = doc(firestore, `series/${chartId}`)
        const data = await getDoc(seriesChartRef)
        const documentData = data.data()
        const filteredSeriesCharacters = documentData.characters.map(
          (character) =>
            character.id === id ? { ...character, name: newName } : character
        )

        promises.push(
          updateDoc(seriesChartRef, {
            characters: filteredSeriesCharacters
          })
        )

        await Promise.all(promises)
      } else {
        const doctRef = doc(
          firestore,
          `series/${chartId}/episodes/${episodeId}`
        )
        await updateDoc(doctRef, {
          characters: filtered
        })
      }
      return undefined
    },
    400
  )

  const changeCharacterColor = debounce(
    async (newColor, index, { id, scope }) => {
      dispatch(charactersActions.changeCharacterColor(index, newColor))

      const filtered = characters.map((character) =>
        character.id === id ? { ...character, color: newColor } : character
      )

      if (chartType === 'MOVIES') {
        const doctRef = doc(firestore, `charts/${chartId}`)
        updateDoc(doctRef, {
          characters: filtered
        })
        return undefined
      }

      if (scope === 'SERIES') {
        // Update all characters in other episodes
        const episodesRef = collection(firestore, 'series', chartId, 'episodes')
        const querySnapshot = await getDocs(episodesRef)
        const promises = querySnapshot.docs.map((docItem) => {
          const docRef = doc(
            firestore,
            `series/${chartId}/episodes/${docItem.id}`
          )
          const documentData = docItem.data()
          const filteredEpisodesCharacters = documentData.characters.map(
            (character) =>
              character.id === id
                ? { ...character, color: newColor }
                : character
          )

          return updateDoc(docRef, {
            characters: filteredEpisodesCharacters
          })
        })

        // Update character in Series chart
        const seriesChartRef = doc(firestore, `series/${chartId}`)
        const data = await getDoc(seriesChartRef)
        const documentData = data.data()
        const filteredSeriesCharacters = documentData.characters.map(
          (character) =>
            character.id === id ? { ...character, color: newColor } : character
        )

        promises.push(
          updateDoc(seriesChartRef, {
            characters: filteredSeriesCharacters
          })
        )

        await Promise.all(promises)
      } else {
        const doctRef = doc(
          firestore,
          `series/${chartId}/episodes/${episodeId}`
        )
        await updateDoc(doctRef, {
          characters: filtered
        })
      }
      return undefined
    },
    400
  )

  const handleOpacity = debounce(async (opacity, index, { id, scope }) => {
    dispatch(charactersActions.changeCharacterOpacity(index, opacity))

    const filtered = characters.map((character) =>
      character.id === id ? { ...character, colorOpacity: opacity } : character
    )

    if (chartType === 'MOVIES') {
      const doctRef = doc(firestore, `charts/${chartId}`)
      updateDoc(doctRef, {
        characters: filtered
      })
      return undefined
    }

    if (scope === 'SERIES') {
      // Update all characters in other episodes
      const episodesRef = collection(firestore, 'series', chartId, 'episodes')
      const querySnapshot = await getDocs(episodesRef)
      const promises = querySnapshot.docs.map((docItem) => {
        const docRef = doc(
          firestore,
          `series/${chartId}/episodes/${docItem.id}`
        )
        const documentData = docItem.data()
        const filteredEpisodesCharacters = documentData.characters.map(
          (character) =>
            character.id === id
              ? { ...character, colorOpacity: opacity }
              : character
        )

        return updateDoc(docRef, {
          characters: filteredEpisodesCharacters
        })
      })

      // Update character in Series chart
      const seriesChartRef = doc(firestore, `series/${chartId}`)
      const data = await getDoc(seriesChartRef)
      const documentData = data.data()
      const filteredSeriesCharacters = documentData.characters.map(
        (character) =>
          character.id === id
            ? { ...character, colorOpacity: opacity }
            : character
      )

      promises.push(
        updateDoc(seriesChartRef, {
          characters: filteredSeriesCharacters
        })
      )

      await Promise.all(promises)
    } else {
      const doctRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
      await updateDoc(doctRef, {
        characters: filtered
      })
    }
    return undefined
  }, 400)

  const removeCharacter = async (characterData) => {
    if (selectedCharacter.id === characterData.id) {
      dispatch(charactersActions.selectCharacter({}))
    }

    const filtered = characters.filter(
      (character) => character.id !== characterData.id
    )

    const filteredDrawings = draw.filter(
      (drawing) => drawing.characterId !== characterData.id
    )

    if (chartType === 'MOVIES') {
      const doctRef = doc(firestore, `charts/${chartId}`)
      await updateDoc(doctRef, {
        characters: filtered,
        draw: filteredDrawings
      })
      updateTrackingData({
        characters: filtered,
        draw: filteredDrawings,
        action: `Deleted character: ${characterData.name}`
      })
      return undefined
    }

    if (characterData.scope === 'SERIES') {
      // Delete all characters in other episodes
      const episodesRef = collection(firestore, 'series', chartId, 'episodes')
      const querySnapshot = await getDocs(episodesRef)
      const trackingPromises = []
      const promises = querySnapshot.docs.map((docItem) => {
        const docRef = doc(
          firestore,
          `series/${chartId}/episodes/${docItem.id}`
        )
        const documentData = docItem.data()
        const filteredEpisodesCharacters = documentData.characters.filter(
          (character) => character.id !== characterData.id
        )

        const filteredEpisodesDrawings = documentData.draw.filter(
          (drawing) => drawing.characterId !== characterData.id
        )
        trackingPromises.push(
          updateTrackingData({
            characters: filteredEpisodesCharacters,
            draw: filteredEpisodesDrawings,
            action: `Deleted character: ${characterData.name}`
          })
        )
        return updateDoc(docRef, {
          characters: filteredEpisodesCharacters,
          draw: filteredEpisodesDrawings
        })
      })

      // Delete character in Series chart
      const seriesChartRef = doc(firestore, `series/${chartId}`)
      const data = await getDoc(seriesChartRef)
      const documentData = data.data()
      const filteredSeriesCharacters = documentData.characters.filter(
        (character) => character.id !== characterData.id
      )

      const filteredSeriesDrawings = documentData.draw.filter(
        (drawing) => drawing.characterId !== characterData.id
      )
      promises.push(
        updateDoc(seriesChartRef, {
          characters: filteredSeriesCharacters,
          draw: filteredSeriesDrawings
        })
      )

      await Promise.all([...promises, ...trackingPromises])

      updateTrackingData({
        characters: filteredSeriesCharacters,
        draw: filteredSeriesDrawings,
        action: `Deleted character: ${characterData.name}`
      })
    } else {
      const doctRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
      await updateDoc(doctRef, {
        characters: filtered,
        draw: filteredDrawings
      })
      updateTrackingData({
        characters: filtered,
        draw: filteredDrawings,
        action: `Deleted character: ${characterData.name}`
      })
    }
    return undefined
  }

  const selectCharacter = (character) => {
    dispatch(charactersActions.selectCharacter(character))
  }

  return (
    <Paper elevation={0} style={{ backgroundColor: 'transparent' }} square>
      {!readOnly && (
        <Box className={classes.root}>
          <ColorPicker
            onChange={({ hex }) =>
              setInputValue({
                ...inputValue,
                color: hex,
                colorChanged: true
              })
            }
            value={inputValue.color}
          />

          <InputBase
            className={classes.input}
            placeholder="Add character name..."
            value={inputValue.name}
            onChange={(e) =>
              setInputValue({ ...inputValue, name: e.target.value })
            }
            onKeyPress={(e) =>
              (e.code === 'Enter' || e.code === 'NumpadEnter') && addCharacter()
            }
          />
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={addCharacter}
          >
            <AddIcon />
          </IconButton>
        </Box>
      )}
      <List
        component="nav"
        style={{ minHeight: 100, maxHeight: 380, overflowY: 'auto' }}
      >
        {characters.map((character, index) => (
          <ListItem
            button
            onClick={() => selectCharacter(character)}
            key={character.id}
            selected={character.id === selectedCharacter.id}
            classes={{
              selected: classes.selectedCharacter
            }}
          >
            <label
              htmlFor={`bgColor${character.id}`}
              style={{
                cursor: 'pointer',
                borderRadius: '50%',
                backgroundColor: character.color,
                width: 26,
                height: 26,
                border: '1px solid black',
                marginRight: 10
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <input
                id={`bgColor${character.id}`}
                name={`bgColor${character.id}`}
                type="color"
                style={{ visibility: 'hidden' }}
                value={character.color}
                onChange={(e) =>
                  changeCharacterColor(e.target.value, index, character)
                }
              />
            </label>

            {isRemane[character.id] ? (
              <ClickAwayListener
                mouseEvent="onMouseDown"
                onClickAway={handleClickAway}
              >
                <InputBase
                  defaultValue={character.name}
                  placeholder="Unnamed"
                  onChange={(e) =>
                    changeCharacterName(e.target.value, index, character)
                  }
                  disabled={readOnly}
                  onClick={(e) => e.stopPropagation()}
                  style={{ width: '100%' }}
                  inputProps={{
                    style: {
                      color: '#fff',
                      fontSize: 16,
                      fontWeight: 'bold',
                      padding: 4,
                      maxWidth: '60%',
                      border: '2px solid blue',
                      borderRadius: 5
                    }
                  }}
                />
              </ClickAwayListener>
            ) : (
              <ListItemText
                onDoubleClick={(e) => activateRename(e, character.id)}
                primary={
                  <Typography
                    noWrap
                    style={{ color: 'white', maxWidth: '60%' }}
                  >
                    {character.name}
                  </Typography>
                }
              />
            )}
            {!readOnly && (
              <ListItemSecondaryAction>
                <input
                  type="range"
                  defaultValue={character.colorOpacity}
                  min={0}
                  step={0.2}
                  max={1}
                  onChange={(e) =>
                    handleOpacity(e.target.value, index, character)
                  }
                  style={{ width: 60, verticalAlign: 'middle' }}
                />
                <IconButton
                  edge="end"
                  onClick={() => removeCharacter(character)}
                >
                  <DeleteIcon style={{ color: '#fff' }} />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    </Paper>
  )
}
