import { useState, useEffect } from 'react'
import { Grid, Typography, Button, TextField, Box } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useParams } from 'react-router-dom'
import { useFirestore } from 'reactfire'
import { doc, updateDoc } from 'firebase/firestore'

import guideActions from '../../redux/actions/guide'
import { Dialog } from '../ui'
import TemplatePDF from './TemplatePDF'
import useAddTrackingData from '../../hooks/useAddTrackingData'

const NotesDialog = () => {
  const { chartType } = useSelector((state) => state.app)
  const { readOnly } = useSelector((state) => state.auth)
  const { isNotesDialogOpen, title, personalNotes } = useSelector(
    (state) => state.guide
  )
  const [formValues, setFormValues] = useState({})
  const [renderDownload, setRenderDownload] = useState(false)
  const firestore = useFirestore()
  const { id: chartId, episodeId } = useParams()
  const dispatch = useDispatch()
  const updateTrackingData = useAddTrackingData()

  useEffect(() => {
    setFormValues(personalNotes)
  }, [personalNotes])

  const updateDb = async () => {
    let doctRef
    if (chartType === 'SERIES') {
      doctRef = doc(firestore, `series/${chartId}`)
    } else if (chartType === 'EPISODE') {
      doctRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
    } else {
      doctRef = doc(firestore, `charts/${chartId}`)
    }

    updateTrackingData({
      personalNotes: formValues,
      action: `Updated personal notes: ${formValues || 'No information'}`
    })
    await updateDoc(doctRef, { personalNotes: formValues })
  }

  const handleClose = () => {
    dispatch(guideActions.showNotesModal(false))
  }

  const handleChange = (event) => {
    setFormValues(event.target.value)
  }

  return (
    <Dialog
      open={isNotesDialogOpen}
      onClose={handleClose}
      title="Notes: your handbook to your chart!"
      maxWidth="sm"
      closable
      divider
      dragglable
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body2" align="justify" paragraph>
            Here you can write notes for your chart.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box marginTop={3}>
            <Typography align="left">Notes:</Typography>
            <TextField
              multiline
              fullWidth
              variant="outlined"
              value={formValues}
              rows={8}
              onChange={handleChange}
              onBlur={updateDb}
              placeholder="Your notes here.."
              disabled={readOnly}
            />
          </Box>
        </Grid>

        <Grid item xs={12} container justifyContent="center">
          {renderDownload ? (
            <PDFDownloadLink
              document={<TemplatePDF title={title} notes={formValues} />}
              fileName={`${title || 'untitled'} - Notes`}
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) => (
                <Button
                  variant="outlined"
                  onClick={() => setRenderDownload(false)}
                >
                  {loading ? 'Loading document...' : 'Download now!'}
                </Button>
              )}
            </PDFDownloadLink>
          ) : (
            <Button variant="outlined" onClick={() => setRenderDownload(true)}>
              Generate document
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default NotesDialog
