import { purchaseTypes } from '../types'

const purchaseOpen = () => (dispatch) => {
  dispatch({ type: purchaseTypes.PURCHASE_OPEN_MODAL })
}
const purchaseClose = () => (dispatch) => {
  dispatch({ type: purchaseTypes.PURCHASE_CLOSE_MODAL })
}

export default {
  purchaseOpen,
  purchaseClose
}
