import { makeStyles } from '@material-ui/core/styles'
import SignpostColums from './SignpostColums'
import SvgGraphEpisode from './SvgGraphEpisode'
import InsertMultimediaDialog from '../InsertMultimediaDialog'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    position: 'relative',
    height: '100%'
  }
}))

export default function ChartEpisodes({ size }) {
  const classes = useStyles()

  const scrollChart = (e) => {
    const CharactersDetailsId = document.getElementById('CharactersDetailsId')
    if (CharactersDetailsId) {
      CharactersDetailsId.scrollLeft = e.target.scrollLeft
    }
  }

  return (
    <div className={classes.root} onScroll={scrollChart} id="ChartId">
      <SignpostColums />
      <SvgGraphEpisode size={size} />
      <InsertMultimediaDialog />
    </div>
  )
}
