import { Tab, Grid } from '@material-ui/core'
import { TabPanel, TabList, TabContext } from '@material-ui/lab'
import { useDispatch, useSelector } from 'react-redux'

import IconsView from './IconsView'
import UploadFiles from './UploadFiles'
import AddUrl from './AddUrl'
import drawingToolsActions from '../../redux/actions/drawingTools'
import { Dialog } from '../ui'

const InsertMultimediaModal = () => {
  const dispatch = useDispatch()
  const { isInsertUrlOpen, multimediaTap } = useSelector(
    (state) => state.drawingTools
  )

  const handleChange = (event, newValue) => {
    dispatch(drawingToolsActions.setMultimediaTab(newValue))
  }

  const closeModal = () => {
    dispatch(drawingToolsActions.insertUrlClose())
  }

  return (
    <Dialog
      open={isInsertUrlOpen}
      onClose={closeModal}
      title="Enter the URL of multimedia to insert"
      maxWidth="sm"
      fullWidth
      closable
      divider
      dragglable
    >
      <TabContext value={multimediaTap}>
        <Grid container>
          <Grid item xs={12}>
            <TabList onChange={handleChange}>
              <Tab label="Insert Using URL" value="URL_INSERT" />
              <Tab label="Flaticons" value="ICONS" />
              <Tab label="Upload File" value="UPLOAD_FILE" />
            </TabList>
          </Grid>

          <Grid item xs={12}>
            <TabPanel value="URL_INSERT">
              <AddUrl />
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value="ICONS">
              <IconsView />
            </TabPanel>
          </Grid>
          <Grid item xs={12}>
            <TabPanel value="UPLOAD_FILE">
              <UploadFiles />
            </TabPanel>
          </Grid>
        </Grid>
      </TabContext>
    </Dialog>
  )
}

export default InsertMultimediaModal
