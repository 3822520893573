const guideTypes = {
  CORE_QUESTION_OPEN_MODAL: 'CORE_QUESTION_OPEN_MODAL',
  CORE_QUESTION_CLOSE_MODAL: 'CORE_QUESTION_CLOSE_MODAL',
  SIGNPOST_OPEN_MODAL: 'SIGNPOST_OPEN_MODAL',
  SIGNPOST_CLOSE_MODAL: 'SIGNPOST_CLOSE_MODAL',
  SET_DOWNLOAD_DIALOG: 'SET_DOWNLOAD_DIALOG',
  SET_GRAPH_TITLE: 'SET_GRAPH_TITLE',
  SET_CORE_QUESTION_ANSWERS: 'SET_CORE_QUESTION_ANSWERS',
  SET_SIGNPOST_ANSWERS: 'SET_SIGNPOST_ANSWERS',
  SET_GRAPH_NOTES: 'SET_GRAPH_NOTES',
  SET_MULTIMEDIA_SOURCE: 'SET_MULTIMEDIA_SOURCE',
  RESET_DRAW_SOURCE: 'RESET_DRAW_SOURCE',
  UPDATE_HISTORIAL_DRAW: 'UPDATE_HISTORIAL_DRAW',
  SHOW_EPISODES_MODAL: 'SHOW_EPISODES_MODAL',
  MOVE_CIRCLE_DRAW: 'MOVE_CIRCLE_DRAW',
  SHOW_NOTES_MODAL: 'SHOW_NOTES_MODAL',
  SET_EPISODE_DATA: 'SET_EPISODE_DATA'
}

export default guideTypes
