import { useState } from 'react'
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { saveAs } from 'file-saver'

import { Dialog } from '../ui'
import guideActions from '../../redux/actions/guide'

const DownloadEventsDialog = () => {
  const { downloadEventDialog, title } = useSelector((state) => state.guide)
  const { characters } = useSelector((state) => state.characters)
  const dispatch = useDispatch()
  const [checked, setChecked] = useState([])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const selectAll = () => {
    if (checked.length === characters.length) {
      setChecked([])
    } else {
      setChecked([...characters])
    }
  }

  const handleClose = () => {
    setChecked([])
    dispatch(guideActions.downloadEventsDialog(false))
  }

  const saveStaticDataToFile = () => {
    const pointsData = checked.reduce((prev, item) => {
      if (item.points.length) {
        return prev.concat(item.points)
      }
      return prev
    }, [])
    const data = pointsData
      .sort((a, b) => a.pos.x - b.pos.x)
      .map(
        (item) =>
          `INT. ${item.header.toUpperCase() || 'no header'}\n\n${
            item.description || 'no description'
          }\n`
      )

    console.log(data)

    const blob = new Blob([data.join('\n')], {
      type: 'text/plain;charset=utf-8'
    })
    saveAs(blob, `${title || 'no title'} - events.txt`)
  }

  return (
    <Dialog
      open={downloadEventDialog}
      onClose={handleClose}
      title="Download your characters events!!"
      maxWidth="sm"
      closable
      divider
      dragglable
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography align="justify" paragraph>
            Here you can select the characters and generate a txt file with all
            their events data, ready to use in your screenplay writting favorite
            app.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="justify">Characters:</Typography>
          <List>
            <ListItem dense button onClick={selectAll}>
              <ListItemIcon>
                <Checkbox
                  indeterminate={checked.length !== characters.length}
                  color="primary"
                  edge="start"
                  checked={checked.length === characters.length}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText primary="Select all" />
            </ListItem>
            {characters.map((value) => (
              <ListItem
                key={value.id}
                dense
                button
                onClick={handleToggle(value)}
              >
                <ListItemIcon>
                  <Checkbox
                    color="primary"
                    edge="start"
                    checked={
                      checked.findIndex((item) => item.id === value.id) !== -1
                    }
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={value.name} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            onClick={saveStaticDataToFile}
            style={{ marginBottom: 20 }}
            disabled={!checked.length}
          >
            Download events
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default DownloadEventsDialog
