import { guideTypes } from '../types'

const coreQuestionOpen = () => (dispatch) => {
  dispatch({ type: guideTypes.CORE_QUESTION_OPEN_MODAL })
}
const coreQuestionClose = () => (dispatch) => {
  dispatch({ type: guideTypes.CORE_QUESTION_CLOSE_MODAL })
}
const signpostOpen = () => (dispatch) => {
  dispatch({ type: guideTypes.SIGNPOST_OPEN_MODAL })
}
const signpostClose = () => (dispatch) => {
  dispatch({ type: guideTypes.SIGNPOST_CLOSE_MODAL })
}
const setGraphTitle = (data) => (dispatch) => {
  dispatch({ type: guideTypes.SET_GRAPH_TITLE, payload: data })
}
const setEpisodeData = (data) => (dispatch) => {
  dispatch({ type: guideTypes.SET_EPISODE_DATA, payload: data })
}
const setGraphNotes = (data) => (dispatch) => {
  dispatch({ type: guideTypes.SET_GRAPH_NOTES, payload: data })
}
const setCoreQuestionAnswers = (data) => (dispatch) => {
  dispatch({ type: guideTypes.SET_CORE_QUESTION_ANSWERS, payload: data })
}
const setSignpostAnswers = (data) => (dispatch) => {
  dispatch({ type: guideTypes.SET_SIGNPOST_ANSWERS, payload: data })
}
const setMultimediaSource = (data) => (dispatch) => {
  dispatch({ type: guideTypes.SET_MULTIMEDIA_SOURCE, payload: data })
}
const resetDrawSource = () => (dispatch) => {
  dispatch({ type: guideTypes.RESET_DRAW_SOURCE })
}
const updateHistorialDraw = (data) => (dispatch) => {
  dispatch({ type: guideTypes.UPDATE_HISTORIAL_DRAW, payload: data })
}

const handleCircleMove = (drawId, x, y) => (dispatch) => {
  dispatch({
    type: guideTypes.MOVE_CIRCLE_DRAW,
    payload: {
      drawId,
      x,
      y
    }
  })
}

const showEpidodesModal = (payload) => (dispatch) => {
  dispatch({ type: guideTypes.SHOW_EPISODES_MODAL, payload })
}

const downloadEventsDialog = (payload) => (dispatch) => {
  dispatch({ type: guideTypes.SET_DOWNLOAD_DIALOG, payload })
}

const showNotesModal = (payload) => (dispatch) => {
  dispatch({ type: guideTypes.SHOW_NOTES_MODAL, payload })
}

export default {
  showNotesModal,
  showEpidodesModal,
  downloadEventsDialog,
  coreQuestionOpen,
  coreQuestionClose,
  signpostOpen,
  signpostClose,
  setGraphTitle,
  setCoreQuestionAnswers,
  setSignpostAnswers,
  setGraphNotes,
  setMultimediaSource,
  resetDrawSource,
  updateHistorialDraw,
  handleCircleMove,
  setEpisodeData
}
