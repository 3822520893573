import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography } from '@material-ui/core'

import logo from '../../assets/img/just-Logo.png'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'black',
    opacity: 0.8
  },
  logo: {
    width: '30%'
  }
}))
const ErrorPage = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img className={classes.logo} alt="hartchart logo" src={logo} />
      <Typography variant="h4" align="center" style={{ color: '#fff' }}>
        Something has gone wrong, please reload the page and try again.
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => document.location.reload()}
        style={{ marginTop: 12 }}
      >
        Reload
      </Button>
    </div>
  )
}

export default ErrorPage
