import {
  Document,
  Page,
  Text,
  Image,
  StyleSheet,
  Font
} from '@react-pdf/renderer'
import logo from '../../assets/img/just-Logo.png'

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
})

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 20
  },
  subtitle: {
    fontSize: 16,
    margin: 12,
    fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman'
  },
  textBreak: {
    margin: 12,
    fontSize: 14,
    textAlign: 'center',
    fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 7,
    width: 170,
    height: 75
  },
  header: {
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'center',
    color: 'grey'
  }
})

const TemplatePDF = ({ notes, title }) => (
  <Document>
    <Page style={styles.body}>
      <Text style={styles.header} fixed>
        ~ {title || 'Untitled'} ~
      </Text>
      <Image style={styles.image} src={logo} />
      <Text style={styles.author}>Episodes descriptions</Text>
      <Text style={styles.subtitle}>Notes</Text>
      <Text style={styles.text}>{notes || 'empty'}</Text>
    </Page>
  </Document>
)

export default TemplatePDF
