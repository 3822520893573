import { useState } from 'react'
import {
  Typography,
  TextField,
  Box,
  Popover,
  IconButton
} from '@material-ui/core'
import { Info } from '@material-ui/icons'
import { useSelector } from 'react-redux'

import signpostGuideData from '../../utils/signpostsGuideData'

const ParagraphForm = ({ signpostData, formValues, handleChange }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const { readOnly } = useSelector((state) => state.auth)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popper' : undefined
  const formatedTitle = signpostData.name
    .trim()
    .toUpperCase()
    .replaceAll(' ', '_')
  const guideData = signpostGuideData.en[formatedTitle] ?? {
    title: `A wonderful custom signpost.`,
    videoUrl: ''
  }

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box marginTop={3}>
        <Typography align="left">
          {signpostData.name || 'Untitled'}:
          <IconButton size="small" onClick={handleClick}>
            <Info />
          </IconButton>
        </Typography>
        <TextField
          multiline
          fullWidth
          name={signpostData.key}
          variant="outlined"
          value={formValues[signpostData.key]}
          rows={4}
          onChange={handleChange}
          placeholder="Describe your story..."
          disabled={readOnly}
        />
      </Box>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
        style={{ maxWidth: 500 }}
      >
        <Box padding={2} style={{ width: 400, padding: 12 }}>
          <Typography variant="body2" align="justify">
            {guideData.title}
          </Typography>
          {guideData.videoUrl && (
            <video
              controls
              preload="none"
              style={{ marginTop: 12, width: '100%' }}
            >
              <source src={guideData.videoUrl} type="video/mp4" />
            </video>
          )}
        </Box>
      </Popover>
    </>
  )
}

export default ParagraphForm
