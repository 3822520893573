import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Menu, MenuItem, Dialog } from '@material-ui/core'
import * as htmlToImage from 'html-to-image'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'reactfire'
import { doc, collection, addDoc, updateDoc } from 'firebase/firestore'
import { useHistory } from 'react-router-dom'

import NestedMenuItem from './NestedMenuItem'
import MyCharts from '../MyCharts'
import SaveAsDialog from './SaveAsDialog'
import ImportDialog from '../ImportDialog'
import undoActions from '../../redux/actions/undo'
import fileActions from '../../redux/actions/file'
import guideActions from '../../redux/actions/guide'
import authActions from '../../redux/actions/auth'
import appActions from '../../redux/actions/app'
import defaultSignposts from '../../utils/defaultSignposts'

import theMartian from '../../utils/examples_chart/theMartian.json'
import eigthGrade from '../../utils/examples_chart/8thGrade.json'
import cinderella from '../../utils/examples_chart/Cinderella.json'
import dracula from '../../utils/examples_chart/Dracula.json'
import getOut from '../../utils/examples_chart/GetOut.json'
import hook from '../../utils/examples_chart/Hook.json'
import lalaland from '../../utils/examples_chart/Lalaland.json'
import LoadingScreen from '../LoadingScreen'

const useStyles = makeStyles(() => ({
  paper: {
    top: '60px !important'
  }
}))

const exampleCharts = [
  { title: 'Hook', data: hook },
  { title: 'LaLa Land', data: lalaland },
  { title: 'Get Out', data: getOut },
  { title: '8th Grade', data: eigthGrade },
  { title: 'Dracula', data: dracula },
  { title: 'Cinderella', data: cinderella },
  { title: 'The Martian', data: theMartian }
]

export default function FileMenu() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { graphWidth } = useSelector((state) => state.animationTools)
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const history = useHistory()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const exportPNG = () => {
    setAnchorEl(null)
    const graph = document.getElementById('ChartId')
    graph.style.width = `${graphWidth}px`
    htmlToImage
      .toPng(graph)
      .then((dataUrl) => {
        const img = new Image()
        img.src = dataUrl
        const download = document.createElement('a')
        download.href = dataUrl
        download.download = 'MyChart.png'
        download.click()
        graph.style.width = 'auto'
      })
      .catch((error) => {
        throw new Error(error)
      })
  }

  const openHartChart = () => {
    dispatch(fileActions.foldersOpen())
    setAnchorEl(null)
  }

  const dateContructor = () => {
    const date = new Date()
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]

    const datestring = `${
      monthNames[date.getMonth()]
    } - ${date.getDate()} - ${date.getFullYear()} // ${date.getHours()}:${date.getMinutes()}`

    return datestring
  }

  const createNewChart = async () => {
    try {
      setIsLoading(true)
      dispatch(guideActions.resetDrawSource())
      const userRef = doc(firestore, 'users', user.email)
      const docRef = await addDoc(collection(firestore, 'charts'), {
        signposts: defaultSignposts,
        characters: [],
        draw: [],
        title: '',
        personalNotes: '',
        questions: {},
        collaborate: [],
        links: [],
        userId: userRef,
        projectId: null,
        lastUsed: dateContructor()
      })
      const updateData = {
        lastChart: docRef.id
      }
      await updateDoc(userRef, updateData)
      dispatch(appActions.setChartType('MOVIES'))
      dispatch(undoActions.resetRegisters())
      dispatch(authActions.updateUserData(updateData))
      dispatch(authActions.resetGraphStatus())
      setIsLoading(false)
      setAnchorEl(null)
      history.push(`/app/${docRef.id}`)
    } catch (error) {
      setIsLoading(false)
      throw new Error(error)
    }
  }

  const createNewSeriesChart = async () => {
    try {
      setIsLoading(true)
      const userRef = doc(firestore, 'users', user.email)
      const docRef = await addDoc(collection(firestore, 'series'), {
        signposts: defaultSignposts,
        characters: [],
        draw: [],
        title: '',
        personalNotes: '',
        questions: {},
        collaborate: [],
        links: [],
        userId: userRef,
        projectId: null,
        type: 'SERIES'
      })
      dispatch(appActions.setChartType('SERIES'))
      dispatch(guideActions.resetDrawSource())
      dispatch(undoActions.resetRegisters())
      dispatch(authActions.resetGraphStatus())
      setIsLoading(false)
      history.push(`/series/${docRef.id}`)
    } catch (error) {
      setIsLoading(false)
      throw new Error(error)
    }
  }

  const openImportDialog = () => {
    setAnchorEl(null)
    dispatch(appActions.importDialogOpen())
  }

  const handleOpenSaveAsDialog = () => {
    setAnchorEl(null)
    dispatch(appActions.handleSaveAsDialog(true))
  }

  const openStartupDialog = () => {
    history.push('/welcome')
  }

  const openExampleChart = async (chartData) => {
    try {
      setIsLoading(true)
      dispatch(guideActions.resetDrawSource())
      const userRef = doc(firestore, 'users', user.email)
      const docRef = await addDoc(collection(firestore, 'charts'), {
        collaborate: [],
        links: [],
        userId: userRef,
        projectId: null,
        lastUsed: dateContructor(),
        ...chartData
      })
      const updateData = {
        lastChart: docRef.id
      }
      await updateDoc(userRef, updateData)
      dispatch(undoActions.resetRegisters())
      dispatch(authActions.updateUserData(updateData))
      dispatch(authActions.resetGraphStatus())
      setAnchorEl(null)
      setIsLoading(false)
      history.push(`/app/${docRef.id}`)
    } catch (error) {
      setIsLoading(false)
      throw new Error(error)
    }
  }

  return (
    <>
      <Dialog fullScreen open={isLoading}>
        <LoadingScreen />
      </Dialog>
      <Button style={{ color: '#fff' }} onClick={(e) => handleClick(e)}>
        File
      </Button>
      <Menu
        classes={{ paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <NestedMenuItem label="New" parentMenuOpen={true}>
          <MenuItem onClick={createNewChart}>Movie chart</MenuItem>
          <MenuItem onClick={createNewSeriesChart}>Series chart</MenuItem>
        </NestedMenuItem>
        <MenuItem onClick={handleOpenSaveAsDialog}>Save as</MenuItem>
        <MenuItem onClick={openHartChart}>Open</MenuItem>
        <MenuItem onClick={openImportDialog}>Import</MenuItem>
        <NestedMenuItem label="Export" parentMenuOpen={true}>
          <MenuItem onClick={exportPNG}>PNG Image</MenuItem>
        </NestedMenuItem>
        <NestedMenuItem label="Examples" parentMenuOpen={true}>
          {exampleCharts.map((chart) => (
            <MenuItem
              key={chart.title}
              onClick={() => openExampleChart(chart.data)}
            >
              {chart.title}
            </MenuItem>
          ))}
        </NestedMenuItem>
        <MenuItem onClick={openStartupDialog}>Go to dashboard</MenuItem>
      </Menu>
      {user && <MyCharts />}
      <ImportDialog />
      <SaveAsDialog />
    </>
  )
}
