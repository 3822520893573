import { animationToolsTypes } from '../types'

const play = (value) => (dispatch) => {
  dispatch({ type: animationToolsTypes.SET_PLAY, payload: value })
}

const animateGraph = (value) => (dispatch) => {
  dispatch({ type: animationToolsTypes.SET_GRAPH_WIDTH, payload: value })
}

const setGraphHeight = (value) => (dispatch) => {
  dispatch({ type: animationToolsTypes.SET_GRAPH_HEIGHT, payload: value })
}

const pause = (value) => (dispatch) => {
  dispatch({ type: animationToolsTypes.SET_PAUSE, payload: value })
}

const stop = () => (dispatch) => {
  dispatch({ type: animationToolsTypes.SET_STOP })
}

const foward = (value) => (dispatch) => {
  dispatch({ type: animationToolsTypes.FOWARD, payload: value })
}

const backwards = (value) => (dispatch) => {
  dispatch({ type: animationToolsTypes.BACKWARDS, payload: value })
}

const animationToolsOpen = () => (dispatch) => {
  dispatch({ type: animationToolsTypes.ANIMATIONTOOLS_OPEN })
}

const animationToolsClose = () => (dispatch) => {
  dispatch({ type: animationToolsTypes.ANIMATIONTOOLS_CLOSE })
}

const setIsAnimating = (payload) => (dispatch) => {
  dispatch({ type: animationToolsTypes.SET_IS_ANIMATING, payload })
}

export default {
  setIsAnimating,
  play,
  pause,
  stop,
  foward,
  backwards,
  animateGraph,
  animationToolsOpen,
  animationToolsClose,
  setGraphHeight
}
