const registerTemplate = (userName) => `<body
style="
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
"
>
<div
  style="
    width: 100%;
    opacity: 0.8;
    background: black;
    border-radius: 10px;
    padding: 10px;
  "
>
  <div style="margin: 0 auto; width: 90%; text-align: center">
    <h1
      style="
        background-color: #0087a4;
        padding: 5px 10px;
        border-radius: 5px;
        color: white;
      "
    >
      ${userName} welcome to the hartchart!
    </h1>
    <div
      style="
        margin: 30px auto;
        background: #eee;
        width: 40%;
        border-radius: 10px;
        padding: 50px;
      "
    >
      <img
        src="https://static.mywebsites360.com/ede5858c877044a983fa2b65a8f2629e/i/abc6592635284739a0bc43a1bc1a45f8/3/5feFb8zhrk/hclogo.png"
        alt="audisoft logo"
        style="width: 40%; margin: 0 auto"
      />
      <h4 style="text-align: justify">
        Hi ${userName} <br />
        <br />
        Thank you for joining the HartChart community! We are glad to help
        and be a part in your writing life.<br /><br />
        Attached you will find a document that will guide you through the
        first steps and functions of the app. Also, in the "Tutorials and
        more" section in the Dashboard, you will find some Video Tutorials
        for you to follow, we will add more clips there soon. <br /><br />
        The new expanded chart now includes the mapping of your characters
        emotional journeys through each episode and also an entire season of
        your series narrative.<br /><br />
        The chart is an important addition to your skillset that requires
        proper preparation before adding your story and character data to
        the chart.<br /><br />
        We also offer the HC toolkit as a download on the site. This is a
        perfect primer for using the chart to your maximum benefit.
        <br /><br />
        The exercises and story prep the toolkit lays out will guarantee you
        never face a blank page. When you have completed these steps,
        transferring your story data to the chart will be that much more
        efficient. <br /><br />
        Take an existing script you are working on and put it to the test.
        <br /><br />
        Let us know what works and what you need more of. <br /><br />
        Brain blizzards to you in your writing life. <br /><br />
      </h4>
      <h4>To be continued,</h4>
      <h4>José Videla</h4>
    </div>
  </div>
</div>
</body>
`

export default registerTemplate
