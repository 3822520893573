import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Typography, Button, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { SentimentVeryDissatisfied } from '@material-ui/icons/'
import { useFirestore } from 'reactfire'
import { doc, getDoc } from 'firebase/firestore'

import LoadingScreen from '../../components/LoadingScreen'
import appActions from '../../redux/actions/app'

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: 'black',
    opacity: 0.8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  messageContainer: {
    backgroundColor: 'white',
    padding: 12,
    borderRadius: 12,
    maxWidth: '50vw'
  }
}))

const ShareLink = () => {
  const classes = useStyles()
  const firestore = useFirestore()
  const dispatch = useDispatch()
  const history = useHistory()
  const { id, chartType } = useParams()
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const linkRef = doc(firestore, `/collaborators_links`, id)

  useEffect(() => {
    const fetch = async () => {
      const linkData = await getDoc(linkRef)
      if (linkData.exists()) {
        const data = linkData.data()
        const chartData = await getDoc(data.chartRef)
        if (chartData.exists()) {
          dispatch(appActions.setInvitationLinkData(data))
          dispatch(appActions.setChartType(chartType))
          history.replace(
            `/${chartType === 'SERIES' ? 'series' : 'app'}/${data.chartRef.id}`
          )
        } else {
          setError('The chart you are trying to access no longer exists.')
        }
      } else {
        setError(
          'Opps, looks like the link you are trying to access has expired.'
        )
      }
      setLoading(false)
    }
    fetch()
  }, [chartType])

  if (loading) {
    return <LoadingScreen />
  }

  return (
    <div className={classes.root}>
      {error && (
        <Grid container className={classes.messageContainer} spacing={4}>
          <Grid item container xs={12} justifyContent="center">
            <SentimentVeryDissatisfied
              style={{ fontSize: 120, color: '#7a3c3c' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h5">
              {error}
            </Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push('/welcome')}
            >
              Return to dashboard
            </Button>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default ShareLink
