import { Fragment, useState } from 'react'
import {
  Grid,
  Typography,
  Select,
  Box,
  MenuItem,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Cancel, Delete, Done } from '@material-ui/icons'
import { useHistory, useParams } from 'react-router-dom'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import {
  doc,
  updateDoc,
  collection,
  orderBy,
  query,
  deleteDoc
} from 'firebase/firestore'

import episodesActions from '../../redux/actions/episodes'
import { Dialog } from '../ui'

const EpisodesConfigDialog = () => {
  const { configModal } = useSelector((state) => state.episodes)
  const { signposts } = useSelector((state) => state.signposts)
  const firestore = useFirestore()
  const { id: chartId, episodeId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [confirmDelete, setConfirmDelete] = useState({})

  const episodesRef = collection(firestore, 'series', chartId, 'episodes')
  const orderByStartQuery = query(episodesRef, orderBy('startSignpost'))

  const { data } = useFirestoreCollectionData(orderByStartQuery, {
    idField: 'id'
  })

  const handleClose = () => {
    dispatch(episodesActions.setShowConfigModal(false))
  }

  const handleChange = ({ target }) => {
    const { value, name } = target
    const [type, id] = name.split(':')
    const chartRef = doc(firestore, `series/${chartId}/episodes/${id}`)

    if (type === 'start') {
      updateDoc(chartRef, { startSignpost: value })
    }

    if (type === 'finish') {
      updateDoc(chartRef, { finishSignpost: value })
    }
  }

  const deleteEpisode = async (id) => {
    try {
      if (episodeId === id) {
        history.push(`/series/${chartId}`)
      }
      await deleteDoc(doc(firestore, `series/${chartId}/episodes/${id}`))
    } catch (err) {
      console.log(err, 'error while erasing the episode')
    }
  }

  const toggleConfirmDelete = (id) => {
    setConfirmDelete({ [id]: true })
  }

  return (
    <Dialog
      open={configModal}
      onClose={handleClose}
      title="Config your episodes"
      maxWidth="md"
      closable
      divider
      dragglable
    >
      <Grid container spacing={2} style={{ marginBottom: 18 }}>
        <Grid item xs={12}>
          <Typography align="justify" paragraph>
            Here you can modify where your episodes starts and ends.
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="h6" align="center">
            Title
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" align="center">
            Starts in
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" align="center">
            Finish in
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h6" align="center">
            Actions
          </Typography>
        </Grid>

        {data && !!data.length ? (
          data.map((ep) => (
            <Fragment key={ep.id}>
              <Grid
                item
                container
                xs={2}
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  style={{
                    backgroundColor: ep.color,
                    padding: 5,
                    width: '100%',
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    borderRadius: 12
                  }}
                >
                  <Typography variant="body1">{ep.title}</Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Select
                  variant="outlined"
                  fullWidth
                  name={`start:${ep.id}`}
                  value={ep.startSignpost}
                  onChange={handleChange}
                >
                  {signposts.map((item, index) => (
                    <MenuItem
                      key={item.id}
                      value={index}
                      disabled={index > ep.finishSignpost}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={4}>
                <Select
                  variant="outlined"
                  fullWidth
                  name={`finish:${ep.id}`}
                  value={ep.finishSignpost}
                  onChange={handleChange}
                >
                  {signposts.map((item, index) => (
                    <MenuItem
                      key={item.id}
                      value={index}
                      disabled={index < ep.startSignpost}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item container xs={2} justifyContent="center">
                {confirmDelete[ep.id] ? (
                  <>
                    <Tooltip title="Confirm">
                      <IconButton onClick={() => deleteEpisode(ep.id)}>
                        <Done />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel">
                      <IconButton onClick={() => setConfirmDelete({})}>
                        <Cancel />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : (
                  <Tooltip title="Delete">
                    <IconButton onClick={() => toggleConfirmDelete(ep.id)}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Fragment>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography align="center">No episodes created</Typography>
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

export default EpisodesConfigDialog
