const guideTypes = {
  CHANGE_ORDER: 'CHANGE_ORDER',
  ADD_BEFORE: 'ADD_BEFORE',
  ADD_AFTER: 'ADD_AFTER',
  REMOVE_SIGNPOST: 'REMOVE_SIGNPOST',
  MOVE_LEFT: 'MOVE_LEFT',
  MOVE_RIGHT: 'MOVE_RIGHT',
  CHANGE_BACKGROUND_COLOR: 'CHANGE_BACKGROUND_COLOR',
  CHANGE_FONT_COLOR: 'CHANGE_FONT_COLOR',
  CHANGE_SIGNPOST_TITLE: 'CHANGE_SIGNPOST_TITLE',
  CHANGE_SIGNPOST_SIZE: 'CHANGE_SIGNPOST_SIZE',
  CHANGE_VALUE_EXPLANATION: 'CHANGE_VALUE_EXPLANATION',
  ZOOM_OUT: 'ZOOM_OUT',
  ZOOM_IN: 'ZOOM_IN',
  SET_ZOOM_LEVEL: 'SET_ZOOM_LEVEL'
}

export default guideTypes
