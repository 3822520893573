const authTypes = {
  AUTH_LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  SET_OWNERSHIP: 'SET_OWNERSHIP',
  SET_GUEST_USER: 'SET_GUEST_USER',
  RESET_GRAPH_STATUS: 'RESET_GRAPH_STATUS',
  SET_PREVIEW_MODE: 'SET_PREVIEW_MODE',
  RESET_PREVIEW_MODE: 'RESET_PREVIEW_MODE'
}

export default authTypes
