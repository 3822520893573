import { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'

import SignpostColums from './SignpostColums'
import SvgGraph from './SvgGraph'
import InsertMultimediaDialog from '../InsertMultimediaDialog'
import animationToolsActions from '../../redux/actions/animationTools'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    position: 'relative',
    height: '100%'
  }
}))
export default function Chart({ size }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      animationToolsActions.setGraphHeight(
        size.height ? size.height - 50 : 500 - 50
      )
    )
  }, [size.height])

  const scrollChart = (e) => {
    const CharactersDetailsId = document.getElementById('CharactersDetailsId')
    if (CharactersDetailsId) {
      CharactersDetailsId.scrollLeft = e.target.scrollLeft
    }
  }

  return (
    <div className={classes.root} onScroll={scrollChart} id="ChartId">
      <SignpostColums />
      <SvgGraph size={size} />
      <InsertMultimediaDialog />
    </div>
  )
}
