import { useState } from 'react'
import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'reactfire'
import { collection, addDoc, onSnapshot } from 'firebase/firestore'

import { Dialog, SubmitButton } from '../ui'
import purchaseActions from '../../redux/actions/purchase'
import config from '../../config'

const PurchaseDialog = () => {
  const { isModalOpen } = useSelector((state) => state.purchase)
  const { user } = useSelector((state) => state.auth)
  const [isLoading, setIsloading] = useState(false)
  const [error, setError] = useState(null)
  const firestore = useFirestore()

  const dispatch = useDispatch()
  const [value, setValue] = useState('month')

  const handleClose = async () => {
    dispatch(purchaseActions.purchaseClose())
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const createStripeCheckout = async () => {
    setIsloading(true)

    const { monthlyProduct, yearProduct } =
      config.productionAccount.prodProducts

    const userPaymentRef = collection(
      firestore,
      'customers',
      user.uid,
      'checkout_sessions'
    )

    const docRef = await addDoc(userPaymentRef, {
      price: value === 'month' ? monthlyProduct : yearProduct,
      success_url: window.location.origin,
      allow_promotion_codes: value !== 'month',
      cancel_url: window.location.origin
    })

    onSnapshot(docRef, (doc) => {
      const { error: errorMessage, url } = doc.data()

      if (errorMessage) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        console.log('error: ', errorMessage)
        setError(`An error occured: ${errorMessage.message}`)
        setIsloading(false)
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        setIsloading(false)
        window.location.assign(url)
      }
    })
  }

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      title="Purchase the HartChart"
      maxWidth="sm"
      closable
      divider
      dragglable
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body2" align="justify">
            Subscribe to the HartChart for:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ul>
            <li>Importing existing scripts or starting charts from scratch</li>
            <li>Access to your HartChart online account from any computer</li>
            <li>
              Videos from J.V. Hart discussing story structure &amp; examples
            </li>
            <li>Example charts from J.V. Hart, with more added regularly</li>
            <li>
              Live &amp; recorded online seminars by J.V. Hart and others, with
              Q &amp; A
            </li>
            <li>
              Real-time collaboration with other members (if you choose to
              share)
            </li>
            <li>
              Access to all the new features &amp; content we're adding - more
              to come!
            </li>
          </ul>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
        >
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="planType"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="month"
                control={<Radio />}
                label="$8.99/month"
              />
              <FormControlLabel
                value="year"
                control={<Radio />}
                label="$89/year"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {error && (
          <Grid item container justifyContent="center" xs={12}>
            <Typography>
              Something bad has happend, please try again latter
            </Typography>
          </Grid>
        )}
        <Grid item container justifyContent="center" xs={12}>
          <SubmitButton
            isLoading={isLoading}
            onClick={createStripeCheckout}
            text="purchase"
            variant="contained"
            style={{ marginTop: 6 }}
            color="primary"
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default PurchaseDialog
