import { nanoid } from 'nanoid'

const defaultSignposts = [
  {
    id: nanoid(),
    key: 'SET_THE_WORLD',
    name: 'Set the World',
    textColor: '#fff',
    bgColor: '#1c74c8',
    width: 400,
    answers: '',
    guideData: {
      title: `Let the audience meet your main characters and live in their world for a few minutes, size them up, empathize, worry, care a bit. This is what your main characters are doing the day before the movie starts. (Often ~15% of story)`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/set%20the%20world%20sign%20post.mp4'
    }
  },
  {
    id: nanoid(),
    key: 'NEW_OPPORTUNITY',
    name: 'New Opportunity',
    textColor: '#fff',
    bgColor: '#9161c9',
    width: 400,
    answers: '',
    guideData: {
      title: `Your main character gets a new opportunity that could/will change the course of their life. What they do with that opportunity determines the course of the rest of your story.`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/new%20opportunity.mp4'
    }
  },
  {
    id: nanoid(),
    key: '2ND_OPPORTUNITY',
    name: '2nd Opportunity',
    textColor: '#fff',
    bgColor: '#a872e9',
    width: 400,
    answers: '',
    guideData: {
      title: `The second opportunity and what the main character does with the opportunity and the consequences of that opportunity puts the character in motion in pursuit of a new direction or new journey to achieve and obtain what the main characters wants/needs.`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/second%20opportunity.mp4'
    }
  },
  {
    id: nanoid(),
    key: 'VISIBLE_TANGIBLE_GOAL',
    name: 'Visible Tangible Goal',
    textColor: '#fff',
    bgColor: '#e4b100',
    width: 400,
    answers: '',
    guideData: {
      title: `The choices your main character makes about the opportunities begins the journey of the story, and identifies a visible tangible goal your character(s) are trying to reach. This is also called the Desire Line: the line your main character follows in pursuit of his/her desire. This initial pursuit and identifying of the Visible Tangible goal usually occurs around the end of what many call Act I. (Often ~25% mark of your story)`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/visible%20tangible%20goal%20audience.mp4'
    }
  },
  {
    id: nanoid(),
    key: 'PROGRESS',
    name: 'Progress',
    textColor: '#fff',
    bgColor: '#3db231',
    width: 400,
    answers: '',
    guideData: {
      title: `Your main character makes progress toward the Visible Tangible Goal and deals with obstacles, characters, conflicts encountered that complicate the journey.`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/character%20progress.mp4'
    }
  },
  {
    id: nanoid(),
    key: 'SETBACK',
    name: 'Setback',
    textColor: '#fff',
    bgColor: '#c23636',
    width: 400,
    answers: '',
    guideData: {
      title: `Something pushes your main character away from the Visible Tangible Goal. Progress and Setbacks are a series of mini-defeats and minor victories. Conflict and consequence comes with the decisions your main character makes that drive the story. There can be a number of short signposts for these. (Often ~25-50% of your story)`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/setback%20for%20char.mp4'
    }
  },
  {
    id: nanoid(),
    key: 'CINDERELLA_MOMENT',
    name: 'Cinderella Moment',
    textColor: '#fff',
    bgColor: '#df519d',
    width: 400,
    answers: '',
    guideData: {
      title: `This gives to the main character a deserved moment of success in their struggle and empowers them to reach the Top of the Mountain. These moments typically occur more than once in your narrative.`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/cinderella%20moment.mp4'
    }
  },
  {
    id: nanoid(),
    key: 'TOP_OF_THE_MOUNTAIN',
    name: 'Top of the Mountain',
    textColor: '#fff',
    bgColor: '#6ab2f0',
    width: 400,
    answers: '',
    guideData: {
      title: `If you are drawing a picture of your movie, Top of the Mountain is typically near the center point of your narrative story. The highest point you have reached. Your main character has made progress toward their goal and realized a major/minor victory over some obstacle. Everything seems to be going according to plan. Your main character appears to be advancing toward their goal and what they want. There is a false sense of security as they reach a major summit. Now they must spend the rest of the story trying not to fall down the mountain.`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/build%20progress%20and%20setback%20to%20top%20of%20mountain.mp4'
    }
  },
  {
    id: nanoid(),
    key: 'POINT_OF_NO_RETURN',
    name: 'Point of No Return',
    textColor: '#fff',
    bgColor: '#7a412b',
    width: 400,
    answers: '',
    guideData: {
      title: `As your main character progresses to the Visible Tangible Goal, there comes a point where a decision, an action, or an event occurs, from which there is no turning back. It is too late to start over, what has happened cannot be undone or changed. The main character must continue toward the Visible Tangible Goal. (Often ~50-60% mark of your story)`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/point%20of%20no%20return.mp4'
    }
  },
  {
    id: nanoid(),
    key: 'PLAN_FALLS_APART',
    name: 'Plan Falls Apart',
    textColor: '#fff',
    bgColor: '#c23636',
    width: 400,
    answers: '',
    guideData: {
      title: `Your main character has faced a series of "apparent defeats" in order to achieve their goal. And just when it seems that all is going to work out, the Hero hits the wall. The rug is pulled out. The plan falls apart. Your Hero is down a hole or up that infamous creek with seemingly no way to reach their goal that their Desire Line has compelled them to seek. (Often ~75-80% mark of your story which is traditionally the end of Act II)`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/plan%20falls%20apart%20after%20mountain%20top.mp4'
    }
  },
  {
    id: nanoid(),
    key: 'RESURRECTION_OPPORTUNITY',
    name: 'Resurrection Opportunity',
    textColor: '#fff',
    bgColor: '#9363cc',
    width: 400,
    answers: '',
    guideData: {
      title: `After your main character has crashed and burned and all is lost, you have a choice to make: do you leave your main character in flames on the desert floor, never to reach their goal or do you give them a resurrection opportunity, a chance to get back on their feet and complete their journey?`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/ressurection%20opportunity%20all%20is%20lost%20until.mp4'
    }
  },
  {
    id: nanoid(),
    key: 'CONFLICT_RESOLUTION',
    name: 'Conflict Resolution',
    textColor: '#fff',
    bgColor: '#0374c8',
    width: 400,
    answers: '',
    guideData: {
      title: `Does your main character get what they want or not? Is it good or bad for them? Do they reach the Visible Tangible Goal? This section should address how your main character comes to grips with their nemesis. Their growth through the previous structure sign posts or lack of growth will determine whether or not they can successfully neutralize the nemesis and if they get what they want – or get what they need – or both. (Often ~80-100% of your story which is traditionally Act III)`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/conflict%20resolution.mp4'
    }
  },
  {
    id: nanoid(),
    key: 'SATISFYING_ENDING',
    name: 'Satisfying Ending',
    textColor: '#fff',
    bgColor: '#3fb733',
    width: 400,
    answers: '',
    guideData: {
      title: `How do you want your audience to feel at the end and have your delivered a satisfying ending that has been earned and properly anticipated?`,
      videoUrl:
        'https://media.writerduet.com/videos/hartchart/satesfying%20ending%20not%20good%20not%20bad.mp4'
    }
  }
]

export default defaultSignposts
