import { useState } from 'react'
import { Grid, Typography, Button, Box, IconButton } from '@material-ui/core'
import {
  Close,
  Twitter,
  Facebook,
  YouTube,
  Storefront
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore, useAuth, useFirebaseApp } from 'reactfire'
import { doc, updateDoc, collection, addDoc, getDoc } from 'firebase/firestore'
import { useHistory } from 'react-router-dom'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { Dialog, SubmitButton } from '../ui'
import VideoSlider from './VideoSlider'
import TipsSlider from './TipsSlider'
import defaultSignposts from '../../utils/defaultSignposts'
import fileActions from '../../redux/actions/file'
import authActions from '../../redux/actions/auth'
import purchaseActions from '../../redux/actions/purchase'
import appActions from '../../redux/actions/app'
import ExampleChartsDialog from '../ExampleChartsDialog'

import logo from '../../assets/img/logo.png'

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%'
  },
  tipsContainer: {
    backgroundColor: '#0087a4',
    color: 'white',
    textAlign: 'center',
    padding: 12
  },
  socialMediaContainer: {
    backgroundColor: '#0087a4',
    color: 'white',
    textAlign: 'center'
  },
  mainContentContainer: {
    background: '#eee'
  },
  buttons: {
    marginTop: 6
  },
  actionButtons: {
    color: theme.palette.getContrastText('#026f87'),
    fontWeight: 'bold',
    backgroundColor: '#026f87',
    '&:hover': {
      backgroundColor: '#004756'
    }
  },
  purchaseButton: {
    color: theme.palette.getContrastText(green[800]),
    backgroundColor: green[800],
    '&:hover': {
      backgroundColor: green[900]
    }
  }
}))

const Dashboard = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isModalOpen } = useSelector((state) => state.dashboard)
  const { user } = useSelector((state) => state.auth)
  const { subscription } = useSelector((state) => state.app)
  const [isLoading, setIsloading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [openLastChartLoading, setOpenLastChartLoading] = useState(false)
  const [seriesLoading, setSeriesLoading] = useState(false)
  const [isBillingDetailsLoading, setBillingDetailsLoading] = useState(false)
  const app = useFirebaseApp()
  const firestore = useFirestore()
  const auth = useAuth()
  const history = useHistory()

  const createNewChart = async () => {
    setIsloading(true)
    try {
      const userRef = doc(firestore, 'users', user.email)
      const docRef = await addDoc(collection(firestore, 'charts'), {
        signposts: defaultSignposts,
        characters: [],
        draw: [],
        title: '',
        personalNotes: '',
        questions: {},
        collaborate: [],
        links: [],
        userId: userRef,
        projectId: null
      })
      const updateData = {
        lastChart: docRef.id
      }
      await updateDoc(userRef, updateData)
      dispatch(appActions.setChartType('MOVIES'))
      dispatch(authActions.updateUserData(updateData))
      setOpenLastChartLoading(false)
      setIsloading(false)
      history.push(`/app/${docRef.id}`)
    } catch (error) {
      setOpenLastChartLoading(false)
      setIsloading(false)
      throw new Error(error)
    }
  }

  const createNewSeriesChart = async () => {
    setSeriesLoading(true)
    try {
      const userRef = doc(firestore, 'users', user.email)
      const docRef = await addDoc(collection(firestore, 'series'), {
        signposts: defaultSignposts,
        characters: [],
        draw: [],
        title: '',
        personalNotes: '',
        questions: {},
        collaborate: [],
        links: [],
        userId: userRef,
        projectId: null,
        type: 'SERIES'
      })
      setSeriesLoading(false)
      dispatch(appActions.setChartType('SERIES'))
      history.push(`/series/${docRef.id}`)
    } catch (error) {
      setSeriesLoading(false)
      throw new Error(error)
    }
  }

  const accessLastChart = async () => {
    setOpenLastChartLoading(true)
    if (!subscription.active) {
      await auth.signOut()
      history.push('/login')
    } else if (user.lastChart) {
      const movieChartRef = doc(firestore, `/charts`, user.lastChart)
      const seriesChartRef = doc(firestore, `/series`, user.lastChart)
      const dataMovieChart = await getDoc(movieChartRef)
      const dataSerieChart = await getDoc(seriesChartRef)
      if (dataMovieChart.exists()) {
        setOpenLastChartLoading(false)
        history.push(`/app/${user.lastChart}`)
      } else if (dataSerieChart.exists()) {
        setOpenLastChartLoading(false)
        history.push(`/series/${user.lastChart}`)
      } else {
        const userRef = doc(firestore, 'users', user.email)
        await updateDoc(userRef, {
          lastChart: null
        })
        setOpenLastChartLoading(false)
        dispatch(
          authActions.updateUserData({
            lastChart: null
          })
        )
      }
    } else {
      createNewChart()
    }
  }

  const openHartChart = () => {
    dispatch(fileActions.foldersOpen())
  }

  const viewPaymentsPortal = async () => {
    try {
      setBillingDetailsLoading(true)
      const functions = getFunctions(app, 'us-west2')
      const functionRef = httpsCallable(
        functions,
        'ext-firestore-stripe-payments-createPortalLink'
      )

      const { data } = await functionRef({
        returnUrl: window.location.origin
      })
      setBillingDetailsLoading(false)
      window.location.assign(data.url)
    } catch (error) {
      setBillingDetailsLoading(false)
      console.log(error)
    }
  }

  const openImportDialog = () => {
    dispatch(appActions.importDialogOpen())
  }

  if (!user) return null

  return (
    <Dialog
      open={isModalOpen}
      onClose={() => null}
      maxWidth="md"
      divider
      dialogContentProps={{ style: { padding: 0 } }}
    >
      <ExampleChartsDialog modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <Grid container>
        <Box padding={2} className={classes.mainContentContainer}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <IconButton
                aria-label="close"
                onClick={accessLastChart}
                style={{ float: 'right', padding: 5 }}
              >
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <img src={logo} alt="hartchart logo" className={classes.image} />
              <Typography
                align="justify"
                color={!subscription.active ? 'error' : 'initial'}
              >
                Welcome, {user.name}
                {!subscription.active && ' - Your trial has expired.'}{' '}
              </Typography>

              {subscription.accountType === 'FREE' && (
                <Typography align="justify" color="primary">
                  Subscribing to the app you will get 15 days of free trial!
                </Typography>
              )}

              <SubmitButton
                isLoading={isLoading}
                onClick={createNewChart}
                text="Create a new chart"
                className={classes.buttons}
                variant="contained"
                disabled={!subscription.active}
                fullWidth
              />
              <SubmitButton
                isLoading={seriesLoading}
                onClick={createNewSeriesChart}
                text="Create a new series chart"
                className={classes.buttons}
                variant="contained"
                disabled={!subscription.active}
                color="primary"
                fullWidth
              />
              <Button
                className={classes.buttons}
                variant="contained"
                color="primary"
                fullWidth
                disabled={!subscription.active}
                onClick={openHartChart}
              >
                Open
              </Button>
              <SubmitButton
                isLoading={openLastChartLoading}
                onClick={accessLastChart}
                text="Open latest chart"
                className={classes.buttons}
                variant="contained"
                disabled={!user.lastChart || !subscription.active}
                color="primary"
                fullWidth
              />
              <Button
                className={classes.buttons}
                variant="contained"
                color="primary"
                fullWidth
                disabled={!subscription.active}
                onClick={() => setModalOpen(true)}
              >
                Example charts
              </Button>
              <Button
                className={classes.buttons}
                variant="contained"
                color="primary"
                fullWidth
                onClick={openImportDialog}
              >
                Import old charts
              </Button>

              <Button
                fullWidth
                variant="contained"
                classes={{ root: clsx(classes.buttons, classes.actionButtons) }}
                href="https://www.youtube.com/playlist?list=PLPTFSiudC-uU0DT7eM7b32L0dNvGQgA6H"
                target="_blank"
                endIcon={<Storefront />}
              >
                Tutorials and more...
              </Button>
              {subscription.accountType === 'SUBSCRIBED' && (
                <>
                  <SubmitButton
                    isLoading={isBillingDetailsLoading}
                    onClick={viewPaymentsPortal}
                    text="View payments details"
                    className={clsx(classes.buttons, classes.purchaseButton)}
                    variant="contained"
                    fullWidth
                  />
                  <Typography
                    align="center"
                    color="primary"
                    style={{ marginTop: 8 }}
                  >
                    Thank you for subscribing, active untill:{' '}
                    {new Date(subscription.end).toLocaleDateString()}
                  </Typography>
                </>
              )}
              {subscription.accountType === 'FREE' && (
                <Button
                  fullWidth
                  variant="contained"
                  className={clsx(classes.buttons, classes.purchaseButton)}
                  onClick={() => dispatch(purchaseActions.purchaseOpen())}
                >
                  Purchase the HartChart + 15 days of free trial!
                </Button>
              )}
            </Grid>
            <Grid item xs={6}>
              <VideoSlider />
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12} className={classes.tipsContainer}>
          <TipsSlider />
        </Grid>
        <Grid item xs={12} className={classes.socialMediaContainer}>
          <IconButton>
            <Twitter />
          </IconButton>
          <IconButton>
            <Facebook />
          </IconButton>
          <IconButton>
            <YouTube />
          </IconButton>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default Dashboard
