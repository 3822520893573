import { useState, useLayoutEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useFirestore } from 'reactfire'
import { doc, updateDoc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'

import undoActions from '../../redux/actions/undo'
import ChangesDrawer from '../ChangesDrawer'

const useStyles = makeStyles(() => ({
  paper: {
    top: '60px !important'
  }
}))

export default function EditMenu() {
  const { id } = useParams()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { future, past } = useSelector((state) => state.undo)
  const { chartType } = useSelector((state) => state.app)
  const { readOnly } = useSelector((state) => state.auth)
  const firestore = useFirestore()
  const dispatch = useDispatch()
  const chartRef = doc(firestore, `charts/${id}`)

  const undo = useCallback(async () => {
    setAnchorEl(null)
    dispatch(undoActions.handleUndo())
    if (past.length) {
      const pastCopy = cloneDeep(past)

      const newPresent = pastCopy[pastCopy.length - 1]

      await updateDoc(chartRef, newPresent)
    }
  }, [past, chartRef])

  const redo = useCallback(async () => {
    setAnchorEl(null)
    dispatch(undoActions.handleRedo())
    if (future.length) {
      const futureCopy = cloneDeep(future)

      const next = futureCopy[0]

      await updateDoc(chartRef, next)
    }
  }, [future, chartRef])

  useLayoutEffect(() => {
    const keydownListener = (e) => {
      const {
        key,
        ctrlKey,
        srcElement: { tagName }
      } = e
      if (key === 'z' && ctrlKey && past.length && tagName === 'BODY') {
        undo()
      }
      if (key === 'y' && ctrlKey && future.length && tagName === 'BODY') {
        redo()
      }
    }
    if (chartType === 'movie') {
      window.addEventListener('keydown', keydownListener, true)
    }
    return () => {
      if (chartType === 'movie') {
        window.removeEventListener('keydown', keydownListener, true)
      }
    }
  }, [future, past, undo, redo, chartType])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev)
    handleClose()
  }

  return (
    <>
      <Button style={{ color: '#fff' }} onClick={(e) => handleClick(e)}>
        Edit
      </Button>
      <ChangesDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <Menu
        classes={{ paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {!readOnly && [
          <MenuItem key="undo" disabled={!past.length} onClick={undo}>
            Undo
          </MenuItem>,
          <MenuItem key="redo" disabled={!future.length} onClick={redo}>
            Redo
          </MenuItem>
        ]}
        <MenuItem onClick={toggleDrawer}>All changes</MenuItem>
      </Menu>
    </>
  )
}
