import { useState, useEffect } from 'react'
import {
  Button,
  Popover,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Grid
} from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import { doc, collection, query, where, addDoc } from 'firebase/firestore'
import ProjectItem from './ProjectItem'
import ChartItem from './ChartItem'

import fileActions from '../../redux/actions/file'
import { Dialog } from '../ui'

const MyCharts = () => {
  const [anchor, setAnchor] = useState(null)
  const [newName, setNewName] = useState('')
  const [allCharts, setAllCharts] = useState([])
  const [searchText, setSeachText] = useState('')
  const { isModalOpen } = useSelector((state) => state.file)
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const projectRef = collection(firestore, 'projects')
  const chartsRef = collection(firestore, 'charts')
  const chartSeriesRef = collection(firestore, 'series')
  const userRef = doc(firestore, 'users', user.email)
  const projectsQuery = query(projectRef, where('userId', '==', userRef))

  const chartsQuery = query(chartsRef, where('userId', '==', userRef))
  const chartsSeriesQuery = query(
    chartSeriesRef,
    where('userId', '==', userRef)
  )
  const { status: projectLoading, data: projects } =
    useFirestoreCollectionData(projectsQuery)
  const { status: chartLoading, data: charts } = useFirestoreCollectionData(
    chartsQuery,
    {
      idField: 'id'
    }
  )
  const { data: seriesCharts } = useFirestoreCollectionData(chartsSeriesQuery, {
    idField: 'id'
  })

  useEffect(() => {
    if (seriesCharts && charts) {
      setAllCharts([...seriesCharts, ...charts])
    }
  }, [charts, seriesCharts])

  const openPopover = async (event) => {
    setAnchor(event.currentTarget)
  }

  const closeModal = () => {
    dispatch(fileActions.foldersClose())
  }

  const addFolder = async () => {
    setAnchor(null)
    try {
      if (newName.trim() !== '') {
        await addDoc(projectRef, {
          name: newName,
          userId: userRef
        })
      }
    } catch (err) {
      console.log(err, 'error al agregar nueva Carpeta')
    }
    setNewName('')
  }

  const handleSearch = (e) => {
    const {
      target: { value }
    } = e
    setSeachText(value)

    if (value) {
      const filteredCharts = charts.filter((item) =>
        item.title.trim().toLowerCase().includes(value.trim().toLowerCase())
      )

      setAllCharts(filteredCharts)
    } else {
      setAllCharts(charts)
    }
  }

  if (projectLoading === 'loading') {
    return <span>loading...</span>
  }

  if (chartLoading === 'loading') {
    return <span>loading...</span>
  }

  return (
    <>
      <Dialog
        open={isModalOpen}
        onClose={closeModal}
        title="Open HartChart"
        maxWidth="md"
        fullWidth
        closable
        divider
        dragglable
      >
        <Grid container>
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <TextField
              onChange={handleSearch}
              value={searchText}
              label="Search for charts"
            />
            <Button
              onClick={openPopover}
              variant="contained"
              color="primary"
              size="small"
              endIcon={<Add />}
              style={{ justifySelf: 'end' }}
            >
              Add Folder
            </Button>
          </Grid>
          <Grid item xs={12}>
            <List>
              <ListSubheader disableSticky>My Projects</ListSubheader>
              {projects.length > 0 ? (
                projects.map((project, index) => (
                  <ProjectItem
                    key={index}
                    Projectfolder={project}
                    charts={allCharts.filter(
                      (chart) => chart.projectId?.id === project.NO_ID_FIELD
                    )}
                  />
                ))
              ) : (
                <ListItem>
                  <ListItemText secondary="No projects" />
                </ListItem>
              )}

              <ListSubheader disableSticky>Without Project</ListSubheader>

              {allCharts.filter((item) => item.projectId === null).length >
              0 ? (
                allCharts
                  .filter((item) => item.projectId === null)
                  .map((chart, index) => (
                    <ChartItem key={index} chartdocument={chart} />
                  ))
              ) : (
                <ListItem>
                  <ListItemText secondary="No charts" />
                </ListItem>
              )}
            </List>
          </Grid>
        </Grid>
      </Dialog>

      <Popover
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Grid container style={{ padding: 12 }}>
          <Grid item xs={8}>
            <TextField
              onChange={(e) => {
                setNewName(e.target.value)
              }}
              defaultValue={newName}
              autoFocus={true}
              required
              label="Folder Name"
            />
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <Button variant="contained" onClick={addFolder}>
              Create
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

export default MyCharts
