import { useState, useEffect } from 'react'
import {
  Grid,
  Input,
  CircularProgress,
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
  ListSubheader,
  LinearProgress
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useParams } from 'react-router-dom'
import { useStorage, useFirestore } from 'reactfire'
import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  getMetadata,
  deleteObject
} from 'firebase/storage'
import { doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'

import drawingToolsActions from '../../redux/actions/drawingTools'
import useAddTrackingData from '../../hooks/useAddTrackingData'
import { SubmitButton } from '../ui'

const maxStorage = 50 * 1000 * 1000

const UploadFiles = () => {
  const [uploading, setUploading] = useState(false)
  const [value, setValue] = useState(null)
  const [userImages, setUserImages] = useState([])
  const [error, setError] = useState(null)
  const [storageTaken, setStorageTaken] = useState({ size: 0, percent: 0 })
  const [userImagesLoading, setUserImagesLoading] = useState(false)
  const dispatch = useDispatch()
  const { subject } = useSelector((state) => state.drawingTools)
  const { user } = useSelector((state) => state.auth)
  const { chartType } = useSelector((state) => state.app)
  const { id: chartId, episodeId } = useParams()
  const storage = useStorage()
  const firestore = useFirestore()
  const updateTrackingData = useAddTrackingData()

  useEffect(() => {
    const getImagesLoaded = async () => {
      try {
        setUserImagesLoading(true)
        const listRef = ref(storage, `${user.email}/`)
        const { items } = await listAll(listRef)
        const promisesMetadaImages = items.map((item) => getMetadata(item))
        const resolvedMetadata = await Promise.all(promisesMetadaImages)

        const promisesUrlImages = items.map((item) => getDownloadURL(item))
        const resolvedUrlData = await Promise.all(promisesUrlImages)
        const combinedData = resolvedMetadata.map((item, index) => ({
          ...item,
          url: resolvedUrlData[index]
        }))

        const totalFilesSize = combinedData.reduce((total, item) => {
          total += item.size
          return total
        }, 0)
        const percentTaken = (totalFilesSize / maxStorage) * 100
        setStorageTaken({
          size: totalFilesSize,
          percent: percentTaken > 100 ? 100 : percentTaken
        })
        setUserImages(combinedData)
        setUserImagesLoading(false)
      } catch (err) {
        setUserImagesLoading(false)
        console.err(err)
      }
    }
    getImagesLoaded()
  }, [])

  const uploadFile = async () => {
    try {
      setUploading(true)

      const subjectClone = cloneDeep(subject)
      const fileRef = ref(storage, `${user.email}/${Date.now()}${value.name}`)
      await uploadBytes(fileRef, value)
      await getDownloadURL(fileRef).then((result) => {
        subjectClone.typeFile = value.type
        subjectClone.url = result
      })

      let docuRef
      if (chartType === 'SERIES') {
        docuRef = doc(firestore, `series/${chartId}`)
      } else if (chartType === 'EPISODE') {
        docuRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
      } else {
        docuRef = doc(firestore, `charts/${chartId}`)
      }

      const query = await getDoc(docuRef)
      if (query.exists()) {
        const currentData = query.data()
        currentData.draw.push(subjectClone)
        await updateDoc(docuRef, currentData)
        updateTrackingData({
          draw: arrayUnion(subjectClone),
          action: `Inserted multimedia via file upload`
        })
        dispatch(drawingToolsActions.chooseTool('SELECT'))
        dispatch(drawingToolsActions.insertUrlClose())
        setValue('')
      }
    } catch (err) {
      setUploading(false)
      console.log(err)
    }
  }

  const insertIconToGraph = async (fileData) => {
    try {
      setUploading(true)

      let chartRef
      if (chartType === 'SERIES') {
        chartRef = doc(firestore, `series/${chartId}`)
      } else if (chartType === 'EPISODE') {
        chartRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
      } else {
        chartRef = doc(firestore, `charts/${chartId}`)
      }

      const subjectClone = cloneDeep(subject)

      subjectClone.typeFile = fileData.contentType
      subjectClone.url = fileData.url

      await updateDoc(chartRef, {
        draw: arrayUnion(subjectClone)
      })
      updateTrackingData({
        draw: arrayUnion(subjectClone),
        action: `Inserted multimedia via file upload`
      })
      setUploading(false)
      setUserImages([])
      dispatch(drawingToolsActions.chooseTool('SELECT'))
      dispatch(drawingToolsActions.insertUrlClose())
    } catch (err) {
      setUploading(false)
      console.log(err)
    }
  }

  const handleFileChange = (e) => {
    setValue(null)
    if (e.target.files[0]) {
      const file = e.target.files[0]
      if (!file.type.includes('image')) {
        setError('Only images are allowed.')
      } else if (file.size > 1 * 1000 * 1000) {
        setError('The file must be less than 1mb.')
      } else {
        setValue(file)
        setError(null)
      }
    }
  }

  const deleteImage = async (data) => {
    const desertRef = ref(storage, data.fullPath)

    await deleteObject(desertRef)
    const filtered = userImages.filter((item) => item.name !== data.name)
    const totalFilesSize = filtered.reduce((total, item) => {
      total += item.size
      return total
    }, 0)
    const percentTaken = (totalFilesSize / maxStorage) * 100
    setStorageTaken({
      size: totalFilesSize,
      percent: percentTaken > 100 ? 100 : percentTaken
    })
    setUserImages(filtered)
  }

  return (
    <Grid container spacing={2}>
      {userImagesLoading ? (
        <Grid
          item
          container
          xs={12}
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <CircularProgress />
          <Typography align="center">
            Loading your storage data
            <br />
            Please Wait
          </Typography>
        </Grid>
      ) : (
        <>
          {storageTaken.percent >= 100 ? (
            <Grid item xs={12}>
              <Typography>
                You have depleated the storage available to your account, please
                delete old files and try again.
              </Typography>
            </Grid>
          ) : (
            <>
              {' '}
              <Grid item xs={12}>
                <Input
                  id="formFileId"
                  type="file"
                  fullWidth
                  variant="dense"
                  onChange={handleFileChange}
                  disabled={uploading}
                  inputProps={{ accept: 'image/*' }}
                />
              </Grid>
              <Grid item xs={12}>
                {error ? (
                  <Typography variant="subtitle2" color="secondary">
                    {error}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2">
                    <strong>NOTE:</strong> You can only upload images files.
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                alignItems="center"
              >
                <SubmitButton
                  isLoading={uploading}
                  onClick={uploadFile}
                  text="Add"
                  variant="contained"
                  color="primary"
                  disabled={uploading || !value}
                  style={{ marginLeft: 12 }}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Typography>Storage available:</Typography>
          </Grid>
          <Grid item xs={12}>
            <LinearProgress
              variant="determinate"
              color={storageTaken.percent > 90 ? 'secondary' : 'primary'}
              value={storageTaken.percent}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography align="center">
              You have used {'  '}
              <strong>
                {(storageTaken.size / 1000 / 1000).toFixed(2)}Mb
              </strong>{' '}
              of <strong>50 mb</strong>.{' '}
            </Typography>
          </Grid>

          {userImages.length > 0 && (
            <Grid item xs={12}>
              <ImageList
                rowHeight={160}
                cols={3}
                gap={12}
                style={{ maxHeight: 500 }}
              >
                <ImageListItem
                  key="Subheader"
                  cols={3}
                  style={{ height: 'auto' }}
                >
                  <ListSubheader component="div">
                    Files you have uploaded
                  </ListSubheader>
                </ImageListItem>
                {userImages.length === 0 ? (
                  <Grid item xs={12}>
                    <Typography align="center">No results</Typography>
                  </Grid>
                ) : (
                  userImages.map((item) => (
                    <ImageListItem key={item.md5Hash} cols={1}>
                      <img
                        src={item.url}
                        alt={item.name}
                        style={{
                          cursor: uploading ? 'not-allowed' : 'pointer'
                        }}
                        onClick={() => !uploading && insertIconToGraph(item)}
                      />
                      <ImageListItemBar
                        title={item.name}
                        subtitle={`${item.size / 1000}KB`}
                        actionIcon={
                          <IconButton
                            color="secondary"
                            onClick={() => deleteImage(item)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  ))
                )}
              </ImageList>
            </Grid>
          )}
        </>
      )}
    </Grid>
  )
}

export default UploadFiles
