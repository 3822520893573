import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'reactfire'
import { useHistory } from 'react-router-dom'
import { doc, collection, addDoc, updateDoc } from 'firebase/firestore'
import {
  ListItemIcon,
  List,
  ListItem,
  ListItemText,
  CircularProgress
} from '@material-ui/core'
import { LocalMovies } from '@material-ui/icons'

import { Dialog } from '../ui'
import authActions from '../../redux/actions/auth'
import undoActions from '../../redux/actions/undo'
import guideActions from '../../redux/actions/guide'

import theMartian from '../../utils/examples_chart/theMartian.json'
import eigthGrade from '../../utils/examples_chart/8thGrade.json'
import cinderella from '../../utils/examples_chart/Cinderella.json'
import dracula from '../../utils/examples_chart/Dracula.json'
import getOut from '../../utils/examples_chart/GetOut.json'
import hook from '../../utils/examples_chart/Hook.json'
import lalaland from '../../utils/examples_chart/Lalaland.json'

const exampleCharts = [
  { title: 'Hook', data: hook },
  { title: 'LaLa Land', data: lalaland },
  { title: 'Get Out', data: getOut },
  { title: '8th Grade', data: eigthGrade },
  { title: 'Dracula', data: dracula },
  { title: 'Cinderella', data: cinderella },
  { title: 'The Martian', data: theMartian }
]

const ExampleChartsDialog = ({ modalOpen, setModalOpen }) => {
  const { user } = useSelector((state) => state.auth)
  const [isLoading, setIsLoading] = useState({})
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const history = useHistory()

  const dateContructor = () => {
    const date = new Date()
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ]

    const datestring = `${
      monthNames[date.getMonth()]
    } - ${date.getDate()} - ${date.getFullYear()} // ${date.getHours()}:${date.getMinutes()}`

    return datestring
  }

  const openExampleChart = async ({ title, data }) => {
    try {
      setIsLoading({ [title]: true })
      dispatch(guideActions.resetDrawSource())
      const userRef = doc(firestore, 'users', user.email)
      const docRef = await addDoc(collection(firestore, 'charts'), {
        collaborate: [],
        links: [],
        userId: userRef,
        projectId: null,
        lastUsed: dateContructor(),
        ...data
      })
      const updateData = {
        lastChart: docRef.id
      }
      await updateDoc(userRef, updateData)
      dispatch(undoActions.resetRegisters())
      dispatch(authActions.updateUserData(updateData))
      dispatch(authActions.resetGraphStatus())
      setIsLoading({})
      setModalOpen(false)
      history.push(`/app/${docRef.id}`)
    } catch (error) {
      setIsLoading({})
      throw new Error(error)
    }
  }

  return (
    <Dialog
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      title="Example charts"
      maxWidth="sm"
      fullWidth
      closable
      divider
      dragglable
    >
      <List component="nav">
        {exampleCharts.map((chart) => (
          <ListItem
            button
            disabled={isLoading[chart.title]}
            key={chart.title}
            onClick={() => openExampleChart(chart)}
          >
            <ListItemIcon>
              <LocalMovies />
            </ListItemIcon>
            <ListItemText primary={chart.title} />
            {isLoading[chart.title] && <CircularProgress />}
          </ListItem>
        ))}
      </List>
    </Dialog>
  )
}

export default ExampleChartsDialog
