import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore, useFirestoreDocData } from 'reactfire'
import { doc } from 'firebase/firestore'
import { Resizable } from 're-resizable'
import { SizeMe } from 'react-sizeme'

import Header from '../../components/Header'
import SidePanel from '../../components/SidePanel'
import ChartEpisodes from '../../components/ChartEpisodes'
import LoadingScreen from '../../components/LoadingScreen'
import CharactersDetails from '../../components/CharactersDetails'
import NotificationAlerts from '../../components/ui/NotificationAlerts'
import charactersActions from '../../redux/actions/characters'
import signpostsActions from '../../redux/actions/signposts'
import guideActions from '../../redux/actions/guide'
import animationToolsActions from '../../redux/actions/animationTools'
import undoActions from '../../redux/actions/undo'
import appActions from '../../redux/actions/app'

const MainEpisodes = () => {
  const { id, episodeId } = useParams()
  const { present } = useSelector((state) => state.undo)
  const { signposts, zoomLevel } = useSelector((state) => state.signposts)
  const firestore = useFirestore()
  const dispatch = useDispatch()

  const chartRef = doc(firestore, `/series/${id}/episodes/${episodeId}`)

  const { status, data } = useFirestoreDocData(chartRef)

  useEffect(() => {
    dispatch(appActions.setChartType('EPISODE'))
  }, [])

  useEffect(() => {
    const handleOffline = () => {
      dispatch(
        appActions.displayNotifications({
          open: true,
          variant: 'error',
          message: 'You are offline, changes will not be saved'
        })
      )
    }

    const handleOnline = () => {
      dispatch(
        appActions.displayNotifications({
          open: true,
          variant: 'success',
          message: 'You are back online'
        })
      )
    }
    window.addEventListener('offline', handleOffline)

    window.addEventListener('online', handleOnline)
    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [dispatch])

  useEffect(() => {
    if (data) {
      dispatch(
        undoActions.handleRegisters({
          characters: data.characters,
          signposts: data.signposts,
          title: data.title,
          personalNotes: data.personalNotes,
          draw: data.draw,
          startSignpost: data.startSignpost,
          finishSignpost: data.finishSignpost,
          color: data.color
        })
      )
    }
  }, [data])

  useEffect(() => {
    if (Object.keys(present).length) {
      dispatch(charactersActions.loadCharacters(present.characters))
      dispatch(signpostsActions.updateSignpostOrder(present.signposts))
      dispatch(guideActions.setGraphTitle(present.title))
      dispatch(
        guideActions.setEpisodeData({
          startSignpost: present.startSignpost,
          finishSignpost: present.finishSignpost,
          color: present.color
        })
      )
      dispatch(guideActions.setGraphNotes(present.personalNotes))
      dispatch(guideActions.setMultimediaSource(present.draw))
    }
  }, [present])

  useEffect(() => {
    const totalWidth = signposts.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.width * zoomLevel,
      0
    )
    dispatch(animationToolsActions.animateGraph(totalWidth))
  }, [zoomLevel, signposts])

  if (status === 'loading') {
    return <LoadingScreen />
  }

  return (
    <>
      <Header />
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          overflow: 'hidden',
          paddingTop: 65
        }}
      >
        <Resizable
          defaultSize={{
            width: '15%',
            height: '100%'
          }}
          maxWidth="100%"
          minWidth="1"
          enable={{
            right: true
          }}
        >
          <SidePanel />
        </Resizable>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
          }}
        >
          <Resizable
            defaultSize={{
              width: '100%',
              height: '45vh'
            }}
            minHeight="10px"
            enable={{
              bottom: true
            }}
          >
            <SizeMe monitorHeight>
              {({ size }) => <ChartEpisodes size={size} />}
            </SizeMe>
          </Resizable>
          <div
            style={{
              width: '100%',
              height: '100%',
              minHeight: '1px',
              zIndex: 2
            }}
          >
            <CharactersDetails />
          </div>
        </div>
      </div>
      <NotificationAlerts />
    </>
  )
}

export default MainEpisodes
