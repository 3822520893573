import config from '../config'

const shareTemplate = (chartId, userName, message, chartType) => `<body
  style="
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Arial, Helvetica, sans-serif;
  "
>
  <div
    style="
      width: 100%;
      opacity: 0.8;
      background: black;
      border-radius: 10px;
      padding: 10px;
    "
  >
    <div style="margin: 0 auto; width: 90%; text-align: center">
      <h1
        style="
          background-color: #0087a4;
          padding: 5px 10px;
          border-radius: 5px;
          color: white;
        "
      >
      ${userName} has shared a chart with you!
      </h1>
      <div
        style="
          margin: 30px auto;
          background: #eee;
          width: 40%;
          border-radius: 10px;
          padding: 50px;
        "
      >
        <img
          src="https://static.mywebsites360.com/ede5858c877044a983fa2b65a8f2629e/i/abc6592635284739a0bc43a1bc1a45f8/3/5feFb8zhrk/hclogo.png"
          alt="audisoft logo"
          style="width: 40%; margin: 0 auto"
        />
        <h4>
          Hi there, click on the button bellow to collaborate on a HartChart
          with ${userName}:
        </h4>
        <a
          style="
            display: block;
            margin: 0 auto;
            border: none;
            background-color: #0087a4;
            color: white;
            line-height: 24px;
            padding: 10px;
            font-size: 16px;
            font-weight: 700;
            border-radius: 50px;
            cursor: pointer;
            text-decoration: none;
          "
          href="${
            process.env.REACT_APP_ENVIRONMENT === 'prod'
              ? `${config.prod}/${
                  chartType === 'SERIES' ? 'series' : 'app'
                }/${chartId}`
              : `${config.dev}/${
                  chartType === 'SERIES' ? 'series' : 'app'
                }/${chartId}`
          }"
          target="_blank"
        >
          GO CHART
        </a>
        ${message && `<h4>${message}</h4>`}
        <h4>
          If you are having troubles with the button above, you can copy and
          paste this link:
        </h4>
        <a
          style="
            display: block;
            margin: 0 auto;
            border: none;
            color: #0087a4;
            line-height: 24px;
            padding: 10px;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            text-decoration: none;
          "
          href="${
            process.env.REACT_APP_ENVIRONMENT === 'prod'
              ? `${config.prod}/${
                  chartType === 'SERIES' ? 'series' : 'app'
                }/${chartId}`
              : `${config.dev}/${
                  chartType === 'SERIES' ? 'series' : 'app'
                }/${chartId}`
          }"
          target="_blank"
        >
        ${
          process.env.REACT_APP_ENVIRONMENT === 'prod'
            ? `${config.prod}/${
                chartType === 'SERIES' ? 'series' : 'app'
              }/${chartId}`
            : `${config.dev}/${
                chartType === 'SERIES' ? 'series' : 'app'
              }/${chartId}`
        }
        </a>
        <h4>Thanks,</h4>
        <h4>HartChart</h4>
      </div>
    </div>
  </div>
</body>
`

export default shareTemplate
