import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { collection, addDoc, Timestamp } from 'firebase/firestore'
import { useFirestore } from 'reactfire'

const useAddTrackingData = () => {
  const firestore = useFirestore()
  const { id: chartId, episodeId } = useParams()
  const { chartType } = useSelector((state) => state.app)
  const { characters } = useSelector((state) => state.characters)
  const { signposts } = useSelector((state) => state.signposts)
  const { draw, title, personalNotes, startSignpost, finishSignpost, color } =
    useSelector((state) => state.guide)

  const updateTrackingData = (trackData) => {
    let collectionRef = null
    const payload = {
      characters,
      draw,
      signposts,
      personalNotes,
      title,
      date: Timestamp.fromDate(new Date()),
      ...trackData
    }

    if (chartType === 'EPISODE') {
      collectionRef = collection(
        firestore,
        'series',
        chartId,
        'episodes',
        episodeId,
        'changes'
      )

      payload.startSignpost = startSignpost
      payload.finishSignpost = finishSignpost
      payload.color = color
      payload.type = 'EPISODE'
    }
    if (chartType === 'MOVIES') {
      collectionRef = collection(firestore, 'charts', chartId, 'changes')
    }
    if (chartType === 'SERIES') {
      collectionRef = collection(firestore, 'series', chartId, 'changes')
      payload.type = 'SERIES'
    }

    return addDoc(collectionRef, payload)
  }

  return updateTrackingData
}

export default useAddTrackingData
