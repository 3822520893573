import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Resizable } from 're-resizable'
import { scaleLinear } from 'd3'
import {
  Box,
  InputBase,
  IconButton,
  Tooltip,
  Menu,
  MenuItem
} from '@material-ui/core'
import { MoreVert, Create } from '@material-ui/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'reactfire'
import { doc, updateDoc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { cloneDeep, debounce } from 'lodash'
import { nanoid } from 'nanoid'

import guideActions from '../../redux/actions/guide'
import useAddTrackingData from '../../hooks/useAddTrackingData'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const useStyles = makeStyles(() => ({
  signpost_titles_container: {
    display: 'flex',
    backgroundColor: '#d1d1d1',
    height: '100%'
  },
  headerIcons: {
    fontSize: 16
  },
  paper: {
    top: '110px !important'
  }
}))

export default function SignpostColums() {
  const [anchorEl, setAnchorEl] = useState(null)
  const [signposts, setSignposts] = useState([])
  const [isOpen, setIsOpen] = useState({})
  const dispatch = useDispatch()
  const { signposts: storeSignposts, zoomLevel } = useSelector(
    (state) => state.signposts
  )
  const { draw } = useSelector((state) => state.guide)
  const { characters } = useSelector((state) => state.characters)
  const { graphWidth } = useSelector((state) => state.animationTools)
  const { readOnly } = useSelector((state) => state.auth)
  const firestore = useFirestore()
  const { id: chartId } = useParams()
  const chartRef = doc(firestore, `/charts`, chartId)
  const classes = useStyles()
  const updateTrackingData = useAddTrackingData()

  useEffect(() => {
    setSignposts(storeSignposts)
  }, [storeSignposts])

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const startIndex = result.source.index
    const endItem = result.destination.index
    const reorderItems = reorder(signposts, startIndex, endItem)

    setSignposts(reorderItems)

    updateDoc(chartRef, {
      signposts: reorderItems
    })

    updateTrackingData({
      signposts: reorderItems,
      action: `Changed "${reorderItems[endItem].name}" signpost position`
    })
  }

  const handleClick = (event, item) => {
    setIsOpen({ [item.id]: true })
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setIsOpen({})
    setAnchorEl(null)
  }

  const changeSignpostTitle = (id, value) => {
    const updatedSp = signposts.map((sp) =>
      sp.id === id ? { ...sp, name: value } : sp
    )
    setSignposts(updatedSp)
  }

  const updateDb = async () => {
    await updateDoc(chartRef, {
      signposts
    })
  }

  const changeBackground = debounce((color, spData) => {
    const updatedSp = signposts.map((sp) =>
      sp.id === spData.id ? { ...sp, bgColor: color } : sp
    )
    setSignposts(updatedSp)
    updateDoc(chartRef, {
      signposts: updatedSp
    })
    updateTrackingData({
      signposts: updatedSp,
      action: `Changed "${spData.name}" signpost color to: ${color}`
    })
  }, 500)

  const changeColorText = debounce((color, spData) => {
    const updatedSp = signposts.map((sp) =>
      sp.id === spData.id ? { ...sp, textColor: color } : sp
    )
    setSignposts(updatedSp)
    updateDoc(chartRef, {
      signposts: updatedSp
    })
    updateTrackingData({
      signposts: updatedSp,
      action: `Changed "${spData.name}" signpost text color to: ${color}`
    })
  }, 500)

  const updateXValue = ({
    signpostStarts,
    signpostInitialEnds,
    xValue,
    xScaleOld,
    signpostScale,
    xScaleNew,
    incrementValue
  }) => {
    if (
      xScaleOld(xValue) >= signpostStarts &&
      xScaleOld(xValue) <= signpostInitialEnds
    ) {
      return xScaleNew.invert(signpostScale(xScaleOld(xValue)))
    }
    if (xScaleOld(xValue) > signpostInitialEnds) {
      return xScaleNew.invert(xScaleOld(xValue) + incrementValue)
    }
    return xScaleNew.invert(xScaleOld(xValue))
  }

  const moveGraphElements = ({
    signpostStarts,
    signpostInitialEnds,
    signpostAfterEnds,
    newWidthGraph,
    incrementValue
  }) => {
    const charactersCopy = cloneDeep(characters)
    const drawCopy = cloneDeep(draw)

    const signpostScale = scaleLinear()
      .domain([signpostStarts, signpostInitialEnds])
      .range([signpostStarts, signpostAfterEnds])

    const xScaleOld = scaleLinear().domain([0, 5200]).range([0, graphWidth])

    const xScaleNew = scaleLinear().domain([0, 5200]).range([0, newWidthGraph])

    xScaleNew.clamp(true)

    const updatedCharacters = charactersCopy.map((character) => ({
      ...character,
      points: character.points.map((point) => ({
        ...point,
        pos: {
          ...point.pos,
          x: updateXValue({
            xValue: point.pos.x,
            signpostStarts,
            signpostInitialEnds,
            xScaleOld,
            signpostScale,
            xScaleNew,
            incrementValue
          })
        }
      }))
    }))

    const updatedDrawings = drawCopy.map((drawItem) => {
      if (
        drawItem.type === 'BRUSH' ||
        drawItem.type === 'PENCIL' ||
        drawItem.type === 'SIMPLE_LINE' ||
        drawItem.type === 'ARROW' ||
        drawItem.type === 'MULTI_ARROW' ||
        drawItem.type === 'TENSION_ARROW'
      ) {
        for (let index = 0; index < drawItem.path.length; index += 1) {
          const path = drawItem.path[index]
          path.x = updateXValue({
            xValue: path.x,
            signpostStarts,
            signpostInitialEnds,
            xScaleOld,
            signpostScale,
            xScaleNew,
            incrementValue
          })
        }
      }
      if (drawItem.type === 'CIRCLE' || drawItem.type === 'MEDIA') {
        drawItem.x1 = updateXValue({
          xValue: drawItem.x1,
          signpostStarts,
          signpostInitialEnds,
          xScaleOld,
          signpostScale,
          xScaleNew,
          incrementValue
        })
        drawItem.x2 = updateXValue({
          xValue: drawItem.x2,
          signpostStarts,
          signpostInitialEnds,
          xScaleOld,
          signpostScale,
          xScaleNew,
          incrementValue
        })
      }
      return drawItem
    })

    return { characters: updatedCharacters, draw: updatedDrawings }
  }

  const handleSignpostResize = (data, spData) => {
    if (!readOnly) {
      const signpostCopy = cloneDeep(signposts)
      let newWidthGraph = 0
      let rangeCount = 0
      let signpostStarts = 0
      let signpostInitialEnds = 0
      let signpostAfterEnds = 0

      for (let index = 0; index < signpostCopy.length; index += 1) {
        const item = signpostCopy[index]
        if (item.id === spData.id) {
          const scaledWidth = item.width * zoomLevel
          const newWidth = scaledWidth + data.width
          const formatedValue = newWidth / zoomLevel
          item.width = formatedValue
          signpostStarts = rangeCount
          signpostInitialEnds = rangeCount + scaledWidth
          signpostAfterEnds = rangeCount + newWidth
        } else {
          rangeCount += item.width * zoomLevel
        }
        newWidthGraph += item.width * zoomLevel
      }

      setSignposts(signpostCopy)

      const updatedElements = moveGraphElements({
        signpostStarts,
        signpostInitialEnds,
        signpostAfterEnds,
        newWidthGraph,
        incrementValue: data.width
      })

      updateDoc(chartRef, {
        signposts: signpostCopy,
        ...updatedElements
      })
      updateTrackingData({
        signposts: signpostCopy,
        action: `Changed "${spData.name}" signpost size`
      })
    }
  }

  const handleSignpostMove = (type, index) => {
    handleClose()
    const signpostCopy = cloneDeep(signposts)
    const [removed] = signpostCopy.splice(index, 1)
    const finalIndex = {
      left: index - 1,
      right: index + 1
    }
    signpostCopy.splice(finalIndex[type], 0, removed)

    setSignposts(signpostCopy)

    updateDoc(chartRef, {
      signposts: signpostCopy
    })
    updateTrackingData({
      signposts: signpostCopy,
      action: `Moved "${removed.name}" signpost to ${type}`
    })
  }

  const handleRemoveSignpost = (spData) => {
    handleClose()
    const signpostCopy = cloneDeep(signposts)
    const filteredSignposts = signpostCopy.filter(
      (item) => item.id !== spData.id
    )

    let rangeCount = 0
    let signpostStarts = 0
    let signpostInitialEnds = 0
    let signpostAfterEnds = 0

    for (let index = 0; index < signpostCopy.length; index += 1) {
      const item = signpostCopy[index]
      if (item.id === spData.id) {
        signpostStarts = rangeCount
        signpostInitialEnds = rangeCount
        signpostAfterEnds = rangeCount
      } else {
        rangeCount += item.width * zoomLevel
      }
    }

    setSignposts(filteredSignposts)

    const updatedElements = moveGraphElements({
      signpostStarts,
      signpostInitialEnds,
      signpostAfterEnds,
      newWidthGraph: graphWidth - spData.width,
      incrementValue: -spData.width
    })

    updateDoc(chartRef, {
      signposts: filteredSignposts,
      ...updatedElements
    })
    updateTrackingData({
      signposts: filteredSignposts,
      action: `Removed "${spData.name}" signpost`
    })
  }

  const cloneSignpost = (spIndex, spData) => {
    handleClose()
    const signpostCopy = cloneDeep(signposts)
    const id = nanoid()
    signpostCopy.splice(spIndex + 1, 0, {
      ...spData,
      id,
      key: id,
      name: 'New custom signpost'
    })

    let newWidthGraph = 0
    let rangeCount = 0
    let signpostStarts = 0
    let signpostInitialEnds = 0
    let signpostAfterEnds = 0

    for (let index = 0; index < signpostCopy.length; index += 1) {
      const item = signpostCopy[index]
      if (item.id === spData.id) {
        signpostStarts = rangeCount
        signpostInitialEnds = rangeCount + spData.width
        signpostAfterEnds = rangeCount + spData.width
      } else {
        rangeCount += item.width * zoomLevel
      }
      newWidthGraph += item.width * zoomLevel
    }

    setSignposts(signpostCopy)

    const updatedElements = moveGraphElements({
      signpostStarts,
      signpostInitialEnds,
      signpostAfterEnds,
      newWidthGraph,
      incrementValue: spData.width
    })

    updateDoc(chartRef, {
      signposts: signpostCopy,
      ...updatedElements
    })
    updateTrackingData({
      signposts: signpostCopy,
      action: `Cloned "${spData.name}" signpost`
    })
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(dropProvided) => (
          <div
            ref={dropProvided.innerRef}
            className={classes.signpost_titles_container}
            {...dropProvided.droppableProps}
          >
            {signposts.map((item, index) => (
              <Draggable
                key={item.key}
                draggableId={item.key}
                index={index}
                isDragDisabled={readOnly}
              >
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      backgroundColor: index % 2 !== 0 ? '#efefef' : '#fafafa',
                      ...provided.draggableProps.style
                    }}
                  >
                    <Resizable
                      size={{ width: item.width * zoomLevel }}
                      enable={{
                        right: !readOnly
                      }}
                      onResizeStop={(e, direction, ref, d) =>
                        handleSignpostResize(d, item)
                      }
                    >
                      <div
                        {...provided.dragHandleProps}
                        style={{
                          height: 45,
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          backgroundColor: item.bgColor,
                          color: item.textColor
                        }}
                      >
                        <Tooltip
                          title={
                            item.answers
                              ? item.answers
                              : 'Describe your story...'
                          }
                        >
                          <IconButton
                            className={classes.headerButtons}
                            onClick={() =>
                              dispatch(guideActions.signpostOpen())
                            }
                          >
                            <Create className={classes.headerIcons} />
                          </IconButton>
                        </Tooltip>
                        <InputBase
                          value={item.name}
                          placeholder="untitled"
                          onChange={(e) => {
                            changeSignpostTitle(item.id, e.target.value)
                          }}
                          inputProps={{
                            style: {
                              color: item.textColor,
                              textAlign: 'center',
                              fontSize: 14,
                              fontWeight: 'bold',
                              padding: 1
                            }
                          }}
                          onBlur={updateDb}
                          disabled={readOnly}
                          style={{ width: '100%' }}
                        />
                        {!readOnly && (
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={(e) => handleClick(e, item)}
                          >
                            <MoreVert />
                          </IconButton>
                        )}
                      </div>
                    </Resizable>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={!!isOpen[item.id]}
                      onClose={handleClose}
                      classes={{ paper: classes.paper }}
                    >
                      <MenuItem onClick={() => cloneSignpost(index, item)}>
                        Clone
                      </MenuItem>
                      {index !== 0 && (
                        <MenuItem
                          onClick={() => handleSignpostMove('left', index)}
                        >
                          Move left
                        </MenuItem>
                      )}
                      {index !== signposts.length - 1 && (
                        <MenuItem
                          onClick={() => handleSignpostMove('right', index)}
                        >
                          Move right
                        </MenuItem>
                      )}
                      <MenuItem>
                        <label
                          htmlFor={`bgColor${item.id}`}
                          style={{ cursor: 'pointer' }}
                        >
                          Change background color
                          <input
                            id={`bgColor${item.id}`}
                            name={`bgColor${item.id}`}
                            type="color"
                            style={{ visibility: 'hidden' }}
                            defaultValue={item.bgColor}
                            onChange={(e) =>
                              changeBackground(e.target.value, item)
                            }
                          />
                        </label>
                      </MenuItem>
                      <MenuItem>
                        <label
                          htmlFor={`textColor${item.id}`}
                          style={{ cursor: 'pointer' }}
                        >
                          Change text color
                          <input
                            id={`textColor${item.id}`}
                            name={`textColor${item.id}`}
                            type="color"
                            defaultValue={item.textColor}
                            style={{ visibility: 'hidden' }}
                            onChange={(e) =>
                              changeColorText(e.target.value, item)
                            }
                          />
                        </label>
                      </MenuItem>
                      <MenuItem onClick={() => handleRemoveSignpost(item)}>
                        Remove
                      </MenuItem>
                    </Menu>
                  </Box>
                )}
              </Draggable>
            ))}
            {dropProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
