import { createTheme } from '@material-ui/core/styles'

// const primary = '#00A8CA'
// const secondary = '#AEB6BF'
// const success = '#00C851'
// const warning = '#FF8000'
// const neutral = '#424242'
// const neutral_text = '#ffffff'

const theme = createTheme({
  palette: {
    type: 'light'
    // primary: {
    //   main: primary
    // },
    // secondary: {
    //   main: secondary
    // },
    // success: {
    //   main: success
    // },
    // warning: {
    //   main: warning
    // },
    // neutral: {
    //   main: neutral,
    //   text: neutral_text
    // }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
          height: '100%',
          width: '100%',
          minWidth: '100%',
          minHeight: '100vh'
        }
      }
    }
  }
})

export default theme
