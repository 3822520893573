import { makeStyles } from '@material-ui/core/styles'
import {
  Popper,
  Paper,
  Grid,
  InputBase,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { Delete, Close } from '@material-ui/icons/'
import { useSelector } from 'react-redux'
import { useFirestore } from 'reactfire'
import {
  doc,
  updateDoc,
  collection,
  addDoc,
  Timestamp
} from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'

const useStyles = makeStyles(() => ({
  footerContainer: { backgroundColor: '#595959', height: 28 },
  input: {
    color: '#fff',
    padding: 6,
    '& :disabled': {
      color: '#fff'
    }
  },
  detailsInput: {
    padding: 6,
    '& :disabled': {
      color: '#000'
    }
  },
  icons: {
    color: '#fff',
    fontSize: 20
  }
}))

export default function MarksDetails({
  data,
  handleClick,
  anchorEl,
  episodeData
}) {
  const classes = useStyles()
  const { characters } = useSelector((state) => state.characters)
  const { readOnly } = useSelector((state) => state.auth)
  const firestore = useFirestore()
  const { id: chartId } = useParams()
  const chartRef = doc(
    firestore,
    `series/${chartId}/episodes/${episodeData.id}`
  )
  const collectionRef = collection(
    firestore,
    'series',
    chartId,
    'episodes',
    episodeData.id,
    'changes'
  )

  const updateTrackingData = (trackData) => {
    const payload = {
      ...episodeData,
      date: Timestamp.fromDate(new Date()),
      ...trackData
    }
    delete payload.id
    return addDoc(collectionRef, payload)
  }

  const handleRemovePoint = () => {
    const characterCopy = cloneDeep(episodeData.characters)

    const [character] = characterCopy.splice(data.characterIndex, 1)
    character.points = character.points.filter((item) => item.id !== data.id)
    characterCopy.splice(data.characterIndex, 0, character)
    handleClick()
    updateDoc(chartRef, {
      characters: characterCopy
    })
    updateTrackingData({
      characters: characterCopy,
      action: `Removed "${character.name}" character "${data.header}" event`
    })
  }

  const setGraphHeader = (value) => {
    const characterCopy = cloneDeep(episodeData.characters)

    const [character] = characterCopy.splice(data.characterIndex, 1)
    character.points[data.pointIndex].header = value
    characterCopy.splice(data.characterIndex, 0, character)
    updateDoc(chartRef, {
      characters: characterCopy
    })
    updateTrackingData({
      characters: characterCopy,
      action: `Updated "${
        characterCopy[data.characterIndex].name
      }" character event header to: "${value}"`
    })
  }

  const setGraphDetails = (value) => {
    const characterCopy = cloneDeep(episodeData.characters)

    const [character] = characterCopy.splice(data.characterIndex, 1)
    character.points[data.pointIndex].description = value
    characterCopy.splice(data.characterIndex, 0, character)
    updateDoc(chartRef, {
      characters: characterCopy
    })
    updateTrackingData({
      characters: characterCopy,
      action: `Updated "${
        characterCopy[data.characterIndex].name
      }" character event details to: "${value}"`
    })
  }

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="top"
      modifiers={{
        flip: {
          enabled: true
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent'
        }
      }}
      style={{ zIndex: 2, border: '1px solid black' }}
    >
      <Paper>
        <Grid container>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            style={{
              backgroundColor: characters[data.characterIndex].color
            }}
          >
            <InputBase
              placeholder="Header..."
              defaultValue={data.header}
              onBlur={(e) => setGraphHeader(e.target.value)}
              className={classes.input}
              disabled={readOnly}
              style={{ fontSize: 13, width: '90%', padding: '0 6px' }}
            />
            <IconButton onClick={handleClick} style={{ padding: 6 }}>
              <Close className={classes.icons} />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <InputBase
              placeholder="Details..."
              fullWidth
              multiline
              rows={3}
              className={classes.detailsInput}
              defaultValue={data.description}
              onBlur={(e) => setGraphDetails(e.target.value)}
              disabled={readOnly}
              style={{ fontSize: 14 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            className={classes.footerContainer}
          >
            {!readOnly && (
              <Tooltip title="Delete">
                <IconButton onClick={handleRemovePoint} style={{ padding: 6 }}>
                  <Delete className={classes.icons} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Popper>
  )
}
