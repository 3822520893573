import { useState, useEffect } from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useParams } from 'react-router-dom'
import { useFirestore } from 'reactfire'
import { doc, updateDoc } from 'firebase/firestore'

import guideActions from '../../redux/actions/guide'
import { Dialog } from '../ui'
import TemplatePDF from './TemplatePDF'
import ParagraphForm from './ParagraphForm'
import useAddTrackingData from '../../hooks/useAddTrackingData'

const SignpostDialog = () => {
  const { yourSignposts, title } = useSelector((state) => state.guide)
  const { chartType } = useSelector((state) => state.app)
  const { signposts } = useSelector((state) => state.signposts)
  const [formValues, setFormValues] = useState({})
  const [renderDownload, setRenderDownload] = useState(false)
  const [isTouched, setIsTouched] = useState(false)
  const firestore = useFirestore()
  const { id: chartId, episodeId } = useParams()
  const dispatch = useDispatch()
  const updateTrackingData = useAddTrackingData()

  useEffect(() => {
    if (signposts) {
      const answersObject = {}
      signposts.forEach((item) => {
        answersObject[item.key] = item.answers
      })
      setFormValues(answersObject)
    }
  }, [signposts])

  const updateDb = async () => {
    let chartRef
    if (chartType === 'SERIES') {
      chartRef = doc(firestore, `series/${chartId}`)
    } else if (chartType === 'EPISODE') {
      chartRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
    } else {
      chartRef = doc(firestore, `charts/${chartId}`)
    }
    const signpostCopy = signposts.map((item) => ({
      ...item,
      answers: formValues[item.key]
    }))
    await updateDoc(chartRef, { signposts: signpostCopy })
    if (isTouched) {
      updateTrackingData({
        signposts: signpostCopy,
        action: 'Updated signposts questions'
      })
    }
  }

  const handleClose = () => {
    dispatch(guideActions.signpostClose())
    updateDb()
    setIsTouched(false)
  }

  const handleChange = (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value
    }))
    setIsTouched(true)
  }

  return (
    <Dialog
      open={yourSignposts.isModalOpen}
      onClose={handleClose}
      title="Signposts: where your characters make their choices"
      maxWidth="sm"
      closable
      divider
      dragglable
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="body2" align="justify" paragraph>
            Below are the signposts offered in our default template, developed
            and revised over many years by screenwriter J.V. Hart. But remember,
            signposts are not a formula, and can differ in every script. They
            are simply tools to help you drive your story forward without
            getting lost.
          </Typography>
        </Grid>

        <Grid item container justifyContent="flex-end" xs={12}>
          {renderDownload ? (
            <PDFDownloadLink
              document={<TemplatePDF title={title} signposts={signposts} />}
              fileName={`${title || 'untitled'} - signpost`}
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) => (
                <Button
                  variant="outlined"
                  onClick={() => setRenderDownload(false)}
                >
                  {loading ? 'Loading document...' : 'Download now!'}
                </Button>
              )}
            </PDFDownloadLink>
          ) : (
            <Button variant="outlined" onClick={() => setRenderDownload(true)}>
              Generate document
            </Button>
          )}
        </Grid>

        {signposts.map((sp) => (
          <Grid item xs={12} key={sp.key}>
            <ParagraphForm
              signpostData={sp}
              formValues={formValues}
              handleChange={handleChange}
            />
          </Grid>
        ))}
        <Grid item xs={12} container justifyContent="center">
          {renderDownload ? (
            <PDFDownloadLink
              document={<TemplatePDF title={title} signposts={signposts} />}
              fileName={`${title || 'untitled'} - signpost`}
              style={{ textDecoration: 'none' }}
            >
              {({ loading }) => (
                <Button
                  variant="outlined"
                  onClick={() => setRenderDownload(false)}
                >
                  {loading ? 'Loading document...' : 'Download now!'}
                </Button>
              )}
            </PDFDownloadLink>
          ) : (
            <Button variant="outlined" onClick={() => setRenderDownload(true)}>
              Generate document
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default SignpostDialog
