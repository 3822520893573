import React, { useEffect, useState, useRef } from 'react'
import { gsap, Linear } from 'gsap'
import { ButtonGroup, Button, Paper, Box, IconButton } from '@material-ui/core'
import {
  PlayArrowRounded,
  PauseRounded,
  StopRounded,
  SkipPreviousRounded,
  SkipNextRounded,
  Close
} from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'
import Draggable from 'react-draggable'
import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'

import animationToolsActions from '../../redux/actions/animationTools'

const useStyles = makeStyles(() => ({
  title: {
    backgroundColor: '#005669',
    color: 'white',
    padding: 2,
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'move'
  },
  closeIcon: { padding: 0, color: 'white' }
}))

export default function AnimationTools() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isPaused, graphWidth, isAnimating } = useSelector(
    (state) => state.animationTools
  )
  const { signposts } = useSelector((state) => state.signposts)
  const graph = document.querySelector('#animationBackground')
  const containerScroll = document.getElementById('CharactersDetailsId')
  const { current: tl } = useRef(gsap.timeline({ paused: true }))
  const [initialized, setInitialized] = useState(false)
  const calcDuration = signposts.length * 2

  useEffect(() => {
    if (initialized) {
      tl.to('#animationBackground', {
        width: 0,
        duration: calcDuration,
        ease: Linear.easeNone
      })
    }
  }, [initialized, calcDuration])

  const handlePlayAnimation = () => {
    dispatch(animationToolsActions.setIsAnimating(true))
    if (!initialized) {
      setInitialized(true)
      dispatch(animationToolsActions.pause(false))
      tl.play()
    }
    if (initialized && isPaused) {
      dispatch(animationToolsActions.pause(false))
      tl.resume()
    }
  }
  const handlePauseAnimation = () => {
    dispatch(animationToolsActions.setIsAnimating(true))
    dispatch(animationToolsActions.pause(true))
    tl.pause()
  }

  const handleForwardAnimation = () => {
    dispatch(animationToolsActions.setIsAnimating(true))
    if (!initialized) {
      setInitialized(true)
      containerScroll.scrollLeft = 0
      tl.seek(tl.startTime())
      tl.seek(tl.time() + 1)
      return
    }
    tl.seek(tl.time() + 1)
  }

  const handleBackwardsAnimation = () => {
    dispatch(animationToolsActions.setIsAnimating(true))
    if (!initialized) {
      setInitialized(true)
      containerScroll.scrollLeft = 0
      tl.seek(tl.startTime())
      tl.seek(tl.time() - 1)
      return
    }
    tl.seek(tl.time() - 1)
  }

  const handleStopAnimation = () => {
    dispatch(animationToolsActions.setIsAnimating(false))
    tl.clear()
    graph.style.width = `${graphWidth}px`
    containerScroll.scrollLeft = 0
    dispatch(animationToolsActions.pause(true))
    setInitialized(false)
  }

  const closeAnimationTools = () => {
    dispatch(animationToolsActions.animationToolsClose())
  }

  return (
    <Draggable handle=".handle" scale={1} bounds="body">
      <Paper style={{ zIndex: 1, position: 'absolute', top: 0, right: '35%' }}>
        <Box className={clsx('handle', classes.title)}>
          Animate
          <IconButton
            className={classes.closeIcon}
            onClick={closeAnimationTools}
          >
            <Close />
          </IconButton>
        </Box>
        <div>
          <ButtonGroup variant="text" style={{ color: 'black' }}>
            <Button size="small" onClick={handlePlayAnimation}>
              <PlayArrowRounded />
            </Button>
            <Button size="small" onClick={handlePauseAnimation}>
              <PauseRounded />
            </Button>
            <Button
              size="small"
              onClick={handleStopAnimation}
              disabled={!isAnimating}
            >
              <StopRounded />
            </Button>
            <Button size="small" onClick={handleBackwardsAnimation}>
              <SkipPreviousRounded />
            </Button>
            <Button size="small" onClick={handleForwardAnimation}>
              <SkipNextRounded />
            </Button>
          </ButtonGroup>
        </div>
      </Paper>
    </Draggable>
  )
}
