import { undoTypes } from '../types'

const handleUndo = () => (dispatch) => {
  dispatch({ type: undoTypes.UNDO })
}
const handleRedo = () => (dispatch) => {
  dispatch({ type: undoTypes.REDO })
}

const handleRegisters = (value) => (dispatch) => {
  dispatch({ type: undoTypes.SET_REGISTERS, payload: value })
}

const resetRegisters = () => (dispatch) => {
  dispatch({ type: undoTypes.RESET })
}

export default {
  handleUndo,
  handleRedo,
  handleRegisters,
  resetRegisters
}
