import { IconButton, Slider } from '@material-ui/core'
import { ZoomIn, ZoomOut } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'

import signpostsActions from '../../redux/actions/signposts'

const IOSSlider = withStyles({
  root: {
    color: '#027994',
    height: 2,
    padding: '15px 0'
  },
  thumb: {
    height: 14,
    width: 14,
    backgroundColor: '#fff'
  },
  active: {},
  valueLabel: {
    left: '-50%',
    top: -20,
    fontWeight: 'bold',
    '& *': {
      background: 'transparent',
      color: '#fff'
    }
  },
  track: {
    height: 2
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf'
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 10,
    width: 3,
    marginTop: -3
  },
  markActive: {
    opacity: 1,
    width: 3,
    backgroundColor: 'currentColor'
  }
})(Slider)

export default function ZoomMenu() {
  const dispatch = useDispatch()
  const { zoomLevel } = useSelector((state) => state.signposts)

  const handleZoom = (type) => {
    if (type === 'zoomOut') {
      dispatch(signpostsActions.zoomOutSignpost())
    }
    if (type === 'zoomIn') {
      dispatch(signpostsActions.zoomInSignpost())
    }
  }

  const handleSliderChange = (event, newValue) => {
    dispatch(signpostsActions.setZoomLevel(newValue))
  }

  return (
    <>
      <IconButton
        onClick={() => handleZoom('zoomOut')}
        disabled={zoomLevel <= 0.1}
      >
        <ZoomOut
          style={{ color: '#fff', opacity: zoomLevel <= 0.1 ? 0.5 : 1 }}
        />
      </IconButton>
      <IOSSlider
        value={zoomLevel}
        step={0.1}
        getAriaValueText={(value) => `${value}x`}
        valueLabelDisplay="auto"
        min={0.1}
        max={2}
        marks={[
          {
            value: 0.1
          },
          {
            value: 1
          },
          {
            value: 2
          }
        ]}
        onChange={handleSliderChange}
        style={{
          alignSelf: 'center',
          maxWidth: 150,
          marginLeft: 10,
          marginRight: 10
        }}
      />
      <IconButton
        onClick={() => handleZoom('zoomIn')}
        disabled={zoomLevel >= 2}
      >
        <ZoomIn style={{ color: '#fff', opacity: zoomLevel >= 2 ? 0.5 : 1 }} />
      </IconButton>
    </>
  )
}
