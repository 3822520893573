import { fileTypes } from '../types'

const foldersOpen = () => (dispatch) => {
  dispatch({ type: fileTypes.HARTCHART_FOLDERS_OPEN_MODAL })
}
const foldersClose = () => (dispatch) => {
  dispatch({ type: fileTypes.HARTCHART_FOLDERS_CLOSE_MODAL })
}

export default {
  foldersOpen,
  foldersClose
}
