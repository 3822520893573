import { appTypes } from '../types'

const importDialogOpen = () => (dispatch) => {
  dispatch({ type: appTypes.IMPORT_OPEN_MODAL })
}

const handleSaveAsDialog = (payload) => (dispatch) => {
  dispatch({ type: appTypes.HANDLE_SAVE_AS_DIALOG, payload })
}

const setChartType = (payload) => (dispatch) => {
  dispatch({ type: appTypes.SET_CHART_TYPE, payload })
}

const importDialogClose = () => (dispatch) => {
  dispatch({ type: appTypes.IMPORT_CLOSE_MODAL })
}

const setSubsData = (payload) => (dispatch) => {
  dispatch({ type: appTypes.SET_USER_SUBSCRIPTION, payload })
}

const setInvitationLinkData = (payload) => (dispatch) => {
  dispatch({ type: appTypes.SET_INVITATION_LINK, payload })
}

const displayNotifications = (payload) => (dispatch) => {
  dispatch({ type: appTypes.NOTIFICATION_SHOW, payload })
}

const closeNotifications = () => (dispatch) => {
  dispatch({
    type: appTypes.NOTIFICATION_SHOW,
    payload: {
      open: false
    }
  })
}

export default {
  handleSaveAsDialog,
  importDialogOpen,
  importDialogClose,
  setSubsData,
  setInvitationLinkData,
  displayNotifications,
  closeNotifications,
  setChartType
}
