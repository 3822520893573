import { animationToolsTypes } from '../types'

const defaultState = {
  isAnimating: false,
  isPaused: true,
  graphWidth: 5200,
  graphHeight: 500,
  isAnimationToolsOpen: true
}

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case animationToolsTypes.SET_PLAY: {
      return { ...state, isAnimating: true, graphWidth: payload }
    }
    case animationToolsTypes.SET_IS_ANIMATING: {
      return { ...state, isAnimating: payload }
    }
    case animationToolsTypes.SET_GRAPH_WIDTH: {
      return { ...state, graphWidth: payload }
    }
    case animationToolsTypes.SET_GRAPH_HEIGHT: {
      return { ...state, graphHeight: payload }
    }
    case animationToolsTypes.SET_PAUSE: {
      return { ...state, isPaused: payload }
    }
    case animationToolsTypes.SET_STOP: {
      return { ...state, isAnimating: false, graphWidth: 5200 }
    }
    case animationToolsTypes.FOWARD: {
      return { ...state, isAnimating: false, graphWidth: payload }
    }
    case animationToolsTypes.BACKWARDS: {
      return { ...state, isAnimating: false, graphWidth: payload }
    }
    case animationToolsTypes.ANIMATIONTOOLS_OPEN: {
      return { ...state, isAnimationToolsOpen: true }
    }
    case animationToolsTypes.ANIMATIONTOOLS_CLOSE: {
      return { ...state, isAnimationToolsOpen: false }
    }
    default:
      return state
  }
}

export default reducer
