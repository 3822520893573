import { useState } from 'react'
import { useFirestore } from 'reactfire'
import { collection, addDoc, doc } from 'firebase/firestore'
import { useSelector } from 'react-redux'
import {
  Grid,
  Typography,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Input,
  IconButton
} from '@material-ui/core'
import { FileCopy, CheckCircle } from '@material-ui/icons'
import { useParams } from 'react-router-dom'

import { SubmitButton } from '../ui'
import config from '../../config'

const LinkShare = () => {
  const [isUploading, setUploading] = useState(false)
  const [linkInfo, setLinkInfo] = useState('')
  const [error, setError] = useState(null)
  const [copyDone, setCopyDone] = useState(false)
  const [values, setValues] = useState({
    isReadOnlyAccess: false,
    isAdminAccess: false
  })
  const firestore = useFirestore()
  const { id } = useParams()
  const { chartType } = useSelector((state) => state.app)

  const createShareLink = async () => {
    try {
      setUploading(true)
      const chartRef = doc(
        firestore,
        chartType === 'SERIES' ? `/series/${id}` : `/charts/${id}`
      )
      const data = await addDoc(collection(firestore, 'collaborators_links'), {
        ...values,
        chartRef
      })
      setLinkInfo(
        process.env.REACT_APP_ENVIRONMENT === 'prod'
          ? `${config.prod}/share/${data.id}/${chartType}`
          : `${config.dev}/share/${data.id}/${chartType}`
      )
      setUploading(false)
      setError(null)
    } catch (err) {
      setUploading(false)
      setError('Something went wrong, please try again later.')
      console.error(err)
    }
  }

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.checked })
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  const copyToClipboard = async () => {
    const { state } = await navigator.permissions.query({
      name: 'clipboard-write'
    })
    if (state === 'granted' || state === 'prompt') {
      await navigator.clipboard.writeText(linkInfo).then(
        () => {
          setCopyDone(true)
        },
        () => {
          setCopyDone(false)
        }
      )
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom>
          Here you can generate a Public link to share your chart.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>Link options:</Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isReadOnlyAccess}
                  onChange={handleChange}
                  name="isReadOnlyAccess"
                />
              }
              label="Read only access"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isAdminAccess}
                  onChange={handleChange}
                  name="isAdminAccess"
                />
              }
              label="Admin access (Users with this link can setup/modify collaborators)"
            />
          </FormGroup>
        </FormControl>
      </Grid>
      {error && (
        <Grid item xs={12} style={{ marginTop: 12 }}>
          <Typography align="center" color="error" style={{ marginTop: 12 }}>
            {error}
          </Typography>
        </Grid>
      )}
      {linkInfo && (
        <Grid item xs={12} style={{ marginTop: 12 }}>
          <Typography style={{ marginTop: 12 }}>
            Here is your link, share it with your friends:
          </Typography>
          <Input
            fullWidth
            value={linkInfo}
            readOnly
            disableUnderline
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={copyToClipboard}
                  onMouseDown={handleMouseDownPassword}
                >
                  {copyDone ? (
                    <CheckCircle style={{ color: 'green' }} />
                  ) : (
                    <FileCopy />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </Grid>
      )}
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <SubmitButton
          isLoading={isUploading}
          onClick={() => createShareLink()}
          disabled={!!linkInfo}
          text="Generate"
          variant="contained"
          color="primary"
        />
      </Grid>
    </Grid>
  )
}

export default LinkShare
