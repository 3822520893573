import { signpostsTypes } from '../types'

const updateSignpostOrder = (data) => (dispatch) => {
  dispatch({ type: signpostsTypes.CHANGE_ORDER, payload: data })
}
const addSignpostBefore = (refSignpostIndex, newSignpost) => (dispatch) => {
  dispatch({
    type: signpostsTypes.ADD_BEFORE,
    payload: { refSignpostIndex, newSignpost }
  })
}
const addSignpostAfter = (refSignpostIndex, newSignpost) => (dispatch) => {
  dispatch({
    type: signpostsTypes.ADD_AFTER,
    payload: { refSignpostIndex, newSignpost }
  })
}
const removeSignpost = (key) => (dispatch) => {
  dispatch({
    type: signpostsTypes.REMOVE_SIGNPOST,
    payload: {
      key
    }
  })
}
const moveSignpostLeft = (startIndex) => (dispatch) => {
  dispatch({
    type: signpostsTypes.MOVE_LEFT,
    payload: {
      startIndex
    }
  })
}
const moveSignpostRight = (startIndex) => (dispatch) => {
  dispatch({
    type: signpostsTypes.MOVE_RIGHT,
    payload: {
      startIndex
    }
  })
}
const changeSignpostBackgroundColor = (startIndex, bgColor) => (dispatch) => {
  dispatch({
    type: signpostsTypes.CHANGE_BACKGROUND_COLOR,
    payload: {
      startIndex,
      bgColor
    }
  })
}
const changeSignpostTextColor = (startIndex, textColor) => (dispatch) => {
  dispatch({
    type: signpostsTypes.CHANGE_FONT_COLOR,
    payload: {
      startIndex,
      textColor
    }
  })
}
const changeSignpostSize = (id, width) => (dispatch) => {
  dispatch({
    type: signpostsTypes.CHANGE_SIGNPOST_SIZE,
    payload: {
      id,
      width
    }
  })
}
const zoomOutSignpost = () => (dispatch) => {
  dispatch({
    type: signpostsTypes.ZOOM_OUT
  })
}
const zoomInSignpost = () => (dispatch) => {
  dispatch({
    type: signpostsTypes.ZOOM_IN
  })
}

const setZoomLevel = (payload) => (dispatch) => {
  dispatch({
    type: signpostsTypes.SET_ZOOM_LEVEL,
    payload
  })
}
const changeSignpostTitle = (startIndex, title) => async (dispatch) => {
  dispatch({
    type: signpostsTypes.CHANGE_SIGNPOST_TITLE,
    payload: {
      startIndex,
      title
    }
  })
}

export default {
  setZoomLevel,
  updateSignpostOrder,
  addSignpostBefore,
  addSignpostAfter,
  removeSignpost,
  moveSignpostLeft,
  moveSignpostRight,
  changeSignpostBackgroundColor,
  changeSignpostTextColor,
  changeSignpostSize,
  zoomOutSignpost,
  zoomInSignpost,
  changeSignpostTitle
}
