import { isEqual, cloneDeep } from 'lodash'
import { undoTypes } from '../types'

const defaultState = {
  past: [],
  present: [],
  future: []
}

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case undoTypes.UNDO: {
      const { past, present, future } = state
      if (!past.length) {
        return state
      }
      const pastCopy = cloneDeep(past)
      const presentCopy = cloneDeep(present)
      const futureCopy = cloneDeep(future)

      const newPresent = pastCopy[pastCopy.length - 1]
      const newPast = pastCopy.slice(0, pastCopy.length - 1)

      return {
        past: newPast || [],
        present: newPresent,
        future: [presentCopy, ...futureCopy]
      }
    }
    case undoTypes.REDO: {
      const { past, present, future } = state
      if (!future.length) {
        return state
      }

      const pastCopy = cloneDeep(past)
      const presentCopy = cloneDeep(present)
      const futureCopy = cloneDeep(future)

      const next = futureCopy[0]
      const newFuture = futureCopy.slice(1)

      return {
        past: [...pastCopy, presentCopy],
        present: next,
        future: newFuture
      }
    }
    case undoTypes.SET_REGISTERS: {
      const { past, present } = state
      if (!Object.keys(present).length) {
        return {
          past: [],
          present: payload,
          future: []
        }
      }

      if (isEqual(present, payload)) {
        return state
      }

      const pastCopy = cloneDeep(past)
      const presentCopy = cloneDeep(present)

      return {
        past: [...pastCopy, presentCopy],
        present: payload,
        future: []
      }
    }
    case undoTypes.RESET: {
      return {
        past: [],
        present: [],
        future: []
      }
    }
    default:
      return state
  }
}

export default reducer
