import { useState } from 'react'
import {
  IconButton,
  Popover,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import {
  ExitToApp as ExitToAppIcon,
  Store,
  Dashboard
} from '@material-ui/icons'
import { useAuth } from 'reactfire'
import { useHistory } from 'react-router-dom'

import purchaseActions from '../../redux/actions/purchase'

export default function UserMenu() {
  const [anchorEl, setAnchorEl] = useState(null)
  const { subscription } = useSelector((state) => state.app)
  const history = useHistory()
  const auth = useAuth()
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = async () => {
    try {
      await auth.signOut()
      history.push('/login')
    } catch (error) {
      throw new Error(error)
    }
  }

  const openPurchaseDialog = () => {
    dispatch(purchaseActions.purchaseOpen())
    setAnchorEl(null)
  }

  const openStartupDialog = () => {
    history.push('/welcome')
  }

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Avatar />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List dense component="nav">
          {!subscription.accountType === 'FREE' && (
            <ListItem button onClick={openPurchaseDialog}>
              <ListItemIcon>
                <Store />
              </ListItemIcon>
              <ListItemText primary="Purchase the HartChart" />
            </ListItem>
          )}
          <ListItem button onClick={openStartupDialog}>
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Go to dashboard" />
          </ListItem>
          <ListItem button onClick={handleLogOut}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </ListItem>
        </List>
      </Popover>
    </>
  )
}
