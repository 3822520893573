const appTypes = {
  IMPORT_OPEN_MODAL: 'IMPORT_OPEN_MODAL',
  IMPORT_CLOSE_MODAL: 'IMPORT_CLOSE_MODAL',
  SET_INVITATION_LINK: 'SET_INVITATION_LINK',
  NOTIFICATION_SHOW: 'NOTIFICATION_SHOW',
  SET_CHART_TYPE: 'SET_CHART_TYPE',
  SET_USER_SUBSCRIPTION: 'SET_USER_SUBSCRIPTION',
  HANDLE_SAVE_AS_DIALOG: 'HANDLE_SAVE_AS_DIALOG'
}

export default appTypes
