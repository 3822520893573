import { dashboardTypes } from '../types'

const defaultState = {
  isModalOpen: false
}

function reducer(state = defaultState, { type }) {
  switch (type) {
    case dashboardTypes.DASHBOARD_OPEN_MODAL: {
      return {
        ...state,
        isModalOpen: true
      }
    }
    case dashboardTypes.DASHBOARD_CLOSE_MODAL: {
      return {
        ...state,
        isModalOpen: false
      }
    }
    default:
      return state
  }
}

export default reducer
