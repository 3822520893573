import { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import {
  Paper,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Collapse,
  Switch,
  IconButton
} from '@material-ui/core'
import { useFirestore } from 'reactfire'
import { FileCopy, CheckCircle } from '@material-ui/icons'
import { doc, updateDoc, deleteDoc } from 'firebase/firestore'

import config from '../../config'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
    height: 45,
    backgroundColor: '#fff'
  },
  selectedCharacter: {
    backgroundColor: '#ffffff66 !important'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 5,
    color: '#fff',
    borderRadius: 0,
    backgroundColor: '#efefef',
    height: 45,
    '&:hover': {
      color: '#000',
      backgroundColor: '#efefef'
    }
  },
  color_picker: {
    width: 17,
    height: 17,
    borderRadius: '50%',
    marginLeft: 5
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}))

export default function CollabLinks({ data }) {
  const { chartType } = useSelector((state) => state.app)
  const [openSettings, setOpenSettings] = useState(false)
  const [copyDone, setCopyDone] = useState(false)
  const classes = useStyles()
  const firestore = useFirestore()

  const handleClickUserSetting = (id) => {
    setOpenSettings({
      [id]: !openSettings[id]
    })
  }

  useEffect(() => {
    const intervalId = setTimeout(() => setCopyDone(false), 1000)
    return () => clearInterval(intervalId)
  }, [copyDone])

  const handlePermissionsChange = async (linkData, type) => {
    const linkRef = doc(firestore, `/collaborators_links`, linkData.id)

    const newData = {
      ...linkData,
      [type]: !linkData[type]
    }

    await updateDoc(linkRef, newData)
  }

  const deleteLink = async (id) => {
    try {
      await deleteDoc(doc(firestore, `collaborators_links/${id}`))
    } catch (err) {
      console.log(err, 'error al eliminar chart')
    }
  }

  const copyToClipboard = async (id) => {
    const { state } = await navigator.permissions.query({
      name: 'clipboard-write'
    })
    if (state === 'granted' || state === 'prompt') {
      await navigator.clipboard
        .writeText(
          process.env.REACT_APP_ENVIRONMENT === 'prod'
            ? `${config.prod}/share/${id}/${chartType}`
            : `${config.dev}/share/${id}/${chartType}`
        )
        .then(
          () => {
            setCopyDone(true)
          },
          () => {
            setCopyDone(false)
          }
        )
    }
  }

  return (
    <Paper elevation={0} style={{ backgroundColor: 'transparent' }} square>
      <List
        subheader={
          <Typography style={{ color: '#F66', fontWeight: 600, marginLeft: 6 }}>
            {' '}
            Collaboration links:
          </Typography>
        }
        style={{ maxHeight: 380, overflowY: 'auto' }}
      >
        {data.map((item) => (
          <div key={item.id}>
            <ListItem
              classes={{
                selected: classes.selectedCharacter
              }}
              button
              onClick={() => handleClickUserSetting(item.id)}
            >
              <ListItemText
                primary={item.id}
                secondary={
                  <Typography
                    component="span"
                    variant="body2"
                    color="secondary"
                  >
                    {item.isReadOnlyAccess ? 'Read only' : 'Can modify'}
                  </Typography>
                }
                style={{ color: 'white' }}
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => copyToClipboard(item.id)}>
                  {copyDone ? (
                    <CheckCircle style={{ color: 'green' }} />
                  ) : (
                    <FileCopy style={{ color: '#fff' }} />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Collapse in={openSettings[item.id]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem className={classes.nested}>
                  <ListItemText
                    primary={
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ color: '#fff' }}
                      >
                        Read only Access
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={() =>
                        handlePermissionsChange(item, 'isReadOnlyAccess')
                      }
                      checked={item.isReadOnlyAccess}
                      color="secondary"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem className={classes.nested}>
                  <ListItemText
                    primary={
                      <Typography
                        component="span"
                        variant="body2"
                        style={{ color: '#fff' }}
                      >
                        Admin Access
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Switch
                      edge="end"
                      onChange={() =>
                        handlePermissionsChange(item, 'isAdminAccess')
                      }
                      checked={item.isAdminAccess}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ListItem button onClick={() => deleteLink(item.id)}>
                  <ListItemText
                    style={{
                      paddingLeft: 12
                    }}
                    primary={
                      <Typography
                        component="span"
                        variant="body2"
                        align="center"
                        color="error"
                      >
                        <strong>Delete</strong>
                      </Typography>
                    }
                  />
                </ListItem>
              </List>
            </Collapse>
          </div>
        ))}
      </List>
    </Paper>
  )
}
