import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

import logo from '../../assets/img/just-Logo.png'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: 'black',
    opacity: 0.8
  },
  logo: {
    width: '30%'
  },
  loading: {
    color: '#fff'
  }
}))
const LoadingScreen = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <img className={classes.logo} alt="hartchart logo" src={logo} />
      <CircularProgress className={classes.loading} />
    </div>
  )
}

export default LoadingScreen
