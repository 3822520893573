import { drawingToolsTypes } from '../types'

const chooseTool = (value) => (dispatch) => {
  dispatch({ type: drawingToolsTypes.SET_TOOL, payload: value })
}

const changeColor = (value) => (dispatch) => {
  dispatch({ type: drawingToolsTypes.SET_COLOR, payload: value })
}

const setMultimediaTab = (value) => (dispatch) => {
  dispatch({ type: drawingToolsTypes.SET_MULTIMEDIA_TAB, payload: value })
}

const drawingToolsOpen = () => (dispatch) => {
  dispatch({ type: drawingToolsTypes.DRAWINGTOOLS_OPEN })
}

const drawingToolsClose = () => (dispatch) => {
  dispatch({ type: drawingToolsTypes.DRAWINGTOOLS_CLOSE })
}

const insertUrlOpen = (payload) => (dispatch) => {
  dispatch({ type: drawingToolsTypes.INSERTURL_OPEN, payload })
}

const insertUrlClose = () => (dispatch) => {
  dispatch({ type: drawingToolsTypes.INSERTURL_CLOSE })
}

export default {
  chooseTool,
  changeColor,
  drawingToolsOpen,
  drawingToolsClose,
  insertUrlOpen,
  insertUrlClose,
  setMultimediaTab
}
