import { BrowserRouter as Router } from 'react-router-dom'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import {
  FirestoreProvider,
  AuthProvider,
  useFirebaseApp,
  StorageProvider
} from 'reactfire'

import Routes from './routes/Routes'
import ErrorBoundary from './components/ui/ErrorBoundary'
import './index.css'

const App = () => {
  const app = useFirebaseApp()
  const firestore = getFirestore(app)
  const auth = getAuth(app)
  const storage = getStorage(app)

  return (
    <Router>
      <AuthProvider sdk={auth}>
        <FirestoreProvider sdk={firestore}>
          <StorageProvider sdk={storage}>
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </StorageProvider>
        </FirestoreProvider>
      </AuthProvider>
    </Router>
  )
}
export default App
