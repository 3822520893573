import { cloneDeep } from 'lodash'

import { charactersTypes } from '../types'
import arrayDeepClone from '../../utils/arrayDeepClone'

const defaultState = {
  characters: [],
  selectedCharacter: {}
}

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case charactersTypes.ADD_MULTIMEDIA_URL: {
      const charactersCopy = state.characters
      let newSrc = ''
      if (payload.toLowerCase().indexOf('youtube') !== -1) {
        const res = payload.split('=')
        const embeddedUrl = `https://www.youtube.com/embed/${res[1]}`
        newSrc = {
          type: 'video',
          url: embeddedUrl,
          width: '300px',
          height: '150px',
          x: 0,
          y: 0
        }
      } else if (payload.toLowerCase().indexOf('soundcloud') !== -1) {
        newSrc = {
          type: 'song',
          url: payload,
          width: '300px',
          height: '150px',
          x: 0,
          y: 0
        }
      } else {
        newSrc = {
          type: 'image',
          url: payload,
          width: '300px',
          height: '150px',
          x: 0,
          y: 0
        }
      }
      for (let i = 0; i < charactersCopy.length; i += 1) {
        if (charactersCopy[i].id === state.selectedCharacter.id) {
          charactersCopy[i].multimedia.push(newSrc)
        }
      }

      return {
        ...state,
        characters: charactersCopy
      }
    }
    case charactersTypes.REMOVE_MULTIMEDIA_URL: {
      const { c, i } = payload
      state.characters[c].multimedia.splice(i, 1)
      return {
        ...state
      }
    }
    case charactersTypes.CHANGE_SIZE_MULTIMEDIA: {
      const charactersCopy = state.characters
      const { c, i, width, height } = payload
      charactersCopy[c].multimedia[i].width = `${width}px`
      charactersCopy[c].multimedia[i].height = `${height}px`

      return {
        ...state,
        characters: charactersCopy
      }
    }
    case charactersTypes.CHANGE_POSITION_MULTIMEDIA: {
      const charactersCopy = state.characters
      const { c, i, x, y } = payload
      charactersCopy[c].multimedia[i].x = x
      charactersCopy[c].multimedia[i].y = y

      return {
        ...state,
        characters: charactersCopy
      }
    }
    case charactersTypes.CHANGE_DESCRIPTION_SP: {
      const { value, signpostId } = payload
      const charactersCopy = arrayDeepClone(state.characters)
      const characterIndex = charactersCopy.findIndex(
        (item) => item.id === state.selectedCharacter.id
      )

      const [character] = charactersCopy.splice(characterIndex, 1)

      character.descriptionSP = character.descriptionSP.map((item) => {
        if (item.id === signpostId) {
          return {
            ...item,
            description: value
          }
        }
        return item
      })

      charactersCopy.splice(characterIndex, 0, character)

      return {
        ...state,
        characters: charactersCopy,
        selectedCharacter: character
      }
    }
    case charactersTypes.CHANGE_DESCRIPTION_CQ: {
      const charactersCopy = arrayDeepClone(state.characters)
      const characterIndex = charactersCopy.findIndex(
        (item) => item.id === state.selectedCharacter.id
      )

      const [character] = charactersCopy.splice(characterIndex, 1)

      character.descriptionCQ = payload

      charactersCopy.splice(characterIndex, 0, character)

      return {
        ...state,
        characters: charactersCopy
      }
    }
    case charactersTypes.SELECT_CHARACTER: {
      return {
        ...state,
        selectedCharacter: payload
      }
    }
    case charactersTypes.ADD_CHARACTER: {
      return {
        ...state,
        characters: [...state.characters, payload],
        selectedCharacter: payload
      }
    }
    case charactersTypes.LOAD_CHARACTERS: {
      const { selectedCharacter } = state
      const validateSelectedCharacter = Object.keys(selectedCharacter).length
      let updatedSelectedCharacter = {}
      if (validateSelectedCharacter) {
        updatedSelectedCharacter = payload.find(
          (item) => item.id === selectedCharacter.id
        )
      }

      return {
        ...state,
        characters: payload,
        selectedCharacter: updatedSelectedCharacter || {}
      }
    }
    case charactersTypes.ADD_CHARACTER_GRAPH_POINT: {
      const characterCopy = arrayDeepClone(state.characters)
      const characterIndex = state.characters.findIndex(
        (item) => payload.id === item.id
      )

      const [character] = characterCopy.splice(characterIndex, 1)

      const graphPoint = {
        id: payload.uid,
        description: '',
        header: '',
        pos: {
          x: payload.x,
          y: payload.y,
          active: false,
          offset: {}
        }
      }
      character.points.push(graphPoint)
      character.points.sort((a, b) => a.pos.x - b.pos.x)

      characterCopy.splice(characterIndex, 0, character)

      return {
        ...state,
        characters: characterCopy,
        selectedCharacter: character
      }
    }
    case charactersTypes.CHANGE_CHARACTER_ORDER: {
      return {
        ...state,
        characters: payload
      }
    }
    case charactersTypes.REMOVE_CHARACTER: {
      const filteredCharacters = state.characters.filter(
        (item) => item.id !== payload
      )
      return {
        ...state,
        characters: filteredCharacters,
        selectedCharacter:
          state.selectedCharacter.id === payload ? {} : state.selectedCharacter
      }
    }
    case charactersTypes.REMOVE_CHARACTER_GRAPH_POINT: {
      const characterCopy = arrayDeepClone(state.characters)
      const characterIndex = state.characters.findIndex(
        (item) => item.id === state.selectedCharacter.id
      )

      const [character] = characterCopy.splice(characterIndex, 1)

      const filteredCharactersPoints = character.points.filter(
        (item) => item.id !== payload
      )
      character.points = filteredCharactersPoints
      characterCopy.splice(characterIndex, 0, character)

      return {
        ...state,
        characters: characterCopy,
        selectedCharacter: character
      }
    }
    case charactersTypes.CHANGE_GRAPH_POINT_HEADER: {
      const characterCopy = cloneDeep(state.characters)
      const characterIndex = state.characters.findIndex(
        (item) => item.id === state.selectedCharacter.id
      )

      const [character] = characterCopy.splice(characterIndex, 1)

      const modifiedCharactersPoints = character.points.map((item) => {
        if (item.id === payload.id) {
          return { ...item, header: payload.header }
        }
        return item
      })

      character.points = modifiedCharactersPoints

      characterCopy.splice(characterIndex, 0, character)

      return {
        ...state,
        characters: characterCopy,
        selectedCharacter: character
      }
    }
    case charactersTypes.CHANGE_GRAPH_POINT_DETAILS: {
      const characterCopy = arrayDeepClone(state.characters)
      const characterIndex = state.characters.findIndex(
        (item) => item.id === state.selectedCharacter.id
      )

      const [character] = characterCopy.splice(characterIndex, 1)

      const modifiedCharactersPoints = character.points.map((item) => {
        if (item.id === payload.id) {
          return { ...item, description: payload.details }
        }
        return item
      })

      character.points = modifiedCharactersPoints

      characterCopy.splice(characterIndex, 0, character)

      return {
        ...state,
        characters: characterCopy,
        selectedCharacter: character
      }
    }
    case charactersTypes.CHANGE_CHARACTER_COLOR: {
      const characterCopy = arrayDeepClone(state.characters)
      const [character] = characterCopy.splice(payload.startIndex, 1)
      character.color = payload.color
      characterCopy.splice(payload.startIndex, 0, character)
      return {
        ...state,
        characters: characterCopy
      }
    }
    case charactersTypes.CHANGE_CHARACTER_COLOR_OPACITY: {
      const characterCopy = arrayDeepClone(state.characters)
      const [character] = characterCopy.splice(payload.startIndex, 1)
      character.colorOpacity = payload.colorOpacity
      characterCopy.splice(payload.startIndex, 0, character)
      return {
        ...state,
        characters: characterCopy
      }
    }
    case charactersTypes.CHANGE_CHARACTER_NAME: {
      const characterCopy = arrayDeepClone(state.characters)
      const [character] = characterCopy.splice(payload.startIndex, 1)
      character.name = payload.name
      characterCopy.splice(payload.startIndex, 0, character)
      return {
        ...state,
        characters: characterCopy
      }
    }
    case charactersTypes.SET_GRAPH_POINT_ACTIVE: {
      const characterCopy = arrayDeepClone(state.characters)

      const [character] = characterCopy.splice(payload.characterIndex, 1)

      character.points = character.points.map((item) => {
        if (item.id === payload.pointId) {
          return {
            ...item,
            pos: {
              ...item.pos,
              active: true,
              offset: {
                x: payload.x,
                y: payload.y
              }
            }
          }
        }
        return item
      })

      characterCopy.splice(payload.characterIndex, 0, character)

      return {
        ...state,
        characters: characterCopy
      }
    }
    case charactersTypes.SET_GRAPH_POINT_MOVE: {
      const characterCopy = cloneDeep(state.characters)

      for (let index = 0; index < characterCopy.length; index += 1) {
        const character = characterCopy[index]
        for (let i = 0; i < character.points.length; i += 1) {
          const point = character.points[i]
          if (point.id === payload.pointId) {
            point.pos.x = payload.x
            point.pos.y = payload.y
          }
        }
        character.points.sort((a, b) => a.pos.x - b.pos.x)
      }

      return {
        ...state,
        characters: characterCopy
      }
    }

    case charactersTypes.UNSET_GRAPH_POINT_ACTIVE: {
      const characterCopy = arrayDeepClone(state.characters)

      const [character] = characterCopy.splice(payload.characterIndex, 1)

      character.points = character.points.map((item) => {
        if (item.id === payload.pointId) {
          return {
            ...item,
            pos: {
              ...item.pos,
              active: false
            }
          }
        }
        return item
      })

      characterCopy.splice(payload.characterIndex, 0, character)

      return {
        ...state,
        characters: characterCopy
      }
    }
    default:
      return state
  }
}

export default reducer
