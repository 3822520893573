import { collaborateTypes } from '../types'

const defaultState = {
  isOpenModalJoinChart: false,
  isOpenModalShareChart: false,
  collaborators: []
}

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case collaborateTypes.UPDATE_COLLABORATORS: {
      return { ...state, collaborators: payload }
    }
    case collaborateTypes.OPEN_MODAL_JOIN_CHART: {
      return { ...state, isOpenModalJoinChart: true }
    }
    case collaborateTypes.CLOSE_MODAL_JOIN_CHART: {
      return { ...state, isOpenModalJoinChart: false }
    }
    case collaborateTypes.OPEN_MODAL_SHARE_CHART: {
      return { ...state, isOpenModalShareChart: true }
    }
    case collaborateTypes.CLOSE_MODAL_SHARE_CHART: {
      return { ...state, isOpenModalShareChart: false }
    }
    default:
      return state
  }
}

export default reducer
