import { authTypes } from '../types'

const loginSuccess = (payload) => ({
  type: authTypes.AUTH_LOGIN_SUCCESS,
  payload
})

const updateUserData = (payload) => ({
  type: authTypes.UPDATE_USER_PROFILE,
  payload
})

const setUserOwnership = () => ({
  type: authTypes.SET_OWNERSHIP
})

const setPreviewMode = (selectedChartId) => ({
  type: authTypes.SET_PREVIEW_MODE,
  payload: selectedChartId
})

const resetPreviewMode = () => ({
  type: authTypes.RESET_PREVIEW_MODE
})

const setGuestUser = (payload) => ({
  type: authTypes.SET_GUEST_USER,
  payload
})

const resetGraphStatus = () => ({
  type: authTypes.RESET_GRAPH_STATUS
})

export default {
  loginSuccess,
  updateUserData,
  setGuestUser,
  setUserOwnership,
  resetGraphStatus,
  setPreviewMode,
  resetPreviewMode
}
