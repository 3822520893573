import { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Typography, IconButton, Grid, Box } from '@material-ui/core'
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  EmojiObjectsOutlined
} from '@material-ui/icons'
import SwipeableViews from 'react-swipeable-views'

const tutorialSteps = [
  {
    key: 0,
    text: 'Double-click the chart to add a dot and header. Right-click to add a dot without adding the header.'
  },
  {
    key: 1,
    text: 'Click & drag the left and bottom of the graph to adjust its size.'
  },
  {
    key: 2,
    text: 'Click & drag either edge of a signpost header to adjust the width of the signpost column.'
  }
]

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1
  },
  hintIcon: {
    fontSize: 60
  },
  arrowIcons: {
    fontSize: 30,
    color: 'white'
  },
  textAlign: {
    textAlign: 'justify'
  }
}))

function TipsSlider() {
  const classes = useStyles()
  const theme = useTheme()
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = tutorialSteps.length

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStepChange = (step) => {
    setActiveStep(step)
  }

  return (
    <Grid container className={classes.root}>
      <Grid item xs={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <IconButton onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight className={classes.arrowIcons} />
            ) : (
              <KeyboardArrowLeft className={classes.arrowIcons} />
            )}
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <EmojiObjectsOutlined className={classes.hintIcon} />
        </Box>
      </Grid>
      <Grid item container xs={9}>
        <Grid item xs={12}>
          <Typography
            className={classes.textAlign}
            style={{ fontWeight: 'bold' }}
          >
            HELPFUL HINT:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SwipeableViews
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {tutorialSteps.map((step) => (
              <Typography className={classes.textAlign} key={step.key}>
                {step.text}
              </Typography>
            ))}
          </SwipeableViews>
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="100%"
          width="100%"
        >
          <IconButton
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft className={classes.arrowIcons} />
            ) : (
              <KeyboardArrowRight className={classes.arrowIcons} />
            )}
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  )
}

export default TipsSlider
