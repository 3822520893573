const animationToolsTypes = {
  SET_PLAY: 'SET_PLAY',
  SET_PAUSE: 'SET_PAUSE',
  SET_STOP: 'SET_STOP',
  SET_GRAPH_WIDTH: 'SET_GRAPH_WIDTH',
  FOWARD: 'FOWARD',
  BACKWARDS: 'BACKWARDS',
  ANIMATIONTOOLS_OPEN: 'ANIMATIONTOOLS_OPEN',
  ANIMATIONTOOLS_CLOSE: 'ANIMATIONTOOLS_CLOSE',
  SET_IS_ANIMATING: 'SET_IS_ANIMATING',
  SET_GRAPH_HEIGHT: 'SET_GRAPH_HEIGHT'
}

export default animationToolsTypes
