import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFirestore } from 'reactfire'
import { useHistory } from 'react-router-dom'
import {
  doc,
  collection,
  addDoc,
  updateDoc,
  Timestamp
} from 'firebase/firestore'
import {
  Button,
  Grid,
  Typography,
  Input,
  Tabs,
  Tab,
  Box,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton
} from '@material-ui/core'
import JSZip from 'jszip'
import { Delete } from '@material-ui/icons'
import { cloneDeep } from 'lodash'

import { Dialog, SubmitButton } from '../ui'
import appActions from '../../redux/actions/app'
import authActions from '../../redux/actions/auth'
import charactersActions from '../../redux/actions/characters'
import undoActions from '../../redux/actions/undo'
import fileActions from '../../redux/actions/file'
import generateChartBaseData from '../../modules/importReader'

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} {...other}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
)

const ImportDialog = () => {
  const { importModalOpen } = useSelector((state) => state.app)
  const { user } = useSelector((state) => state.auth)
  const { graphHeight } = useSelector((state) => state.animationTools)
  const [isDone, setIsDone] = useState(false)
  const [isZipUploadDone, setZipUploadIsDone] = useState(false)
  const [isUploading, setUploading] = useState(false)
  const [isZipLoading, setIsZipLoading] = useState(false)
  const [zipFile, setZipFile] = useState(null)
  const [loadedData, setLoadedData] = useState(false)
  const [error, setError] = useState(null)
  const [zipData, setZipData] = useState(null)
  const [subscriptionData, setSubscriptionData] = useState(null)
  const [value, setValue] = useState('one')
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const history = useHistory()

  const userRef = doc(firestore, 'users', user.email)
  const projectRef = collection(firestore, 'projects')

  const handleClose = () => {
    if (!isZipLoading && !isUploading) {
      setIsDone(false)
      setLoadedData(false)
      setUploading(false)
      setIsZipLoading(false)
      setZipFile(null)
      setZipData(null)
      setError(null)
      setSubscriptionData(null)
      setZipUploadIsDone(false)
      dispatch(appActions.importDialogClose())
    }
  }

  const handleTabChange = (event, newValue) => {
    setSubscriptionData(null)
    setError(null)
    setValue(newValue)
  }

  const createNewChart = async (multipleUpload = false) => {
    try {
      setUploading(true)
      if (multipleUpload) {
        if (subscriptionData) {
          if (subscriptionData === 'lifetime') {
            await updateDoc(userRef, { isAdmin: true })
          } else {
            await updateDoc(userRef, {
              subscribedUntil: Timestamp.fromMillis(subscriptionData),
              oldAppSubscriber: true
            })
          }
        }
        const promisesArray = []
        const projectId = await addDoc(projectRef, {
          name: `Charts imported - ${new Date().toLocaleDateString()}`,
          userId: userRef
        })
        for (let index = 0; index < zipData.length; index += 1) {
          const item = zipData[index]
          const chartPromise = addDoc(collection(firestore, 'charts'), {
            ...item,
            projectId
          })
          promisesArray.push(chartPromise)
        }
        await Promise.all(promisesArray)
        setZipFile(null)
        setZipData(null)
        setZipUploadIsDone(true)
      } else {
        const data = await addDoc(collection(firestore, 'charts'), loadedData)
        setLoadedData({ ...loadedData, id: data.id })
      }
      setIsDone(true)
      setUploading(false)
      setError(null)
    } catch (err) {
      setUploading(false)
      setError('Something went wrong, please try again later.')
      console.error(err)
    }
  }

  const openChart = async () => {
    if (loadedData.id) {
      handleClose()
      dispatch(undoActions.resetRegisters())
      dispatch(authActions.resetGraphStatus())
      dispatch(charactersActions.selectCharacter({}))
      history.push(`/app/${loadedData.id}`)
      await updateDoc(userRef, {
        lastChart: loadedData.id
      })
    }
  }

  const handleChange = (e) => {
    setError(null)
    setSubscriptionData(null)
    if (e.target.files[0]) {
      const fileReader = new FileReader()
      fileReader.readAsText(e.target.files[0], 'UTF-8')
      fileReader.onload = (event) => {
        setUploading(true)
        const processedFile = generateChartBaseData(event.target.result, {
          graphHeight,
          userRef
        })
        if (processedFile) {
          setLoadedData(processedFile)
        } else {
          setError('This chart has no data, try with another file.')
        }
        setUploading(false)
      }
    }
  }

  const readZipFile = async () => {
    try {
      setIsZipLoading(true)
      const fileContent = []
      const promisesChartsFile = []

      const zip = await JSZip.loadAsync(zipFile)
      zip.forEach((relativePath, zipEntry) => {
        fileContent.push(zipEntry.name)
      })

      for (let index = 0; index < fileContent.length; index += 1) {
        const item = fileContent[index]
        const fileInfo = zip.file(item).async('string')
        promisesChartsFile.push(fileInfo)
      }

      const allData = await Promise.all(promisesChartsFile)
      const fileRef = zip.file('info.json')

      if (fileRef) {
        const subData = await fileRef.async('string')
        const parsedData = JSON.parse(subData)

        if (parsedData.sub && parsedData.sub !== 0) {
          setSubscriptionData(parsedData.sub)
        }
      }

      const formatedJsons = allData
        .map((item) => generateChartBaseData(item, { graphHeight, userRef }))
        .filter((item) => item)

      if (formatedJsons.length) {
        setZipData(formatedJsons)
      } else {
        setError('This zip has incorrent data.')
      }

      setIsZipLoading(false)
    } catch (err) {
      setIsZipLoading(false)
      setError('Something has go wrong, please try again later.')
      console.error(err)
    }
  }

  const handleZipChange = (evt) => {
    setError(null)
    setSubscriptionData(null)
    if (evt.target.files[0]) {
      setZipFile(evt.target.files[0])
    }
  }

  const removeFileFromImport = (index) => {
    const zipDataCopy = cloneDeep(zipData)
    zipDataCopy.splice(index, 1)
    setZipData(zipDataCopy)
  }

  const openMyCharts = () => {
    handleClose()
    dispatch(fileActions.foldersOpen())
  }

  const subscriptionToShow = () => {
    if (subscriptionData === 'lifetime') {
      return 'Your subscription will never expires.'
    }
    return `Your subscription expires in: ${new Date(
      subscriptionData
    ).toLocaleDateString()}`
  }

  return (
    <Dialog
      open={importModalOpen}
      onClose={handleClose}
      title="Import a file"
      maxWidth="sm"
      closable
      divider
      dragglable
    >
      <Tabs value={value} onChange={handleTabChange}>
        <Tab value="one" label="Upload your zip file" />
        <Tab value="two" label="Upload a single file" />
      </Tabs>
      <TabPanel value={value} index="one">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom>
              Select the zip file with all your charts and import it to the new
              app.
            </Typography>
            <Typography gutterBottom>
              This process will create a project named "Charts imported -
              Imported Date", so that you can find them easier.
            </Typography>
            <Typography>
              <strong>Note:</strong> Only charts with data will be imported
              (characters, signpost and core questions answered, etc.).
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 12 }}>
            <Input
              type="file"
              fullWidth
              variant="dense"
              onChange={handleZipChange}
              inputProps={{ accept: 'application/zip' }}
            />
          </Grid>
          <Grid item xs={12}>
            {isZipUploadDone && (
              <Typography
                variant="body1"
                align="center"
                style={{ marginTop: 6, marginBottom: 6 }}
              >
                Upload successful, you can find your charts in the project: "
                {`Charts imported - ${new Date().toLocaleDateString()}`}"
              </Typography>
            )}
            {error && (
              <Grid item xs={12} style={{ marginTop: 12 }}>
                <Typography
                  align="center"
                  color="error"
                  style={{ marginTop: 12 }}
                >
                  {error}
                </Typography>
              </Grid>
            )}

            {zipData && (
              <>
                {subscriptionData && (
                  <Grid item xs={12} style={{ marginTop: 12 }}>
                    <Typography align="center" style={{ marginTop: 12 }}>
                      <strong>{subscriptionToShow()}</strong>
                    </Typography>
                  </Grid>
                )}
                <Typography
                  variant="body1"
                  align="center"
                  style={{ marginTop: 6, marginBottom: 6 }}
                >
                  Read completed, total files to upload: {'  '}
                  {Math.abs(zipData.length)}
                </Typography>
                <List style={{ maxHeight: 400, overflowY: 'auto' }}>
                  {zipData.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={item.title || 'Untitled'} />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          onClick={() => removeFileFromImport(index)}
                        >
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </Grid>
          {isUploading && (
            <Grid item xs={12}>
              <Typography>
                Your charts are uploading, this process can take a while. Do not
                close or refresh the window.
              </Typography>
            </Grid>
          )}
          <Grid
            item
            container
            xs={12}
            justifyContent="center"
            alignItems="center"
            style={{ marginTop: 16, marginBottom: 16 }}
            disabled={isZipLoading}
          >
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>

            {!zipData && (
              <SubmitButton
                isLoading={isZipLoading}
                onClick={readZipFile}
                text="Read Zip"
                variant="contained"
                color="primary"
                disabled={!zipFile}
                style={{ marginLeft: 12 }}
              />
            )}
            {isZipUploadDone && (
              <Button
                variant="contained"
                onClick={openMyCharts}
                color="primary"
                style={{ marginLeft: 12 }}
              >
                Open my charts
              </Button>
            )}
            {zipData && !isZipUploadDone && (
              <SubmitButton
                isLoading={isUploading}
                onClick={() => createNewChart(true)}
                text="yes, upload it!"
                variant="contained"
                color="primary"
                style={{ marginLeft: 12 }}
              />
            )}
          </Grid>
        </Grid>
      </TabPanel>

      <TabPanel value={value} index="two">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography gutterBottom>
              Here you can select a single JSON file to upload it in the new
              platform.
            </Typography>
            <Typography>
              <strong>Note:</strong> Only charts with data will be imported
              (characters, signpost and core questions answered, etc.).
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 12 }}>
            <Input
              fullWidth
              type="file"
              variant="dense"
              onChange={handleChange}
              inputProps={{ accept: '.json' }}
            />
          </Grid>
          {error && (
            <Grid item xs={12} style={{ marginTop: 12 }}>
              <Typography
                align="center"
                color="error"
                style={{ marginTop: 12 }}
              >
                {error}
              </Typography>
            </Grid>
          )}
          {loadedData && (
            <>
              <Grid item xs={12}>
                {isDone ? (
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ marginTop: 12 }}
                  >
                    Upload successful, chart loaded: "
                    {loadedData.title ?? 'Untitled'}"
                  </Typography>
                ) : (
                  <Typography
                    variant="body1"
                    align="center"
                    style={{ marginTop: 12 }}
                  >
                    You have selected the Chart named: "
                    {loadedData.title ?? 'Untitled'}". <br />
                    Confirm to upload the chart
                  </Typography>
                )}
              </Grid>
              {isUploading && (
                <Grid item xs={12}>
                  <Typography>
                    Your charts are uploading, this process can take a while. Do
                    not close or refresh the window.
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                container
                xs={12}
                justifyContent="center"
                alignItems="center"
                style={{ marginTop: 16, marginBottom: 16 }}
              >
                <Button variant="outlined" onClick={handleClose}>
                  Close
                </Button>
                {isDone ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={openChart}
                    style={{ marginLeft: 12 }}
                  >
                    Go to chart
                  </Button>
                ) : (
                  <SubmitButton
                    isLoading={isUploading}
                    onClick={() => createNewChart()}
                    text="yes, upload it!"
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 12 }}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </TabPanel>
    </Dialog>
  )
}

export default ImportDialog
