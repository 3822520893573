import { dashboardTypes } from '../types'

const dashboardOpen = () => (dispatch) => {
  dispatch({ type: dashboardTypes.DASHBOARD_OPEN_MODAL })
}
const dashboardClose = () => (dispatch) => {
  dispatch({ type: dashboardTypes.DASHBOARD_CLOSE_MODAL })
}

export default {
  dashboardOpen,
  dashboardClose
}
