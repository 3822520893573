import { episodesTypes } from '../types'
import arrayDeepClone from '../../utils/arrayDeepClone'

const defaultState = {
  episodes: [],
  selectedEpisode: {},
  allSeasonView: false,
  createModal: false,
  configModal: false,
  episodeToCreate: null
}

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case episodesTypes.SHOW_MODAL: {
      return {
        ...state,
        createModal: payload.modalShow,
        episodeToCreate: payload.data
      }
    }
    case episodesTypes.SHOW_CONFIG_MODAL: {
      return {
        ...state,
        configModal: payload
      }
    }
    case episodesTypes.SELECT_EPISODE: {
      return {
        ...state,
        selectedEpisode: payload
      }
    }
    case episodesTypes.FULL_SEASON_VIEW: {
      return {
        ...state,
        allSeasonView: payload
      }
    }
    case episodesTypes.ADD_EPISODE: {
      return {
        ...state,
        episodes: [...state.episodes, payload],
        selectedEpisode: payload
      }
    }
    case episodesTypes.REMOVE_EPISODE: {
      const filteredCharacters = state.episodes.filter(
        (item) => item.id !== payload
      )
      return {
        ...state,
        episodes: filteredCharacters
      }
    }
    case episodesTypes.CHANGE_EPISODE_COLOR: {
      const characterCopy = arrayDeepClone(state.episodes)
      const [character] = characterCopy.splice(payload.startIndex, 1)
      character.color = payload.color
      characterCopy.splice(payload.startIndex, 0, character)
      return {
        ...state,
        episodes: characterCopy
      }
    }
    case episodesTypes.CHANGE_EPISODE_NAME: {
      const characterCopy = arrayDeepClone(state.episodes)
      const [character] = characterCopy.splice(payload.startIndex, 1)
      character.name = payload.name
      characterCopy.splice(payload.startIndex, 0, character)
      return {
        ...state,
        episodes: characterCopy
      }
    }
    default:
      return state
  }
}

export default reducer
