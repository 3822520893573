import { makeStyles } from '@material-ui/core/styles'
import { useSelector } from 'react-redux'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import { collection, query, orderBy } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { Resizable } from 're-resizable'
import { SizeMe } from 'react-sizeme'

import SignpostColums from './SignpostColums'
import SvgGraphSeason from './SvgGraphSeason'
import InsertMultimediaDialog from '../InsertMultimediaDialog'
import EpisodeMiniature from '../EpisodeMinuature'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    overflowY: 'hidden',
    position: 'relative',
    height: '100%'
  }
}))
export default function ChartSeries() {
  const classes = useStyles()
  const { graphWidth } = useSelector((state) => state.animationTools)
  const { allSeasonView } = useSelector((state) => state.episodes)
  const firestore = useFirestore()
  const { id: chartId } = useParams()
  const episodesRef = collection(firestore, 'series', chartId, 'episodes')
  const orderByStartQuery = query(episodesRef, orderBy('startSignpost'))

  const { data } = useFirestoreCollectionData(orderByStartQuery, {
    idField: 'id'
  })

  const scrollChart = (e) => {
    const CharactersDetailsId = document.getElementById('CharactersDetailsId')
    if (CharactersDetailsId) {
      CharactersDetailsId.scrollLeft = e.target.scrollLeft
    }
  }

  return (
    <div className={classes.root} onScroll={scrollChart} id="ChartId">
      <SizeMe monitorHeight>
        {({ size }) => (
          <div
            style={{
              display: 'flex',
              position: 'relative',
              height: '100%'
            }}
          >
            <SignpostColums />
            <SvgGraphSeason size={size} />
          </div>
        )}
      </SizeMe>
      {allSeasonView && data && (
        <Resizable
          defaultSize={{
            width: graphWidth,
            height: '30vh'
          }}
          minHeight="10px"
          enable={{
            top: true
          }}
        >
          <SizeMe monitorHeight>
            {({ size }) => (
              <div
                style={{
                  width: graphWidth,
                  height: '100%',
                  minHeight: '1px',
                  display: 'flex',
                  backgroundColor: 'lightgrey'
                }}
              >
                {data.map((item) => (
                  <EpisodeMiniature
                    key={item.id}
                    episodeData={item}
                    size={size}
                  />
                ))}
              </div>
            )}
          </SizeMe>
        </Resizable>
      )}
      <InsertMultimediaDialog />
    </div>
  )
}
