import { drawingToolsTypes } from '../types'

const defaultState = {
  tool: 'SELECT',
  color: '#111111',
  isDrawingToolsOpen: true,
  isInsertUrlOpen: false,
  subject: null,
  multimediaTap: 'URL_INSERT'
}

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case drawingToolsTypes.SET_TOOL: {
      return { ...state, tool: payload }
    }
    case drawingToolsTypes.SET_MULTIMEDIA_TAB: {
      return { ...state, multimediaTap: payload }
    }
    case drawingToolsTypes.SET_COLOR: {
      return { ...state, color: payload }
    }
    case drawingToolsTypes.INSERTURL_OPEN: {
      return {
        ...state,
        isInsertUrlOpen: true,
        subject: payload
      }
    }
    case drawingToolsTypes.INSERTURL_CLOSE: {
      return {
        ...state,
        isInsertUrlOpen: false,
        subject: null
      }
    }
    case drawingToolsTypes.DRAWINGTOOLS_OPEN: {
      return {
        ...state,
        isDrawingToolsOpen: true
      }
    }
    case drawingToolsTypes.DRAWINGTOOLS_CLOSE: {
      return {
        ...state,
        isDrawingToolsOpen: false
      }
    }
    default:
      return state
  }
}

export default reducer
