import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListItemIcon,
  TextField,
  Button,
  Grid,
  ListItemAvatar,
  Avatar,
  Typography,
  Collapse,
  Switch
} from '@material-ui/core'
import { Chat, Send } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import { useFirestore } from 'reactfire'
import {
  doc,
  updateDoc,
  getDocs,
  collection,
  where,
  query
} from 'firebase/firestore'
import { useParams } from 'react-router-dom'

import { Dialog } from '../ui'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
    height: 45,
    backgroundColor: '#fff'
  },
  selectedCharacter: {
    backgroundColor: '#ffffff66 !important'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 5,
    color: '#fff',
    borderRadius: 0,
    backgroundColor: '#efefef',
    height: 45,
    '&:hover': {
      color: '#000',
      backgroundColor: '#efefef'
    }
  },
  color_picker: {
    width: 17,
    height: 17,
    borderRadius: '50%',
    marginLeft: 5
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}))

export default function OnlineUsers() {
  const [open, setOpen] = useState(false)
  const [openSettings, setOpenSettings] = useState(false)
  const [messages, setMessages] = useState([])
  const { collaborators } = useSelector((state) => state.collaborate)
  const { user } = useSelector((state) => state.auth)
  const { chartType } = useSelector((state) => state.app)
  const [text, setText] = useState('')
  const classes = useStyles()
  const firestore = useFirestore()
  const { id: chartId } = useParams()
  const chartRef = doc(
    firestore,
    chartType === 'SERIES' ? `/series/${chartId}` : `/charts/${chartId}`
  )
  const chatsRef = collection(firestore, 'chats')

  const handleSend = () => {
    if (text) {
      setMessages([...messages, text])
      setText('')
    }
  }

  const handleClickUserSetting = (id) => {
    setOpenSettings({
      [id]: !openSettings[id]
    })
  }

  const handlePermissionsChange = async (id, type) => {
    const newData = collaborators.map((item) => {
      if (item.email === id) {
        return {
          ...item,
          [type]: !item[type]
        }
      }
      return item
    })
    await updateDoc(chartRef, {
      collaborate: newData
    })
  }

  const handleRevokeAccess = async (id) => {
    const newData = collaborators.filter((item) => item.email !== id)
    await updateDoc(chartRef, {
      collaborate: newData
    })
  }

  const handleChatRoom = async (receiverId) => {
    const roomName = [receiverId, user.email].sort().join('_')
    const q = query(
      chatsRef,
      where('chartId', '==', chartRef),
      where('roomId', '==', roomName)
    )
    const data = await getDocs(q)
    console.log(data)
  }

  return (
    <Paper elevation={0} style={{ backgroundColor: 'transparent' }} square>
      <List
        subheader={
          <Typography
            style={{ color: '#F66', fontWeight: 600, paddingLeft: 6 }}
          >
            {' '}
            Collaboration users:
          </Typography>
        }
        style={{ maxHeight: 380, overflowY: 'auto' }}
      >
        {collaborators
          .filter((item) => item.email !== user.email)
          .map((item) => (
            <div key={item.email}>
              <ListItem
                classes={{
                  selected: classes.selectedCharacter
                }}
                button
                onClick={() => handleClickUserSetting(item.email)}
              >
                <ListItemIcon>
                  <Send style={{ color: 'green' }} />
                </ListItemIcon>
                <ListItemText
                  primary={item.email}
                  secondary={
                    <Typography
                      component="span"
                      variant="body2"
                      color="secondary"
                    >
                      {item.isReadOnlyAccess ? 'Read only' : 'Can modify'}
                    </Typography>
                  }
                  style={{ color: 'white' }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() => handleChatRoom(item.email)}
                  >
                    <Chat style={{ color: '#fff' }} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Collapse
                in={openSettings[item.email]}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  <ListItem className={classes.nested}>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="body2"
                          style={{ color: '#fff' }}
                        >
                          Read only Access
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        edge="end"
                        onChange={() =>
                          handlePermissionsChange(
                            item.email,
                            'isReadOnlyAccess'
                          )
                        }
                        checked={item.isReadOnlyAccess}
                        color="secondary"
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem className={classes.nested}>
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="body2"
                          style={{ color: '#fff' }}
                        >
                          Admin Access
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Switch
                        edge="end"
                        onChange={() =>
                          handlePermissionsChange(item.email, 'isAdminAccess')
                        }
                        checked={item.isAdminAccess}
                      />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                    button
                    className={classes.nested}
                    onClick={() => handleRevokeAccess(item.email)}
                  >
                    <ListItemText
                      primary={
                        <Typography
                          component="span"
                          variant="body2"
                          align="center"
                          color="error"
                        >
                          Revoke access
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Collapse>
            </div>
          ))}
      </List>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
        divider
        title="Chat"
        closable
        dragglable
        dialogContentProps={{ style: { padding: 0 } }}
      >
        <Grid container>
          <Grid item xs={12}>
            <List
              component="nav"
              style={{ minHeight: 200, maxHeight: 380, overflowY: 'auto' }}
            >
              {messages.map((message) => (
                <ListItem
                  selected
                  classes={{
                    selected: classes.selectedCharacter
                  }}
                >
                  <ListItemAvatar>
                    <Avatar alt="Sam" src="/static/images/avatar/1.jpg" />
                  </ListItemAvatar>
                  <ListItemText primary={message} />
                  {new Date().toLocaleDateString()}
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={10}>
            <TextField
              fullWidth
              variant="outlined"
              value={text}
              onChange={(e) => setText(e.target.value)}
              multiline
              rows={3}
            />
          </Grid>
          <Grid item container xs={2}>
            <Button onClick={handleSend} variant="contained">
              Send
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </Paper>
  )
}
