import { cloneDeep } from 'lodash'
import { guideTypes } from '../types'

const defaultState = {
  title: '',
  draw: [],
  personalNotes: '',
  coreQuestions: {
    isModalOpen: false,
    answers: {}
  },
  yourSignposts: {
    isModalOpen: false
  },
  isEpisodeModalOpen: false,
  downloadEventDialog: false,
  isNotesDialogOpen: false,
  startSignpost: 0,
  finishSignpost: 1,
  color: ''
}

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case guideTypes.CORE_QUESTION_OPEN_MODAL: {
      return {
        ...state,
        coreQuestions: {
          ...state.coreQuestions,
          isModalOpen: true
        }
      }
    }
    case guideTypes.SET_DOWNLOAD_DIALOG: {
      return {
        ...state,
        downloadEventDialog: payload
      }
    }
    case guideTypes.SHOW_NOTES_MODAL: {
      return {
        ...state,
        isNotesDialogOpen: payload
      }
    }
    case guideTypes.SET_EPISODE_DATA: {
      return {
        ...state,
        startSignpost: payload.startSignpost,
        finishSignpost: payload.finishSignpost,
        color: payload.color
      }
    }
    case guideTypes.SHOW_EPISODES_MODAL: {
      return {
        ...state,
        isEpisodeModalOpen: payload
      }
    }
    case guideTypes.CORE_QUESTION_CLOSE_MODAL: {
      return {
        ...state,
        coreQuestions: {
          ...state.coreQuestions,
          isModalOpen: false
        }
      }
    }
    case guideTypes.SIGNPOST_OPEN_MODAL: {
      return {
        ...state,
        yourSignposts: {
          ...state.yourSignposts,
          isModalOpen: true
        }
      }
    }
    case guideTypes.SIGNPOST_CLOSE_MODAL: {
      return {
        ...state,
        yourSignposts: {
          ...state.yourSignposts,
          isModalOpen: false
        }
      }
    }
    case guideTypes.SET_GRAPH_TITLE: {
      return { ...state, title: payload }
    }
    case guideTypes.SET_GRAPH_NOTES: {
      return { ...state, personalNotes: payload }
    }
    case guideTypes.SET_CORE_QUESTION_ANSWERS: {
      return {
        ...state,
        coreQuestions: {
          ...state.coreQuestions,
          answers: payload
        }
      }
    }
    case guideTypes.SET_SIGNPOST_ANSWERS: {
      return {
        ...state,
        yourSignposts: {
          ...state.yourSignposts,
          answers: payload
        }
      }
    }
    case guideTypes.SET_MULTIMEDIA_SOURCE: {
      return {
        ...state,
        draw: payload
      }
    }
    case guideTypes.UPDATE_HISTORIAL_DRAW: {
      /* const { pastDraw, presentDraw } = state.draw */
      const { past, present, future } = payload
      return {
        ...state,
        draw: {
          pastDraw: past,
          presentDraw: present,
          futureDraw: future
        }
      }
    }
    case guideTypes.RESET_DRAW_SOURCE: {
      if (state.draw.presentDraw === payload) {
        return state
      }
      return {
        ...state,
        draw: {
          pastDraw: [],
          presentDraw: [],
          futureDraw: []
        }
      }
    }
    case guideTypes.MOVE_CIRCLE_DRAW: {
      const drawCopy = cloneDeep(state.draw.presentDraw)
      for (let index = 0; index < drawCopy.length; index += 1) {
        const draw = drawCopy[index]
        if (draw.id === payload.drawId) {
          draw.path[1].cx = payload.x
          draw.path[1].cy = payload.y
        }
      }
      return {
        ...state,
        draw: {
          pastDraw: [],
          presentDraw: drawCopy,
          futureDraw: []
        }
      }
    }
    default:
      return state
  }
}

export default reducer
