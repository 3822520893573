import { appTypes } from '../types'

const defaultState = {
  importModalOpen: false,
  saveAsModalOpen: false,
  subscription: { active: false, status: '', start: '', end: '' },
  invitationData: null,
  chartType: 'MOVIES',
  notificationData: {
    open: false,
    variant: 'success',
    message: ''
  }
}

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case appTypes.IMPORT_OPEN_MODAL: {
      return {
        ...state,
        importModalOpen: true
      }
    }
    case appTypes.SET_CHART_TYPE: {
      return {
        ...state,
        chartType: payload
      }
    }
    case appTypes.HANDLE_SAVE_AS_DIALOG: {
      return {
        ...state,
        saveAsModalOpen: payload
      }
    }
    case appTypes.IMPORT_CLOSE_MODAL: {
      return {
        ...state,
        importModalOpen: false
      }
    }
    case appTypes.SET_USER_SUBSCRIPTION: {
      return {
        ...state,
        subscription: payload
      }
    }
    case appTypes.SET_INVITATION_LINK: {
      return {
        ...state,
        invitationData: payload
      }
    }
    case appTypes.NOTIFICATION_SHOW: {
      return {
        ...state,
        notificationData: { ...payload }
      }
    }
    default:
      return state
  }
}

export default reducer
