import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import JoinChart from '../Colaborate/JoinChart'
import ShareChart from '../Colaborate/ShareChart'
import collaborateActions from '../../redux/actions/collaborate'

const useStyles = makeStyles(() => ({
  paper: {
    top: '60px !important'
  }
}))

export default function CollaborateMenu() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const { adminAccess, isOwner } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openModalJoin = () => {
    dispatch(collaborateActions.joinChartOpen())
    setAnchorEl(null)
  }
  const openModalShare = () => {
    dispatch(collaborateActions.shareChartOpen())
    setAnchorEl(null)
  }

  return (
    <>
      <Button style={{ color: '#fff' }} onClick={(e) => handleClick(e)}>
        Collaborate
      </Button>
      <Menu
        classes={{ paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {(isOwner || adminAccess) && (
          <MenuItem key="share_chart_button" onClick={openModalShare}>
            Share chart
          </MenuItem>
        )}
        <MenuItem onClick={openModalJoin}>Join chart</MenuItem>
      </Menu>
      <JoinChart />
      <ShareChart />
    </>
  )
}
