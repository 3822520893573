import { charactersTypes } from '../types'

const addMultimediaYoutube = (url) => (dispatch) => {
  dispatch({
    type: charactersTypes.ADD_MULTIMEDIA_URL,
    payload: url
  })
}

const loadCharacters = (data) => (dispatch) => {
  dispatch({
    type: charactersTypes.LOAD_CHARACTERS,
    payload: data
  })
}

const removeMultimediaYoutube = (index) => (dispatch) => {
  dispatch({
    type: charactersTypes.REMOVE_MULTIMEDIA_URL,
    payload: index
  })
}

const changeSizeMultimedia = (size) => (dispatch) => {
  dispatch({
    type: charactersTypes.CHANGE_SIZE_MULTIMEDIA,
    payload: size
  })
}

const changePositionMultimedia = (position) => (dispatch) => {
  dispatch({
    type: charactersTypes.CHANGE_POSITION_MULTIMEDIA,
    payload: position
  })
}

const changeDescriptionSp = (value, signpostId) => (dispatch) => {
  dispatch({
    type: charactersTypes.CHANGE_DESCRIPTION_SP,
    payload: { value, signpostId }
  })
}

const changeDescriptionCq = (value) => (dispatch) => {
  dispatch({
    type: charactersTypes.CHANGE_DESCRIPTION_CQ,
    payload: value
  })
}

const selectCharacter = (character) => (dispatch) => {
  dispatch({
    type: charactersTypes.SELECT_CHARACTER,
    payload: character
  })
}

const addCharacterGraphPoint = (id, x, y, uid) => (dispatch) => {
  dispatch({
    type: charactersTypes.ADD_CHARACTER_GRAPH_POINT,
    payload: { id, x, y, uid }
  })
}

const updateCharacterOrder = (data) => (dispatch) => {
  dispatch({ type: charactersTypes.CHANGE_CHARACTER_ORDER, payload: data })
}

const createCharacter = (data) => (dispatch) => {
  dispatch({ type: charactersTypes.ADD_CHARACTER, payload: data })
}

const removeCharacter = (id) => (dispatch) => {
  dispatch({
    type: charactersTypes.REMOVE_CHARACTER,
    payload: id
  })
}

const removeCharacterGraphPoint = (id) => (dispatch) => {
  dispatch({
    type: charactersTypes.REMOVE_CHARACTER_GRAPH_POINT,
    payload: id
  })
}

const setGraphPointHeader = (id, header) => (dispatch) => {
  dispatch({
    type: charactersTypes.CHANGE_GRAPH_POINT_HEADER,
    payload: { id, header }
  })
}

const setGraphPointDetails = (id, details) => (dispatch) => {
  dispatch({
    type: charactersTypes.CHANGE_GRAPH_POINT_DETAILS,
    payload: { id, details }
  })
}

const changeCharacterColor = (startIndex, color) => (dispatch) => {
  dispatch({
    type: charactersTypes.CHANGE_CHARACTER_COLOR,
    payload: {
      startIndex,
      color
    }
  })
}
const changeCharacterOpacity = (startIndex, colorOpacity) => (dispatch) => {
  dispatch({
    type: charactersTypes.CHANGE_CHARACTER_COLOR_OPACITY,
    payload: {
      startIndex,
      colorOpacity
    }
  })
}
const changeCharacterName = (startIndex, name) => (dispatch) => {
  dispatch({
    type: charactersTypes.CHANGE_CHARACTER_NAME,
    payload: {
      startIndex,
      name
    }
  })
}
const handlePointerDown = (characterIndex, pointId, x, y) => (dispatch) => {
  dispatch({
    type: charactersTypes.SET_GRAPH_POINT_ACTIVE,
    payload: {
      characterIndex,
      pointId,
      x,
      y
    }
  })
}

const handlePointerMove = (pointId, x, y) => (dispatch) => {
  dispatch({
    type: charactersTypes.SET_GRAPH_POINT_MOVE,
    payload: {
      pointId,
      x,
      y
    }
  })
}

const handlePointerUp = (characterIndex, pointId) => (dispatch) => {
  dispatch({
    type: charactersTypes.UNSET_GRAPH_POINT_ACTIVE,
    payload: {
      characterIndex,
      pointId
    }
  })
}

export default {
  loadCharacters,
  addMultimediaYoutube,
  removeMultimediaYoutube,
  changeSizeMultimedia,
  changePositionMultimedia,
  changeDescriptionSp,
  createCharacter,
  updateCharacterOrder,
  removeCharacter,
  changeCharacterName,
  changeCharacterOpacity,
  changeCharacterColor,
  removeCharacterGraphPoint,
  selectCharacter,
  addCharacterGraphPoint,
  setGraphPointHeader,
  setGraphPointDetails,
  handlePointerDown,
  handlePointerMove,
  handlePointerUp,
  changeDescriptionCq
}
