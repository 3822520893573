import { useState, useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Resizable } from 're-resizable'
import { Box, Typography } from '@material-ui/core'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { scaleLinear } from 'd3'
import { useFirestore } from 'reactfire'
import {
  doc,
  updateDoc,
  collection,
  addDoc,
  Timestamp
} from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { createPortal } from 'react-dom'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const useDraggableInPortal = () => {
  const self = useRef({}).current

  useEffect(() => {
    const div = document.createElement('div')
    div.style.position = 'absolute'
    div.style.pointerEvents = 'none'
    div.style.top = '0'
    div.style.width = '100%'
    div.style.height = '100%'
    self.elt = div
    document.body.appendChild(div)
    return () => {
      document.body.removeChild(div)
    }
  }, [self])

  return (render) =>
    (provided, ...args) => {
      const element = render(provided, ...args)
      if (provided.draggableProps.style.position === 'fixed') {
        return createPortal(element, self.elt)
      }
      return element
    }
}

const useStyles = makeStyles(() => ({
  signpost_titles_container: {
    display: 'flex',
    backgroundColor: '#d1d1d1',
    height: '100%'
  },
  headerIcons: {
    fontSize: 16
  },
  paper: {
    top: '110px !important'
  }
}))

export default function EpisodesSignpostColums({ episodeData }) {
  const [signposts, setSignposts] = useState([])
  const classes = useStyles()
  const { readOnly } = useSelector((state) => state.auth)
  const { zoomLevel, signposts: storeSignposts } = useSelector(
    (state) => state.signposts
  )
  const firestore = useFirestore()
  const { id: chartId } = useParams()
  const renderDraggable = useDraggableInPortal()

  const chartRef = doc(
    firestore,
    `series/${chartId}/episodes/${episodeData.id}`
  )
  const collectionRef = collection(
    firestore,
    'series',
    chartId,
    'episodes',
    episodeData.id,
    'changes'
  )

  useEffect(() => {
    setSignposts(episodeData.signposts)
  }, [episodeData])

  const updateTrackingData = (trackData) => {
    const payload = {
      ...episodeData,
      date: Timestamp.fromDate(new Date()),
      ...trackData
    }
    delete payload.id
    return addDoc(collectionRef, payload)
  }

  const calculateWidth = () => {
    const totalWidth = storeSignposts.reduce((acum, current, index) => {
      if (
        index >= episodeData.startSignpost &&
        index <= episodeData.finishSignpost
      ) {
        return acum + current.width * zoomLevel
      }
      return acum
    }, 0)
    return totalWidth
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }
    const startIndex = result.source.index
    const endItem = result.destination.index
    const reorderItems = reorder(signposts, startIndex, endItem)

    setSignposts(reorderItems)

    updateDoc(chartRef, {
      signposts: reorderItems
    })
    updateTrackingData({
      signposts: reorderItems,
      action: `Changed "${reorderItems[endItem].name}" signpost position`
    })
  }

  const updateXValue = ({
    signpostStarts,
    signpostInitialEnds,
    xValue,
    xScaleOld,
    signpostScale,
    xScaleNew,
    incrementValue
  }) => {
    if (
      xScaleOld(xValue) >= signpostStarts &&
      xScaleOld(xValue) <= signpostInitialEnds
    ) {
      return xScaleNew.invert(signpostScale(xScaleOld(xValue)))
    }
    if (xScaleOld(xValue) > signpostInitialEnds) {
      return xScaleNew.invert(xScaleOld(xValue) + incrementValue)
    }
    return xScaleNew.invert(xScaleOld(xValue))
  }

  const moveGraphElements = ({
    signpostStarts,
    signpostInitialEnds,
    signpostAfterEnds,
    newWidthGraph,
    incrementValue
  }) => {
    const charactersCopy = cloneDeep(episodeData.characters)
    const drawCopy = cloneDeep(episodeData.draw)

    const signpostScale = scaleLinear()
      .domain([signpostStarts, signpostInitialEnds])
      .range([signpostStarts, signpostAfterEnds])

    const xScaleOld = scaleLinear()
      .domain([0, 5200])
      .range([0, calculateWidth()])

    const xScaleNew = scaleLinear().domain([0, 5200]).range([0, newWidthGraph])

    xScaleNew.clamp(true)

    const updatedCharacters = charactersCopy.map((character) => ({
      ...character,
      points: character.points.map((point) => ({
        ...point,
        pos: {
          ...point.pos,
          x: updateXValue({
            xValue: point.pos.x,
            signpostStarts,
            signpostInitialEnds,
            xScaleOld,
            signpostScale,
            xScaleNew,
            incrementValue
          })
        }
      }))
    }))

    const updatedDrawings = drawCopy.map((drawItem) => {
      if (
        drawItem.type === 'BRUSH' ||
        drawItem.type === 'PENCIL' ||
        drawItem.type === 'SIMPLE_LINE' ||
        drawItem.type === 'ARROW' ||
        drawItem.type === 'MULTI_ARROW' ||
        drawItem.type === 'TENSION_ARROW'
      ) {
        for (let index = 0; index < drawItem.path.length; index += 1) {
          const path = drawItem.path[index]
          path.x = updateXValue({
            xValue: path.x,
            signpostStarts,
            signpostInitialEnds,
            xScaleOld,
            signpostScale,
            xScaleNew,
            incrementValue
          })
        }
      }
      if (drawItem.type === 'CIRCLE' || drawItem.type === 'MEDIA') {
        drawItem.x1 = updateXValue({
          xValue: drawItem.x1,
          signpostStarts,
          signpostInitialEnds,
          xScaleOld,
          signpostScale,
          xScaleNew,
          incrementValue
        })
        drawItem.x2 = updateXValue({
          xValue: drawItem.x2,
          signpostStarts,
          signpostInitialEnds,
          xScaleOld,
          signpostScale,
          xScaleNew,
          incrementValue
        })
      }
      return drawItem
    })

    return { characters: updatedCharacters, draw: updatedDrawings }
  }

  const handleSignpostResize = (data, spData) => {
    if (!readOnly) {
      const signpostCopy = cloneDeep(signposts)
      const signpostTotalWidth = signposts.reduce(
        (accumulator, currentValue) => accumulator + currentValue.width,
        0
      )
      const containerWidth = calculateWidth()

      let newWidthGraph = 0
      let rangeCount = 0
      let signpostStarts = 0
      let signpostInitialEnds = 0
      let signpostAfterEnds = 0

      for (let index = 0; index < signpostCopy.length; index += 1) {
        const item = signpostCopy[index]
        if (item.id === spData.id) {
          const scaledWidth = (item.width / signpostTotalWidth) * containerWidth

          const newWidth = scaledWidth + data.width
          const formatedValue = (newWidth / containerWidth) * signpostTotalWidth

          item.width = formatedValue
          signpostStarts = rangeCount
          signpostInitialEnds = rangeCount + scaledWidth
          signpostAfterEnds = rangeCount + newWidth
        } else {
          rangeCount += (item.width / signpostTotalWidth) * containerWidth
        }
        newWidthGraph += (item.width / signpostTotalWidth) * containerWidth
      }

      const updatedElements = moveGraphElements({
        signpostStarts,
        signpostInitialEnds,
        signpostAfterEnds,
        newWidthGraph,
        incrementValue: data.width
      })

      updateDoc(chartRef, {
        signposts: signpostCopy,
        ...updatedElements
      })
      updateTrackingData({
        signposts: signpostCopy,
        action: `Changed "${spData.name}" signpost size`
      })
    }
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="miniatureSignpostsDroppable"
          direction="horizontal"
        >
          {(dropProvided) => (
            <div
              ref={dropProvided.innerRef}
              className={classes.signpost_titles_container}
              {...dropProvided.droppableProps}
            >
              {signposts.map((item, index) => (
                <Draggable
                  key={item.id}
                  draggableId={item.id}
                  index={index}
                  isDragDisabled={readOnly}
                >
                  {renderDraggable((provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      style={{
                        backgroundColor:
                          index % 2 !== 0 ? '#efefef' : '#fafafa',
                        ...provided.draggableProps.style
                      }}
                    >
                      <Resizable
                        size={{
                          width:
                            (item.width /
                              signposts.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue.width,
                                0
                              )) *
                            calculateWidth()
                        }}
                        enable={{
                          right: !readOnly
                        }}
                        onResizeStop={(e, direction, ref, d) =>
                          handleSignpostResize(d, item)
                        }
                      >
                        <div
                          {...provided.dragHandleProps}
                          style={{
                            height: 45,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: item.bgColor,
                            color: item.textColor
                          }}
                        >
                          <Typography
                            noWrap
                            variant="subtitle2"
                            align="center"
                            style={{
                              color: item.textColor,
                              width: '100%',
                              fontWeight: 'bold',
                              fontSize: 12,
                              padding: 3
                            }}
                          >
                            {item.name || 'untitled'}
                          </Typography>
                        </div>
                      </Resizable>
                    </Box>
                  ))}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}
