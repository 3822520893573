import { fileTypes } from '../types'

const defaultState = {
  isModalOpen: false
}

function reducer(state = defaultState, { type }) {
  switch (type) {
    case fileTypes.HARTCHART_FOLDERS_OPEN_MODAL: {
      return {
        ...state,
        isModalOpen: true
      }
    }
    case fileTypes.HARTCHART_FOLDERS_CLOSE_MODAL: {
      return {
        ...state,
        isModalOpen: false
      }
    }
    default:
      return state
  }
}

export default reducer
