import { useState } from 'react'
import { Button, TextField, Grid } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useFirestore } from 'reactfire'
import { doc, updateDoc, arrayUnion } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { cloneDeep } from 'lodash'

import drawingToolsActions from '../../redux/actions/drawingTools'
import { SubmitButton } from '../ui'
import useAddTrackingData from '../../hooks/useAddTrackingData'

const AddUrl = () => {
  const [value, setValue] = useState('')
  const [uploading, setUploading] = useState(false)
  const dispatch = useDispatch()
  const { subject } = useSelector((state) => state.drawingTools)
  const { chartType } = useSelector((state) => state.app)
  const { draw } = useSelector((state) => state.guide)
  const { id: chartId, episodeId } = useParams()
  const firestore = useFirestore()
  const updateTrackingData = useAddTrackingData()

  const handleChange = ({ target: { value: urlValue } }) => {
    setValue(urlValue.trim())
  }

  const addUrl = async () => {
    try {
      setUploading(true)

      let chartRef
      if (chartType === 'SERIES') {
        chartRef = doc(firestore, `series/${chartId}`)
      } else if (chartType === 'EPISODE') {
        chartRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
      } else {
        chartRef = doc(firestore, `charts/${chartId}`)
      }

      const subjectClone = cloneDeep(subject)
      subjectClone.url = value
      if (
        value.toLowerCase().indexOf('youtube.com') !== -1 ||
        value.toLowerCase().indexOf('youtu.be') !== -1
      ) {
        subjectClone.typeFile = 'youtube/media'
      } else if (value.toLowerCase().indexOf('soundcloud.com') !== -1) {
        subjectClone.typeFile = 'soundcloud/media'
      } else if (value.toLowerCase().indexOf('mp3') !== -1) {
        subjectClone.typeFile = 'audio/mpeg'
      } else if (value.toLowerCase().indexOf('mp4') !== -1) {
        subjectClone.typeFile = 'video/mp4'
      } else {
        subjectClone.typeFile = 'image/png'
      }
      await updateDoc(chartRef, {
        draw: arrayUnion(subjectClone)
      })
      updateTrackingData({
        draw: [...draw, subjectClone],
        action: `Inserted multimedia via URL: ${subjectClone.url}`
      })
      setUploading(false)
      setValue('')
      dispatch(drawingToolsActions.chooseTool('SELECT'))
      dispatch(drawingToolsActions.insertUrlClose())
    } catch (err) {
      setUploading(false)
      console.log(err)
    }
  }

  const closeModal = () => {
    dispatch(drawingToolsActions.insertUrlClose())
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          id="formUrlId"
          autoFocus
          margin="normal"
          label="Image, audio, video, or YouTube..."
          type="text"
          fullWidth
          variant="outlined"
          value={value}
          onChange={handleChange}
        />
      </Grid>

      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Button onClick={closeModal} variant="outlined">
          Cancel
        </Button>
        <SubmitButton
          isLoading={uploading}
          onClick={addUrl}
          text="Add"
          variant="contained"
          color="primary"
          disabled={!value}
          style={{ marginLeft: 12 }}
        />
      </Grid>
    </Grid>
  )
}

export default AddUrl
