import React, { useState } from 'react'
import { useFirestore } from 'reactfire'
import { doc, updateDoc, deleteDoc } from 'firebase/firestore'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Popover,
  TextField,
  IconButton,
  ListItemSecondaryAction,
  Grid,
  Button
} from '@material-ui/core'
import { Folder, MoreVert, Create, Delete } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import ChartItem from './ChartItem'

const useStyles = makeStyles(() => ({
  folders: {
    background: '#3333',
    borderRadius: '5px',
    marginBottom: '2px'
  },
  root: {
    marginLeft: '30px'
  }
}))

const ProjectItem = ({ Projectfolder, charts }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [nameProject, setNameProject] = useState(Projectfolder.name)
  const firestore = useFirestore()

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const openOption = Boolean(anchorEl2)
  const openChangename = Boolean(anchorEl)

  const handleClick = () => {
    setOpen(!open)
  }

  const reNameProject = async () => {
    setAnchorEl(null)
    setAnchorEl2(null)
    try {
      if (nameProject.trim() !== '' && nameProject !== Projectfolder.name) {
        const docuRef = doc(firestore, `projects/${Projectfolder.NO_ID_FIELD}`)
        await updateDoc(docuRef, { name: nameProject })
      }
    } catch (err) {
      console.log(err, 'error al cambiar nombre de proyecto')
    }
  }

  const deleteProject = async () => {
    try {
      const promises = []
      const projectCharts = charts.filter(
        (item) => item.projectId?.id === Projectfolder.NO_ID_FIELD
      )
      for (let i = 0; i < projectCharts.length; i += 1) {
        const idChart = projectCharts[i].id
        promises.push(deleteDoc(doc(firestore, `charts/${idChart}`)))
      }
      await Promise.all(promises)
      await deleteDoc(doc(firestore, `projects/${Projectfolder.NO_ID_FIELD}`))
      setConfirmDelete(false)
    } catch (err) {
      console.log(err, 'error al borrar proyecto')
    }
  }

  const openProjectMenu = (e) => {
    setAnchorEl2(e.currentTarget)
  }

  return (
    <>
      <ListItem className={classes.folders} button onClick={handleClick}>
        <ListItemIcon>
          <Folder />
        </ListItemIcon>
        <ListItemText primary={Projectfolder.name} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={openProjectMenu}>
            <MoreVert />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={classes.root} component="div">
          {charts.length > 0 ? (
            charts.map((chart, index) => (
              <ChartItem key={index} chartdocument={chart} />
            ))
          ) : (
            <ListItem>
              <ListItemText secondary="No charts" />
            </ListItem>
          )}
        </List>
      </Collapse>

      <Popover
        open={openOption}
        anchorEl={anchorEl2}
        onClose={() => {
          setAnchorEl2(null)
          setConfirmDelete(false)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List>
          <ListItem
            button
            onClick={(e) => {
              setAnchorEl(e.currentTarget)
            }}
          >
            <ListItemIcon>
              <Create />
            </ListItemIcon>
            <ListItemText primary="Change Project Name" />
          </ListItem>
          {confirmDelete ? (
            <ListItem button onClick={deleteProject} style={{ color: 'red' }}>
              <ListItemIcon>
                <Delete style={{ color: 'red' }} />
              </ListItemIcon>
              <ListItemText primary="Confirm delete" />
            </ListItem>
          ) : (
            <ListItem button onClick={() => setConfirmDelete(true)}>
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </ListItem>
          )}
        </List>
      </Popover>

      <Popover
        open={openChangename}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
          setAnchorEl2(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
      >
        <Grid container style={{ padding: 12 }}>
          <Grid item xs={8}>
            <TextField
              onChange={(e) => {
                setNameProject(e.target.value)
              }}
              autoFocus={true}
              required
              defaultValue={nameProject}
              label="Change Project Name"
            />
          </Grid>
          <Grid item container xs={4} justifyContent="flex-end">
            <Button variant="contained" onClick={reNameProject}>
              Rename
            </Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  )
}

export default ProjectItem
