import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  InputBase,
  Box,
  Grid,
  Typography,
  Button
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import { doc, updateDoc, collection, query, where } from 'firebase/firestore'
import { useParams, useHistory } from 'react-router-dom'

import episodesActions from '../../redux/actions/episodes'
import charactersActions from '../../redux/actions/characters'
import signpostsActions from '../../redux/actions/signposts'
import guideActions from '../../redux/actions/guide'
import animationToolsActions from '../../redux/actions/animationTools'
import authActions from '../../redux/actions/auth'
import collaborateActions from '../../redux/actions/collaborate'
import CharacterInput from './CharacterInput'
import EpisodesInput from './EpisodesInput'
import OnlineUsers from './OnlineUsers'
import CollabLinks from './CollabLinks'
import CoreQuestionsDialog from '../CoreQuestionsDialog'
import SignpostDialog from '../SignpostDialog'
import EpisodesDialog from '../EpisodesDialog'
import NotesDialog from '../NotesDialog'
import useAddTrackingData from '../../hooks/useAddTrackingData'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100vh - 65px)',
    maxHeight: 'calc(100vh - 65px)',
    backgroundColor: '#304044',
    borderRight: '1px rgba(0,0,0,.75) solid',
    width: '100%',
    overflowY: 'auto'
  },
  personalNotesContainer: {
    borderColor: 'rgb(211 211 211 / 42%)',
    border: '1px solid white',
    borderRadius: 1,
    padding: 10,
    color: '#fff',
    '& :disabled': {
      color: '#fff'
    }
  },
  titleInput: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 'bold',
    backgroundColor: ({ chartType }) =>
      chartType === 'EPISODE' ? '#f50057' : '#3f51b5',
    borderRadius: '4px',
    marginBottom: '20px',
    fontFamily: 'system-ui',
    height: '25px',
    transition: 'background-color 0.2s',
    '&:hover': {
      backgroundColor: ({ chartType }) =>
        chartType === 'EPISODE' ? '#c30146' : '#3a4376'
    }
  },
  actionButtons: {
    color: theme.palette.getContrastText('#026f87'),
    fontWeight: 'bold',
    backgroundColor: '#026f87',
    '&:hover': {
      backgroundColor: '#004756'
    }
  }
}))

export default function SidePanel() {
  const dispatch = useDispatch()
  const { title } = useSelector((state) => state.guide)
  const { collaborators } = useSelector((state) => state.collaborate)
  const { readOnly, adminAccess, previewMode } = useSelector(
    (state) => state.auth
  )
  const { allSeasonView } = useSelector((state) => state.episodes)
  const { chartType } = useSelector((state) => state.app)
  const { present } = useSelector((state) => state.undo)
  const { zoomLevel } = useSelector((state) => state.signposts)
  const firestore = useFirestore()
  const { id: chartId, episodeId } = useParams()
  const classes = useStyles({ chartType })
  const history = useHistory()
  const chartRef = doc(
    firestore,
    chartType === 'SERIES' ? `series/${chartId}` : `charts/${chartId}`
  )
  const collabLinkRef = collection(firestore, `/collaborators_links`)
  const collabLinkQuery = query(
    collabLinkRef,
    where('chartRef', '==', chartRef)
  )
  const { data: collabLinks } = useFirestoreCollectionData(collabLinkQuery, {
    idField: 'id'
  })
  const updateTrackingData = useAddTrackingData()

  const updateChartDb = async (e) => {
    console.log(e.target.value)
    let doctRef
    if (chartType === 'SERIES') {
      doctRef = doc(firestore, `series/${chartId}`)
    } else if (chartType === 'EPISODE') {
      doctRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
    } else {
      doctRef = doc(firestore, `charts/${chartId}`)
    }

    updateTrackingData({
      title,
      action: `Updated title: ${title || 'No title'}`
    })

    await updateDoc(doctRef, {
      title
    })
  }

  const handleExitPreviewMode = () => {
    dispatch(authActions.resetPreviewMode())
    dispatch(charactersActions.loadCharacters(present.characters))
    dispatch(signpostsActions.updateSignpostOrder(present.signposts))
    dispatch(guideActions.setGraphTitle(present.title))
    dispatch(guideActions.setGraphNotes(present.personalNotes))
    dispatch(guideActions.setMultimediaSource(present.draw))
    dispatch(collaborateActions.getCollaborators(present.collaborate))

    const totalWidth = present.signposts.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.width * zoomLevel,
      0
    )

    dispatch(animationToolsActions.animateGraph(totalWidth))
  }

  return (
    <Paper className={classes.root} square>
      <Box padding={1} marginBottom={4}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography style={{ color: '#F66', fontWeight: 600 }}>
              {' '}
              Start here...
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <InputBase
              size="small"
              placeholder="Enter title..."
              value={title}
              fullWidth
              disabled={readOnly}
              onChange={(e) =>
                dispatch(guideActions.setGraphTitle(e.target.value))
              }
              onBlur={updateChartDb}
              inputProps={{
                className: classes.titleInput
              }}
              style={{
                borderRadius: 3
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              classes={{ root: classes.actionButtons }}
              onClick={() => dispatch(guideActions.coreQuestionOpen())}
            >
              Core questions
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              classes={{ root: classes.actionButtons }}
              onClick={() => dispatch(guideActions.signpostOpen())}
            >
              Signposts
            </Button>
          </Grid>
          {chartType === 'SERIES' && (
            <Grid item xs={12}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => dispatch(guideActions.showEpidodesModal(true))}
                classes={{ root: classes.actionButtons }}
              >
                Episodes Summaries
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              classes={{ root: classes.actionButtons }}
              onClick={() => dispatch(guideActions.showNotesModal(true))}
            >
              Notes
            </Button>
          </Grid>
          <Grid item xs={12}>
            {previewMode && (
              <>
                <Button
                  fullWidth
                  onClick={handleExitPreviewMode}
                  variant="contained"
                  color="secondary"
                >
                  Exit Preview Mode
                </Button>
                <Typography color="error" style={{ marginTop: 14 }}>
                  You are in preview mode, you can save a copy of this version
                  with the options "File" -> "Save as".
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <CharacterInput />
      {chartType === 'SERIES' && (
        <Box padding={1} marginBottom={4}>
          {allSeasonView ? (
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => dispatch(episodesActions.setAllSeasonView(false))}
            >
              Season
            </Button>
          ) : (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => dispatch(episodesActions.setAllSeasonView(true))}
            >
              Season + Episodes
            </Button>
          )}
        </Box>
      )}
      {chartType === 'EPISODE' && (
        <Box padding={1} marginBottom={4}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/series/${chartId}`)
              dispatch(episodesActions.setAllSeasonView(true))
            }}
          >
            Season + Episodes
          </Button>
        </Box>
      )}
      {chartType !== 'MOVIES' && <EpisodesInput />}

      {adminAccess && collaborators && collaborators.length !== 0 && (
        <OnlineUsers />
      )}
      {adminAccess && collabLinks && collabLinks.length !== 0 && (
        <CollabLinks data={collabLinks} />
      )}
      <CoreQuestionsDialog />
      <SignpostDialog />
      <EpisodesDialog />
      <NotesDialog />
    </Paper>
  )
}
