import { nanoid } from 'nanoid'

const colorsPool = [
  '#1c74c8',
  '#9161c9',
  '#a872e9',
  '#e4b100',
  '#3db231',
  '#c23636',
  '#df519d',
  '#6ab2f0',
  '#7a412b',
  '#c23636',
  '#9363cc',
  '#0374c8',
  '#3fb733'
]

const createSignpost = () => {
  const randomColor =
    colorsPool[Math.ceil(Math.random() * colorsPool.length - 1)]
  const id = nanoid()
  return {
    id,
    key: id,
    name: 'New custom signpost',
    textColor: '#fff',
    bgColor: randomColor,
    width: 400,
    guideData: {
      title: `A wonderful custom signpost.`,
      videoUrl: ''
    },
    answers: ''
  }
}

export { colorsPool, createSignpost }
