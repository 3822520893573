import { TwitterTimelineEmbed } from 'react-twitter-embed'

function VideoSlider() {
  return (
    <TwitterTimelineEmbed
      url="https://twitter.com/TheHartChart"
      sourceType="profile"
      screenName="saurabhnemade"
      autoHeight
      noFooter
    />
  )
}

export default VideoSlider
