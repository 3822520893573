import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { InputBase, IconButton, Grid } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useSelector, useDispatch } from 'react-redux'
import { useFirestore } from 'reactfire'
import { doc, updateDoc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'

import charactersActions from '../../redux/actions/characters'
import useAddTrackingData from '../../hooks/useAddTrackingData'

const useStyles = makeStyles(() => ({
  container: { border: '1px solid #353535' },
  input: {
    color: '#fff',
    padding: 6,
    '& :disabled': {
      color: '#fff'
    }
  },
  detailsContainer: { backgroundColor: '#363636' },
  footerContainer: { backgroundColor: '#595959', height: 45 }
}))

const CharacterCardField = ({ data }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { selectedCharacter, characters } = useSelector(
    (state) => state.characters
  )
  const { chartType } = useSelector((state) => state.app)
  const { readOnly } = useSelector((state) => state.auth)
  const firestore = useFirestore()
  const { id: chartId, episodeId } = useParams()
  const [isTouched, setIsTouched] = useState(false)
  const updateTrackingData = useAddTrackingData()

  const getChartRef = () => {
    let chartRef
    if (chartType === 'SERIES') {
      chartRef = doc(firestore, `series/${chartId}`)
    } else if (chartType === 'EPISODE') {
      chartRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
    } else {
      chartRef = doc(firestore, `charts/${chartId}`)
    }
    return chartRef
  }

  const handleRemovePoint = () => {
    const characterCopy = cloneDeep(characters)
    const characterIndex = characterCopy.findIndex(
      (item) => item.id === selectedCharacter.id
    )
    const [character] = characterCopy.splice(characterIndex, 1)
    character.points = character.points.filter((item) => item.id !== data.id)
    characterCopy.splice(characterIndex, 0, character)
    dispatch(charactersActions.loadCharacters(characterCopy))
    dispatch(charactersActions.selectCharacter(character))
    updateDoc(getChartRef(), {
      characters: characterCopy
    })
    updateTrackingData({
      characters: characterCopy,
      action: `Removed "${selectedCharacter.name}" character "${data.header}" event`
    })
    setIsTouched(false)
  }

  const setGraphHeader = (value) => {
    dispatch(charactersActions.setGraphPointHeader(data.id, value))
    setIsTouched(true)
  }

  const setGraphDetails = (value) => {
    dispatch(charactersActions.setGraphPointDetails(data.id, value))
    setIsTouched(true)
  }

  const updatePointsDb = async (e) => {
    const {
      target: { value, name }
    } = e
    await updateDoc(getChartRef(), {
      characters
    })
    if (isTouched) {
      updateTrackingData({
        characters,
        action: `Updated "${selectedCharacter.name}" character event ${name} to: "${value}"`
      })
    }
    setIsTouched(false)
  }

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} style={{ backgroundColor: selectedCharacter.color }}>
        <InputBase
          fullWidth
          placeholder="Header..."
          className={classes.input}
          value={data.header}
          onChange={(e) => setGraphHeader(e.target.value)}
          name="title"
          onBlur={updatePointsDb}
          disabled={readOnly}
        />
      </Grid>
      <Grid item xs={12} className={classes.detailsContainer}>
        <InputBase
          fullWidth
          multiline
          rows={4}
          placeholder="Details..."
          className={classes.input}
          value={data.description}
          onChange={(e) => setGraphDetails(e.target.value)}
          name="description"
          onBlur={updatePointsDb}
          disabled={readOnly}
        />
      </Grid>
      <Grid
        item
        xs={12}
        container
        justifyContent="flex-end"
        className={classes.footerContainer}
      >
        {!readOnly && (
          <IconButton edge="end" onClick={handleRemovePoint}>
            <DeleteIcon style={{ color: '#fff' }} />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

export default CharacterCardField
