import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Menu,
  MenuItem,
  Collapse,
  Box,
  ListItemAvatar
} from '@material-ui/core'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import { useSelector, useDispatch } from 'react-redux'
import { SketchPicker } from 'react-color'
import { scaleLinear } from 'd3'
import { useFirestore } from 'reactfire'
import { doc, updateDoc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'

import NestedMenuItem from './NestedMenuItem'
import signpostsActions from '../../redux/actions/signposts'
import guideActions from '../../redux/actions/guide'
import { createSignpost } from '../../utils/createNewSignpost'
import useAddTrackingData from '../../hooks/useAddTrackingData'

const useStyles = makeStyles(() => ({
  paper: {
    top: '60px !important'
  }
}))

export default function SignpostMenu() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [textColorOpen, setTextColorOpen] = useState({})
  const [bgColorOpen, setBgColorOpen] = useState({})
  const { signposts, zoomLevel } = useSelector((state) => state.signposts)
  const { draw } = useSelector((state) => state.guide)
  const { characters } = useSelector((state) => state.characters)
  const { graphWidth } = useSelector((state) => state.animationTools)
  const { chartType } = useSelector((state) => state.app)
  const dispatch = useDispatch()
  const firestore = useFirestore()
  const { id: chartId, episodeId } = useParams()
  const updateTrackingData = useAddTrackingData()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setTextColorOpen({})
    setBgColorOpen({})
  }

  const getChartRef = () => {
    let chartRef
    if (chartType === 'SERIES') {
      chartRef = doc(firestore, `series/${chartId}`)
    } else if (chartType === 'EPISODE') {
      chartRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
    } else {
      chartRef = doc(firestore, `charts/${chartId}`)
    }
    return chartRef
  }

  const handleClickTextDrop = (id) => {
    setTextColorOpen({
      [id]: !textColorOpen[id]
    })
  }

  const handleClickBgColorDrop = (id) => {
    setBgColorOpen({
      [id]: !bgColorOpen[id]
    })
  }

  const updateXValue = ({
    signpostStarts,
    signpostInitialEnds,
    xValue,
    xScaleOld,
    signpostScale,
    xScaleNew,
    incrementValue
  }) => {
    if (
      xScaleOld(xValue) >= signpostStarts &&
      xScaleOld(xValue) <= signpostInitialEnds
    ) {
      return xScaleNew.invert(signpostScale(xScaleOld(xValue)))
    }
    if (xScaleOld(xValue) > signpostInitialEnds) {
      return xScaleNew.invert(xScaleOld(xValue) + incrementValue)
    }
    return xScaleNew.invert(xScaleOld(xValue))
  }

  const moveGraphElements = ({
    signpostStarts,
    signpostInitialEnds,
    signpostAfterEnds,
    newWidthGraph,
    incrementValue
  }) => {
    const charactersCopy = cloneDeep(characters)
    const drawCopy = cloneDeep(draw)

    const signpostScale = scaleLinear()
      .domain([signpostStarts, signpostInitialEnds])
      .range([signpostStarts, signpostAfterEnds])

    const xScaleOld = scaleLinear().domain([0, 5200]).range([0, graphWidth])

    const xScaleNew = scaleLinear().domain([0, 5200]).range([0, newWidthGraph])

    xScaleNew.clamp(true)

    const updatedCharacters = charactersCopy.map((character) => ({
      ...character,
      points: character.points.map((point) => ({
        ...point,
        pos: {
          ...point.pos,
          x: updateXValue({
            xValue: point.pos.x,
            signpostStarts,
            signpostInitialEnds,
            xScaleOld,
            signpostScale,
            xScaleNew,
            incrementValue
          })
        }
      }))
    }))

    const updatedDrawings = drawCopy.map((drawItem) => {
      if (
        drawItem.type === 'BRUSH' ||
        drawItem.type === 'PENCIL' ||
        drawItem.type === 'SIMPLE_LINE' ||
        drawItem.type === 'ARROW' ||
        drawItem.type === 'MULTI_ARROW' ||
        drawItem.type === 'TENSION_ARROW'
      ) {
        for (let index = 0; index < drawItem.path.length; index += 1) {
          const path = drawItem.path[index]
          path.x = updateXValue({
            xValue: path.x,
            signpostStarts,
            signpostInitialEnds,
            xScaleOld,
            signpostScale,
            xScaleNew,
            incrementValue
          })
        }
      }
      if (drawItem.type === 'CIRCLE' || drawItem.type === 'MEDIA') {
        drawItem.x1 = updateXValue({
          xValue: drawItem.x1,
          signpostStarts,
          signpostInitialEnds,
          xScaleOld,
          signpostScale,
          xScaleNew,
          incrementValue
        })
        drawItem.x2 = updateXValue({
          xValue: drawItem.x2,
          signpostStarts,
          signpostInitialEnds,
          xScaleOld,
          signpostScale,
          xScaleNew,
          incrementValue
        })
      }
      return drawItem
    })

    return { characters: updatedCharacters, draw: updatedDrawings }
  }

  const handleAddSignpost = async (spIndex, type) => {
    handleClose()
    const newSignpost = createSignpost()
    const signpostCopy = cloneDeep(signposts)
    const finalIndex = {
      before: spIndex,
      after: spIndex + 1
    }

    signpostCopy.splice(finalIndex[type], 0, newSignpost)

    let newWidthGraph = 0
    let rangeCount = 0
    let signpostStarts = 0
    let signpostInitialEnds = 0
    let signpostAfterEnds = 0

    for (let index = 0; index < signpostCopy.length; index += 1) {
      const item = signpostCopy[index]
      if (index === spIndex) {
        signpostStarts =
          type === 'before' ? rangeCount - item.width : rangeCount
        signpostInitialEnds =
          type === 'before' ? rangeCount : rangeCount + item.width
        signpostAfterEnds =
          type === 'before' ? rangeCount : rangeCount + item.width
      } else {
        rangeCount += item.width * zoomLevel
      }
      newWidthGraph += item.width * zoomLevel
    }

    const updatedElements = moveGraphElements({
      signpostStarts,
      signpostInitialEnds,
      signpostAfterEnds,
      newWidthGraph,
      incrementValue: newSignpost.width
    })

    await updateDoc(getChartRef(), {
      signposts: signpostCopy,
      ...updatedElements
    })

    updateTrackingData({
      signposts: signpostCopy,
      action: `Added new signpost ${type} "${signposts[spIndex].name}"`
    })
  }

  const handleAddRemove = async (spData) => {
    handleClose()
    const signpostCopy = cloneDeep(signposts)
    const filteredSignposts = signposts.filter((item) => item.id !== spData.id)

    let rangeCount = 0
    let signpostStarts = 0
    let signpostInitialEnds = 0
    let signpostAfterEnds = 0

    for (let index = 0; index < signpostCopy.length; index += 1) {
      const item = signpostCopy[index]
      if (item.id === spData.id) {
        signpostStarts = rangeCount
        signpostInitialEnds = rangeCount
        signpostAfterEnds = rangeCount
      } else {
        rangeCount += item.width * zoomLevel
      }
    }

    const updatedElements = moveGraphElements({
      signpostStarts,
      signpostInitialEnds,
      signpostAfterEnds,
      newWidthGraph: graphWidth - spData.width,
      incrementValue: -spData.width
    })

    await updateDoc(getChartRef(), {
      signposts: filteredSignposts,
      ...updatedElements
    })
    updateTrackingData({
      signposts: filteredSignposts,
      action: `Removed "${spData.name}" signpost`
    })
  }

  const handleSignpostMove = async (type, index) => {
    handleClose()
    const signpostCopy = cloneDeep(signposts)
    const [signpostMoved] = signpostCopy.splice(index, 1)
    const finalIndex = {
      left: index - 1,
      right: index + 1
    }

    signpostCopy.splice(finalIndex[type], 0, signpostMoved)

    await updateDoc(getChartRef(), {
      signposts: signpostCopy
    })
    updateTrackingData({
      signposts: signpostCopy,
      action: `Moved "${signpostMoved.name}" signpost to ${type}`
    })
  }

  const openSignposts = () => {
    dispatch(guideActions.signpostOpen())
    handleClose()
  }

  const handleBgChange = ({ hex }, event, index) => {
    dispatch(signpostsActions.changeSignpostBackgroundColor(index, hex))
  }

  const handleTextColorChange = ({ hex }, event, index) => {
    dispatch(signpostsActions.changeSignpostTextColor(index, hex))
  }

  const updateSignpostDb = async () => {
    await updateDoc(getChartRef(), {
      signposts
    })
    updateTrackingData({
      signposts,
      action: `Changed signpost text or background color`
    })
  }

  return (
    <>
      <Button style={{ color: '#fff' }} onClick={(e) => handleClick(e)}>
        Signposts
      </Button>
      <Menu
        classes={{ paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <NestedMenuItem label="Add before" parentMenuOpen={Boolean(anchorEl)}>
          {signposts.map((item, index) => (
            <MenuItem
              key={item.key}
              onClick={() => handleAddSignpost(index, 'before')}
            >
              <ListItemAvatar>
                <BookmarkIcon style={{ color: item.bgColor }} />
              </ListItemAvatar>
              {item.name}
            </MenuItem>
          ))}
        </NestedMenuItem>
        <NestedMenuItem label="Add after" parentMenuOpen={Boolean(anchorEl)}>
          {signposts.map((item, index) => (
            <MenuItem
              key={item.key}
              onClick={() => handleAddSignpost(index, 'after')}
            >
              <ListItemAvatar>
                <BookmarkIcon style={{ color: item.bgColor }} />
              </ListItemAvatar>
              {item.name}
            </MenuItem>
          ))}
        </NestedMenuItem>
        <NestedMenuItem label="Move left" parentMenuOpen={Boolean(anchorEl)}>
          {signposts.map((item, index) => (
            <MenuItem
              key={item.key}
              onClick={() => handleSignpostMove('left', index)}
            >
              <ListItemAvatar>
                <BookmarkIcon style={{ color: item.bgColor }} />
              </ListItemAvatar>
              {item.name}
            </MenuItem>
          ))}
        </NestedMenuItem>
        <NestedMenuItem label="Move right" parentMenuOpen={Boolean(anchorEl)}>
          {signposts.map((item, index) => (
            <MenuItem
              key={item.key}
              onClick={() => handleSignpostMove('right', index)}
            >
              <ListItemAvatar>
                <BookmarkIcon style={{ color: item.bgColor }} />
              </ListItemAvatar>
              {item.name}
            </MenuItem>
          ))}
        </NestedMenuItem>
        <NestedMenuItem
          label="Change background"
          parentMenuOpen={Boolean(anchorEl)}
        >
          {signposts.map((item, index) => (
            <div key={item.key}>
              <MenuItem onClick={() => handleClickBgColorDrop(item.key)}>
                <ListItemAvatar>
                  <BookmarkIcon style={{ color: item.bgColor }} />
                </ListItemAvatar>
                {item.name}{' '}
                {bgColorOpen[item.key] ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              <Collapse in={bgColorOpen[item.key]} timeout="auto" unmountOnExit>
                <Box display="flex" justifyContent="center" padding={1}>
                  <SketchPicker
                    color={item.bgColor}
                    onChange={(colorPayload, event) =>
                      handleBgChange(colorPayload, event, index)
                    }
                    onChangeComplete={updateSignpostDb}
                  />
                </Box>
              </Collapse>
            </div>
          ))}
        </NestedMenuItem>
        <NestedMenuItem
          label="Change font color"
          parentMenuOpen={Boolean(anchorEl)}
        >
          {signposts.map((item, index) => (
            <div key={item.key}>
              <MenuItem onClick={() => handleClickTextDrop(item.key)}>
                <ListItemAvatar>
                  <BookmarkIcon style={{ color: item.bgColor }} />
                </ListItemAvatar>
                {item.name}{' '}
                {textColorOpen[item.key] ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              <Collapse
                in={textColorOpen[item.key]}
                timeout="auto"
                unmountOnExit
              >
                <Box display="flex" justifyContent="center" padding={1}>
                  <SketchPicker
                    color={item.textColor}
                    onChange={(colorPayload, event) =>
                      handleTextColorChange(colorPayload, event, index)
                    }
                    onChangeComplete={updateSignpostDb}
                  />
                </Box>
              </Collapse>
            </div>
          ))}
        </NestedMenuItem>
        <NestedMenuItem label="Remove" parentMenuOpen={Boolean(anchorEl)}>
          {signposts.map((item) => (
            <MenuItem key={item.key} onClick={() => handleAddRemove(item)}>
              <ListItemAvatar>
                <BookmarkIcon style={{ color: item.bgColor }} />
              </ListItemAvatar>
              {item.name}
            </MenuItem>
          ))}
        </NestedMenuItem>
        <MenuItem onClick={() => openSignposts()}>What are signposts?</MenuItem>
      </Menu>
    </>
  )
}
