import { scaleLinear } from 'd3'

const toolDic = {
  pencil: 'PENCIL',
  brush: 'BRUSH',
  accordion: 'TENSION_ARROW',
  line: 'SIMPLE_LINE',
  arrow: 'ARROW',
  multi_arrow: 'MULTI_ARROW',
  oval: 'CIRCLE',
  media: 'MEDIA'
}

const generateChartBaseData = (rawText, { graphHeight, userRef }) => {
  let importedGraphWidth = 0

  const importedData = JSON.parse(rawText)

  if (!importedData.charts && !importedData.base) {
    return null
  }

  const newObjectData = {
    projectId: null,
    characters: [],
    draw: [],
    personalNotes: '',
    signposts: [],
    title: '',
    userId: userRef,
    collaborate: [],
    links: []
  }

  // load title
  newObjectData.title = importedData.info?.title ?? ''

  // load Signposts colums
  const importedSignpostsColums =
    importedData.charts.outlines['-']?.signposts ?? {}
  const importedSignpostsIds = Object.keys(importedSignpostsColums)
  newObjectData.signposts = importedSignpostsIds
    .filter((signpostItem) => !importedSignpostsColums[signpostItem].removed)
    .sort(
      (a, b) =>
        importedSignpostsColums[a].pos.x - importedSignpostsColums[b].pos.x
    )
    .map((signpostItem) => {
      const signpostData = importedSignpostsColums[signpostItem]
      const newSignpostData = {
        id: signpostItem,
        key: signpostItem,
        name: signpostData.title?.text ?? '',
        textColor: '#fff',
        bgColor: signpostData.colors?.bg ?? '#cca6a6',
        width: signpostData.pos?.width ?? 400
      }
      importedGraphWidth += signpostData.pos.width

      return newSignpostData
    })

  // Load signpost answers
  const importedSignpostQuestions = importedData.base?.signposts?.answers ?? {}

  newObjectData.signposts.forEach((item) => {
    item.answers =
      importedSignpostQuestions[
        item.name.trim().toUpperCase().replaceAll(' ', '_')
      ]?.answer ?? ''
  })

  // define scales
  const xScale = scaleLinear().domain([0, 5200]).range([0, importedGraphWidth])
  const yScaleOldApp = scaleLinear().domain([0, 300]).range([0, graphHeight])
  const yScale = scaleLinear().domain([-50, 50]).range([graphHeight, 0])

  // load characters
  const importedCharacters = importedData.charts.characters?.list ?? {}
  const charactersIds = Object.keys(importedCharacters)
  newObjectData.characters = charactersIds
    .filter((characterItem) => !importedCharacters[characterItem].removed)
    .map((characterItem) => {
      const characterData = importedCharacters[characterItem]
      const charactersPoints = characterData.points ?? []
      const charactersPointsIds = Object.keys(charactersPoints)
      const newData = {
        id: characterItem,
        color: characterData.color ?? '#222',
        name: characterData.name ?? '',
        colorOpacity: 1,
        points: charactersPointsIds
          .filter((points) => !charactersPoints[points].removed)
          .map((points) => {
            const pointData = charactersPoints[points]
            const pointNewData = {
              description: pointData.description ?? '',
              header: pointData.header ?? '',
              id: points,
              // here we have to apply scale to the points
              pos: {
                x: xScale.invert(pointData.pos.x < 0 ? 10 : pointData.pos.x),
                y: yScale.invert(yScaleOldApp(pointData.pos.y)) * -1
              }
            }
            return pointNewData
          })
      }
      return newData
    })

  // load Core questions Answers
  const importedCoreQuestions = importedData.base?.questions?.answers ?? {}

  newObjectData.characters.forEach((character) => {
    character.descriptionCQ = {
      q1: importedCoreQuestions.q1?.answer ?? '',
      q2: importedCoreQuestions.q2?.answer ?? '',
      q3: importedCoreQuestions.q3?.answer ?? '',
      q4: importedCoreQuestions.q4?.answer ?? '',
      q5: importedCoreQuestions.q5?.answer ?? '',
      q6: importedCoreQuestions.q6?.answer ?? '',
      q7: importedCoreQuestions.q7?.answer ?? '',
      q8: '',
      q9: importedCoreQuestions.q8?.answer ?? '',
      q10: importedCoreQuestions.q9?.answer ?? ''
    }
  })

  // load drawings
  const importedDraws = importedData.draw?.screens['-']?.list ?? {}
  const importedDrawsIds = Object.keys(importedDraws)
  newObjectData.draw = importedDrawsIds
    .filter((drawItem) => importedDraws[drawItem].tool)
    .filter((drawItem) => !importedDraws[drawItem].removed)
    .map((drawItem) => {
      const drawData = importedDraws[drawItem]
      const newDrawData = {
        id: drawItem,
        characterId: drawData.character_id ?? null,
        color: drawData.color,
        type: toolDic[`${drawData.tool}`]
      }
      if (
        toolDic[`${drawData.tool}`] === 'PENCIL' ||
        toolDic[`${drawData.tool}`] === 'BRUSH'
      ) {
        const drawPoints = drawData.points
        const drawPointsIds = Object.keys(drawPoints)
        newDrawData.path = drawPointsIds.map((pointItem) => {
          const pointData = drawPoints[pointItem]
          // add scales
          const newPointValue = {
            x: xScale.invert(drawData.x1 + pointData.xd),
            y: yScale.invert(yScaleOldApp(drawData.y1 + pointData.yd)) * -1
          }
          return newPointValue
        })
      }
      if (
        toolDic[`${drawData.tool}`] === 'SIMPLE_LINE' ||
        toolDic[`${drawData.tool}`] === 'ARROW' ||
        toolDic[`${drawData.tool}`] === 'MULTI_ARROW' ||
        toolDic[`${drawData.tool}`] === 'TENSION_ARROW'
      ) {
        newDrawData.path = [
          {
            x: xScale.invert(drawData.x1),
            y: yScale.invert(yScaleOldApp(drawData.y1)) * -1
          },
          {
            x: xScale.invert(drawData.x2),
            y: yScale.invert(yScaleOldApp(drawData.y2)) * -1
          }
        ]
      }
      if (toolDic[`${drawData.tool}`] === 'CIRCLE') {
        newDrawData.x1 = xScale.invert(drawData.x1)
        newDrawData.x2 = xScale.invert(drawData.x2)
        newDrawData.y1 = yScale.invert(yScaleOldApp(drawData.y1)) * -1
        newDrawData.y2 = yScale.invert(yScaleOldApp(drawData.y2)) * -1
      }
      if (toolDic[`${drawData.tool}`] === 'MEDIA') {
        newDrawData.url = drawData.url
        newDrawData.x1 = xScale.invert(Math.min(drawData.x1, drawData.x2))
        newDrawData.y1 = yScale.invert(
          Math.min(yScaleOldApp(drawData.y1), yScaleOldApp(drawData.y2))
        )
        newDrawData.x2 = xScale.invert(Math.max(drawData.x1, drawData.x2))
        newDrawData.y2 = yScale.invert(
          Math.max(yScaleOldApp(drawData.y1), yScaleOldApp(drawData.y2))
        )

        // platforms
        if (
          drawData.url.toLowerCase().indexOf('youtube.com') !== -1 ||
          drawData.url.toLowerCase().indexOf('youtu.be') !== -1
        ) {
          newDrawData.typeFile = 'youtube/media'
        }
        if (drawData.url.toLowerCase().indexOf('soundcloud.com') !== -1) {
          newDrawData.typeFile = 'soundcloud/media'
        }

        // videos
        if (drawData.url.toLowerCase().indexOf('mp4') !== -1) {
          newDrawData.typeFile = 'video/mp4'
        }
        if (drawData.url.toLowerCase().indexOf('mpeg') !== -1) {
          newDrawData.typeFile = 'video/mpeg'
        }
        if (drawData.url.toLowerCase().indexOf('avi') !== -1) {
          newDrawData.typeFile = 'video/x-msvideo'
        }
        if (drawData.url.toLowerCase().indexOf('3gp') !== -1) {
          newDrawData.typeFile = 'video/3gpp'
        }
        if (drawData.url.toLowerCase().indexOf('webm') !== -1) {
          newDrawData.typeFile = 'video/webm'
        }

        // audio
        if (drawData.url.toLowerCase().indexOf('mp3') !== -1) {
          newDrawData.typeFile = 'audio/mpeg'
        }
        if (drawData.url.toLowerCase().indexOf('weba') !== -1) {
          newDrawData.typeFile = 'audio/webm'
        }
        if (drawData.url.toLowerCase().indexOf('wav') !== -1) {
          newDrawData.typeFile = 'audio/x-wav'
        }

        // images
        if (drawData.url.toLowerCase().indexOf('png') !== -1) {
          newDrawData.typeFile = 'image/png'
        }
        if (drawData.url.toLowerCase().indexOf('svg') !== -1) {
          newDrawData.typeFile = 'image/svg+xml'
        }
        if (drawData.url.toLowerCase().indexOf('ico') !== -1) {
          newDrawData.typeFile = 'image/x-icon'
        }
        if (drawData.url.toLowerCase().indexOf('webp') !== -1) {
          newDrawData.typeFile = 'image/webp'
        }
        if (
          drawData.url.toLowerCase().indexOf('jpg') !== -1 ||
          drawData.url.toLowerCase().indexOf('jpeg') !== -1
        ) {
          newDrawData.typeFile = 'image/jpeg'
        }
      }
      return newDrawData
    })

  if (!newObjectData.characters.length && !newObjectData.draw.length) {
    return null
  }

  return newObjectData
}

export default generateChartBaseData
