import { makeStyles } from '@material-ui/core/styles'
import { Box, Fab, Tooltip } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { scaleLinear } from 'd3'
import { GetApp } from '@material-ui/icons'

import CharacterCardField from './CharacterCardField'
import DownloadEventsDialog from '../DownloadEventsDialog'
import guideActions from '../../redux/actions/guide'

const signpostCount = {
  prev: 0,
  current: 0
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'auto',
    backgroundColor: '#253235',
    height: '100%'
  }
}))

export default function CharactersDetails() {
  const { signposts, zoomLevel } = useSelector((state) => state.signposts)
  const { selectedCharacter } = useSelector((state) => state.characters)
  const { graphWidth } = useSelector((state) => state.animationTools)
  const classes = useStyles()
  const dispatch = useDispatch()

  const charactersBoxes = (signpost, signpostIndex) => {
    if (!selectedCharacter.points) {
      return null
    }

    if (signpostIndex === 0) {
      signpostCount.prev = 0
      signpostCount.current = signpost.width * zoomLevel
    } else {
      signpostCount.prev = signpostCount.current
      signpostCount.current += signpost.width * zoomLevel
    }

    const xScale = scaleLinear().domain([0, 5200]).range([0, graphWidth])

    const jsx = []
    for (let i = 0; i < selectedCharacter.points.length; i += 1) {
      const item = selectedCharacter.points[i]
      if (
        !item.skip &&
        xScale(item.pos.x) > signpostCount.prev &&
        xScale(item.pos.x) <= signpostCount.current
      ) {
        jsx.push(<CharacterCardField data={item} key={item.id} index={i} />)
      }
    }
    return jsx
  }

  const scrollCharactersDetails = (e) => {
    const ChartId = document.getElementById('ChartId')
    if (ChartId) {
      ChartId.scrollLeft = e.target.scrollLeft
    }
  }

  return (
    <div
      className={classes.root}
      onScroll={scrollCharactersDetails}
      id="CharactersDetailsId"
    >
      {signposts.map((signpost, index) => (
        <Box
          key={signpost.id}
          style={{
            backgroundColor: index % 2 !== 0 ? '#202b2e' : '#253235',
            minWidth: signpost.width * zoomLevel,
            maxWidth: signpost.width * zoomLevel,
            minHeight: '100%'
          }}
        >
          {charactersBoxes(signpost, index)}
        </Box>
      ))}
      <Tooltip placement="top" title="Download characters events">
        <Fab
          style={{
            position: 'fixed',
            right: 35,
            bottom: 20,
            zIndex: 1
          }}
          onClick={() => {
            dispatch(guideActions.downloadEventsDialog(true))
          }}
        >
          <GetApp />
        </Fab>
      </Tooltip>
      <DownloadEventsDialog />
    </div>
  )
}
