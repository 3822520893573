import { collaborateTypes } from '../types'

const joinChartOpen = () => (dispatch) => {
  dispatch({ type: collaborateTypes.OPEN_MODAL_JOIN_CHART })
}

const getCollaborators = (payload) => (dispatch) => {
  dispatch({ type: collaborateTypes.UPDATE_COLLABORATORS, payload })
}

const joinChartClose = () => (dispatch) => {
  dispatch({ type: collaborateTypes.CLOSE_MODAL_JOIN_CHART })
}
const shareChartOpen = () => (dispatch) => {
  dispatch({ type: collaborateTypes.OPEN_MODAL_SHARE_CHART })
}
const shareChartClose = () => (dispatch) => {
  dispatch({ type: collaborateTypes.CLOSE_MODAL_SHARE_CHART })
}

export default {
  joinChartOpen,
  joinChartClose,
  shareChartOpen,
  shareChartClose,
  getCollaborators
}
