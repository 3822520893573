/* eslint no-use-before-define: ["error", { "functions": false }] */
import { useRef, useState, useLayoutEffect, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  pointer,
  line,
  select,
  selectAll,
  scaleLinear,
  axisRight,
  drag,
  range,
  interpolateObject,
  brush,
  curveBasis
} from 'd3'
import { useSelector, useDispatch } from 'react-redux'
import { nanoid } from 'nanoid'
import { useFirestore } from 'reactfire'
import {
  doc,
  updateDoc,
  arrayUnion,
  collection,
  getDocs,
  addDoc,
  Timestamp
} from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import urlParser from 'js-video-url-parser'

import generateRandomColor from '../../utils/randomColor'
import { userBaseSchema } from '../../utils/defaultCoreQuestions'
import charactersActions from '../../redux/actions/characters'
import appActions from '../../redux/actions/app'
import animationToolsActions from '../../redux/actions/animationTools'
import drawingToolsActions from '../../redux/actions/drawingTools'
import './styles.css'

import MarksDetails from './MarksDetails'
import useOnScreen from '../../hooks/useOnScreen'

const useStyles = makeStyles(() => ({
  svgWrapper: {
    width: '100%',
    height: '100%'
  }
}))

const notificationReadOnlyMessage = {
  open: true,
  variant: 'info',
  message: 'You are in reading only mode'
}

const SvgGraphSeason = ({ size }) => {
  const { selectedCharacter, characters: allCharactersData } = useSelector(
    (state) => state.characters
  )
  const { signposts, zoomLevel } = useSelector((state) => state.signposts)
  const { graphWidth, isAnimating } = useSelector(
    (state) => state.animationTools
  )
  const {
    draw: allDrawingsData,
    title,
    personalNotes
  } = useSelector((state) => state.guide)
  const { tool, color } = useSelector((state) => state.drawingTools)
  const { readOnly } = useSelector((state) => state.auth)
  const firestore = useFirestore()
  const dispatch = useDispatch()
  const { id: chartId } = useParams()
  const chartRef = doc(firestore, `/series`, chartId)
  const classes = useStyles()
  const collectionRef = collection(firestore, 'series', chartId, 'changes')
  const refGraph = useRef()

  const [anchorEl, setAnchorEl] = useState(null)
  const [data, setData] = useState(null)

  const mainContainer = useRef(null)
  const rightLimit = useRef(null)
  const onScreen = useOnScreen(rightLimit, '-5px')

  useLayoutEffect(() => {
    const containerScroll = document.getElementById('CharactersDetailsId')
    const chartContainer = document.getElementById('ChartId')
    const { scrollLeft } = containerScroll
    if (isAnimating && !onScreen) {
      containerScroll.scrollLeft = scrollLeft + chartContainer.clientWidth
    }
  }, [onScreen])

  useEffect(() => {
    const svg = select(refGraph.current)
    const characters = cloneDeep(allCharactersData)
    const drawings = cloneDeep(allDrawingsData)

    const xScale = scaleLinear()
      .domain([0, 5200])
      .range([0, graphWidth])
      .clamp(true)
    const yScale = scaleLinear()
      .domain([-50, 50])
      .range([refGraph.current.clientHeight, 0])
      .clamp(true)

    dispatch(
      animationToolsActions.setGraphHeight(refGraph.current.clientHeight)
    )

    const updateTrackingData = (trackData) => {
      const payload = {
        characters,
        draw: drawings,
        signposts,
        personalNotes,
        title,
        date: Timestamp.fromDate(new Date()),
        ...trackData
      }

      return addDoc(collectionRef, payload)
    }

    // calculate the y axis
    const yAxis = axisRight(yScale)
    svg
      .select('.y-axis')
      .call(yAxis)
      .call((g) => g.select('.domain').remove())
      .call((g) => g.selectAll('.tick text').attr('x', 4).attr('dy', -4))
      .call((g) =>
        g.selectAll('.tick').each(function (d) {
          if (d === 50 || d === -50) {
            this.remove()
          }
        })
      )

    // calculate the y axis lines
    const yAxisGrid = yAxis.tickSize(graphWidth)
    svg
      .select('.y-axis-lines')
      .call(yAxisGrid)
      .call((g) =>
        g.selectAll('.tick line').each(function (d) {
          if (d === 50 || d === -50) {
            this.remove()
          }
          if (d === 0) {
            select(this).attr('stroke', 'gray')
          }
        })
      )

    const filterDrags = (event) => {
      const allowedDrag = !event.ctrlKey && !event.button && !readOnly
      if (readOnly) {
        dispatch(appActions.displayNotifications(notificationReadOnlyMessage))
      }
      return allowedDrag
    }

    const lineFunction = line()
      .defined((d) => d.pos.y && d.pos.x)
      .x((d) => xScale(d.pos.x))
      .y((d) => yScale(d.pos.y))

    const charactersGroup = svg
      .selectAll('.charactersGroup')
      .data(characters, (d) => d.id)
      .join('g')
      .attr('class', 'charactersGroup')
      .attr('stroke', (d) => d.color)
      .attr('fill', (d) => d.color)
      .attr('opacity', (d) => d.colorOpacity)

    const renderGraphLines = () => {
      charactersGroup
        .selectAll('.charactersLines')
        .data(characters, (d) => d.id)
        .join('path')
        .attr('class', 'charactersLines')
        .attr('fill', 'none')
        .attr('stroke', (d) => d.color)
        .attr('opacity', (d) => d.colorOpacity)
        .attr('stroke-width', 2)
        .attr('d', (d) =>
          lineFunction(
            d.points
              .sort((a, b) => a.pos.x - b.pos.x)
              .map((item) =>
                item.skip ? { ...item, pos: { x: null, y: null } } : item
              )
          )
        )
    }

    renderGraphLines()

    const openPointHeader = (e, d) => {
      if (e.defaultPrevented) return

      const character = characters[d.characterIndex]

      dispatch(charactersActions.selectCharacter(character))

      setData(d)
      setAnchorEl(anchorEl ? null : e.currentTarget)
    }

    const moveSelectedElements = (event, dataProps) => {
      charactersGroup.selectAll('circle').each(function (datum) {
        const isSelected = select(this).classed('selected')
        if (isSelected && dataProps.id !== datum.id) {
          datum.pos.x = xScale.invert(xScale(datum.pos.x) + event.dx)
          datum.pos.y = yScale.invert(yScale(datum.pos.y) + event.dy)
          select(this)
            .attr('cx', xScale(datum.pos.x) + event.dx)
            .attr('cy', yScale(datum.pos.y) + event.dy)
          selectAll('.text').style(
            'transform',
            (d) =>
              `translate(${xScale(d.pos.x) - 15}px,${yScale(d.pos.y) - 40}px)`
          )
          renderGraphLines()
        }
      })
      selectAll('.allDrawings').each(function (datum) {
        const isSelected = select(this).classed('selected')

        if (datum.type === 'BRUSH' || datum.type === 'PENCIL') {
          if (isSelected && dataProps.id !== datum.id) {
            for (let index = 0; index < datum.path.length; index += 1) {
              const path = datum.path[index]
              const unformatedYValue = yScale(path.y)
              const newFromYValue = unformatedYValue + event.dy

              const unformatedXValue = xScale(path.x)
              const newFromXValue = unformatedXValue + event.dx

              path.x = xScale.invert(newFromXValue)
              path.y = yScale.invert(newFromYValue)
            }
            select(this).attr('d', generateArrowPath)

            selectAll(`.drawingHandles${datum.id}`)
              .data([datum.path[0], datum.path.at(-1)])
              .attr('cx', (internalDatum) => xScale(internalDatum.x))
              .attr('cy', (internalDatum) => yScale(internalDatum.y))
          }
        }
        if (
          datum.type === 'SIMPLE_LINE' ||
          datum.type === 'ARROW' ||
          datum.type === 'MULTI_ARROW' ||
          datum.type === 'TENSION_ARROW'
        ) {
          if (isSelected && dataProps.id !== datum.id) {
            const unformatedYValueFrom = yScale(datum.path[0].y)
            const unformatedYValueTo = yScale(datum.path[1].y)

            const newFromYValue = unformatedYValueFrom + event.dy
            const newToYValue = unformatedYValueTo + event.dy

            const unformatedXValueFrom = xScale(datum.path[0].x)
            const unformatedXValueTo = xScale(datum.path[1].x)

            const newFromXValue = unformatedXValueFrom + event.dx
            const newToXValue = unformatedXValueTo + event.dx

            datum.path[0].x = xScale.invert(newFromXValue)
            datum.path[0].y = yScale.invert(newFromYValue)

            datum.path[1].x = xScale.invert(newToXValue)
            datum.path[1].y = yScale.invert(newToYValue)

            select(this).attr('d', generateArrowPath)

            svg
              .selectAll(`.drawingHandles${datum.id}`)
              .attr('cx', (internalDatum) => xScale(internalDatum.x))
              .attr('cy', (internalDatum) => yScale(internalDatum.y))
          }
        }
        if (datum.type === 'CIRCLE') {
          if (isSelected && dataProps.id !== datum.id) {
            let reversedStartXScale = xScale(datum.x1)
            let reversedStartYScale = yScale(datum.y1)
            let reversedFinishXScale = xScale(datum.x2)
            let reversedFinishYScale = yScale(datum.y2)

            reversedStartXScale += event.dx
            reversedStartYScale += event.dy

            reversedFinishXScale += event.dx
            reversedFinishYScale += event.dy

            datum.x1 = xScale.invert(reversedStartXScale)
            datum.y1 = yScale.invert(reversedStartYScale)

            datum.x2 = xScale.invert(reversedFinishXScale)
            datum.y2 = yScale.invert(reversedFinishYScale)

            select(this)
              .attr('cx', (internalDatum) => xScale(internalDatum.x1))
              .attr('cy', (internalDatum) => yScale(internalDatum.y1))

            selectAll(`.drawingHandles${datum.id}`)
              .attr('cx', (internalDatum) => xScale(internalDatum.x2))
              .attr('cy', (internalDatum) => yScale(internalDatum.y2))
          }
        }
        if (datum.type === 'MEDIA') {
          if (isSelected && dataProps.id !== datum.id) {
            let reversedStartXScale = xScale(datum.x1)
            let reversedStartYScale = yScale(datum.y1)
            let reversedFinishXScale = xScale(datum.x2)
            let reversedFinishYScale = yScale(datum.y2)

            reversedStartXScale += event.dx
            reversedStartYScale += event.dy

            reversedFinishXScale += event.dx
            reversedFinishYScale += event.dy

            datum.x1 = xScale.invert(reversedStartXScale)
            datum.y1 = yScale.invert(reversedStartYScale)

            datum.x2 = xScale.invert(reversedFinishXScale)
            datum.y2 = yScale.invert(reversedFinishYScale)

            select(`.drawingGroup${datum.id}`)
              .attr('x', (internalDatum) => xScale(internalDatum.x1))
              .attr('y', (internalDatum) => yScale(internalDatum.y1))
            select(`.drawingGroup${datum.id}`)
              .selectAll(`.image${datum.id}`)
              .attr('x', (internalDatum) => xScale(internalDatum.x1))
              .attr('y', (internalDatum) => yScale(internalDatum.y1))
            select(`.drawingGroup${datum.id}`)
              .selectAll(`.topleft`)
              .attr('cx', (internalDatum) => xScale(internalDatum.x1))
              .attr('cy', (internalDatum) => yScale(internalDatum.y1))
            select(`.drawingGroup${datum.id}`)
              .selectAll(`.bottomright`)
              .attr('cx', (internalDatum) => xScale(internalDatum.x2))
              .attr('cy', (internalDatum) => yScale(internalDatum.y2))

            select(`.drawingGroup${datum.id}`)
              .selectAll(`.playIcon`)
              .attr(
                'x',
                (internalDatum) =>
                  xScale((internalDatum.x1 + internalDatum.x2) / 2) - 25
              )
              .attr(
                'y',
                (internalDatum) =>
                  yScale((internalDatum.y1 + internalDatum.y2) / 2) - 20
              )
          }
        }
      })
    }

    const renderHeaderText = () => {
      const textContainer = select('#mainContainer')
        .selectAll('.textContainer')
        .data(characters)
        .join('div')
        .classed('textContainer', true)
        .style('position', 'absolute')
        .style('opacity', (d) => d.colorOpacity)

      textContainer
        .selectAll('.text')
        .data(
          (d, characterIndex) => {
            d.points.forEach((pointTemp, pointIndex) => {
              pointTemp.characterIndex = characterIndex
              pointTemp.pointIndex = pointIndex
            })
            return d.points
          },
          (d) => d.id
        )
        .join('p')
        .classed('text', true)
        .style('position', 'absolute')
        .style(
          'transform',
          (d) =>
            `translate(${xScale(d.pos.x) - 15}px,${yScale(d.pos.y) - 40}px)`
        )
        .style('background-color', (d) =>
          d.skip ? 'rgba(75, 78, 202, 0.83)' : '#ffffff80'
        )
        .style('padding', '5px')
        .style('border-radius', '12px')
        .style('color', (d) => (d.skip ? '#fff' : '#777'))
        .style('margin', '0')
        .style('max-width', '250px')
        .style('text-overflow', 'ellipsis')
        .style('white-space', 'nowrap')
        .style('box-shadow', '0px 2px 4px 0px rgb(0 0 0 / 5%)')
        .style('overflow', 'hidden')
        .text((d) => {
          const croppedText = d.header.slice(0, 20)
          if (d.skip) {
            return 'SKIPPED'
          }
          if (d.pointIndex === 0) {
            return `${
              croppedText.length === 20 ? `${croppedText}...` : croppedText
            } ${Math.round(d.pos.y)}`
          }
          const prevPoint =
            characters[d.characterIndex].points[d.pointIndex - 1].pos.y
          return `${
            croppedText.length === 20 ? `${croppedText}...` : croppedText
          } ${Math.round(d.pos.y - prevPoint)}`
        })
        .style('cursor', 'pointer')
        .on('click', openPointHeader)
        .on('mouseenter', function () {
          select(this).style('z-index', 3)
        })
        .on('mouseleave', function () {
          select(this).style('z-index', 1)
        })
        .call(
          drag()
            .filter(filterDrags)
            .subject(() => ({
              hasMoved: false
            }))
            .on('drag', ({ dy, dx, subject }, d) => {
              const formatedXValue = xScale(d.pos.x)
              const formatedYValue = yScale(d.pos.y)

              const newXValue = formatedXValue + dx
              const newYValue = formatedYValue + dy

              d.pos.x = xScale.invert(newXValue)
              d.pos.y = yScale.invert(newYValue)

              renderGraphLines()
              renderHeaderText()
              select(`.circle${d.id}`)
                .attr('cx', newXValue)
                .attr('cy', newYValue)
              moveSelectedElements({ dy, dx }, d)
              subject.hasMoved = true
            })
            .on('end', async ({ subject }, d) => {
              if (subject.hasMoved) {
                await updateDoc(chartRef, {
                  characters,
                  draw: drawings
                })
                updateTrackingData({
                  action: `Moved "${d.header || 'No header'}" event`
                })
              }
            })
        )
    }

    renderHeaderText()

    charactersGroup
      .selectAll('circle')
      .data(
        (d, characterIndex) => {
          d.points.forEach((pointTemp, pointIndex) => {
            pointTemp.characterIndex = characterIndex
            pointTemp.pointIndex = pointIndex
          })
          return d.points
        },
        (d) => d.id
      )
      .join('circle')
      .attr('class', (d) => `circle${d.id}`)
      .attr('r', 7)
      .attr('cx', (d) => xScale(d.pos.x))
      .attr('cy', (d) => yScale(d.pos.y))
      .style('cursor', 'pointer')
      .call(
        drag()
          .filter(filterDrags)
          .subject(() => ({
            hasMoved: false
          }))
          .on('drag', function (event, d) {
            const { subject, x, y } = event
            d.pos.x = xScale.invert(x)
            d.pos.y = yScale.invert(y)
            subject.hasMoved = true
            renderGraphLines()
            renderHeaderText()
            select(this).attr('cx', x).attr('cy', y)
            moveSelectedElements(event, d)
          })
          .on('end', async ({ subject }, d) => {
            if (subject.hasMoved) {
              await updateDoc(chartRef, {
                characters,
                draw: drawings
              })
              updateTrackingData({
                action: `Moved "${d.header || 'No header'}" event`
              })
            }
          })
      )
      .on('click', openPointHeader)

    svg
      .on('dblclick contextmenu', async (e) => {
        e.preventDefault()
        if (readOnly) {
          dispatch(appActions.displayNotifications(notificationReadOnlyMessage))
        } else {
          const [x, y] = pointer(e)
          if (!characters.length) {
            const newCharacter = {
              id: nanoid(),
              name: 'Protagonist',
              color: generateRandomColor(),
              colorOpacity: 1,
              descriptionCQ: userBaseSchema,
              points: [
                {
                  id: nanoid(),
                  description: '',
                  header: '',
                  pos: {
                    x: xScale.invert(x),
                    y: yScale.invert(y)
                  }
                }
              ],
              scope: 'SERIES'
            }
            await updateDoc(chartRef, {
              characters: arrayUnion(newCharacter)
            })
            updateTrackingData({
              action: `Created a new "${newCharacter.name}" character event`
            })
            dispatch(charactersActions.selectCharacter(newCharacter))

            const episodesRef = collection(
              firestore,
              'series',
              chartId,
              'episodes'
            )
            const querySnapshot = await getDocs(episodesRef)
            const changesPromises = []
            const promises = querySnapshot.docs.map((docItem) => {
              const docRef = doc(
                firestore,
                `series/${chartId}/episodes/${docItem.id}`
              )

              changesPromises.push(
                addDoc(
                  collection(
                    firestore,
                    'series',
                    chartId,
                    'episodes',
                    docItem.id,
                    'changes'
                  ),
                  {
                    ...docItem.data(),
                    date: Timestamp.fromDate(new Date()),
                    action: `Created a new "${newCharacter.name}" character event`
                  }
                )
              )
              return updateDoc(docRef, {
                characters: arrayUnion({ ...newCharacter, points: [] })
              })
            })

            Promise.all(promises)
            Promise.all(changesPromises)
          } else {
            const characterIndex = characters.findIndex(
              (item) => selectedCharacter.id === item.id
            )

            const [item] = characters.splice(characterIndex, 1)

            const graphPoint = {
              id: nanoid(),
              description: '',
              header: '',
              pos: {
                x: xScale.invert(x),
                y: yScale.invert(y)
              }
            }
            item.points.push(graphPoint)
            item.points.sort((a, b) => a.pos.x - b.pos.x)

            characters.splice(characterIndex, 0, item)
            dispatch(charactersActions.selectCharacter(item))

            await updateDoc(chartRef, {
              characters
            })
            updateTrackingData({
              action: `Created a new "${
                item.name || 'No name'
              }" character event`
            })
          }
        }
      })
      .on('click', () => {
        selectAll('.externalContainer').remove()
        selectAll('.charactersGroup, .charactersLines, circle').raise()
      })

    const drawLine = line()
      .curve(curveBasis)
      .x((d) => xScale(d.x))
      .y((d) => yScale(d.y))

    function generateArrow(x1, y1, x2, y2) {
      const scaledX1 = xScale(x1)
      const scaledY1 = yScale(y1)

      const scaledX2 = xScale(x2)
      const scaledY2 = yScale(y2)

      const dx = scaledX2 - scaledX1
      const dy = scaledY2 - scaledY1

      const px = scaledY1 - scaledY2
      const py = scaledX2 - scaledX1
      const plength = Math.sqrt(px * px + py * py)
      const pmultiplier = 15 / plength

      const px1 = px * pmultiplier
      const py1 = py * pmultiplier

      const sx = dx * pmultiplier
      const sy = dy * pmultiplier

      const a1 = scaledX1
      const b1 = scaledY1
      const a2 = scaledX2
      const b2 = scaledY2

      return `M${a1}, ${b1} L${a2}, ${b2} M${a2 + px1 - sx}, ${
        b2 + py1 - sy
      }L${a2}, ${b2}L${a2 - px1 - sx}, ${b2 - py1 - sy}`
    }

    function drawTensionArrow(
      x1,
      y1,
      x2,
      y2,
      isStart = false,
      isMiddle = false
    ) {
      const scaledX1 = xScale(x1)
      const scaledY1 = yScale(y1)

      const scaledX2 = xScale(x2)
      const scaledY2 = yScale(y2)

      const px = scaledY1 - scaledY2
      const py = scaledX2 - scaledX1
      const plength = Math.sqrt(px * px + py * py)
      const pmultiplier = 50 / plength

      const px1 = px * pmultiplier
      const py1 = py * pmultiplier

      const a1 = scaledX1
      const b1 = scaledY1
      const a2 = scaledX2
      const b2 = scaledY2

      if (isMiddle) {
        const rangeItems = [0, 0.5, 1]
        const interpolate = interpolateObject(
          { x: a1, y: b1 },
          { x: a2, y: b2 }
        )

        const items = []

        for (let i = 0; i < rangeItems.length; i += 1) {
          const number = rangeItems[i]
          const newValue = interpolate(number)
          items.push(cloneDeep(newValue))
        }

        const [first, second, third] = items

        return `M${first.x}, ${first.y} L${(second.x - px1 + first.x) / 2}, ${
          (second.y - py1 + first.y) / 2
        } L${second.x}, ${second.y} L${(third.x + px1 + second.x) / 2}, ${
          (third.y + py1 + second.y) / 2
        } L${third.x}, ${third.y}`
      }

      const rangeItems = [0, 0.33, 0.66, 1]
      const interpolate = interpolateObject({ x: a1, y: b1 }, { x: a2, y: b2 })

      const items = []

      for (let i = 0; i < rangeItems.length; i += 1) {
        const number = rangeItems[i]
        const newValue = interpolate(number)
        items.push(cloneDeep(newValue))
      }

      const [first, second, third, fourth] = items

      if (isStart) {
        return `M${first.x}, ${first.y} L${second.x}, ${second.y} L${
          (third.x - px1 + second.x) / 2
        }, ${(third.y - py1 + second.y) / 2} L${third.x}, ${third.y} L${
          (fourth.x + px1 + third.x) / 2
        }, ${(fourth.y + py1 + third.y) / 2} L${fourth.x}, ${fourth.y}`
      }

      return `M${first.x}, ${first.y} L${(second.x - px1 + first.x) / 2}, ${
        (second.y - py1 + first.y) / 2
      } L${second.x}, ${second.y} L${(third.x + px1 + second.x) / 2}, ${
        (third.y + py1 + second.y) / 2
      } L${third.x}, ${third.y} L${fourth.x}, ${fourth.y}`
    }

    function getDistance(xA, yA, xB, yB) {
      const unformatedYFromValue = yScale(yA)
      const unformatedYToValue = yScale(yB)
      return Math.sqrt(
        (xB - xA) ** 2 + (unformatedYToValue - unformatedYFromValue) ** 2
      )
    }

    function generateMultiArrow(x1, y1, x2, y2) {
      const interpolate = interpolateObject({ x: x1, y: y1 }, { x: x2, y: y2 })

      const distance = getDistance(x1, y1, x2, y2)

      const rangeItems = range(0, 1, 25 / distance)

      const items = []

      for (let i = 0; i < rangeItems.length; i += 1) {
        const number = rangeItems[i]
        const newValue = interpolate(number)
        items.push(cloneDeep(newValue))
      }

      items.push({ x: x2, y: y2 })

      const dPath = []
      const itemsLength = items.length - 1
      for (let index = 0; index < items.length; index += 1) {
        const newPath = items[index]
        if (index !== itemsLength) {
          const pathValue = generateArrow(
            newPath.x,
            newPath.y,
            items[index + 1].x,
            items[index + 1].y
          )
          dPath.push(pathValue)
        }
      }
      return dPath.join('')
    }

    function generateTensionArrow(x1, y1, x2, y2) {
      const interpolate = interpolateObject({ x: x1, y: y1 }, { x: x2, y: y2 })

      const distance = getDistance(x1, y1, x2, y2)

      const rangeItems = range(0, 1, 50 / distance)

      const items = []

      for (let i = 0; i < rangeItems.length; i += 1) {
        const number = rangeItems[i]
        const newValue = interpolate(number)
        items.push(cloneDeep(newValue))
      }

      items.push({ x: x2, y: y2 })

      const dPath = []
      const itemsLength = items.length - 1
      if (items.length > 1) {
        for (let index = 0; index < items.length; index += 1) {
          const newPath = items[index]
          if (index === 0) {
            const pathValue = drawTensionArrow(
              newPath.x,
              newPath.y,
              items[index + 1].x,
              items[index + 1].y,
              true
            )
            dPath.push(pathValue)
          }
          if (index > 0 && index < itemsLength - 1) {
            const pathValue = drawTensionArrow(
              newPath.x,
              newPath.y,
              items[index + 1].x,
              items[index + 1].y,
              false,
              true
            )
            dPath.push(pathValue)
          }
          if (index === itemsLength - 1) {
            const pathValue = drawTensionArrow(
              newPath.x,
              newPath.y,
              items[index + 1].x,
              items[index + 1].y
            )
            dPath.push(pathValue)
          }
        }
      }
      return dPath.join('')
    }

    function generateArrowPath(arrowData) {
      if (arrowData.type === 'ARROW') {
        return generateArrow(
          arrowData.path[0].x,
          arrowData.path[0].y,
          arrowData.path[1].x,
          arrowData.path[1].y
        )
      }
      if (arrowData.type === 'MULTI_ARROW') {
        return generateMultiArrow(
          arrowData.path[0].x,
          arrowData.path[0].y,
          arrowData.path[1].x,
          arrowData.path[1].y
        )
      }
      if (arrowData.type === 'TENSION_ARROW') {
        return generateTensionArrow(
          arrowData.path[0].x,
          arrowData.path[0].y,
          arrowData.path[1].x,
          arrowData.path[1].y
        )
      }
      return drawLine(arrowData.path)
    }

    const render = ({ subject }) => {
      if (subject.type === 'CIRCLE') {
        svg
          .selectAll('.circleDrawing')
          .data([subject], (d) => d.id)
          .join('ellipse')
          .attr('class', 'circleDrawing')
          .attr('stroke', (d) => d.color)
          .attr('fill', (d) => d.color)
          .attr('cx', (d) => xScale(d.x1))
          .attr('cy', (d) => yScale(d.y1))
          .attr('rx', (d) => Math.abs(xScale(d.x1) - xScale(d.x2)))
          .attr('ry', (d) => Math.abs(yScale(d.y1) - yScale(d.y2)))
      }

      if (subject.type === 'MEDIA' || subject.type === 'MEDIA_ICONS') {
        svg
          .selectAll('.multimediaDrawing')
          .data([subject], (d) => d.id)
          .join('rect')
          .attr('class', 'multimediaDrawing')
          .attr('stroke', '#808080')
          .attr('fill', 'none')
          .attr('stroke-width', 1)
          .attr('width', (d) => Math.abs(xScale(d.x1) - xScale(d.x2)))
          .attr('height', (d) => Math.abs(yScale(d.y1) - yScale(d.y2)))
          .attr('x', (d) => Math.min(xScale(d.x1), xScale(d.x2)))
          .attr('y', (d) => Math.min(yScale(d.y1), yScale(d.y2)))
      }

      if (
        subject.type === 'BRUSH' ||
        subject.type === 'PENCIL' ||
        subject.type === 'SIMPLE_LINE' ||
        subject.type === 'ARROW' ||
        subject.type === 'MULTI_ARROW' ||
        subject.type === 'TENSION_ARROW'
      ) {
        svg
          .selectAll('.linesDrawing')
          .data([subject], (d) => d.id)
          .join('path')
          .attr('class', 'linesDrawing')
          .attr('stroke', (d) => d.color)
          .attr('stroke-linejoin', 'round')
          .attr('stroke-linecap', 'round')
          .attr('fill', 'none')
          .attr('stroke-width', (d) => (d.type === 'PENCIL' ? 1 : 3))
          .attr('d', generateArrowPath)
      }
    }

    svg.call(
      drag()
        .container(refGraph.current)
        .filter(filterDrags)
        .subject(({ x, y }) => {
          if (tool === 'PENCIL' || tool === 'BRUSH') {
            return {
              id: nanoid(),
              type: tool,
              color,
              path: [],
              characterId: selectedCharacter.id ? selectedCharacter.id : null,
              hasMoved: false
            }
          }
          if (
            tool === 'SIMPLE_LINE' ||
            tool === 'ARROW' ||
            tool === 'MULTI_ARROW' ||
            tool === 'TENSION_ARROW'
          ) {
            return {
              id: nanoid(),
              type: tool,
              color,
              path: [{ x: xScale.invert(x), y: yScale.invert(y) }],
              characterId: selectedCharacter.id ? selectedCharacter.id : null,
              hasMoved: false
            }
          }
          if (tool === 'CIRCLE' || tool === 'MEDIA' || tool === 'MEDIA_ICONS') {
            return {
              id: nanoid(),
              type: tool,
              color,
              x1: xScale.invert(x),
              y1: yScale.invert(y),
              x2: 0,
              y2: 0,
              characterId: selectedCharacter.id ? selectedCharacter.id : null,
              hasMoved: false
            }
          }
          return null
        })
        .on('drag', ({ subject, x, y }) => {
          subject.hasMoved = true
          const scaledX = xScale.invert(x)
          const scaledY = yScale.invert(y)
          if (tool === 'PENCIL' || tool === 'BRUSH') {
            subject.path.push({ x: scaledX, y: scaledY })
          }
          if (
            tool === 'SIMPLE_LINE' ||
            tool === 'ARROW' ||
            tool === 'MULTI_ARROW' ||
            tool === 'TENSION_ARROW'
          ) {
            subject.path = [subject.path[0], { x: scaledX, y: scaledY }]
          }
          if (tool === 'CIRCLE' || tool === 'MEDIA' || tool === 'MEDIA_ICONS') {
            subject.x2 = scaledX
            subject.y2 = scaledY
          }
        })
        .on('drag.render', render)
        .on('end', async ({ subject }) => {
          if (subject.hasMoved) {
            delete subject.hasMoved
            if (subject.type === 'MEDIA' || subject.type === 'MEDIA_ICONS') {
              select('.multimediaDrawing').remove()
              const { x1, x2, y1, y2 } = subject
              const fixedSubject = {
                ...subject,
                x1: Math.min(x1, x2),
                y1: yScale.invert(Math.min(yScale(y1), yScale(y2))),
                x2: Math.max(x1, x2),
                y2: yScale.invert(Math.max(yScale(y1), yScale(y2)))
              }
              dispatch(drawingToolsActions.insertUrlOpen(fixedSubject))
            } else {
              select('.circleDrawing').remove()
              select('.linesDrawing').remove()
              await updateDoc(chartRef, {
                draw: arrayUnion(subject)
              })
              updateTrackingData({
                draw: [...drawings, subject],
                action: `Created a new "${subject.type}" draw`
              })
            }
          }
        })
    )

    const handleCharacterDrawingOpacity = (drawingData) => {
      if (!drawingData.characterId) {
        return 1
      }
      const characterFound = characters.find(
        (item) => item.id === drawingData.characterId
      )

      if (!characterFound) {
        return 1
      }
      return characterFound.colorOpacity
    }

    async function eraseDrawings({ buttons }, d) {
      if (tool === 'ERASER' && buttons === 1) {
        select(this).remove()
        const results = drawings.filter(({ id }) => id !== this.id)
        await updateDoc(chartRef, {
          draw: results
        })
        updateTrackingData({
          draw: results,
          action: `Deleted ${d.type} draw`
        })
      }
    }

    const renderExternalContent = (drawData, youtubeUrl) => {
      const externalMediaContainer = select('#mainContainer')
        .selectAll(`.mediaContainer${drawData.id}`)
        .data([drawData])
        .join('div')
        .classed(`mediaContainer${drawData.id}`, true)
        .classed('externalContainer', true)
        .style('position', 'absolute')
        .style('z-index', 1)
        .style(
          'transform',
          (datum) => `translate(${xScale(datum.x1)}px,${yScale(datum.y1)}px)`
        )
        .style(
          'width',
          (datum) => `${Math.abs(xScale(datum.x1) - xScale(datum.x2))}px`
        )
        .style(
          'height',
          (datum) => `${Math.abs(yScale(datum.y1) - yScale(datum.y2))}px`
        )

      if (drawData.typeFile === 'youtube/media') {
        externalMediaContainer
          .selectAll('iframe')
          .data((datum) => [datum])
          .join('iframe')
          .attr('src', youtubeUrl)
          .attr('type', 'text/html')
          .style('width', '100%')
          .style('height', '100%')
      }

      if (
        drawData.typeFile === 'audio/mpeg' ||
        drawData.typeFile === 'audio/ogg' ||
        drawData.typeFile === 'audio/x-wav' ||
        drawData.typeFile === 'audio/webm'
      ) {
        externalMediaContainer
          .selectAll('audio')
          .data((datum) => [datum])
          .join('audio')
          .attr('src', (datum) => datum.url)
          .attr('controls', true)
          .style('width', '100%')
          .style('height', '100%')
      }
      if (
        drawData.typeFile === 'video/mp4' ||
        drawData.typeFile === 'audio/webm' ||
        drawData.typeFile === 'video/mpeg' ||
        drawData.typeFile === 'video/x-msvideo' ||
        drawData.typeFile === 'video/3gpp' ||
        drawData.typeFile === 'video/webm'
      ) {
        const videoContainer = externalMediaContainer
          .selectAll('video')
          .data((datum) => [datum])
          .join('video')
          .attr('controls', true)
          .style('width', '100%')
          .style('height', '100%')
          .style('object-fit', 'cover')

        videoContainer
          .selectAll('source')
          .data((datum) => [datum])
          .join('source')
          .attr('src', (d) => d.url)
      }
    }

    const renderSelectedItems = () => {
      charactersGroup.selectAll('circle').each(function () {
        const isSelected = select(this).classed('selected')
        if (isSelected) {
          select(this).attr('stroke', 'blue').attr('stroke-width', 3)
        } else {
          select(this).attr('stroke', 'inherit').attr('stroke-width', 'inherit')
        }
      })

      selectAll('.allDrawings').each(function (datum) {
        const isSelected = select(this).classed('selected')

        if (datum.type === 'BRUSH' || datum.type === 'PENCIL') {
          if (isSelected) {
            select(this).attr('stroke', 'blue')
            svg
              .selectAll(`.drawingHandles${datum.id}`)
              .data([datum.path[0], datum.path.at(-1)])
              .join('circle')
              .attr('class', `drawingHandles${datum.id} drawResizeHandlers`)
              .attr('stroke', 'blue')
              .attr('cursor', readOnly ? 'not-allowed' : 'grab')
              .attr('fill', 'blue')
              .attr('r', 7)
              .attr('cx', (d) => xScale(d.x))
              .attr('cy', (d) => yScale(d.y))
          } else {
            svg.selectAll(`.drawingHandles${datum.id}`).remove()
            select(this).attr('stroke', (d) => d.color)
          }
        }
        if (
          datum.type === 'SIMPLE_LINE' ||
          datum.type === 'ARROW' ||
          datum.type === 'MULTI_ARROW' ||
          datum.type === 'TENSION_ARROW'
        ) {
          if (isSelected) {
            select(this).attr('stroke', 'blue')
            svg
              .selectAll(`.drawingHandles${datum.id}`)
              .data(datum.path)
              .join('circle')
              .attr('class', `drawingHandles${datum.id} drawResizeHandlers`)
              .attr('stroke', 'blue')
              .attr('cursor', readOnly ? 'not-allowed' : 'grab')
              .attr('fill', 'blue')
              .attr('r', 7)
              .attr('cx', (d) => xScale(d.x))
              .attr('cy', (d) => yScale(d.y))
              .call(
                drag()
                  .filter(filterDrags)
                  .on('drag', function ({ dx, dy }, d) {
                    const unscaledY = yScale(d.y)
                    const newYValue = unscaledY + dy

                    const unscaledX = xScale(d.x)
                    const newXValue = unscaledX + dx

                    d.x = xScale.invert(newXValue)
                    d.y = yScale.invert(newYValue)

                    select(this)
                      .attr('cx', (item) => xScale(item.x))
                      .attr('cy', (item) => yScale(item.y))

                    svg
                      .selectAll(`.drawing${datum.id}`)
                      .data([datum])
                      .attr('d', generateArrowPath)
                  })
                  .on('end', async () => {
                    await updateDoc(chartRef, {
                      draw: drawings
                    })
                  })
              )
          } else {
            svg.selectAll(`.drawingHandles${datum.id}`).remove()
            select(this).attr('stroke', (d) => d.color)
          }
        }
        if (datum.type === 'CIRCLE') {
          if (isSelected) {
            svg
              .selectAll(`.drawingHandles${datum.id}`)
              .data([datum], (d) => d.id)
              .join('circle')
              .attr('class', `drawingHandles${datum.id} drawResizeHandlers`)
              .attr('r', 7)
              .attr('fill', 'blue')
              .attr('cx', (d) => xScale(d.x2))
              .attr('cy', (d) => yScale(d.y2))
              .style('cursor', readOnly ? 'not-allowed' : 'se-resize')
              .call(
                drag()
                  .filter(filterDrags)
                  .on('drag', (event, drawData) => {
                    let reversedFinishXScale = xScale(drawData.x2)
                    let reversedFinishYScale = yScale(drawData.y2)

                    reversedFinishXScale += event.dx
                    reversedFinishYScale += event.dy

                    drawData.x2 = xScale.invert(reversedFinishXScale)
                    drawData.y2 = yScale.invert(reversedFinishYScale)

                    select(`.drawing${drawData.id}`)
                      .data([drawData])
                      .attr('rx', (d) => Math.abs(xScale(d.x1) - xScale(d.x2)))
                      .attr('ry', (d) => Math.abs(yScale(d.y1) - yScale(d.y2)))

                    selectAll(`.drawingHandles${drawData.id}`)
                      .attr('cx', (d) => xScale(d.x2))
                      .attr('cy', (d) => yScale(d.y2))
                  })
                  .on('end', async () => {
                    await updateDoc(chartRef, {
                      draw: drawings
                    })
                  })
              )
          } else {
            svg.selectAll(`.drawingHandles${datum.id}`).remove()
          }
        }
        if (datum.type === 'MEDIA') {
          if (isSelected) {
            select(`.drawingGroup${datum.id}`)
              .selectAll('.resizeGroup')
              .data([datum], (d) => d.id)
              .join('g')
              .attr('class', 'resizeGroup drawResizeHandlers')
              .each(function (drawData) {
                select(this)
                  .selectAll('.topleft')
                  .data([drawData], (d) => d.id)
                  .join('circle')
                  .attr('fill', 'blue')
                  .classed('topleft', true)
                  .attr('r', 7)
                  .attr('cx', (d) => xScale(d.x1))
                  .attr('cy', (d) => yScale(d.y1))
                  .style('cursor', readOnly ? 'not-allowed' : 'nw-resize')
                  .call(
                    drag()
                      .filter(filterDrags)
                      .on('drag', function (event, dragPayload) {
                        let reversedStartXScale = xScale(dragPayload.x1)
                        let reversedStartYScale = yScale(dragPayload.y1)

                        reversedStartXScale += event.dx
                        reversedStartYScale += event.dy

                        dragPayload.x1 = xScale.invert(reversedStartXScale)
                        dragPayload.y1 = yScale.invert(reversedStartYScale)

                        select(this)
                          .attr('cx', (d) => xScale(d.x1))
                          .attr('cy', (d) => yScale(d.y1))

                        selectAll(`.drawingGroup${dragPayload.id}`)
                          .selectAll('.playIcon')
                          .attr('x', (d) => xScale((d.x1 + d.x2) / 2) - 25)
                          .attr('y', (d) => yScale((d.y1 + d.y2) / 2) - 20)

                        select(`.image${dragPayload.id}`)
                          .attr('x', (d) => xScale(d.x1))
                          .attr('y', (d) => yScale(d.y1))
                          .attr('width', (d) =>
                            Math.abs(xScale(d.x2) - xScale(d.x1))
                          )
                          .attr('height', (d) =>
                            Math.abs(yScale(d.y2) - yScale(d.y1))
                          )

                        select(`.mediaContainer${dragPayload.id}`)
                          .style(
                            'transform',
                            (d) =>
                              `translate(${xScale(d.x1)}px,${yScale(d.y1)}px)`
                          )
                          .style(
                            'width',
                            (d) => `${Math.abs(xScale(d.x1) - xScale(d.x2))}px`
                          )
                          .style(
                            'height',
                            (d) => `${Math.abs(yScale(d.y1) - yScale(d.y2))}px`
                          )
                      })
                      .on('end', async () => {
                        await updateDoc(chartRef, {
                          draw: drawings
                        })
                      })
                  )

                select(this)
                  .selectAll('.bottomright')
                  .data([drawData], (d) => d.id)
                  .join('circle')
                  .classed('bottomright', true)
                  .attr('r', 7)
                  .attr('fill', 'blue')
                  .attr('cx', (d) => xScale(d.x2))
                  .attr('cy', (d) => yScale(d.y2))
                  .style('cursor', readOnly ? 'not-allowed' : 'se-resize')
                  .call(
                    drag()
                      .filter(filterDrags)
                      .on('drag', function (event, dragPayload) {
                        let reversedFinishXScale = xScale(dragPayload.x2)
                        let reversedFinishYScale = yScale(dragPayload.y2)

                        reversedFinishXScale += event.dx
                        reversedFinishYScale += event.dy

                        dragPayload.x2 = xScale.invert(reversedFinishXScale)
                        dragPayload.y2 = yScale.invert(reversedFinishYScale)

                        selectAll(`.drawingGroup${dragPayload.id}`)
                          .selectAll('.playIcon')
                          .attr('x', (d) => xScale((d.x1 + d.x2) / 2) - 25)
                          .attr('y', (d) => yScale((d.y1 + d.y2) / 2) - 20)

                        select(`.image${dragPayload.id}`)
                          .attr('width', (d) =>
                            Math.abs(xScale(d.x2) - xScale(d.x1))
                          )
                          .attr('height', (d) =>
                            Math.abs(yScale(d.y2) - yScale(d.y1))
                          )

                        select(this)
                          .attr('cx', (d) => xScale(d.x2))
                          .attr('cy', (d) => yScale(d.y2))

                        select(`.mediaContainer${dragPayload.id}`)
                          .style(
                            'transform',
                            (d) =>
                              `translate(${xScale(d.x1)}px,${yScale(d.y1)}px)`
                          )
                          .style(
                            'width',
                            (d) => `${Math.abs(xScale(d.x1) - xScale(d.x2))}px`
                          )
                          .style(
                            'height',
                            (d) => `${Math.abs(yScale(d.y1) - yScale(d.y2))}px`
                          )
                      })
                      .on('end', async () => {
                        await updateDoc(chartRef, {
                          draw: drawings
                        })
                      })
                  )
              })
          } else {
            svg.selectAll(`.drawingGroup${datum.id} .resizeGroup`).remove()
          }
        }
      })
    }

    const renderDrawings = () => {
      const drawingsGroup = svg
        .selectAll('.allDrawingGroup')
        .data(drawings, (d) => d.id)
        .join('g')
        .attr('class', 'allDrawingGroup')

      drawingsGroup.each(function (draw) {
        const that = this
        if (draw.type === 'MEDIA') {
          const group = select(this)
            .selectAll(`.drawingGroup${draw.id}`)
            .data([draw], (d) => d.id)
            .join('g')
            .attr('class', `drawingGroup${draw.id} allDrawings`)
            .attr('x', (d) => xScale(d.x1))
            .attr('y', (d) => yScale(d.y1))
            .attr('opacity', handleCharacterDrawingOpacity)

          const dragFunction = drag()
            .filter(filterDrags)
            .subject(() => ({ hasMoved: false }))
            .on('drag', ({ dx, dy, subject }, d) => {
              subject.hasMoved = true
              let reversedStartXScale = xScale(d.x1)
              let reversedStartYScale = yScale(d.y1)
              let reversedFinishXScale = xScale(d.x2)
              let reversedFinishYScale = yScale(d.y2)

              reversedStartXScale += dx
              reversedStartYScale += dy

              reversedFinishXScale += dx
              reversedFinishYScale += dy

              d.x1 = xScale.invert(reversedStartXScale)
              d.y1 = yScale.invert(reversedStartYScale)

              d.x2 = xScale.invert(reversedFinishXScale)
              d.y2 = yScale.invert(reversedFinishYScale)

              group
                .attr('x', (datum) => xScale(datum.x1))
                .attr('y', (datum) => yScale(datum.y1))
                .attr('opacity', 0.2)
              group
                .selectAll(`.image${draw.id}`)
                .attr('x', (datum) => xScale(datum.x1))
                .attr('y', (datum) => yScale(datum.y1))

              group
                .selectAll(`.topleft`)
                .attr('cx', (datum) => xScale(datum.x1))
                .attr('cy', (datum) => yScale(datum.y1))
              group
                .selectAll(`.bottomright`)
                .attr('cx', (datum) => xScale(datum.x2))
                .attr('cy', (datum) => yScale(datum.y2))

              group
                .selectAll(`.playIcon`)
                .attr('x', (datum) => xScale((datum.x1 + datum.x2) / 2) - 25)
                .attr('y', (datum) => yScale((datum.y1 + datum.y2) / 2) - 20)
              moveSelectedElements({ dy, dx }, d)
            })
            .on('end', async ({ subject }, d) => {
              if (subject.hasMoved) {
                group.attr('opacity', handleCharacterDrawingOpacity)
                await updateDoc(chartRef, {
                  draw: drawings,
                  characters
                })
                updateTrackingData({
                  draw: drawings,
                  characters,
                  action: `Moved ${d.type} draw`
                })
              }
            })

          if (
            draw.typeFile === 'image/png' ||
            draw.typeFile === 'image/jpeg' ||
            draw.typeFile === 'image/svg+xml' ||
            draw.typeFile === 'image/x-icon' ||
            draw.typeFile === 'image/jpg' ||
            draw.typeFile === 'image/webp'
          ) {
            group
              .selectAll(`.image${draw.id}`)
              .data([draw], (d) => d.id)
              .join('image')
              .attr('class', `image${draw.id} allDrawings`)
              .attr('id', (d) => d.id)
              .attr('x', (d) => xScale(d.x1))
              .attr('y', (d) => yScale(d.y1))
              .attr('width', (d) => Math.abs(xScale(d.x1) - xScale(d.x2)))
              .attr('height', (d) => Math.abs(yScale(d.y1) - yScale(d.y2)))
              .attr('href', (d) => d.url)
              .style('cursor', readOnly ? 'not-allowed' : 'move')
              .call(dragFunction)
              .on('click', function (e) {
                e.stopPropagation()
                selectAll('.selected').classed('selected', false)
                select(this).classed('selected', true)
                select(that).raise()
                renderSelectedItems()
              })
              .on('mouseenter', eraseDrawings)
          }
          if (draw.typeFile === 'youtube/media') {
            const { id, params = {} } = urlParser.parse(draw.url)
            const embeddedUrl = `https://www.youtube.com/embed/${id}${
              params.start ? `?start=${params.start}` : ''
            }`

            group
              .selectAll(`.image${draw.id}`)
              .data([draw], (d) => d.id)
              .join('image')
              .attr('class', `image${draw.id} allDrawings`)
              .attr('id', (d) => d.id)
              .attr('x', (d) => xScale(d.x1))
              .attr('y', (d) => yScale(d.y1))
              .attr('width', (d) => Math.abs(xScale(d.x1) - xScale(d.x2)))
              .attr('height', (d) => Math.abs(yScale(d.y1) - yScale(d.y2)))
              .attr('href', `https://img.youtube.com/vi/${id}/sddefault.jpg`)
              .attr('preserveAspectRatio', 'none')
              .style('cursor', 'pointer')
              .call(dragFunction)
              .on('click', function (e) {
                e.stopPropagation()
                selectAll('.selected').classed('selected', false)
                select(this).classed('selected', true)
                select(that).raise()
                renderSelectedItems()
              })
              .on('mouseenter', eraseDrawings)

            group
              .selectAll(`.playIcon`)
              .data([draw], (d) => d.id)
              .join('image')
              .attr('class', `playIcon`)
              .attr('x', (d) => xScale((d.x1 + d.x2) / 2) - 25)
              .attr('y', (d) => yScale((d.y1 + d.y2) / 2) - 20)
              .style('cursor', 'pointer')
              .attr('width', 50)
              .attr('height', 40)
              .attr('preserveAspectRatio', 'none')
              .attr(
                'href',
                'https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_play_button_icon_%282013%E2%80%932017%29.svg'
              )
              .on('click', function (e, d) {
                e.stopPropagation()
                selectAll('.selected').classed('selected', false)
                select(this).classed('selected', true)

                renderExternalContent(d, embeddedUrl)
              })
          }
          if (
            draw.typeFile === 'audio/mpeg' ||
            draw.typeFile === 'audio/ogg' ||
            draw.typeFile === 'audio/x-wav' ||
            draw.typeFile === 'audio/webm'
          ) {
            group
              .selectAll(`.image${draw.id}`)
              .data([draw], (d) => d.id)
              .join('image')
              .attr('class', `image${draw.id} allDrawings`)
              .attr('id', (d) => d.id)
              .attr('x', (d) => xScale(d.x1))
              .attr('y', (d) => yScale(d.y1))
              .attr('width', (d) => Math.abs(xScale(d.x1) - xScale(d.x2)))
              .attr('height', (d) => Math.abs(yScale(d.y1) - yScale(d.y2)))
              .attr(
                'href',
                'https://www.pngfind.com/pngs/m/174-1747603_png-file-svg-sound-symbol-transparent-png.png'
              )
              .attr('preserveAspectRatio', 'none')
              .style('cursor', 'pointer')
              .call(dragFunction)
              .on('click', function (e) {
                e.stopPropagation()
                selectAll('.selected').classed('selected', false)
                select(this).classed('selected', true)
                select(that).raise()
                renderSelectedItems()
              })
              .on('mouseenter', eraseDrawings)

            group
              .selectAll(`.playIcon`)
              .data([draw], (d) => d.id)
              .join('image')
              .attr('class', `playIcon`)
              .attr('x', (d) => xScale((d.x1 + d.x2) / 2) - 25)
              .attr('y', (d) => yScale((d.y1 + d.y2) / 2) - 20)
              .style('cursor', 'pointer')
              .attr('width', 50)
              .attr('height', 40)
              .attr('preserveAspectRatio', 'none')
              .attr(
                'href',
                'https://www.svgrepo.com/show/111229/play-button.svg'
              )
              .on('click', function (e, d) {
                e.stopPropagation()
                selectAll('.selected').classed('selected', false)
                select(this).classed('selected', true)
                renderExternalContent(d)
              })
          }
          if (
            draw.typeFile === 'video/mp4' ||
            draw.typeFile === 'audio/webm' ||
            draw.typeFile === 'video/mpeg' ||
            draw.typeFile === 'video/x-msvideo' ||
            draw.typeFile === 'video/3gpp' ||
            draw.typeFile === 'video/webm'
          ) {
            group
              .selectAll(`.image${draw.id}`)
              .data([draw], (d) => d.id)
              .join('image')
              .attr('class', `image${draw.id} allDrawings`)
              .attr('id', (d) => d.id)
              .attr('x', (d) => xScale(d.x1))
              .attr('y', (d) => yScale(d.y1))
              .attr('width', (d) => Math.abs(xScale(d.x1) - xScale(d.x2)))
              .attr('height', (d) => Math.abs(yScale(d.y1) - yScale(d.y2)))
              .attr('href', 'https://i.postimg.cc/Wb0X0dLm/video.png')
              .attr('preserveAspectRatio', 'none')
              .style('cursor', 'pointer')
              .call(dragFunction)
              .on('click', function (e) {
                e.stopPropagation()
                selectAll('.selected').classed('selected', false)
                select(this).classed('selected', true)
                select(that).raise()
                renderSelectedItems()
              })
              .on('mouseenter', eraseDrawings)

            group
              .selectAll(`.playIcon`)
              .data([draw], (d) => d.id)
              .join('image')
              .attr('class', `playIcon`)
              .attr('x', (d) => xScale((d.x1 + d.x2) / 2) - 25)
              .attr('y', (d) => yScale((d.y1 + d.y2) / 2) - 20)
              .style('cursor', 'pointer')
              .attr('width', 50)
              .attr('height', 40)
              .attr('preserveAspectRatio', 'none')
              .attr(
                'href',
                'https://www.svgrepo.com/show/111229/play-button.svg'
              )
              .on('click', function (e, d) {
                e.stopPropagation()
                selectAll('.selected').classed('selected', false)
                select(this).classed('selected', true)
                renderExternalContent(d)
              })
          }
        }
        if (draw.type === 'BRUSH' || draw.type === 'PENCIL') {
          select(that)
            .selectAll(`.drawing${draw.id}`)
            .data([draw], (d) => d.id)
            .join('path')
            .attr('class', `drawing${draw.id} allDrawings`)
            .attr('id', (d) => d.id)
            .attr('stroke', (d) => d.color)
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('opacity', handleCharacterDrawingOpacity)
            .attr('cursor', readOnly ? 'not-allowed' : 'grab')
            .attr('fill', 'none')
            .attr('stroke-width', (d) => (d.type === 'PENCIL' ? 1 : 3))
            .attr('d', generateArrowPath)
            .call(
              drag()
                .filter(filterDrags)
                .subject(() => ({ hasMoved: false }))
                .on('drag', function ({ dx, dy, subject }, d) {
                  subject.hasMoved = true
                  for (let index = 0; index < d.path.length; index += 1) {
                    const path = d.path[index]
                    const unformatedYValue = yScale(path.y)
                    const newFromYValue = unformatedYValue + dy

                    const unformatedXValue = xScale(path.x)
                    const newFromXValue = unformatedXValue + dx

                    path.x = xScale.invert(newFromXValue)
                    path.y = yScale.invert(newFromYValue)
                  }
                  select(this).attr('d', generateArrowPath)

                  selectAll(`.drawingHandles${d.id}`)
                    .data([d.path[0], d.path.at(-1)])
                    .attr('cx', (datum) => xScale(datum.x))
                    .attr('cy', (datum) => yScale(datum.y))
                  moveSelectedElements({ dy, dx }, d)
                })
                .on('end', async ({ subject }, d) => {
                  if (subject.hasMoved) {
                    await updateDoc(chartRef, {
                      draw: drawings,
                      characters
                    })
                    updateTrackingData({
                      draw: drawings,
                      characters,
                      action: `Moved ${d.type} draw`
                    })
                  }
                })
            )
            .on('mouseenter', eraseDrawings)
            .on('click', function (e) {
              e.stopPropagation()
              selectAll('.selected').classed('selected', false)
              select(this).classed('selected', true)
              select(that).raise()
              renderSelectedItems()
            })
        }
        if (
          draw.type === 'SIMPLE_LINE' ||
          draw.type === 'ARROW' ||
          draw.type === 'MULTI_ARROW' ||
          draw.type === 'TENSION_ARROW'
        ) {
          select(that)
            .selectAll(`.drawing${draw.id}`)
            .data([draw], (d) => d.id)
            .join('path')
            .attr('class', `drawing${draw.id} allDrawings`)
            .attr('stroke', (d) => d.color)
            .attr('id', (d) => d.id)
            .attr('stroke-linejoin', 'round')
            .attr('stroke-linecap', 'round')
            .attr('opacity', handleCharacterDrawingOpacity)
            .attr('cursor', readOnly ? 'not-allowed' : 'grab')
            .attr('fill', 'none')
            .attr('stroke-width', 3)
            .attr('d', generateArrowPath)
            .call(
              drag()
                .filter(filterDrags)
                .subject(() => ({ hasMoved: false }))
                .on('drag', function ({ dx, dy, subject }, d) {
                  subject.hasMoved = true
                  const unformatedYValueFrom = yScale(d.path[0].y)
                  const unformatedYValueTo = yScale(d.path[1].y)

                  const newFromYValue = unformatedYValueFrom + dy
                  const newToYValue = unformatedYValueTo + dy

                  const unformatedXValueFrom = xScale(d.path[0].x)
                  const unformatedXValueTo = xScale(d.path[1].x)

                  const newFromXValue = unformatedXValueFrom + dx
                  const newToXValue = unformatedXValueTo + dx

                  d.path[0].x = xScale.invert(newFromXValue)
                  d.path[0].y = yScale.invert(newFromYValue)

                  d.path[1].x = xScale.invert(newToXValue)
                  d.path[1].y = yScale.invert(newToYValue)

                  select(this).attr('d', generateArrowPath)

                  svg
                    .selectAll(`.drawingHandles${d.id}`)
                    .attr('cx', (datum) => xScale(datum.x))
                    .attr('cy', (datum) => yScale(datum.y))
                  moveSelectedElements({ dy, dx }, d)
                })
                .on('end', async ({ subject }, d) => {
                  if (subject.hasMoved) {
                    await updateDoc(chartRef, {
                      draw: drawings,
                      characters
                    })
                    updateTrackingData({
                      draw: drawings,
                      characters,
                      action: `Moved ${d.type} draw`
                    })
                  }
                })
            )
            .on('click', function (e) {
              e.stopPropagation()
              selectAll('.selected').classed('selected', false)
              select(this).classed('selected', true)
              select(that).raise()
              renderSelectedItems()
            })
            .on('mouseenter', eraseDrawings)
        }
        if (draw.type === 'CIRCLE') {
          select(that)
            .selectAll(`.drawing${draw.id}`)
            .data([draw], (d) => d.id)
            .join('ellipse')
            .attr('class', `drawing${draw.id} allDrawings`)
            .attr('id', (d) => d.id)
            .attr('stroke', (d) => d.color)
            .attr('fill', (d) => d.color)
            .attr('cx', (d) => xScale(d.x1))
            .attr('cy', (d) => yScale(d.y1))
            .attr('rx', (d) => Math.abs(xScale(d.x1) - xScale(d.x2)))
            .attr('ry', (d) => Math.abs(yScale(d.y1) - yScale(d.y2)))
            .attr('opacity', handleCharacterDrawingOpacity)
            .attr('cursor', readOnly ? 'not-allowed' : 'grab')
            .call(
              drag()
                .filter(filterDrags)
                .subject(() => ({ hasMoved: false }))
                .on('drag', function (event, d) {
                  event.subject.hasMoved = true
                  let reversedStartXScale = xScale(d.x1)
                  let reversedStartYScale = yScale(d.y1)
                  let reversedFinishXScale = xScale(d.x2)
                  let reversedFinishYScale = yScale(d.y2)

                  reversedStartXScale += event.dx
                  reversedStartYScale += event.dy

                  reversedFinishXScale += event.dx
                  reversedFinishYScale += event.dy

                  d.x1 = xScale.invert(reversedStartXScale)
                  d.y1 = yScale.invert(reversedStartYScale)

                  d.x2 = xScale.invert(reversedFinishXScale)
                  d.y2 = yScale.invert(reversedFinishYScale)

                  select(this)
                    .attr('cx', (datum) => xScale(datum.x1))
                    .attr('cy', (datum) => yScale(datum.y1))

                  selectAll(`.drawingHandles${d.id}`)
                    .attr('cx', (datum) => xScale(datum.x2))
                    .attr('cy', (datum) => yScale(datum.y2))
                  moveSelectedElements(event, d)
                })
                .on('end', async ({ subject }, d) => {
                  if (subject.hasMoved) {
                    await updateDoc(chartRef, {
                      draw: drawings,
                      characters
                    })
                    updateTrackingData({
                      draw: drawings,
                      characters,
                      action: `Moved ${d.type} draw`
                    })
                  }
                })
            )
            .on('mouseenter', eraseDrawings)
            .on('click', function (e) {
              e.stopPropagation()
              selectAll('.selected').classed('selected', false)
              select(this).classed('selected', true)
              select(that).raise()
              renderSelectedItems()
            })
        }
      })
    }

    renderDrawings()

    const contains = (selection, elementData) =>
      selection[0][0] <= xScale(elementData.x) &&
      selection[1][0] >= xScale(elementData.x) &&
      selection[0][1] <= yScale(elementData.y) &&
      selection[1][1] >= yScale(elementData.y)

    function brushed(selection) {
      if (selection) {
        charactersGroup
          .selectAll('circle')
          .classed('selected', (d) => contains(selection, d.pos))

        selectAll('.allDrawings').each(function (drawTempData) {
          if (
            drawTempData.type === 'BRUSH' ||
            drawTempData.type === 'PENCIL' ||
            drawTempData.type === 'SIMPLE_LINE' ||
            drawTempData.type === 'ARROW' ||
            drawTempData.type === 'MULTI_ARROW' ||
            drawTempData.type === 'TENSION_ARROW'
          ) {
            select(this).classed(
              'selected',
              contains(selection, drawTempData.path[0]) &&
                contains(
                  selection,
                  drawTempData.path[drawTempData.path.length - 1]
                )
            )
          }

          if (drawTempData.type === 'CIRCLE' || drawTempData.type === 'MEDIA') {
            select(this).classed(
              'selected',
              contains(selection, { x: drawTempData.x1, y: drawTempData.y1 })
            )
          }
        })
      }
    }

    const brushFunction = brush()
      .filter(
        (event) =>
          !event.ctrlKey && !event.button && !readOnly && tool === 'SELECT'
      )
      .on('start brush', ({ selection }) => {
        brushed(selection)
        renderSelectedItems()
      })
      .on('end', ({ sourceEvent }) => {
        if (!sourceEvent) return
        brushFunction.clear(svg.select('.brushOverlay'))
      })

    svg.select('.brushOverlay').call(brushFunction)

    svg
      .select('.overlay')
      .style('cursor', tool !== 'SELECT' ? 'crosshair' : 'auto')

    svg
      .on('keydown', async (e) => {
        if (readOnly) {
          dispatch(appActions.displayNotifications(notificationReadOnlyMessage))
        } else if (e.code === 'Delete') {
          let numbersElements = 0
          charactersGroup.selectAll('circle').each(function (pointsData) {
            const isSelected = select(this).classed('selected')
            if (isSelected) {
              pointsData.status = 'DELETED'
              numbersElements += 1
            }
          })

          svg.selectAll('.allDrawings').each(function (drawTempData) {
            const isSelected = select(this).classed('selected')

            if (isSelected) {
              drawTempData.status = 'DELETED'
              numbersElements += 1
            }

            if (drawTempData.type === 'MEDIA') {
              select(`.mediaContainer${drawTempData.id}`).remove()
            }
          })

          const filterDeletedPoints = characters.map((item) => ({
            ...item,
            points: item.points.filter(
              (pointItem) => pointItem.status !== 'DELETED'
            )
          }))

          console.log('filterDeletedPoints: ', filterDeletedPoints)
          const filterDeletedDrawings = drawings.filter((item) => !item.status)

          try {
            await updateDoc(chartRef, {
              draw: filterDeletedDrawings,
              characters: filterDeletedPoints
            })
            updateTrackingData({
              draw: filterDeletedDrawings,
              characters: filterDeletedPoints,
              action: `Deleted ${numbersElements} elements`
            })
          } catch (error) {
            console.error(error)
          }
        }
      })
      .on('focus', () => {})

    selectAll('.charactersGroup, .charactersLines, circle').raise()

    return () => {
      selectAll('.drawResizeHandlers').remove()
      charactersGroup
        .selectAll('circle')
        .attr('stroke', 'inherit')
        .attr('stroke-width', 'inherit')
    }
  }, [
    allCharactersData,
    graphWidth,
    readOnly,
    selectedCharacter,
    allDrawingsData,
    tool,
    color,
    size.height,
    anchorEl,
    title,
    personalNotes,
    signposts
  ])

  const handleDetailsClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      id="mainContainer"
      ref={mainContainer}
      style={{
        position: 'absolute',
        width: graphWidth,
        height: 'calc(100% - 45px)',
        bottom: 0
      }}
    >
      <div
        className="contentSVG"
        style={{ position: 'absolute', width: '100%', height: '100%' }}
      >
        <svg ref={refGraph} className={classes.svgWrapper}>
          <g className="y-axis" />
          <g className="y-axis-lines" style={{ color: 'lightgray' }} />
          <g className="brushOverlay" />
          {anchorEl && (
            <MarksDetails
              handleClick={handleDetailsClose}
              anchorEl={anchorEl}
              data={data}
            />
          )}
        </svg>
      </div>
      <div
        id="animationBackground"
        ref={rightLimit}
        style={{
          backgroundColor: '#fafafa',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          width: graphWidth,
          height: '90vh',
          zIndex: 2,
          display: isAnimating ? 'flex' : 'none',
          overflow: 'hidden',
          transform: 'scale(-1, 1)'
        }}
      >
        <div
          style={{
            minWidth: graphWidth,
            transform: 'scale(-1, 1)',
            height: '100%'
          }}
        >
          {signposts.map((item, index) => (
            <div
              key={item.id}
              style={{
                backgroundColor: index % 2 !== 0 ? '#efefef' : '#fafafa',
                height: '100%',
                display: 'inline-block',
                minWidth: item.width * zoomLevel,
                whiteSpace: 'nowrap'
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SvgGraphSeason
