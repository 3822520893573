import { useEffect, useState } from 'react'
import {
  Grid,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { useFirestore } from 'reactfire'
import { doc, updateDoc } from 'firebase/firestore'
import { useDispatch, useSelector } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'

import ParagraphForm from './ParagraphForm'
import ParagrahTitle from './ParagrahTitle'
import TemplatePDF from './TemplatePDF'
import { Dialog } from '../ui'
import charactersActions from '../../redux/actions/characters'
import guideActions from '../../redux/actions/guide'
import {
  threeQuestions,
  bonusQuestions,
  userBaseSchema
} from '../../utils/defaultCoreQuestions'
import useAddTrackingData from '../../hooks/useAddTrackingData'

const CoreQuestionsDialog = () => {
  const { coreQuestions, title } = useSelector((state) => state.guide)
  const [formValues, setFormValues] = useState({})
  const [isTouched, setIsTouched] = useState(false)
  const { chartType } = useSelector((state) => state.app)
  const { characters, selectedCharacter } = useSelector(
    (state) => state.characters
  )
  const [renderDownload, setRenderDownload] = useState(false)
  const dispatch = useDispatch()
  const { id: chartId, episodeId } = useParams()
  const firestore = useFirestore()
  const updateTrackingData = useAddTrackingData()

  useEffect(() => {
    if (selectedCharacter) {
      const { descriptionCQ } = selectedCharacter
      setFormValues(descriptionCQ || userBaseSchema)
    }
  }, [selectedCharacter])

  const handleChange = (event) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: event.target.value
    }))
    setIsTouched(true)
  }

  const handleCharactersChange = (e) => {
    if (Object.keys(selectedCharacter).length) {
      dispatch(charactersActions.changeDescriptionCq(formValues))
    }
    const id = e.target.value

    const character = characters.find((item) => item.id === id)
    dispatch(charactersActions.selectCharacter(character))
  }

  const handleClose = async () => {
    const characterIndex = characters.findIndex(
      (item) => selectedCharacter.id === item.id
    )

    const [item] = characters.splice(characterIndex, 1)

    if (item) {
      item.descriptionCQ = formValues
      characters.splice(characterIndex, 0, item)
    }

    let chartRef
    if (chartType === 'SERIES') {
      chartRef = doc(firestore, `series/${chartId}`)
    } else if (chartType === 'EPISODE') {
      chartRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
    } else {
      chartRef = doc(firestore, `charts/${chartId}`)
    }

    await updateDoc(chartRef, {
      characters
    })
    if (isTouched) {
      updateTrackingData({ characters, action: 'Updated core questions' })
    }
    setIsTouched(false)
    dispatch(guideActions.coreQuestionClose())
  }

  return (
    <Dialog
      open={coreQuestions.isModalOpen}
      onClose={handleClose}
      title="HartCheck for your script"
      maxWidth="sm"
      closable
      divider
      dragglable
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <ParagrahTitle video>
            Getting started writing, by J.V. Hart (writer of Hook, Contact and
            Dracula):
          </ParagrahTitle>
          <Typography variant="body2" align="justify" paragraph>
            "The following questions are the Three Kings of story telling in my
            opinion. I believe you must answer the first three questions before
            you can even think of writing a script. Francis Ford Coppola gave me
            this exercise a year after we had completed working together on
            Dracula. I have used it ever since. I use it every day in my
            writing. If you can answer these three questions then you are ready
            to proceed to the next step in preparing to write a script."
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Characters</InputLabel>
            <Select
              value={selectedCharacter.id || ''}
              onChange={handleCharactersChange}
              label="Characters"
            >
              {characters.length ? (
                characters.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name || 'Unnamed'}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  No characters...
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>
        {!!Object.keys(selectedCharacter).length && (
          <Grid item container justifyContent="flex-end" xs={6}>
            {renderDownload ? (
              <PDFDownloadLink
                document={
                  <TemplatePDF title={title} data={selectedCharacter} />
                }
                fileName={`${selectedCharacter.name || 'untitled'} - signpost`}
                style={{ textDecoration: 'none' }}
              >
                {({ loading }) => (
                  <Button
                    variant="outlined"
                    onClick={() => setRenderDownload(false)}
                  >
                    {loading ? 'Loading document...' : 'Download now!'}
                  </Button>
                )}
              </PDFDownloadLink>
            ) : (
              <Button
                variant="outlined"
                onClick={() => setRenderDownload(true)}
              >
                Generate document
              </Button>
            )}
          </Grid>
        )}
        {Object.keys(selectedCharacter).length ? (
          <>
            <Grid item xs={12}>
              <ParagrahTitle align="center">THE THREE QUESTIONS:</ParagrahTitle>
            </Grid>
            {threeQuestions.map((item) => (
              <ParagraphForm
                key={item.fieldName}
                title={item.title}
                fieldName={item.fieldName}
                fieldValue={formValues}
                videoUrl={item.videoUrl}
                handlechange={handleChange}
              />
            ))}
            <Grid item xs={12}>
              <ParagrahTitle align="center">BONUS QUESTIONS:</ParagrahTitle>
            </Grid>
            {bonusQuestions.map((item) => (
              <ParagraphForm
                key={item.fieldName}
                title={item.title}
                fieldName={item.fieldName}
                fieldValue={formValues}
                videoUrl={item.videoUrl}
                handlechange={handleChange}
              />
            ))}
            <Grid item xs={12} container justifyContent="center">
              {renderDownload ? (
                <PDFDownloadLink
                  document={
                    <TemplatePDF data={selectedCharacter} title={title} />
                  }
                  fileName={`${title || 'untitled'} - core questions`}
                  style={{ textDecoration: 'none' }}
                >
                  {({ loading }) => (
                    <Button
                      variant="outlined"
                      onClick={() => setRenderDownload(false)}
                    >
                      {loading ? 'Loading document...' : 'Download now!'}
                    </Button>
                  )}
                </PDFDownloadLink>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => setRenderDownload(true)}
                >
                  Generate document
                </Button>
              )}
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <Typography align="center" paragraph>
              Please create or choose a character to start writting your core
              questions descriptions.
            </Typography>
          </Grid>
        )}
      </Grid>
    </Dialog>
  )
}

export default CoreQuestionsDialog
