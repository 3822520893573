import { useEffect, useState } from 'react'
import {
  Button,
  TextField,
  CircularProgress,
  Grid,
  ImageList,
  ImageListItem,
  Typography
} from '@material-ui/core'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { doc, updateDoc, arrayUnion } from 'firebase/firestore'
import { useFirestore } from 'reactfire'
import { cloneDeep } from 'lodash'

import drawingToolsActions from '../../redux/actions/drawingTools'
import useAddTrackingData from '../../hooks/useAddTrackingData'

const FLATICON_URI = 'https://api.flaticon.com/v3/'

const IconsView = () => {
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [apiIsLoading, setApiIsLoading] = useState(false)
  const [hasSearch, setHasSearch] = useState(false)
  const [token, setToken] = useState('')
  const [iconData, setIconData] = useState([])
  const firestore = useFirestore()
  const { id: chartId, episodeId } = useParams()
  const dispatch = useDispatch()
  const { subject } = useSelector((state) => state.drawingTools)
  const { chartType } = useSelector((state) => state.app)
  const { draw } = useSelector((state) => state.guide)
  const updateTrackingData = useAddTrackingData()

  useEffect(() => {
    const getToken = async () => {
      try {
        setApiIsLoading(true)
        const response = await axios.post(`${FLATICON_URI}app/authentication`, {
          apikey: process.env.REACT_APP_FLATICON_KEY
        })
        setToken(response.data.data.token)
        setApiIsLoading(false)
      } catch (err) {
        setApiIsLoading(false)
        console.error(err)
      }
    }
    getToken()
  }, [])

  const handleChange = (event) => {
    setValue(event.target.value)
    setHasSearch(false)
  }

  const searchIcon = async () => {
    setIsLoading(true)
    const headers = {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    }
    const response = await axios.get(
      `${FLATICON_URI}search/icons/priority?q=${value}`,
      {
        headers
      }
    )
    setIconData(response.data.data)
    setIsLoading(false)
    setHasSearch(true)
  }

  const insertIconToGraph = async (urlValue) => {
    try {
      setIsLoading(true)
      let chartRef
      if (chartType === 'SERIES') {
        chartRef = doc(firestore, `series/${chartId}`)
      } else if (chartType === 'EPISODE') {
        chartRef = doc(firestore, `series/${chartId}/episodes/${episodeId}`)
      } else {
        chartRef = doc(firestore, `charts/${chartId}`)
      }

      const subjectClone = cloneDeep(subject)

      subjectClone.typeFile = 'image/png'
      subjectClone.url = urlValue
      subjectClone.type = 'MEDIA'

      await updateDoc(chartRef, {
        draw: arrayUnion(subjectClone)
      })
      updateTrackingData({
        draw: [...draw, subjectClone],
        action: `Inserted multimedia via icon library: ${value}`
      })
      setIsLoading(false)
      setIconData([])
      setValue('')
      setHasSearch(false)
      dispatch(drawingToolsActions.chooseTool('SELECT'))
      dispatch(drawingToolsActions.insertUrlClose())
    } catch (err) {
      setIsLoading(false)
      console.log(err)
    }
  }

  return (
    <Grid container spacing={2}>
      {apiIsLoading ? (
        <Grid
          item
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          xs={12}
          style={{ height: 200 }}
        >
          <CircularProgress />
          <Typography align="center">Loading FlatIcon service...</Typography>
        </Grid>
      ) : (
        <>
          <Grid item xs={12}>
            <TextField
              name="iconSearch"
              label="Search"
              fullWidth
              autoFocus
              onChange={handleChange}
              value={value}
              onKeyPress={(e) =>
                (e.code === 'Enter' || e.code === 'NumpadEnter') && searchIcon()
              }
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={searchIcon}
            >
              Search
            </Button>
          </Grid>

          {isLoading ? (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              xs={12}
            >
              <CircularProgress />
            </Grid>
          ) : (
            <>
              {iconData.length > 0 && (
                <Grid item xs={12}>
                  <Typography gutterBottom>
                    Select an icon to add it to the chart
                  </Typography>
                  <ImageList
                    rowHeight={160}
                    cols={3}
                    gap={12}
                    style={{ maxHeight: 500, marginTop: 12 }}
                  >
                    {iconData.map((item) => (
                      <ImageListItem key={item.id} cols={1}>
                        <img
                          src={item.images['256']}
                          alt={item.description}
                          style={{ cursor: 'pointer' }}
                          onClick={() => insertIconToGraph(item.images['256'])}
                        />
                      </ImageListItem>
                    ))}
                  </ImageList>
                </Grid>
              )}
              {hasSearch && iconData.length === 0 && (
                <Grid item xs={12}>
                  <Typography align="center">No results</Typography>
                </Grid>
              )}
            </>
          )}
        </>
      )}
    </Grid>
  )
}

export default IconsView
