import { authTypes } from '../types'

const defaultState = {
  isOwner: false,
  isGuest: false,
  readOnly: false,
  previewMode: false,
  adminAccess: false,
  user: null
}

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case authTypes.AUTH_LOGIN_SUCCESS: {
      return {
        ...state,
        user: payload
      }
    }
    case authTypes.SET_PREVIEW_MODE: {
      return {
        ...state,
        readOnly: true,
        previewMode: true,
        selectedHistoryChart: payload
      }
    }
    case authTypes.RESET_PREVIEW_MODE: {
      return {
        ...state,
        readOnly: false,
        previewMode: false,
        selectedHistoryChart: null
      }
    }
    case authTypes.UPDATE_USER_PROFILE: {
      return {
        ...state,
        user: { ...state.user, ...payload }
      }
    }
    case authTypes.SET_OWNERSHIP: {
      return {
        ...state,
        isOwner: true,
        readOnly: false,
        adminAccess: true
      }
    }
    case authTypes.SET_GUEST_USER: {
      return {
        ...state,
        isOwner: false,
        isGuest: true,
        readOnly: payload.isReadOnlyAccess,
        adminAccess: payload.isAdminAccess
      }
    }
    case authTypes.RESET_GRAPH_STATUS: {
      return {
        ...state,
        isOwner: false,
        isGuest: false,
        readOnly: false,
        adminAccess: false,
        previewMode: false,
        selectedHistoryChart: null
      }
    }
    default:
      return state
  }
}

export default reducer
