import { signpostsTypes } from '../types'

const zoomFactor = 0.1

const defaultState = {
  signposts: [],
  zoomLevel: 1
}

function reducer(state = defaultState, { type, payload }) {
  switch (type) {
    case signpostsTypes.CHANGE_ORDER: {
      return {
        ...state,
        signposts: payload
      }
    }
    case signpostsTypes.ADD_BEFORE: {
      const signpostCopy = Array.from(state.signposts)
      signpostCopy.splice(payload.refSignpostIndex, 0, payload.newSignpost)
      return {
        ...state,
        signposts: signpostCopy
      }
    }
    case signpostsTypes.ADD_AFTER: {
      const signpostCopy = Array.from(state.signposts)
      signpostCopy.splice(payload.refSignpostIndex + 1, 0, payload.newSignpost)
      return {
        ...state,
        signposts: signpostCopy
      }
    }
    case signpostsTypes.REMOVE_SIGNPOST: {
      const filteredSignposts = state.signposts.filter(
        (item) => item.key !== payload.key
      )
      return {
        ...state,
        signposts: filteredSignposts
      }
    }
    case signpostsTypes.MOVE_LEFT: {
      if (payload.startIndex === 0) {
        return {
          ...state
        }
      }
      const signpostCopy = Array.from(state.signposts)
      const [removed] = signpostCopy.splice(payload.startIndex, 1)
      signpostCopy.splice(payload.startIndex - 1, 0, removed)
      return {
        ...state,
        signposts: signpostCopy
      }
    }
    case signpostsTypes.MOVE_RIGHT: {
      if (payload.startIndex === state.signposts.length - 1) {
        return {
          ...state
        }
      }
      const signpostCopy = Array.from(state.signposts)
      const [removed] = signpostCopy.splice(payload.startIndex, 1)
      signpostCopy.splice(payload.startIndex + 1, 0, removed)
      return {
        ...state,
        signposts: signpostCopy
      }
    }
    case signpostsTypes.CHANGE_BACKGROUND_COLOR: {
      const signpostCopy = Array.from(state.signposts)
      const [signpost] = signpostCopy.splice(payload.startIndex, 1)
      signpost.bgColor = payload.bgColor
      signpostCopy.splice(payload.startIndex, 0, signpost)
      return {
        ...state,
        signposts: signpostCopy
      }
    }
    case signpostsTypes.CHANGE_FONT_COLOR: {
      const signpostCopy = Array.from(state.signposts)
      const [signpost] = signpostCopy.splice(payload.startIndex, 1)
      signpost.textColor = payload.textColor
      signpostCopy.splice(payload.startIndex, 0, signpost)
      return {
        ...state,
        signposts: signpostCopy
      }
    }
    case signpostsTypes.CHANGE_SIGNPOST_SIZE: {
      const signpostCopy = state.signposts.map((item) => {
        if (item.id === payload.id) {
          return {
            ...item,
            width: payload.width
          }
        }
        return item
      })
      return {
        ...state,
        signposts: signpostCopy
      }
    }
    case signpostsTypes.ZOOM_OUT: {
      if (state.zoomLevel <= 0.3) {
        return state
      }
      const newZoomLevel = state.zoomLevel - zoomFactor

      return {
        ...state,
        zoomLevel: parseFloat(newZoomLevel.toFixed(1))
      }
    }
    case signpostsTypes.ZOOM_IN: {
      if (state.zoomLevel >= 2) {
        return state
      }
      const newZoomLevel = state.zoomLevel + zoomFactor

      return {
        ...state,
        zoomLevel: parseFloat(newZoomLevel.toFixed(1))
      }
    }
    case signpostsTypes.SET_ZOOM_LEVEL: {
      return {
        ...state,
        zoomLevel: payload
      }
    }
    case signpostsTypes.CHANGE_SIGNPOST_TITLE: {
      const signpostCopy = Array.from(state.signposts)
      const [signpost] = signpostCopy.splice(payload.startIndex, 1)
      signpost.name = payload.title
      signpostCopy.splice(payload.startIndex, 0, signpost)
      return {
        ...state,
        signposts: signpostCopy
      }
    }
    default:
      return state
  }
}

export default reducer
