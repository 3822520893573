import { useState, useEffect, useCallback } from 'react'
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { History, CheckCircleOutline } from '@material-ui/icons/'
import { useSelector, useDispatch } from 'react-redux'
import { useFirestore } from 'reactfire'
import {
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  startAfter
} from 'firebase/firestore'
import { useParams } from 'react-router-dom'

import charactersActions from '../../redux/actions/characters'
import signpostsActions from '../../redux/actions/signposts'
import guideActions from '../../redux/actions/guide'
import animationToolsActions from '../../redux/actions/animationTools'
import authActions from '../../redux/actions/auth'
import collaborateActions from '../../redux/actions/collaborate'

const queryLimit = 25

export default function ChangesDrawer({ isDrawerOpen, toggleDrawer }) {
  const { id: chartId, episodeId } = useParams()
  const { chartType } = useSelector((state) => state.app)
  const { selectedHistoryChart } = useSelector((state) => state.auth)
  const { zoomLevel } = useSelector((state) => state.signposts)
  const [allChartChanges, setAllChartChanges] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [lastVisible, setLastVisible] = useState(null)
  const [isLastPage, setIsLastPage] = useState(false)
  const firestore = useFirestore()
  const dispatch = useDispatch()

  const getChartChangesRef = useCallback(() => {
    let collectionRef
    if (chartType === 'SERIES') {
      collectionRef = collection(firestore, 'series', chartId, 'changes')
    } else if (chartType === 'EPISODE') {
      collectionRef = collection(
        firestore,
        'series',
        chartId,
        'episodes',
        episodeId,
        'changes'
      )
    } else {
      collectionRef = collection(firestore, 'charts', chartId, 'changes')
    }
    return collectionRef
  }, [chartId, episodeId, chartType])

  useEffect(() => {
    const fetch = async () => {
      const first = query(
        getChartChangesRef(),
        orderBy('date', 'desc'),
        limit(queryLimit)
      )
      const documentSnapshots = await getDocs(first)
      let lastPage = true
      if (documentSnapshots.size) {
        const last = documentSnapshots.docs[documentSnapshots.docs.length - 1]
        setAllChartChanges(
          documentSnapshots.docs.map((item) => ({
            ...item.data(),
            id: item.id
          }))
        )
        setLastVisible(last)
        if (documentSnapshots.docs.length === queryLimit) {
          const verificationQuery = query(
            getChartChangesRef(),
            orderBy('date', 'desc'),
            startAfter(last),
            limit(1)
          )
          const verificationData = await getDocs(verificationQuery)
          lastPage = !verificationData.size
        }
      }
      setIsLastPage(lastPage)
      setIsLoading(false)
    }
    fetch()
  }, [isDrawerOpen, getChartChangesRef])

  const loadMore = async () => {
    setIsLoadingMore(true)
    const first = query(
      getChartChangesRef(),
      orderBy('date', 'desc'),
      startAfter(lastVisible),
      limit(queryLimit)
    )
    const documentSnapshots = await getDocs(first)
    setAllChartChanges([
      ...allChartChanges,
      ...documentSnapshots.docs.map((item) => ({
        ...item.data(),
        id: item.id
      }))
    ])
    let lastPage = true
    if (documentSnapshots.docs.length === queryLimit) {
      const last = documentSnapshots.docs[documentSnapshots.docs.length - 1]
      const verificationQuery = query(
        getChartChangesRef(),
        orderBy('date', 'desc'),
        startAfter(last),
        limit(1)
      )
      const verificationData = await getDocs(verificationQuery)
      lastPage = !verificationData.size
      setLastVisible(last)
    }
    setIsLastPage(lastPage)
    setIsLoadingMore(false)
  }

  const handleOpenPreview = (chartData) => {
    dispatch(authActions.setPreviewMode(chartData.id))
    dispatch(charactersActions.loadCharacters(chartData.characters))
    dispatch(signpostsActions.updateSignpostOrder(chartData.signposts))
    dispatch(guideActions.setGraphTitle(chartData.title))
    dispatch(guideActions.setGraphNotes(chartData.personalNotes))
    dispatch(guideActions.setMultimediaSource(chartData.draw))
    dispatch(collaborateActions.getCollaborators(chartData.collaborate))

    const totalWidth = chartData.signposts.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.width * zoomLevel,
      0
    )

    dispatch(animationToolsActions.animateGraph(totalWidth))
    toggleDrawer()
  }

  const checkCurrentVersion = (chartData, index) => {
    if (selectedHistoryChart === chartData.id) {
      return true
    }

    if (!selectedHistoryChart && index === 0) {
      return true
    }

    return false
  }

  return (
    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
      <Typography variant="h6" align="center" style={{ marginTop: 12 }}>
        History of changes
      </Typography>
      <Typography style={{ margin: 12 }}>
        Select a version of the chart to view it and later you can restore it.
      </Typography>
      <List
        style={{
          width: 500
        }}
      >
        {isLoading && <CircularProgress />}
        {!isLoading && allChartChanges.length ? (
          <>
            {allChartChanges.map((chart, index) => (
              <ListItem
                button
                disabled={checkCurrentVersion(chart, index)}
                selected={checkCurrentVersion(chart, index)}
                key={chart.id}
                onClick={() => handleOpenPreview(chart)}
              >
                <ListItemIcon>
                  <History />
                </ListItemIcon>
                <ListItemText
                  primary={chart.action}
                  secondary={chart.date.toDate().toLocaleString()}
                />
                {checkCurrentVersion(chart, index) && (
                  <ListItemSecondaryAction>
                    <Tooltip title="Selected chart">
                      <CheckCircleOutline color="primary" />
                    </Tooltip>
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </>
        ) : (
          <ListItem>
            <ListItemText primary="No data" />
          </ListItem>
        )}
      </List>
      {!isLastPage && (
        <Button
          variant="contained"
          color="primary"
          style={{ margin: '0 12px' }}
          onClick={loadMore}
        >
          {isLoadingMore ? 'Loading' : 'Load more'}
        </Button>
      )}
    </Drawer>
  )
}
