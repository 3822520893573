import React from 'react'
import { ErrorPage } from '.'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo
    })
  }

  render() {
    if (this.state.error) {
      return <ErrorPage />
    }
    return this.props.children
  }
}

export default ErrorBoundary
