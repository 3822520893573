import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { useSelector, useDispatch } from 'react-redux'

import appActions from '../../redux/actions/app'

const NotificationAlerts = () => {
  const { notificationData } = useSelector((state) => state.app)
  const dispatch = useDispatch()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(appActions.closeNotifications())
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={notificationData.open}
      autoHideDuration={2000}
      onClose={handleClose}
    >
      <Alert
        variant="filled"
        onClose={handleClose}
        severity={notificationData.variant}
      >
        {notificationData.message}
      </Alert>
    </Snackbar>
  )
}

export default NotificationAlerts
