import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import {
  doc,
  collection,
  query,
  where,
  getDoc,
  updateDoc,
  deleteDoc
} from 'firebase/firestore'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  IconButton,
  ListItemSecondaryAction
} from '@material-ui/core'
import {
  LocalMovies,
  OndemandVideo,
  MoreVert,
  ArrowForwardIos,
  Folder,
  Delete
} from '@material-ui/icons'

import fileActions from '../../redux/actions/file'
import authActions from '../../redux/actions/auth'
import charactersActions from '../../redux/actions/characters'
import undoActions from '../../redux/actions/undo'
import appActions from '../../redux/actions/app'

const ChartItem = ({ chartdocument }) => {
  const firestore = useFirestore()
  const [confirmDelete, setConfirmDelete] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { id: chartId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const projectRef = collection(firestore, 'projects')
  const userRef = doc(firestore, 'users', user.email)
  const projectsQuery = query(projectRef, where('userId', '==', userRef))
  const { data: projects } = useFirestoreCollectionData(projectsQuery)

  const [anchorEl, setAnchorEl] = useState(null)
  const [anchorEl2, setAnchorEl2] = useState(null)
  const openOption = Boolean(anchorEl)
  const openListFolder = Boolean(anchorEl2)

  const chartToProject = async (idProject) => {
    setAnchorEl(null)
    setAnchorEl2(null)
    try {
      const docuRef = doc(
        firestore,
        chartdocument.type === 'SERIES'
          ? `series/${chartdocument.id}`
          : `charts/${chartdocument.id}`
      )
      const queryChart = await getDoc(docuRef)
      if (queryChart.exists()) {
        const currentData = queryChart.data()
        currentData.projectId = doc(firestore, `projects/${idProject}`)
        await updateDoc(docuRef, { projectId: currentData.projectId })
      }
    } catch (err) {
      console.log(err, 'error al mover documento a proyecto')
    }
  }

  const deleteChart = async () => {
    setAnchorEl(null)
    setAnchorEl2(null)
    setConfirmDelete(false)
    try {
      await deleteDoc(
        doc(
          firestore,
          chartdocument.type === 'SERIES'
            ? `series/${chartdocument.id}`
            : `charts/${chartdocument.id}`
        )
      )
    } catch (err) {
      console.log(err, 'error al eliminar chart')
    }
  }

  const openChart = async () => {
    dispatch(undoActions.resetRegisters())
    dispatch(fileActions.foldersClose())
    dispatch(authActions.resetGraphStatus())
    dispatch(charactersActions.selectCharacter({}))
    await updateDoc(userRef, {
      lastChart: chartdocument.id
    })
    dispatch(
      authActions.updateUserData({
        lastChart: chartdocument.id
      })
    )
    if (chartdocument.type === 'SERIES') {
      history.push(`/series/${chartdocument.id}`)
      dispatch(appActions.setChartType('SERIES'))
    } else {
      history.push(`/app/${chartdocument.id}`)
      dispatch(appActions.setChartType('MOVIES'))
    }
  }

  return (
    <>
      <ListItem
        button
        disabled={chartId === chartdocument.id}
        selected={chartId === chartdocument.id}
        onClick={openChart}
      >
        <ListItemIcon>
          {chartdocument.type === 'SERIES' ? (
            <OndemandVideo />
          ) : (
            <LocalMovies />
          )}
        </ListItemIcon>
        <ListItemText
          primary={
            chartdocument.title !== '' ? chartdocument.title : 'No title'
          }
          secondary={
            chartId === chartdocument.id
              ? `Current chart`
              : `Last used: ${chartdocument.lastUsed}`
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            onClick={(e) => {
              setAnchorEl(e.currentTarget)
            }}
          >
            <MoreVert />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Popover
        open={openOption}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null)
          setConfirmDelete(false)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <List>
          <ListItem
            button
            onClick={(e) => {
              setAnchorEl2(e.currentTarget)
            }}
          >
            <ListItemText primary="Move To" />

            <ArrowForwardIos />
          </ListItem>
          {confirmDelete ? (
            <ListItem
              button
              disabled={chartId === chartdocument.id}
              onClick={deleteChart}
            >
              <ListItemIcon>
                <Delete style={{ color: 'red' }} />
              </ListItemIcon>
              <ListItemText style={{ color: 'red' }} primary="Confirm delete" />
            </ListItem>
          ) : (
            <ListItem
              button
              disabled={chartId === chartdocument.id}
              onClick={() => setConfirmDelete(true)}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText primary="Delete" />
            </ListItem>
          )}
        </List>
      </Popover>

      <Popover
        open={openListFolder}
        anchorEl={anchorEl2}
        onClose={() => {
          setAnchorEl2(null)
          setAnchorEl(null)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        <List>
          {projects.length > 0 ? (
            projects.map((item) => (
              <ListItem
                key={item.NO_ID_FIELD}
                button
                onClick={() => {
                  chartToProject(item.NO_ID_FIELD)
                }}
              >
                <ListItemIcon>
                  <Folder />
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No projects" />
            </ListItem>
          )}
        </List>
      </Popover>
    </>
  )
}

export default ChartItem
