const characterTypes = {
  ADD_CHARACTER: 'ADD_CHARACTER',
  LOAD_CHARACTERS: 'LOAD_CHARACTERS',
  SELECT_CHARACTER: 'SELECT_CHARACTER',
  CHANGE_CHARACTER_ORDER: 'CHANGE_ CHARACTER_ORDER',
  REMOVE_CHARACTER: 'REMOVE_CHARACTER',
  REMOVE_CHARACTER_GRAPH_POINT: 'REMOVE_CHARACTER_GRAPH_POINT',
  ADD_CHARACTER_GRAPH_POINT: 'ADD_CHARACTER_GRAPH_POINT',
  CHANGE_CHARACTER_COLOR: 'CHANGE_CHARACTER_COLOR',
  CHANGE_CHARACTER_COLOR_OPACITY: 'CHANGE_CHARACTER_COLOR_OPACITY',
  CHANGE_CHARACTER_NAME: 'CHANGE_CHARACTER_NAME',
  CHANGE_GRAPH_POINT_HEADER: 'CHANGE_GRAPH_POINT_HEADER',
  SET_GRAPH_POINT_ACTIVE: 'SET_GRAPH_POINT_ACTIVE',
  SET_GRAPH_POINT_MOVE: 'SET_GRAPH_POINT_MOVE',
  UNSET_GRAPH_POINT_ACTIVE: 'UNSET_GRAPH_POINT_ACTIVE',
  CHANGE_GRAPH_POINT_DETAILS: 'CHANGE_GRAPH_POINT_DETAILS',
  ADD_MULTIMEDIA_URL: 'ADD_MULTIMEDIA_URL_YOUTUBE',
  REMOVE_MULTIMEDIA_URL: 'REMOVE_MULTIMEDIA_URL_YOUTUBE',
  CHANGE_SIZE_MULTIMEDIA: 'CHANGE_SIZE_MULTIMEDIA',
  CHANGE_POSITION_MULTIMEDIA: 'CHANGE_POSITION_MULTIMEDIA',
  CHANGE_DESCRIPTION_SP: 'CHANGE_DESCRIPTION_SP',
  CHANGE_DESCRIPTION_CQ: 'CHANGE_DESCRIPTION_CQ'
}

export default characterTypes
