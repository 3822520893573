import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  InputBase,
  IconButton,
  List,
  ListItem,
  Box,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction
} from '@material-ui/core'
import { Add, Settings } from '@material-ui/icons'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import { doc, collection, updateDoc, query, orderBy } from 'firebase/firestore'

import ColorPicker from './ColorPicker'
import EpisodeCreateDialog from '../EpisodeCreateDialog'
import EpisodesConfigDialog from '../EpisodesConfigDialog'
import generateRandomColor from '../../utils/randomColor'
import defaultSignposts from '../../utils/defaultSignposts'
import episodesActions from '../../redux/actions/episodes'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
    height: 45,
    backgroundColor: '#fff'
  },
  selectedItem: {
    backgroundColor: '#ffffff66 !important'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 5,
    borderRadius: 0,
    backgroundColor: '#cbcbcb',
    height: 45,
    color: '#000',
    borderLeft: '1px solid black',
    '&:hover': {
      backgroundColor: '#939393'
    }
  },
  color_picker: {
    marginLeft: 5,
    border: '1px solid black'
  }
}))

export default function EpisodesInput() {
  const [inputValue, setInputValue] = useState({
    name: '',
    color: '#000000',
    colorChanged: false
  })
  const classes = useStyles()
  const { readOnly } = useSelector((state) => state.auth)
  const firestore = useFirestore()
  const { id: chartId, episodeId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const episodesRef = collection(firestore, 'series', chartId, 'episodes')
  const orderByStartQuery = query(episodesRef, orderBy('startSignpost'))

  const { data } = useFirestoreCollectionData(orderByStartQuery, {
    idField: 'id'
  })

  const addEpisode = () => {
    const newEpisode = {
      signposts: defaultSignposts,
      characters: [],
      draw: [],
      title: inputValue.name,
      color: inputValue.colorChanged ? inputValue.color : generateRandomColor(),
      personalNotes: '',
      questions: {},
      description: '',
      type: 'EPISODE'
    }
    dispatch(episodesActions.setShowCreateModal(true, newEpisode))
    setInputValue({ name: '', color: '#000000' })
  }

  const changeEpisodeColor = async (newColor, id) => {
    try {
      const docuRef = doc(firestore, `series/${chartId}/episodes/${id}`)
      await updateDoc(docuRef, { color: newColor })
    } catch (err) {
      console.log(err, 'error al cambiar color del episodio')
    }
  }

  const goToEpisode = (id) => {
    if (episodeId === id) {
      history.push(`/series/${chartId}`)
    } else {
      history.push(`/series/${chartId}/episode/${id}`)
    }
  }

  return (
    <Paper elevation={0} style={{ backgroundColor: 'transparent' }} square>
      {!readOnly && (
        <Box className={classes.root}>
          <ColorPicker
            onChange={({ hex }) =>
              setInputValue({
                ...inputValue,
                color: hex,
                colorChanged: true
              })
            }
            value={inputValue.color}
          />

          <InputBase
            className={classes.input}
            placeholder="Add episode name..."
            value={inputValue.name}
            onChange={(e) =>
              setInputValue({ ...inputValue, name: e.target.value })
            }
            onKeyPress={(e) =>
              (e.code === 'Enter' || e.code === 'NumpadEnter') && addEpisode()
            }
          />
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={() => dispatch(episodesActions.setShowConfigModal(true))}
          >
            <Settings />
          </IconButton>
          <IconButton
            color="primary"
            className={classes.iconButton}
            onClick={addEpisode}
          >
            <Add />
          </IconButton>
        </Box>
      )}
      <List component="nav" style={{ maxHeight: 380, overflowY: 'auto' }}>
        {data &&
          !!data.length &&
          data.map((episode) => (
            <ListItem
              selected={episodeId === episode.id}
              key={episode.id}
              onClick={() => {
                goToEpisode(episode.id)
              }}
              button
              disableRipple
              classes={{
                selected: classes.selectedItem
              }}
            >
              <ListItemIcon />
              <ListItemText
                style={{ color: 'white' }}
                primary={episode.title || 'Unnamed'}
              />
              <ListItemSecondaryAction
                style={{ left: 15, width: 26, height: 26 }}
              >
                <ColorPicker
                  onChangleComplete={({ hex }, event) => {
                    event.stopPropagation()
                    changeEpisodeColor(hex, episode.id)
                  }}
                  value={episode.color}
                />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
      <EpisodeCreateDialog />
      <EpisodesConfigDialog />
    </Paper>
  )
}
