import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import animationToolsActions from '../../redux/actions/animationTools'
import drawingToolsActions from '../../redux/actions/drawingTools'

const useStyles = makeStyles(() => ({ paper: { top: '60px !important' } }))

export default function ViewMenu() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const openAnimationTools = () => {
    dispatch(animationToolsActions.animationToolsOpen())
    setAnchorEl(null)
  }
  const openDrawingTools = () => {
    dispatch(drawingToolsActions.drawingToolsOpen())
    setAnchorEl(null)
  }

  const openStartupDialog = () => {
    history.push('/welcome')
  }

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen()
    } else if (document.exitFullscreen) {
      document.exitFullscreen()
    }
    setAnchorEl(null)
  }

  return (
    <>
      <Button style={{ color: '#fff' }} onClick={(e) => handleClick(e)}>
        View
      </Button>
      <Menu
        classes={{ paper: classes.paper }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <MenuItem onClick={toggleFullScreen}>
          {document.fullscreenElement ? 'Exit full screen' : 'Full screen'}
        </MenuItem>
        <MenuItem onClick={openAnimationTools}>Animation tools</MenuItem>
        <MenuItem onClick={openDrawingTools}>Drawing tools</MenuItem>
        <MenuItem onClick={openStartupDialog}>Startup dashboard</MenuItem>
      </Menu>
    </>
  )
}
