import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Popover } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { SketchPicker } from 'react-color'

const useStyles = makeStyles(() => ({
  color_picker: {
    marginLeft: 5,
    marginRight: 5,
    border: '1px solid black'
  }
}))

export default function ColorPicker({
  onChangleComplete = (color, e) => e.stopPropagation(),
  onChange = (color, e) => e.stopPropagation(),
  value
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const { readOnly } = useSelector((state) => state.auth)

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        className={classes.color_picker}
        onClick={handleClick}
        disabled={readOnly}
        style={{ backgroundColor: value }}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <SketchPicker
          disableAlpha
          color={value}
          onChange={onChange}
          onChangeComplete={onChangleComplete}
        />
      </Popover>
    </>
  )
}
