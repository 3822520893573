import { useState } from 'react'
import {
  Grid,
  Typography,
  TextField,
  Link,
  InputAdornment,
  IconButton,
  Container
} from '@material-ui/core'
import {
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon
} from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useAuth, useFirestore } from 'reactfire'
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail
} from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'

import { SubmitButton, Dialog } from '../ui'
import authActions from '../../redux/actions/auth'

const validationSchema = yup.object({
  email: yup
    .string('Must be string values')
    .email('Provide a valid email')
    .trim()
    .required('This field is required'),
  password: yup.string().required('This field is required')
})

const errorDict = {
  'auth/user-not-found':
    "We don't have an account associated to this email, please check the email or create an account.",
  'auth/wrong-password': "User name or password doesn't match."
}

const LoginForm = () => {
  const auth = useAuth()
  const firestore = useFirestore()
  const [passwordShow, setPasswordShow] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()
  const { state } = useLocation()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true)
      try {
        const user = await signInWithEmailAndPassword(
          auth,
          values.email,
          values.password
        )
        const userStoreRef = doc(firestore, 'users', user.user.email)
        const userStore = await getDoc(userStoreRef)
        if (userStore.exists()) {
          dispatch(
            authActions.loginSuccess({
              ...userStore.data(),
              uid: user.user.uid
            })
          )
        } else {
          dispatch(authActions.loginSuccess(user.user))
        }
        setIsLoading(false)

        if (state && state.from) {
          history.push(state.from.pathname)
        } else {
          history.push('/welcome')
        }
      } catch (err) {
        setIsLoading(false)
        setError(err.code)
      }
    }
  })

  const handlePasswordRecovery = async () => {
    if (formik.errors.email) {
      formik.setFieldTouched('email', true, true)
    } else {
      try {
        await sendPasswordResetEmail(auth, formik.values.email)
        setOpenDialog(true)
      } catch (err) {
        setError(err.code)
      }
    }
  }

  return (
    <Container maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoFocus
              id="email"
              label="Email"
              size="small"
              name="email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Password"
              name="password"
              size="small"
              type={passwordShow ? 'text' : 'password'}
              variant="outlined"
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPasswordShow((prevState) => !prevState)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {!passwordShow ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography align="center" color="error">
                {errorDict[error]}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <SubmitButton
              isLoading={isLoading}
              type="submit"
              text="Login"
              color="primary"
              variant="contained"
            />
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Link
              component="button"
              type="button"
              color="inherit"
              variant="body2"
              onClick={handlePasswordRecovery}
            >
              Forgot your password?
            </Link>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        title="Password recovery started"
        closable
        divider
        dragglable
      >
        <Typography>
          We just e-mailed you instructions on how to reset your password!
        </Typography>
      </Dialog>
    </Container>
  )
}

export default LoginForm
