import { Typography, TextField, Box } from '@material-ui/core'
import { useSelector } from 'react-redux'

const ParagraphForm = ({
  episodeData,
  formValues,
  handleChange,
  handleBlur
}) => {
  const { readOnly } = useSelector((state) => state.auth)

  return (
    <Box marginTop={3}>
      <Typography align="left">{episodeData.title || 'Untitled'}:</Typography>
      <TextField
        multiline
        fullWidth
        name={episodeData.id}
        variant="outlined"
        value={formValues[episodeData.id]}
        rows={4}
        onChange={handleChange}
        placeholder="Describe what happends here.."
        disabled={readOnly}
        onBlur={handleBlur}
      />
    </Box>
  )
}

export default ParagraphForm
