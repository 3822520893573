import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography, TextField, Box } from '@material-ui/core'
import { Videocam } from '@material-ui/icons'

const ParagraphForm = ({
  title,
  fieldName,
  fieldValue,
  videoUrl,
  handlechange
}) => {
  const [videoOpen, setVideoOpen] = useState(false)
  const { readOnly } = useSelector((state) => state.auth)

  return (
    <Box marginBottom={3} width="100%">
      <Typography variant="body2" align="justify" paragraph>
        <Videocam
          onClick={() => setVideoOpen((prev) => !prev)}
          style={{ cursor: 'pointer' }}
        />
        {'  '}
        {title}
      </Typography>
      <TextField
        multiline
        fullWidth
        name={fieldName}
        id={fieldName}
        variant="outlined"
        rows={4}
        value={fieldValue[fieldName]}
        onChange={handlechange}
        disabled={readOnly}
        placeholder="Describe your story..."
      />
      {videoOpen && (
        <Box
          style={{
            marginTop: 12,
            height: 300,
            width: '100%'
          }}
        >
          <video
            controls
            preload="none"
            style={{ width: '100%', height: '100%' }}
          >
            <source src={videoUrl} type="video/mp4" />
          </video>
        </Box>
      )}
    </Box>
  )
}

export default ParagraphForm
