import { useState } from 'react'
import { Tab, Tabs, Box } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'

import collaborateActions from '../../redux/actions/collaborate'
import { Dialog } from '../ui'
import LinkShare from './LinkShare'
import EmailShare from './EmailShare'

const TabPanel = ({ children, value, index, ...other }) => (
  <div role="tabpanel" hidden={value !== index} {...other}>
    {value === index && <Box p={3}>{children}</Box>}
  </div>
)

const ShareChart = () => {
  const { isOpenModalShareChart } = useSelector((state) => state.collaborate)
  const [value, setValue] = useState('one')
  const dispatch = useDispatch()
  const closeModal = () => {
    dispatch(collaborateActions.shareChartClose())
  }

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Dialog
      open={isOpenModalShareChart}
      onClose={closeModal}
      fullWidth={true}
      maxWidth="sm"
      title="Share your HartChart with collaborators"
      closable
      divider
      dragglable
    >
      <Tabs value={value} onChange={handleTabChange}>
        <Tab value="one" label="Share by email" />
        <Tab value="two" label="Generate a link" />
      </Tabs>
      <TabPanel value={value} index="one">
        <EmailShare />
      </TabPanel>

      <TabPanel value={value} index="two">
        <LinkShare />
      </TabPanel>
    </Dialog>
  )
}

export default ShareChart
