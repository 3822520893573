import { useState } from 'react'
import {
  Grid,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Button
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { useSigninCheck } from 'reactfire'
import { Redirect } from 'react-router-dom'

import { LoginForm, RegisterForm } from '../../components/Login'
import LoadingScreen from '../../components/LoadingScreen'

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '100vh',
    minWidth: '100vw',
    backgroundColor: '#304044'
  },
  title: {
    backgroundColor: '#0087a4'
  },
  form_container: {
    minHeight: 400
  }
}))

const Login = () => {
  const [isRegister, setRegister] = useState(true)
  const { status, data: signInCheckResult } = useSigninCheck()
  const classes = useStyles()

  if (status === 'loading') {
    return <LoadingScreen />
  }

  return signInCheckResult.signedIn ? (
    <Redirect to="/welcome" />
  ) : (
    <Grid
      container
      className={classes.root}
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Card>
        <CardHeader
          className={classes.title}
          action={
            <IconButton
              onClick={() =>
                window.location.assign('https://www.hartchart.com/')
              }
            >
              <CloseIcon style={{ color: 'white' }} />
            </IconButton>
          }
          title={isRegister ? 'Register' : 'Login'}
          style={{ color: 'white' }}
        />
        <CardContent className={classes.form_container}>
          <Grid container>
            <Grid
              item
              container
              xs={12}
              sm={2}
              justifyContent="flex-start"
              alignItems="center"
              direction="column"
            >
              <Button
                variant="contained"
                size="large"
                fullWidth
                style={{ marginBottom: 12 }}
                onClick={() => setRegister(false)}
              >
                Login
              </Button>
              <Button
                variant="contained"
                size="large"
                fullWidth
                onClick={() => setRegister(true)}
              >
                Register
              </Button>
            </Grid>
            <Grid item container xs={12} sm={10}>
              {!isRegister ? <LoginForm /> : <RegisterForm />}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}

export default Login
