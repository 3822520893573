import { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Popper,
  Paper,
  Grid,
  InputBase,
  IconButton,
  Tooltip
} from '@material-ui/core'
import { Delete, Close, LinkOff, Link } from '@material-ui/icons/'
import { useSelector, useDispatch } from 'react-redux'
import { useFirestore } from 'reactfire'
import { doc, updateDoc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import useAddTrackingData from '../../hooks/useAddTrackingData'

import charactersActions from '../../redux/actions/characters'

const useStyles = makeStyles(() => ({
  footerContainer: { backgroundColor: '#595959', height: 28 },
  input: {
    color: '#fff',
    padding: 6,
    '& :disabled': {
      color: '#fff'
    }
  },
  detailsInput: {
    padding: 6,
    '& :disabled': {
      color: '#000'
    }
  },
  icons: {
    color: '#fff',
    fontSize: 20
  }
}))

export default function MarksDetails({ data, handleClick, anchorEl }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { characters } = useSelector((state) => state.characters)
  const { readOnly } = useSelector((state) => state.auth)
  const firestore = useFirestore()
  const { id: chartId, episodeId } = useParams()
  const chartRef = doc(firestore, `/series/${chartId}/episodes/${episodeId}`)
  const [pointData, setPointData] = useState(
    characters[data.characterIndex].points[data.pointIndex]
  )
  const [isTouched, setIsTouched] = useState(false)
  const updateTrackingData = useAddTrackingData()

  const handleRemovePoint = () => {
    const characterCopy = cloneDeep(characters)

    const [character] = characterCopy.splice(data.characterIndex, 1)
    character.points = character.points.filter((item) => item.id !== data.id)
    characterCopy.splice(data.characterIndex, 0, character)
    dispatch(charactersActions.loadCharacters(characterCopy))
    dispatch(charactersActions.selectCharacter(character))
    handleClick()
    updateDoc(chartRef, {
      characters: characterCopy
    })
    updateTrackingData({
      characters: characterCopy,
      action: `Removed "${character.name}" character "${data.header}" event`
    })
  }

  const handleLinkPoint = () => {
    const characterCopy = cloneDeep(characters)

    const [character] = characterCopy.splice(data.characterIndex, 1)

    const pointRef = character.points[data.pointIndex]
    pointRef.skip = !pointRef.skip

    characterCopy.splice(data.characterIndex, 0, character)
    dispatch(charactersActions.loadCharacters(characterCopy))
    dispatch(charactersActions.selectCharacter(character))
    handleClick()
    updateDoc(chartRef, {
      characters: characterCopy
    })
    updateTrackingData({
      characters: characterCopy,
      action: `Unlink "${character.name}" character "${data.header}" event`
    })
    setIsTouched(false)
  }

  const setGraphHeader = (value) => {
    setPointData({ ...pointData, header: value })
    setIsTouched(true)
    dispatch(charactersActions.setGraphPointHeader(data.id, value))
  }

  const setGraphDetails = (value) => {
    setPointData({ ...pointData, description: value })
    setIsTouched(true)
    dispatch(charactersActions.setGraphPointDetails(data.id, value))
  }

  const updatePointsDb = async (e) => {
    const {
      target: { name, value }
    } = e
    await updateDoc(chartRef, {
      characters
    })
    if (isTouched) {
      updateTrackingData({
        characters,
        action: `Updated "${
          characters[data.characterIndex].name
        }" character event ${name} to: "${value}"`
      })
    }
  }

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="top"
      modifiers={{
        flip: {
          enabled: true
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: 'scrollParent'
        }
      }}
      style={{ zIndex: 2, border: '1px solid black' }}
    >
      <Paper>
        <Grid container>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-between"
            style={{
              backgroundColor: characters[data.characterIndex].color
            }}
          >
            <InputBase
              placeholder="Header..."
              value={pointData.header}
              onChange={(e) => setGraphHeader(e.target.value)}
              onBlur={updatePointsDb}
              className={classes.input}
              disabled={readOnly}
              style={{ fontSize: 13, width: '90%', padding: '0 6px' }}
            />
            <IconButton onClick={handleClick} style={{ padding: 6 }}>
              <Close className={classes.icons} />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <InputBase
              placeholder="Details..."
              fullWidth
              multiline
              rows={3}
              className={classes.detailsInput}
              value={pointData.description}
              onChange={(e) => setGraphDetails(e.target.value)}
              onBlur={updatePointsDb}
              disabled={readOnly}
              style={{ fontSize: 14 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="flex-end"
            className={classes.footerContainer}
          >
            {!readOnly && (
              <>
                <Tooltip title={pointData.skip ? 'Link event' : 'Unlink event'}>
                  <IconButton onClick={handleLinkPoint} style={{ padding: 6 }}>
                    {pointData.skip ? (
                      <Link className={classes.icons} />
                    ) : (
                      <LinkOff className={classes.icons} />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={handleRemovePoint}
                    style={{ padding: 6 }}
                  >
                    <Delete className={classes.icons} />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Popper>
  )
}
