import { useState } from 'react'
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Container,
  FormControlLabel,
  Checkbox,
  Link,
  FormHelperText,
  FormControl
} from '@material-ui/core'
import {
  VisibilityOff as VisibilityOffIcon,
  Visibility as VisibilityIcon
} from '@material-ui/icons'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useAuth, useFirestore } from 'reactfire'
import { doc, setDoc, addDoc, collection } from 'firebase/firestore'
import { createUserWithEmailAndPassword } from 'firebase/auth'

import { SubmitButton } from '../ui'
import authActions from '../../redux/actions/auth'
import registerTemplate from '../../templates/registerTemplate'

const validationSchema = yup.object({
  email: yup
    .string('Must be string values')
    .email('Provide a valid email')
    .trim()
    .required('This field is required'),
  password: yup.string().required('This field is required'),
  name: yup.string().required('This field is required'),
  agreeTerms: yup.boolean().oneOf([true], 'Must Accept Terms and Conditions')
})

const errorDict = {
  'auth/email-already-in-use':
    'This user already exists, try to login with these credentials.'
}

const RegisterForm = () => {
  const [passwordShow, setPasswordShow] = useState(false)
  const dispatch = useDispatch()
  const [isLoading, setIsloading] = useState(false)
  const [error, setError] = useState(null)
  const auth = useAuth()
  const firestore = useFirestore()
  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      name: '',
      agreeTerms: false
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsloading(true)
      try {
        const { user } = await createUserWithEmailAndPassword(
          auth,
          values.email,
          values.password
        )
        const newUser = {
          name: values.name,
          email: values.email,
          dashboardOnStartup: true,
          isSuscribed: false,
          lastChart: null,
          uid: user.uid
        }
        await setDoc(doc(firestore, 'users', user.email), newUser)

        dispatch(authActions.loginSuccess({ ...newUser }))
        await addDoc(collection(firestore, 'mail'), {
          to: values.email,
          from: 'HartChart <noreply.hartchart@gmail.com>',
          message: {
            subject: 'Welcome to the hartchart!',
            html: registerTemplate(values.name),
            attachments: [
              {
                filename: 'USING THE HARTCHART TOOL OR HOW TO TYPE THE END.pdf',
                path: 'https://firebasestorage.googleapis.com/v0/b/hartchart-3d356.appspot.com/o/public%2FUSING%20THE%20HARTCHART%20TOOL%20OR%20HOW%20TO%20TYPE%20THE%20END.pdf?alt=media'
              }
            ]
          }
        })
        setIsloading(false)
        history.push('/welcome')
      } catch (err) {
        setIsloading(false)
        setError(err.code)
      }
    }
  })

  return (
    <Container maxWidth="xs">
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12}>
            <Typography align="justify" variant="body2">
              To subscribe to HartChart app, first register a new account here.
              Once in the Dashboard, click on{' '}
              <strong>
                Purchase the HartChart and get 15 days of free trial!
              </strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoFocus
              id="email"
              required
              size="small"
              label="Email"
              name="email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              size="small"
              label="Password"
              name="password"
              type={passwordShow ? 'text' : 'password'}
              variant="outlined"
              id="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setPasswordShow((prevState) => !prevState)}
                      onMouseDown={(e) => e.preventDefault()}
                    >
                      {!passwordShow ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="name"
              required
              size="small"
              label="Name"
              name="name"
              variant="outlined"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl required>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={formik.values.agreeTerms}
                    onChange={formik.handleChange}
                    name="agreeTerms"
                    color="primary"
                  />
                }
                label={
                  <Typography>
                    I agree to the{' '}
                    <Link
                      href="https://www.writerduet.com/terms"
                      rel="noreferrer"
                      target="_blank"
                      color="inherit"
                      style={{ textDecoration: 'underline' }}
                    >
                      Terms of Use
                    </Link>
                  </Typography>
                }
              />

              <FormHelperText error>
                {formik.touched.agreeTerms && formik.errors.agreeTerms}
              </FormHelperText>
            </FormControl>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Typography align="center" color="error">
                {errorDict[error]}
              </Typography>
            </Grid>
          )}
          <Grid item>
            <SubmitButton
              isLoading={isLoading}
              type="submit"
              text="Register"
              color="primary"
              variant="contained"
            />
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}

export default RegisterForm
