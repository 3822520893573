import { useEffect, useState } from 'react'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useSigninCheck, useFirestore } from 'reactfire'
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs
} from 'firebase/firestore'
import { addDays } from 'date-fns'

import authActions from '../../redux/actions/auth'
import appActions from '../../redux/actions/app'
import LoadingScreen from '../LoadingScreen'

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const [isLoading, setIsLoading] = useState(true)
  const { subscription } = useSelector((state) => state.app)
  const { data: signInCheckResult } = useSigninCheck()
  const firestore = useFirestore()
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    const fetch = async () => {
      const { user } = signInCheckResult

      const userStoreRef = doc(firestore, 'users', user.email)
      const userStore = await getDoc(userStoreRef)
      const userData = userStore.data()
      if (userData) {
        dispatch(
          authActions.loginSuccess({
            ...userData,
            uid: user.uid
          })
        )
      }

      const subscriptionRef = collection(
        firestore,
        'customers',
        user.uid,
        'subscriptions'
      )
      const queryActiveSubs = query(
        subscriptionRef,
        where('status', 'in', ['trialing', 'active'])
      )
      const subsData = await getDocs(queryActiveSubs)

      if (subsData.empty) {
        if (userData.isAdmin) {
          dispatch(
            appActions.setSubsData({
              active: true,
              status: 'active',
              start: null,
              end: null,
              accountType: 'ADMIN'
            })
          )
        } else if (userData.oldAppSubscriber) {
          const start = new Date(user.metadata.creationTime)
          const end = userData.subscribedUntil.toDate()

          const isActive = Date.now() <= end
          dispatch(
            appActions.setSubsData({
              active: isActive,
              status: isActive ? 'active' : 'unsubscribed',
              start,
              end,
              accountType: isActive ? 'SUBSCRIBED' : 'FREE'
            })
          )
        } else {
          const startDateObject = new Date(user.metadata.creationTime)
          const endDateObject = addDays(startDateObject, 1)
          const isActive = Date.now() <= endDateObject
          dispatch(
            appActions.setSubsData({
              active: isActive,
              status: isActive ? 'active' : 'unsubscribed',
              start: startDateObject,
              end: endDateObject,
              accountType: 'FREE'
            })
          )
        }
      }

      subsData.forEach((data) => {
        const formatedData = data.data()
        dispatch(
          appActions.setSubsData({
            active: true,
            status: formatedData.status,
            start: formatedData.current_period_start.toDate(),
            end: formatedData.current_period_end.toDate(),
            accountType: 'SUBSCRIBED'
          })
        )
      })
      setIsLoading(false)
    }
    if (signInCheckResult && signInCheckResult.user) {
      fetch()
    }
    if (signInCheckResult && !signInCheckResult.signedIn) {
      setIsLoading(false)
    }
  }, [signInCheckResult])

  useEffect(() => {
    if (!subscription.active && !location.pathname.includes('/share')) {
      history.replace('/welcome')
    }
  }, [subscription, location.pathname])

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        signInCheckResult.signedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  )
}

export default ProtectedRoute
