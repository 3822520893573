const episodesTypes = {
  ADD_EPISODE: 'ADD_EPISODE',
  SELECT_EPISODE: 'SELECT_EPISODE',
  REMOVE_EPISODE: 'REMOVE_EPISODE',
  FULL_SEASON_VIEW: 'FULL_SEASON_VIEW',
  CHANGE_EPISODE_COLOR: 'CHANGE_EPISODE_COLOR',
  SHOW_MODAL: 'SHOW_MODAL',
  SHOW_CONFIG_MODAL: 'SHOW_CONFIG_MODAL',
  CHANGE_EPISODE_NAME: 'CHANGE_EPISODE_NAME'
}

export default episodesTypes
