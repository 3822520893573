import { Paper, Box, IconButton, TextField, Tooltip } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab/'
import { Close } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import Draggable from 'react-draggable'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMousePointer,
  faEraser,
  faPhotoVideo,
  faIcons,
  faPencilAlt,
  faPaintBrush,
  faMinus,
  faArrowRight,
  faCircle
} from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'

import charactersActions from '../../redux/actions/characters'
import drawingToolsActions from '../../redux/actions/drawingTools'
import multiarrow from '../../assets/img/multiarrow.svg'
import tensionLine from '../../assets/img/tensionLine.svg'

const useStyles = makeStyles(() => ({
  title: {
    backgroundColor: '#005669',
    color: 'white',
    padding: '0 2px',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'move'
  },
  closeIcon: { padding: 0, color: 'white' },
  fontIcons: { fontSize: 18, verticalAlign: 'middle' }
}))

export default function DrawingTools() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { characters, selectedCharacter } = useSelector(
    (state) => state.characters
  )
  const { tool, color } = useSelector((state) => state.drawingTools)

  const tools = [
    {
      value: 'SELECT',
      label: 'Select',
      icon: (
        <FontAwesomeIcon className={classes.fontIcons} icon={faMousePointer} />
      )
    },
    {
      value: 'PENCIL',
      label: 'Pencil',
      icon: <FontAwesomeIcon className={classes.fontIcons} icon={faPencilAlt} />
    },
    {
      value: 'BRUSH',
      label: 'Brush',
      icon: (
        <FontAwesomeIcon className={classes.fontIcons} icon={faPaintBrush} />
      )
    },
    {
      value: 'SIMPLE_LINE',
      label: 'Line',
      icon: (
        <FontAwesomeIcon
          className={classes.fontIcons}
          style={{ transform: 'rotate(313deg)', fontSize: 24 }}
          icon={faMinus}
        />
      )
    },
    {
      value: 'TENSION_ARROW',
      label: 'Tension arrow',
      icon: <img style={{ width: 25, height: 25 }} src={tensionLine} />
    },
    {
      value: 'ARROW',
      label: 'Arrow',
      icon: (
        <FontAwesomeIcon
          className={classes.fontIcons}
          style={{ transform: 'rotate(313deg)', fontSize: 24 }}
          icon={faArrowRight}
        />
      )
    },
    {
      value: 'MULTI_ARROW',
      label: 'Multi arrow line',
      icon: <img style={{ width: 25, height: 25 }} src={multiarrow} />
    },
    {
      value: 'CIRCLE',
      label: 'Circle',
      icon: <FontAwesomeIcon className={classes.fontIcons} icon={faCircle} />
    },
    {
      value: 'MEDIA',
      label: 'Media link',
      icon: (
        <FontAwesomeIcon className={classes.fontIcons} icon={faPhotoVideo} />
      )
    },
    {
      value: 'MEDIA_ICONS',
      label: 'Icon pack',
      icon: <FontAwesomeIcon className={classes.fontIcons} icon={faIcons} />
    },
    {
      value: 'ERASER',
      label: 'Eraser',
      icon: <FontAwesomeIcon className={classes.fontIcons} icon={faEraser} />
    }
  ]

  const handleChange = (e) => {
    const { value } = e.target
    if (value !== 'ALL') {
      const character = characters.find((item) => item.id === value)
      dispatch(charactersActions.selectCharacter(character))
    } else {
      dispatch(charactersActions.selectCharacter({}))
    }
  }

  const handleWidgetsChange = (e, value) => {
    if (value && value !== 'COLOR') {
      if (value === 'MEDIA_ICONS') {
        dispatch(drawingToolsActions.setMultimediaTab('ICONS'))
      }
      if (value === 'MEDIA') {
        dispatch(drawingToolsActions.setMultimediaTab('URL_INSERT'))
      }
      dispatch(drawingToolsActions.chooseTool(value))
    }
  }

  const closeDrawingTools = () => {
    dispatch(drawingToolsActions.drawingToolsClose())
  }

  return (
    <Draggable cancel="select" handle=".handle" scale={1} bounds="body">
      <Paper style={{ position: 'absolute', top: 0, right: '5%', zIndex: 1 }}>
        <Box className={clsx('handle', classes.title)}>
          <TextField
            select
            margin="none"
            size="small"
            SelectProps={{
              native: true,
              style: { fontSize: 14, width: 200 }
            }}
            style={{ backgroundColor: 'white' }}
            value={selectedCharacter.id}
            onChange={handleChange}
          >
            <option value="ALL">Draw for all characters</option>
            {characters.map((item) => (
              <option key={item.id} value={item.id}>
                Draw for {item.name || 'Unnamed'}
              </option>
            ))}
          </TextField>
          <IconButton className={classes.closeIcon} onClick={closeDrawingTools}>
            <Close />
          </IconButton>
        </Box>
        <div>
          <ToggleButtonGroup
            value={tool}
            exclusive
            onChange={handleWidgetsChange}
          >
            {tools.map((item, index) => (
              <ToggleButton
                key={`${item.value}-${index}`}
                value={item.value}
                size="small"
                style={{ color: 'black' }}
              >
                <Tooltip title={item.label}>
                  <span style={{ width: 24, height: 24 }}>{item.icon}</span>
                </Tooltip>
              </ToggleButton>
            ))}
            <Tooltip title="Change drawings colors">
              <ToggleButton value="COLOR" size="small">
                <input
                  type="color"
                  value={color}
                  onChange={(e) =>
                    dispatch(drawingToolsActions.changeColor(e.target.value))
                  }
                />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
        </div>
      </Paper>
    </Draggable>
  )
}
