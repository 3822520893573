import { useState, useEffect } from 'react'
import { Grid, Typography, Button, Box, Tooltip } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import { collection, addDoc, query, orderBy } from 'firebase/firestore'
import { cloneDeep, isNull } from 'lodash'

import episodesActions from '../../redux/actions/episodes'
import { Dialog } from '../ui'

const EpisodeCreateDialog = () => {
  const { signposts } = useSelector((state) => state.signposts)
  const { characters } = useSelector((state) => state.characters)
  const { createModal, episodeToCreate } = useSelector(
    (state) => state.episodes
  )
  const [firstSelection, setFirstSelection] = useState(null)
  const [secondSelection, setSecondSelection] = useState(null)
  const [signpostToShow, setSignpostToShow] = useState([])
  const firestore = useFirestore()
  const { id: chartId } = useParams()
  const dispatch = useDispatch()
  const episodesRef = collection(firestore, 'series', chartId, 'episodes')
  const orderByStartQuery = query(episodesRef, orderBy('startSignpost'))

  const { data } = useFirestoreCollectionData(orderByStartQuery, {
    idField: 'id'
  })

  useEffect(() => {
    if (data) {
      const clonedSignpost = cloneDeep(signposts)
      data.forEach((ep) => {
        clonedSignpost.forEach((sp, index) => {
          if (index >= ep.startSignpost && index <= ep.finishSignpost) {
            sp.hasEpisode = true
            sp.episodeTitle = ep.title
          }
        })
      })
      setSignpostToShow(clonedSignpost)
    }
  }, [data, signposts])

  const handleClose = () => {
    dispatch(episodesActions.setShowCreateModal(false, null))
    setFirstSelection(null)
    setSecondSelection(null)
  }

  const createEpisode = async () => {
    handleClose()
    await addDoc(collection(firestore, 'series', chartId, 'episodes'), {
      ...episodeToCreate,
      characters: characters.map((item) => ({
        ...item,
        points: [],
        colorOpacity: 1,
        descriptionCQ: {}
      })),
      startSignpost: Math.min(
        firstSelection,
        isNull(secondSelection) ? 99999 : secondSelection
      ),
      finishSignpost: Math.max(firstSelection, secondSelection)
    })
  }

  const setEpisodeRange = (index) => {
    if (firstSelection === index) {
      setFirstSelection(null)
      return undefined
    }
    if (secondSelection === index) {
      setSecondSelection(null)
      return undefined
    }
    if (isNull(firstSelection)) {
      setFirstSelection(index)
      return undefined
    }

    setSecondSelection(index)

    return undefined
  }

  const selectedSignpostStyles = (sp, index) => {
    const baseStyles = {
      backgroundColor: index % 2 !== 0 ? '#efefef' : '#fafafa',
      cursor: 'pointer',
      height: 200
    }
    if (sp.hasEpisode) {
      return {
        ...baseStyles,
        backgroundColor: index % 2 !== 0 ? '#e0151575' : '#e015155e',
        cursor: 'not-allowed'
      }
    }
    if (firstSelection === index) {
      return { ...baseStyles, backgroundColor: '#7a86c857', cursor: 'pointer' }
    }
    if (secondSelection === index) {
      return { ...baseStyles, backgroundColor: '#7a86c8d1', cursor: 'pointer' }
    }
    return baseStyles
  }

  return (
    <Dialog
      open={createModal}
      onClose={handleClose}
      title="Select the range ofyour episode!"
      maxWidth="xl"
      closable
      divider
      dragglable
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5" paragraph align="center">
            Select the signpost where your episode will start and end.
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          style={{ flexFlow: 'nowrap', overflow: 'auto' }}
        >
          {signpostToShow.map((sp, index) => (
            <Tooltip
              arrow
              placement="top"
              key={sp.key}
              title={sp.hasEpisode ? sp.episodeTitle : 'Click to select'}
            >
              <Box
                style={selectedSignpostStyles(sp, index)}
                onClick={() => !sp.hasEpisode && setEpisodeRange(index)}
              >
                <Box
                  style={{
                    backgroundColor: sp.bgColor,
                    width: 130,
                    height: 50,
                    padding: 5
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    align="center"
                    style={{ color: 'white', fontWeight: 'bold' }}
                  >
                    {sp.name}
                  </Typography>
                </Box>
              </Box>
            </Tooltip>
          ))}
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            disabled={isNull(firstSelection)}
            onClick={createEpisode}
            style={{ marginTop: 18 }}
          >
            Create episode
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default EpisodeCreateDialog
