import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'

import DashboardDialog from '../../components/Dashboard'
import PurchaseDialog from '../../components/PurchaseDialog'
import ImportDialog from '../../components/ImportDialog'
import MyCharts from '../../components/MyCharts'
import dashboardActions from '../../redux/actions/dashboard'

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
    width: '100vw',
    backgroundColor: 'black',
    opacity: 0.8
  }
}))

const Welcome = () => {
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    dispatch(dashboardActions.dashboardOpen())
  }, [])

  return (
    <div className={classes.root}>
      <DashboardDialog />
      <PurchaseDialog />
      <ImportDialog />
      <MyCharts />
    </div>
  )
}

export default Welcome
